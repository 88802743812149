import {
  HOME_ROUTE,
  LOG_OUT_ROUTE,
  MAINTENANCE_CHANGE_ACCOUNT_NUMBER_ROUTE,
  MAINTENANCE_CREW_WAGES_ROUTE,
  MAINTENANCE_DEFINE_VARIOUS_TABLES_ROUTE,
  MAINTENANCE_EXCHANGE_RATE_PER_VESSEL_ROUTE,
  MAINTENANCE_GOVT_CONTRIBUTION_ENTRY_ROUTE,
  MAINTENANCE_VESSEL_PARAMETER_SETUP_ROUTE,
  REPORT_PAYSLIP_PER_VESSEL_ROUTE,
  REPORT_PAYSLIP_PER_CREW_ROUTE,
  REPORT_FINAL_PAY_ROUTE,
  TRANSACTION_CREW_MASTERFILE_ROUTE,
  TRANSACTION_FINAL_PAY_ROUTE,
  TRANSACTION_PAYROLL_PROCESS_ROUTE,
  SYSTEM_SETTINGS_USER_SETUP,
  SYSTEM_SETTINGS_ACCESS_LEVEL_SETUP,
  REPORT_BANK_DOWNLOADING_ROUTE,
} from '../../utils/router/routes';

import { RxDashboard } from 'react-icons/rx';
import { HiOutlineDocumentReport, HiOutlineLogout } from 'react-icons/hi';
import { GiProcessor, GiSettingsKnobs } from 'react-icons/gi';
import { GrVmMaintenance } from 'react-icons/gr';
import { IoMdSettings } from 'react-icons/io';
import { useContext } from 'react';
import { UserContext } from '../../utils/context/UserContext';

const dashboardMenuNavigation = {
  title: 'Dashboard',
  icon: <RxDashboard className='me-2' size={20} />,
  path: HOME_ROUTE,
  access_key: '',
};

const logoutNavigation = {
  title: 'Logout',
  icon: <HiOutlineLogout className='me-2' size={20} />,
  path: LOG_OUT_ROUTE,
  access_key: 'log_out',
};

const transactionSubMenuNavigation = {
  id: '1',
  title: 'Transaction',
  icon: <GiProcessor className='me-2' size={20} />,
  access_key: 'main_transaction',
  items: [
    // {
    //   title: 'Crew Masterfile',
    //   icon: <RxDashboard className='me-2' size={20} />,
    //   path: TRANSACTION_CREW_MASTERFILE_ROUTE,
    //   access_key: '',
    // },
    {
      title: 'Payroll Process',
      icon: <RxDashboard className='me-2' size={20} />,
      path: TRANSACTION_PAYROLL_PROCESS_ROUTE,
      access_key: 'tran_payroll_process',
    },
    {
      title: 'Final Pay',
      icon: <RxDashboard className='me-2' size={20} />,
      path: TRANSACTION_FINAL_PAY_ROUTE,
      access_key: 'tran_payroll_process',
    },
  ],
};

const reportSubMenuNavigation = {
  id: '2',
  title: 'Reports',
  icon: <HiOutlineDocumentReport className='me-2' size={20} />,
  access_key: 'main_reports',
  items: [
    {
      title: 'Crew Payslip',
      icon: <RxDashboard className='me-2' size={20} />,
      path: REPORT_PAYSLIP_PER_CREW_ROUTE,
      access_key: 'reports_crew_payslips',
    },
    {
      title: 'Vessel Payslip',
      icon: <RxDashboard className='me-2' size={20} />,
      path: REPORT_PAYSLIP_PER_VESSEL_ROUTE,
      access_key: 'reports_vessel_payslips',
    },
    {
      title: 'Bank Downloading',
      icon: <RxDashboard className='me-2' size={20} />,
      path: REPORT_BANK_DOWNLOADING_ROUTE,
      access_key: 'reports_bank_downloading',
    },
  ],
};

const maintenanceSubMenuNavigation = {
  id: '3',
  title: 'Maintenance',
  icon: <GiSettingsKnobs className='me-2' size={20} />,
  access_key: 'main_maintenance',
  items: [
    {
      title: 'Vessel Parameter Setup',
      icon: <RxDashboard className='me-2' size={20} />,
      path: MAINTENANCE_VESSEL_PARAMETER_SETUP_ROUTE,
      access_key: 'maintenance_vessel_parameters',
    },
    {
      title: 'Define various Tables',
      icon: <RxDashboard className='me-2' size={20} />,
      path: MAINTENANCE_DEFINE_VARIOUS_TABLES_ROUTE,
      access_key: 'maintenance_dvt',
    },
    {
      title: 'Govt Contribution Entry',
      icon: <RxDashboard className='me-2' size={20} />,
      path: MAINTENANCE_GOVT_CONTRIBUTION_ENTRY_ROUTE,
      access_key: 'maintenance_govt_contri',
    },
    {
      title: 'Exchange Rate per Vessel',
      icon: <RxDashboard className='me-2' size={20} />,
      path: MAINTENANCE_EXCHANGE_RATE_PER_VESSEL_ROUTE,
      access_key: 'maintenance_exchange_rate',
    },
    {
      title: 'Crew Wages',
      icon: <RxDashboard className='me-2' size={20} />,
      path: MAINTENANCE_CREW_WAGES_ROUTE,
      access_key: 'maintenance_crew_wages',
    },
    // {
    //   title: 'Change Account Number',
    //   icon: <RxDashboard className='me-2' size={20} />,
    //   path: MAINTENANCE_CHANGE_ACCOUNT_NUMBER_ROUTE,
    //   access_key: '',
    // },
  ],
};

const systemSettingsSubMenuNavigation = {
  id: '4',
  title: 'System Setup',
  icon: <IoMdSettings className='me-2' size={20} />,
  access_key: 'main_system_setup',
  items: [
    {
      title: 'User Setup',
      icon: <RxDashboard className='me-2' size={20} />,
      path: SYSTEM_SETTINGS_USER_SETUP,
      access_key: 'system_setup_user_setup',
    },
    {
      title: 'Access Level Setup',
      icon: <RxDashboard className='me-2' size={20} />,
      path: SYSTEM_SETTINGS_ACCESS_LEVEL_SETUP,
      access_key: 'system_setup_access_level_setup',
    },
  ],
};

export {
  dashboardMenuNavigation,
  logoutNavigation,
  transactionSubMenuNavigation,
  reportSubMenuNavigation,
  maintenanceSubMenuNavigation,
  systemSettingsSubMenuNavigation,
};
