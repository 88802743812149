import RemainingWagesType from '../types/RemainingWagesType';

export const getRemainingWagesDeductionFields = (
  isLoading,
  state,
  setState,
  setHasChanges,
  deductionOptions
) => {
  return [
    {
      type: 'dropdown',
      name: 'deduction_id',
      placeholder: 'Deduction ID',
      label: 'Deduction ID',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.deduction_id,
      dropdownOptions: deductionOptions,
      setValue: (value, option) => {
        setState({
          ...state,
          deduction_id: value,
          desc: option?.children,
        });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'desc',
      placeholder: 'Description',
      label: 'Description',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.desc,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, desc: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'deduction_amount',
      placeholder: 'Amount',
      label: 'Amount',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.amount_php,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, deduction_amount: value });
        setHasChanges(true);
      },
    },
  ];
};
