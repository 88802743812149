import React, { useContext } from 'react';
import {
  PDFViewer,
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from '@react-pdf/renderer';
import HomeAllotmentHeaderType from '../../utils/types/HomeAllotmentHeaderType';
import dayjs from 'dayjs';
import logoImage from '../../assets/icon-name.png';
import { UserContext } from '../../utils/context/UserContext';

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 10,
    paddingBottom: 20,
  },
  container: {
    flex: 1,
    alignItems: 'center',
  },
  headerImage: {
    height: 60,
    width: 60,
  },
  headerText: {
    fontSize: 6,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
  },
  tableRow: {
    flexDirection: 'row',
    borderTopWidth: 0.5,
    paddingBottom: 0,
  },
  tableCell: {
    width: '6%',
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    paddingTop: 5,
    paddingBottom: 0,
    paddingHorizontal: 1,
    fontSize: 4,
    marginBottom: 0,
  },

  pageNumber: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 10,
    fontSize: 6,
    textAlign: 'left',
    paddingHorizontal: 10,
  },
  legends: {
    //   position: 'absolute',
    //   bottom: 30,
    //   left: 80,
    //   right: 0,
    fontSize: 8,
    textAlign: 'left',
  },

  row: { flexDirection: 'row', margin: 0, padding: 0 },
  column: {
    flex: 1,
    margin: 10,
  },

  insideRow: {
    flexDirection: 'row',
    margin: 0,
    padding: 0,
  },
  insideColumn: {
    flex: 1,
    margin: 0,
    padding: 0,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    // borderTopWidth: 1,
    // textAlign: 'center',
    // backgroundColor: 'lightgray',
    paddingTop: 10,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
});

export default function CrewWagesUKWagesPDF({
  crewList,
  earningsHeader,
  selectedVessel,
  month,
  year,
}) {
  const { inputToLocale, generateFiveNumber } = useContext(UserContext);

  const getEarningValue = (header, earnings) => {
    let amount = 0;
    const record = earnings.find(
      (earning) => earning.salary_code == header.salary_code
    );
    if (record) {
      amount = record.amount;
    }
    return parseFloat(amount) != 0 ? inputToLocale(amount) : '';
  };

  const parseValue = (value) => {
    if (parseFloat(value) != 0) {
      return inputToLocale(value);
    } else {
      return '';
    }
  };

  return (
    <PDFViewer style={{ width: '98%', height: '100vh' }}>
      <Document>
        <Page size="LEGAL" style={styles.page} orientation="landscape" wrap>
          <View style={{ ...styles.row, marginBottom: -15 }} fixed>
            <View
              style={{
                ...styles.column,
                alignItems: 'flex-end',
                paddingVertical: 0,
                marginRight: 0,
                marginTop: 4,
              }}
            >
              <Image src={logoImage} style={styles.headerImage} />
            </View>
            <View style={{ ...styles.column, paddingTop: 28, marginLeft: 1 }}>
              <Text style={styles.headerText}>SCHEDULE OF UK WAGES</Text>
              <Text style={styles.headerText}>
                {`(${selectedVessel.vessel_code}) ${selectedVessel.vessel_name}`}
              </Text>
              <Text style={styles.headerText}>
                {`${dayjs(`${month}-${year}`, 'M-YYYY')
                  .format('MMMM')
                  .toUpperCase()} 1-${dayjs(`${month}-${year}`, 'M-YYYY')
                  .endOf('month')
                  .format('DD, YYYY')}`}
              </Text>
            </View>
          </View>
          <View style={styles.table}>
            <View style={{ ...styles.tableRow }}>
              <View style={{ ...styles.tableCell, width: '1%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '7%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '7%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '3%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '3%' }}>
                <Text></Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: `${earningsHeader.length * 3}%`,
                }}
              >
                <Text>EARNINGS</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '2%' }}>
                <Text></Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: `22%`,
                }}
              >
                <Text>PHP WAGE</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: '9%',
                }}
              >
                <Text>UK WAGE</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: '2%',
                }}
              >
                <Text></Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  width: '3%',
                }}
              >
                <Text></Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  borderRight: 0.5,
                  width: '6%',
                }}
              >
                <Text>COLLECTIBLE</Text>
              </View>
            </View>
            <View style={{ ...styles.tableRow, borderBottomWidth: 0.5 }} fixed>
              <View style={{ ...styles.tableCell, width: '1%' }}>
                <Text>NO.</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '7%' }}>
                <Text>CREW NAME</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '7%' }}>
                <Text>POSITION</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '3%' }}>
                <Text>SIGN ON</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '3%' }}>
                <Text>SIGN OFF</Text>
              </View>
              {earningsHeader.map((earning, index) => (
                <View
                  key={index}
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    borderLeft: index == 0 ? 0.5 : 0,
                    width: '3%',
                  }}
                >
                  <Text>{earning.wage_other_desc}</Text>
                </View>
              ))}
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  width: '2%',
                  backgroundColor: '#c5c9c7',
                }}
              >
                <Text>TOTAL WAGES</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: '3%',
                }}
              >
                <Text>MONTHLY WAGES (EXCLUDING FOT/GOT)</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: '3%',
                }}
              >
                <Text>EXCESS OT RATE/HOUR</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: '3%',
                }}
              >
                <Text>MONTHLY FIXED / GUARRANTEED OVERTIME</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: '3%',
                }}
              >
                <Text>PRORATE WAGE {'\n'}(EXCLUDING FOT/GOT)</Text>
                <View style={{ ...styles.insideRow }}>
                  <View
                    style={{
                      ...styles.insideColumn,
                    }}
                  >
                    <Text style={{ fontSize: 3, margin: 0, padding: 0 }}>
                      HRS
                    </Text>
                  </View>

                  <View style={{ ...styles.insideColumn }}>
                    <Text style={{ fontSize: 3 }}>USD</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: '3%',
                }}
              >
                <Text>PRORATE FIXED / GUARANTEED OVERTIME</Text>
                <View style={{ ...styles.insideRow }}>
                  <View
                    style={{
                      ...styles.insideColumn,
                    }}
                  >
                    <Text style={{ fontSize: 3, margin: 0, padding: 0 }}>
                      HRS
                    </Text>
                  </View>

                  <View style={{ ...styles.insideColumn }}>
                    <Text style={{ fontSize: 3 }}>USD</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: '3%',
                }}
              >
                <Text>EXCESS OVERTIME</Text>
                <View style={{ ...styles.insideRow }}>
                  <View
                    style={{
                      ...styles.insideColumn,
                    }}
                  >
                    <Text style={{ fontSize: 3, margin: 0, padding: 0 }}>
                      HRS
                    </Text>
                  </View>

                  <View style={{ ...styles.insideColumn }}>
                    <Text style={{ fontSize: 3 }}>USD</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: '4%',
                }}
              >
                <Text>TOTAL</Text>
                <View style={{ ...styles.insideRow }}>
                  <View
                    style={{
                      ...styles.insideColumn,
                    }}
                  >
                    <Text style={{ fontSize: 3, margin: 0, padding: 0 }}>
                      HRS
                    </Text>
                  </View>

                  <View style={{ ...styles.insideColumn }}>
                    <Text style={{ fontSize: 3 }}>USD</Text>
                  </View>
                  <View style={{ ...styles.insideColumn }}>
                    <Text style={{ fontSize: 3 }}>GBP</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: '3%',
                }}
              >
                <Text>HRS</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: '3%',
                }}
              >
                <Text>GBP{parseValue(crewList?.gb_hrs)}/HR</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: '3%',
                }}
              >
                <Text>USD</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: '2%',
                }}
              >
                <Text>NO OF DAYS</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: '3%',
                }}
              >
                <Text>
                  ACCOM OFFSET GBP{parseValue(crewList?.gbp_days)}/DAY
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: '3%',
                }}
              >
                <Text>GBP</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  borderRight: 0.5,
                  width: '3%',
                }}
              >
                <Text>USD</Text>
              </View>
            </View>
            {crewList?.crew_list?.map((crew, index) => (
              <View
                key={index * generateFiveNumber()}
                style={{
                  ...styles.tableRow,
                  borderBottom:
                    index + 1 == crewList?.crew_list?.length ? 0.5 : 0,
                }}
              >
                <View style={{ ...styles.tableCell, width: '1%' }}>
                  <Text>{index + 1}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: '7%' }}>
                  <Text>{crew.crew_name}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: '7%' }}>
                  <Text>{crew.position}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: '3%' }}>
                  <Text>{crew.sign_on}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: '3%' }}>
                  <Text>{crew.sign_off}</Text>
                </View>
                {earningsHeader.map((header, ind) => (
                  <View
                    key={`${ind * generateFiveNumber()}`}
                    style={{
                      ...styles.tableCell,
                      textAlign: 'right',
                      borderLeft: ind == 0 ? 0.5 : 0,
                      width: '3%',
                    }}
                  >
                    <Text>{getEarningValue(header, crew.earnings)}</Text>
                  </View>
                ))}
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    backgroundColor: '#c5c9c7',
                    width: '2%',
                  }}
                >
                  <Text>{parseValue(crew.total)}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '3%',
                    borderLeft: 0.5,
                  }}
                >
                  <Text>{parseValue(crew.total_excluding_ot)}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '3%',
                    borderLeft: 0.5,
                  }}
                >
                  <Text>{parseValue(crew.excess_ot)}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '3%',
                    borderLeft: 0.5,
                  }}
                >
                  <Text>{parseValue(crew.fixed_ot)}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '3%',
                    borderLeft: 0.5,
                  }}
                >
                  <View style={{ ...styles.insideRow }}>
                    <View
                      style={{
                        ...styles.insideColumn,
                      }}
                    >
                      <Text style={{ fontSize: 3, margin: 0, padding: 0 }}>
                        {parseValue(crew.prorate_exclu_ot_hrs)}
                      </Text>
                    </View>

                    <View style={{ ...styles.insideColumn }}>
                      <Text style={{ fontSize: 3 }}>
                        {parseValue(crew.prorate_exclu_ot_usd)}
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '3%',
                    borderLeft: 0.5,
                  }}
                >
                  <View style={{ ...styles.insideRow }}>
                    <View
                      style={{
                        ...styles.insideColumn,
                      }}
                    >
                      <Text style={{ fontSize: 3, margin: 0, padding: 0 }}>
                        {parseValue(crew.prorate_fot_hrs)}
                      </Text>
                    </View>

                    <View style={{ ...styles.insideColumn }}>
                      <Text style={{ fontSize: 3 }}>
                        {parseValue(crew.prorate_fot_usd)}
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '3%',
                    borderLeft: 0.5,
                  }}
                >
                  <View style={{ ...styles.insideRow }}>
                    <View
                      style={{
                        ...styles.insideColumn,
                      }}
                    >
                      <Text style={{ fontSize: 3, margin: 0, padding: 0 }}>
                        {parseValue(crew.excess_ot_hrs)}
                      </Text>
                    </View>

                    <View style={{ ...styles.insideColumn }}>
                      <Text style={{ fontSize: 3 }}>
                        {parseValue(crew.excess_ot_usd)}
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '4%',
                    borderLeft: 0.5,
                  }}
                >
                  <View style={{ ...styles.insideRow }}>
                    <View
                      style={{
                        ...styles.insideColumn,
                      }}
                    >
                      <Text style={{ fontSize: 3, margin: 0, padding: 0 }}>
                        {parseValue(crew.total_hrs)}
                      </Text>
                    </View>

                    <View style={{ ...styles.insideColumn }}>
                      <Text style={{ fontSize: 3 }}>
                        {parseValue(crew.total_usd)}
                      </Text>
                    </View>
                    <View style={{ ...styles.insideColumn }}>
                      <Text style={{ fontSize: 3 }}>
                        {parseValue(crew.total_gbp)}
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '3%',
                    borderLeft: 0.5,
                  }}
                >
                  <Text>{parseValue(crew.uk_wage_hrs)}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '3%',
                    borderLeft: 0.5,
                  }}
                >
                  <Text>{parseValue(crew.uk_wage_gbp)}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '3%',
                    borderLeft: 0.5,
                  }}
                >
                  <Text>{parseValue(crew.uk_wage_usd)}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '2%',
                    borderLeft: 0.5,
                  }}
                >
                  <Text>{parseInt(crew.no_of_days)}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '3%',
                    borderLeft: 0.5,
                  }}
                >
                  <Text>{parseInt(crew.accom_offset)}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '3%',
                    borderLeft: 0.5,
                  }}
                >
                  <Text>{parseInt(crew.coll_gbp)}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '3%',
                    borderLeft: 0.5,
                    borderRight: 0.5,
                  }}
                >
                  <Text>{parseInt(crew.coll_usd)}</Text>
                </View>
              </View>
            ))}
          </View>
          <View style={styles.footer} wrap={false}>
            <View style={{ ...styles.row, paddingLeft: 100 }}>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Prepared By:{`\n\n_____________________\n`} {`        `}
                  Administrator
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Checked By:{`\n\n_____________________\n`} {`           `}
                  Accountant
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Approved By:{`\n\n_____________________\n`} {`             `}
                  President
                </Text>
              </View>
            </View>
          </View>
          <Text
            style={{ ...styles.pageNumber, textAlign: 'right' }}
            render={({ pageNumber, totalPages }) =>
              `Page: ${pageNumber} / ${totalPages}`
            }
            fixed
          />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `Date printed: [${dayjs().format('YYYY-MM-DD HH:mm:ss')}]`
            }
            fixed
          />
        </Page>
      </Document>
    </PDFViewer>
  );
}
