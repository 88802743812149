export default {
  id: null,
  crew_no: null,
  crew_name: null,
  position_code: null,
  position_name: null,
  vescode: null,
  vesname: null,
  sign_on: null,
  sign_off: null,
  exrate: null,
  month: null,
  year: null,
  cancelled: null,
  date: null,
  details: null,
  wages: null,
  addWages: null,
  govContri: null,
  cancelled: null,

  net_amount: null,
};
