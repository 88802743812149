import RemainingWagesType from '../types/RemainingWagesType';

export const getRemainingWagesEntryFields = (
  isLoading,
  state = RemainingWagesType,
  setState,
  setHasChanges,
  exrate
) => {
  return [
    {
      type: 'text',
      name: 'crew_name',
      placeholder: 'Crew Name',
      label: 'Crew Name',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.crew_name,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, crew_name: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'position',
      placeholder: 'Position',
      label: 'Position',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.position,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, position: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'vessel',
      placeholder: 'Vessel',
      label: 'Vessel',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.vessel,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, vessel: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'allottee',
      placeholder: 'Allottee',
      label: 'Allottee',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: state.allottee,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, allottee: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'amount_usd',
      placeholder: 'Amount USD',
      label: 'Amount USD',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.amount_usd,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, amount_usd: value, amount_php: value * exrate });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'amount_php',
      placeholder: 'Amount PHP',
      label: 'Amount PHP',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.amount_php,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, amount_php: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'account_no',
      placeholder: 'Account no.',
      label: 'Account no.',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: state.account_no,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, account_no: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'bank_code',
      placeholder: 'Bank',
      label: 'Bank',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: state.bank_code,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, bank_code: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'bank_branch',
      placeholder: 'Branch',
      label: 'Branch',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: state.bank_branch,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, bank_branch: value });
        setHasChanges(true);
      },
    },

    // {
    //   type: 'number',
    //   name: 'amount',
    //   placeholder: 'Amount',
    //   label: 'Amount',
    //   autofocus: false,
    //   required: true,
    //   disabled: isLoading,
    //   value: state.amount,
    //   setValue: (e) => {
    //     const value = e.target.value;
    //     if (state.wage_type === 'earning') {
    //       setState({ ...state, amount: value });
    //     } else {
    //       setState({ ...state, deduction_amount: value });
    //     }
    //     setHasChanges(true);
    //   },
    // },
  ];
};
