import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  FloatButton,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../utils/context/UserContext';
import { GlobalContext } from '../../../../App';
import Search from 'antd/es/input/Search';
import VesselType from '../../../../utils/types/VesselType';
import CrewType from '../../../../utils/types/CrewType';
import { IoIosRefresh } from 'react-icons/io';
import {
  getAllPostedVessel,
  getCrewByVessel,
} from '../../../../utils/api/TransactionsAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  WARNING_NOTIFICATION_TYPE,
} from '../../../../utils/api/_constants';
import { getVessels } from '../../../../utils/api/VesselAPI';
import {
  getAdvanceCrewWagesReport,
  getAllSavedCrewWages,
  getCrewHomeAllotment,
  getCrewIndividualPayslip,
  getCrewWageTemplates,
  getCrewWagesInput,
  getCrewWagesSetupPerVessel,
  getCrewWagesSetupPerVesselForestwave,
  getCrewWagesSetupPerVesselSMT,
  getCurrentCrewWages,
  getSavedCrewWages,
  getScheduleUKWages,
  postAddCrewWageEntry,
  postAddCrewWagesInput,
  postProcessCrewWages,
  postRecomputeCrewWages,
  postUpdateLeaveProv,
} from '../../../../utils/api/CrewWagesAPI';
import CrewWagesPerCrewType from '../../../../utils/types/CrewWagesPerCrewType';
import { getCrewWagesComputeTable } from '../../../../utils/tableheader/Transactions/CrewWagesComputeTable';
import FormModal from '../../../../components/CustomModal/FormModal';
import FormInput from '../../../../components/FormInput/FormInput';
import CustomModal from '../../../../components/CustomModal/CustomModal';
import { getCrewWagesEntryTable } from '../../../../utils/tableheader/Transactions/CrewWagesEntryTable';
import { AiFillDelete } from 'react-icons/ai';
import { getCrewWagesEtntryFields } from '../../../../utils/inputfields/CrewWagesEntryFields';
import ConfirmationModal from '../../../../components/CustomModal/ConfirmationModal';
import CrewWagesEntryType from '../../../../utils/types/CrewWagesEntryType';
import { getAllOtherDeductions } from '../../../../utils/api/DeductionsAPI';
import CrewWagesAdvancedPDF from '../../../../components/PDFReports/CrewWagesAdvancedPDF';
import { getCrewWagesDetailsFields } from '../../../../utils/inputfields/CrewWagesDetailsFields';
import CrewWagesIndividualPayslipPDF from '../../../../components/PDFReports/CrewWagesIndividualPayslipPDF';
import { postProcessCrewRemainingWages } from '../../../../utils/api/RemainingWagesAPI';
import { getUkWagesDetailsFields } from '../../../../utils/inputfields/UkWagesFields';
import { FaPrint } from 'react-icons/fa';
import CrewWagesAllIndividualPayslipPDF from '../../../../components/PDFReports/CrewWagesAllIndividualPayslipPDF';
import CrewWagesHomeAllotmentPDF from '../../../../components/PDFReports/CrewWagesHomeAllotmentPDF';
import CrewWagesUKWagesPDF from '../../../../components/PDFReports/CrewWagesUKWagesPDF';
import CrewWagesCurrentPDF from '../../../../components/PDFReports/CrewWagesCurrentPDF';
import { getCrewWagesForInitialTable } from '../../../../utils/tableheader/Transactions/CrewWagesForInitialTable';
import CrewWagesForestwavePDF from '../../../../components/PDFReports/CrewWagesForestwavePDF';
import CrewWagesSMTPDF from '../../../../components/PDFReports/CrewWagesSMTPDF';

export default function CrewWagesPage() {
  const TAG = 'Crew Wages';

  const {
    getCurrentDate,
    doSearchInArray,
    parseToFloat,
    inputToLocale,
    validateAccessToFeature,
    profile,
  } = useContext(UserContext);

  const [form] = Form.useForm();

  const [crewDetailsForm] = Form.useForm();
  const [ukWagesForm] = Form.useForm();

  const { openNotification } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);

  const [month, setMonth] = useState(dayjs().month() + 1);
  const [year, setYear] = useState(dayjs().year());

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const [vesselList, setVesselList] = useState([]);
  const [selectedVessel, setSelectedVessel] = useState(VesselType);

  const [selectedCrew, setSelectedCrew] = useState(CrewWagesPerCrewType);
  const [selectedCrewWages, setSelectedCrewWages] = useState([]);

  const crewWagesTableContent = selectedCrewWages
    .filter((item) => item.is_deleted != '1')
    .map((record, index) => ({
      ...record,
      key: index,
    }));

  const [crewList, setCrewList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const [postedVessel, setPostedVessel] = useState([]);
  const [isVesselPosted, setIsVesselPosted] = useState(false);

  const [deductionOptions, setDeductionOptions] = useState([]);

  // Form modal
  const [isWagesModalOpen, setIsWagesModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);

  const [wageEntryRecord, setWageEntryRecord] = useState(CrewWagesEntryType);

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isIndividualPrintModalOpen, setIsIndividualPrintModalOpen] =
    useState(false);
  const [isUkWagesPrintModalOpen, setIsUkWagesPrintModalOpen] = useState(false);
  const [isCurrentPrintModalOpen, setIsCurrentPrintModalOpen] = useState(false);
  const [isForInitialModalOpen, setIsForInitialModalOpen] = useState(false);
  const [isForInitialConfirmationOpen, setIsForInitialConfirmationOpen] =
    useState(false);
  const [forInitialCrewList, setForInitialCrewList] = useState([]);

  const [payslipForPrintRecord, setPayslipForPrintRecord] = useState(null);

  const [withProvident, setWithProvident] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [includeCrewForPrint, setIncludeCrewForPrint] = useState([]);
  const [includeCrewForPrintKeys, setIncludeCrewForPrintKeys] = useState([]);

  // PRINTING STATES
  const [printingCrewList, setPrintingCrewList] = useState([]);
  const [printingEarningsHeader, setPrintingEarningsHeader] = useState([]);
  const [printingEarningsProrateHeader, setPrintingEarningsProrateHeader] =
    useState([]);
  const [printDeductionsHeader, setPrintDeductionsHeader] = useState([]);
  const [printAccruedHeader, setPrintAccruedHeader] = useState([]);
  const [grandTotals, setGrandTotals] = useState([]);
  const [isFugroPrintModalOpen, setIsFugroPrintModalOpen] = useState(false);

  const [isComputeConfirmationOpen, setIsComputeConfirmationOpen] =
    useState(false);

  const [isAllCrewIndividualPayslipOpen, setIsAllCrewIndividualPayslipOpen] =
    useState(false);
  const [allCrewIndividualPayslip, setAllCrewIndividualPayslip] = useState([]);

  const [isCrewWagesHomeAllotmentOpen, setIsCrewWagesHomeAllotmentOpen] =
    useState(false);
  const [homeAllotmentList, setHomeAllotmentList] = useState([]);

  const [templateList, setTemplateList] = useState([]);

  const handleAction = async (record = CrewWagesPerCrewType, action) => {
    if (action === 'print') {
      // Print
      setIsLoading(true);
      const { data: res, error } = await getCrewIndividualPayslip(
        record.crew_no,
        month,
        year
      );

      if (error) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          TAG,
          `Fetching payslip for ${record.crew_name} failed.`
        );
      } else {
        if (res.crew_name) {
          setPayslipForPrintRecord(res);
          setIsIndividualPrintModalOpen(true);
        } else {
          openNotification(
            WARNING_NOTIFICATION_TYPE,
            TAG,
            `Payslip for ${record.crew_name} is not yet available.`
          );
        }
      }
      setIsLoading(false);
    } else {
      let earnings = [];
      let deductions = [];
      let accrued = [];
      if (record?.earnings?.length > 1) {
        earnings = record.earnings
          .map((earning) => ({
            ...earning,
            wage_type: 'earning',
            editable: earning.fixed == '1',
            deduction_id: 0,
            deduction_amount: 0,
            is_deleted: 0,
          }))
          .slice(0, -1);
      }

      if (record?.deductions?.length > 1) {
        deductions = record.deductions
          .map((ded) => ({
            ...ded,
            wage_type: 'deduction',
            editable: ded.fixed == '1',
            deduction_id: ded.deduction_id,
            deduction_amount: ded.amount,
            is_deleted: 0,
          }))
          .slice(0, -1);
      }
      if (record?.accrued?.length > 1) {
        accrued = record.accrued
          .map((acc) => ({
            ...acc,
            wage_type: 'accrued',
            editable: acc.fixed == '1',
            deduction_id: acc.deduction_id,
            deduction_amount: acc.amount,
            is_deleted: 0,
          }))
          .slice(0, -1);
      }

      const wages = earnings.concat(deductions).concat(accrued);

      const parseCrew = {
        ...record,
        sign_on: dayjs(record.sign_on, 'YYYY-MM-DD'),
        embark_date: dayjs(record.embark_date, 'YYYY-MM-DD'),
        sign_off:
          record.sign_off != null ? dayjs(record.sign_off, 'YYYY-MM-DD') : null,
        disembark:
          record.disembark != null
            ? dayjs(record.disembark, 'YYYY-MM-DD')
            : null,
      };
      crewDetailsForm.setFieldsValue(parseCrew);
      ukWagesForm.setFieldsValue(parseCrew);
      setSelectedCrew(parseCrew);
      setSelectedCrewWages(wages);
      setIsWagesModalOpen(true);
    }
  };

  const handleWagesAction = (action, index, field, value) => {
    if (action === 'delete') {
      const updatedCrewWages = selectedCrewWages.map((item, i) => {
        if (i === index) {
          return { ...item, is_deleted: 1 };
        } else return item;
      });
      setSelectedCrewWages(updatedCrewWages);
      setHasChanges(true);
    } else {
      const updatedCrewWages = selectedCrewWages.map((item, i) => {
        if (i === index) {
          const updatedRec = { ...item };
          updatedRec[field] = value;
          return updatedRec;
        }
        return item;
      });

      setSelectedCrewWages(updatedCrewWages);
    }
  };

  const loadPostedVessels = async (m, y) => {
    setIsLoading(true);
    const { data: res, error } = await getAllPostedVessel(m, y);
    if (error) {
      console.error('Failed fetching posted vessels.');
    } else {
      setPostedVessel(res);
      setIsVesselPosted(
        res.find((item) => item.vescode == selectedVessel.vessel_code)
          ?.crew_wages == 1
      );
    }
    setIsLoading(false);
  };

  const loadCrewsByVessel = async () => {
    setIsLoading(true);
    const { data: res, error } = await getCrewWagesSetupPerVessel(
      selectedVessel.vessel_code,
      year,
      month,
      selectedTemplate?.id
    );
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        TAG,
        'Error fetching crew list.'
      );
    } else {
      const list = res.map((item, index) => ({
        ...item,
        key: index,
        sign_off: item.crew_status == 'on-board' ? null : item.sign_off,
        disembark: item.crew_status == 'on-board' ? null : item.disembark,
        original_disembark: item.disembark,
      }));

      setFilteredList(list);
      setCrewList(list);
      doSearchInArray(searchQuery, setFilteredList, list);
      loadPostedVessels(month, year);
    }
    setIsLoading(false);
  };

  const fetchDeductionTypes = async () => {
    const { data: res, error } = await getAllOtherDeductions();
    if (!error) {
      const list = res.map((item) => ({
        ...item,
        id: item.id,
        description: item.desc,
      }));
      setDeductionOptions(list);
    }
  };

  const fetchDatas = async () => {
    setIsLoading(true);
    await Promise.all([
      getVessels(profile.user_id),
      loadPostedVessels(),
      getCrewWageTemplates(),
    ]).then((response) => {
      if (!response[2].error) {
        setTemplateList(response[2].data);
      }
    });
    const { data: res, error } = await getVessels(profile.user_id);
    if (error) {
      openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Error fetching vessels.');
    } else {
      setVesselList(res);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDatas();
    fetchDeductionTypes();
  }, []);

  return (
    <Content>
      <Spin spinning={isLoading} fullscreen />
      <Row>
        <Col xs={24} sm={6} className='me-2'>
          <Typography.Text strong className='me-2'>
            Month/Year:
          </Typography.Text>
          <DatePicker
            allowClear={false}
            style={{ width: '60%' }}
            picker='month'
            defaultValue={dayjs(`${month}/${year}`, 'M/YYYY')}
            onChange={(_, dateString) => {
              const [dateMonth, dateYear] = dateString.split('/');
              setMonth((prevMonth) => dateMonth);
              setYear((prevYear) => dateYear);
              setCrewList([]);
              setFilteredList([]);
              loadPostedVessels(dateMonth, dateYear);
            }}
            format='M/YYYY'
            disabled={isLoading}
          />
        </Col>
        <Col xs={24} sm={8} className='text-start'>
          <Typography.Text strong className='me-2'>
            Vessel:
          </Typography.Text>
          <Select
            style={{ width: '80%' }}
            placeholder={'Search vessel!'}
            value={selectedVessel.vessel_code}
            onChange={(value, option) => {
              const vessel = vesselList.find(
                (item) => item.vessel_code === value
              );
              setSelectedVessel(vessel);
              setIsVesselPosted(
                postedVessel.some((item) => item.vescode == vessel.vessel_code)
              );
            }}
            disabled={isLoading}
            showSearch
            filterOption={(inputValue, option) => {
              const optionDescription = option.children.toLowerCase();
              return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
            }}
          >
            {vesselList.map((option) => (
              <Select.Option
                key={option.vessel_code}
                value={option.vessel_code}
              >
                {option.vessel_name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={8}>
          <Button
            type='primary'
            className='me-3'
            disabled={
              isLoading ||
              selectedVessel.id === null ||
              selectedTemplate === null ||
              !validateAccessToFeature(
                'function_tran_pp_compute_crew_wages_filter'
              )
            }
            onClick={() => {
              loadCrewsByVessel();
            }}
          >
            Filter
          </Button>
          <Button
            type='primary'
            disabled={
              isLoading ||
              month == null ||
              year == null ||
              selectedVessel.vessel_code == null ||
              filteredList.length <= 0 ||
              crewList.length <= 0 ||
              selectedTemplate == null ||
              !validateAccessToFeature(
                'function_tran_pp_compute_crew_wages_print'
              )
            }
            onClick={async () => {
              switch (selectedTemplate?.cw_format) {
                case 1:
                  setIsFugroPrintModalOpen(true);
                  break;
                case 2:
                  setIsLoading(true);
                  const { data: smtRes, smtError } =
                    await getCrewWagesSetupPerVesselSMT(
                      selectedVessel.vessel_code,
                      year,
                      month
                    );
                  if (smtError) {
                    openNotification(
                      ERROR_NOTIFICATION_TYPE,
                      TAG,
                      'Something went wrong.'
                    );
                  } else {
                    if (smtRes.length > 0) {
                      // const grandTotal = res.pop();
                      // setGrandTotals(grandTotal);
                      setPrintingCrewList(smtRes);
                      const uniqueEarningsHeaders = [];
                      const uniqueDeductionsHeaders = [];
                      const uniqueAccruedHeaders = [];
                      const salaryCodesSetEarnings = new Set();
                      const salaryCodesSetDeductions = new Set();
                      const salaryCodesSetAccrued = new Set();
                      smtRes.forEach((item) => {
                        item.earnings.forEach((earning, index) => {
                          const salary_code = earning.salary_code;
                          if (!salaryCodesSetEarnings.has(salary_code)) {
                            salaryCodesSetEarnings.add(salary_code);
                            uniqueEarningsHeaders.push(earning);
                          }
                        });
                      });
                      smtRes.forEach((item) => {
                        item.deductions.forEach((ded, index) => {
                          const salary_code = ded.salary_code;
                          if (!salaryCodesSetDeductions.has(salary_code)) {
                            salaryCodesSetDeductions.add(salary_code);
                            uniqueDeductionsHeaders.push(ded);
                          }
                        });
                      });
                      smtRes.forEach((item) => {
                        item.accrued?.forEach((acc, index) => {
                          const salary_code = acc.salary_code;
                          if (!salaryCodesSetAccrued.has(salary_code)) {
                            salaryCodesSetAccrued.add(salary_code);
                            uniqueAccruedHeaders.push(acc);
                          }
                        });
                      });
                      setPrintingEarningsHeader(uniqueEarningsHeaders);
                      setPrintDeductionsHeader(uniqueDeductionsHeaders);
                      setPrintAccruedHeader(uniqueAccruedHeaders);
                      setIsPrintModalOpen(true);
                    } else {
                      openNotification(
                        WARNING_NOTIFICATION_TYPE,
                        TAG,
                        'No records found'
                      );
                    }
                  }
                  setIsLoading(false);
                  break;
                case 3:
                  setIsLoading(true);
                  const { data: res, error } =
                    await getCrewWagesSetupPerVesselForestwave(
                      selectedVessel.vessel_code,
                      year,
                      month
                    );
                  if (error) {
                    openNotification(
                      ERROR_NOTIFICATION_TYPE,
                      TAG,
                      'Something went wrong.'
                    );
                  } else {
                    if (res.length > 0) {
                      // const grandTotal = res.pop();
                      // setGrandTotals(grandTotal);
                      setPrintingCrewList(res);
                      const uniqueEarningsHeaders = [];
                      const uniqueDeductionsHeaders = [];
                      const uniqueAccruedHeaders = [];
                      const salaryCodesSetEarnings = new Set();
                      const salaryCodesSetDeductions = new Set();
                      const salaryCodesSetAccrued = new Set();
                      res.forEach((item) => {
                        item.earnings.forEach((earning, index) => {
                          const salary_code = earning.salary_code;
                          if (!salaryCodesSetEarnings.has(salary_code)) {
                            salaryCodesSetEarnings.add(salary_code);
                            uniqueEarningsHeaders.push(earning);
                          }
                        });
                      });
                      res.forEach((item) => {
                        item.deduction.forEach((ded, index) => {
                          const salary_code = ded.salary_code;
                          if (!salaryCodesSetDeductions.has(salary_code)) {
                            salaryCodesSetDeductions.add(salary_code);
                            uniqueDeductionsHeaders.push(ded);
                          }
                        });
                      });
                      res.forEach((item) => {
                        item.accrued.forEach((acc, index) => {
                          const salary_code = acc.salary_code;
                          if (!salaryCodesSetAccrued.has(salary_code)) {
                            salaryCodesSetAccrued.add(salary_code);
                            uniqueAccruedHeaders.push(acc);
                          }
                        });
                      });
                      setPrintingEarningsHeader(uniqueEarningsHeaders);
                      setPrintDeductionsHeader(uniqueDeductionsHeaders);
                      setPrintAccruedHeader(uniqueAccruedHeaders);
                      setIsPrintModalOpen(true);
                    } else {
                      openNotification(
                        WARNING_NOTIFICATION_TYPE,
                        TAG,
                        'No records found'
                      );
                    }
                  }
                  setIsLoading(false);
                  break;

                default:
                  break;
              }
            }}
          >
            Print
          </Button>
        </Col>
      </Row>
      <Row className='mb-4 mt-2' justify={'space-between'}>
        <Col xs={24} sm={16}>
          <Typography.Text strong className='me-2'>
            Template:
          </Typography.Text>
          <Select
            style={{ width: '30%' }}
            placeholder={'Select template'}
            value={selectedTemplate?.id}
            onChange={(value, option) => {
              const template = templateList.find((item) => item.id === value);
              setSelectedTemplate(template);
            }}
            disabled={isLoading}
            showSearch
            filterOption={(inputValue, option) => {
              const optionDescription = option.children.toLowerCase();
              return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
            }}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
          >
            {templateList.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.template_name.toUpperCase()}
              </Select.Option>
            ))}
          </Select>

          <Checkbox
            className='ms-2'
            onChange={(e) => setWithProvident(e.target.checked)}
            disabled={
              isLoading ||
              month == null ||
              year == null ||
              selectedVessel.vessel_code == null ||
              selectedTemplate?.cw_format != 1
            }
          >
            Print with Prov. Fund
          </Checkbox>

          <Button
            type='primary'
            className='ms-2'
            disabled={
              isLoading ||
              month == null ||
              year == null ||
              selectedVessel.vessel_code == null ||
              filteredList.length <= 0 ||
              crewList.length <= 0 ||
              isVesselPosted ||
              selectedTemplate == null ||
              !validateAccessToFeature(
                'function_tran_pp_compute_crew_wages_compute'
              )
            }
            onClick={() => setIsComputeConfirmationOpen(true)}
          >
            Compute
          </Button>
          <Button
            type='primary'
            className='ms-2'
            disabled={
              isLoading ||
              month == null ||
              year == null ||
              selectedVessel.vessel_code == null ||
              filteredList.length <= 0 ||
              crewList.length <= 0 ||
              isVesselPosted ||
              selectedTemplate == null ||
              !validateAccessToFeature(
                'function_tran_pp_compute_crew_wages_edit_initial_modify'
              )
            }
            onClick={async () => {
              switch (selectedTemplate?.cw_format) {
                case 1:
                  setForInitialCrewList(crewList);
                  setIsForInitialModalOpen(true);
                  break;
                default:
                  setIsLoading(true);
                  const { data: res, error } = await getCrewWagesInput(
                    selectedVessel.vessel_code,
                    month,
                    year
                  );
                  if (error) {
                    openNotification(
                      ERROR_NOTIFICATION_TYPE,
                      TAG,
                      'Something went wrong.'
                    );
                  } else {
                    const iniCrewList = crewList.map((rec) => {
                      const haveInputs = res.find(
                        (inputs) => inputs.crew_no == rec.crew_no
                      );
                      if (haveInputs) {
                        return {
                          ...rec,
                          ...haveInputs,
                        };
                      } else {
                        return rec;
                      }
                    });
                    setForInitialCrewList(iniCrewList);
                    setIsForInitialModalOpen(true);
                  }
                  setIsLoading(false);
                  break;
              }
            }}
          >
            Inputs
          </Button>
        </Col>
        <Col xs={24} sm={8} className='text-end'>
          <Space.Compact block>
            <Search
              disabled={isLoading || crewList.length === 0}
              placeholder='Search crew!'
              onChange={(e) => {
                setSearchQuery(e.target.value);
                doSearchInArray(e.target.value, setFilteredList, crewList);
              }}
              onSearch={(value) => {
                setSearchQuery(value);
                doSearchInArray(value, setFilteredList, crewList);
              }}
              allowClear={true}
            />
          </Space.Compact>
        </Col>
      </Row>
      <Table
        columns={getCrewWagesComputeTable(
          handleAction,
          selectedTemplate,
          isVesselPosted,
          validateAccessToFeature
        )}
        dataSource={filteredList}
        size='small'
        bordered='true'
        scroll={{ x: true }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
        rowSelection={{
          selectedRowKeys: includeCrewForPrintKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setIncludeCrewForPrint(newSelectedRows);
            setIncludeCrewForPrintKeys(newSelectedRowKeys);
          },
        }}
      />
      <CustomModal
        title={selectedCrew.crew_name}
        isLoading={isLoading}
        isOpen={isWagesModalOpen}
        setIsOpen={setIsWagesModalOpen}
        props={{ width: '50vw' }}
        okText='Save'
        onOkDisabled={isLoading || !hasChanges}
        onOk={() => {
          setIsConfirmationModalOpen(true);
        }}
        onCancel={() => {
          if (hasChanges) {
            setIsUnsavedModalOpen(true);
          } else {
            setIsWagesModalOpen(false);
          }
        }}
      >
        <Form
          labelAlign='left'
          form={crewDetailsForm}
          name='crew_wages_details_form'
          layout='vertical'
          initialValues={selectedCrew}
        >
          <Row gutter={16}>
            {getCrewWagesDetailsFields(
              isLoading,
              selectedCrew,
              setSelectedCrew,
              setHasChanges,
              selectedTemplate
            ).map((data, index) => (
              <Col key={index} xs={24} sm={12}>
                <FormInput
                  type={data.type}
                  name={data.name}
                  placeholder={data.placeholder}
                  label={data.label}
                  autoFocus={data.autofocus}
                  value={data.value}
                  onChange={data.setValue}
                  required={data.required}
                  disabled={data.disabled}
                  hidden={data.hidden}
                  dropdownOptions={data.dropdownOptions}
                  props={data.props}
                />
              </Col>
            ))}
          </Row>
        </Form>
        <Row className='mb-2'>
          <Col xs={24} className='text-end'>
            <Button
              type='primary'
              onClick={() => {
                setWageEntryRecord(CrewWagesEntryType);
                form.setFieldsValue(CrewWagesEntryType);
                setIsFormModalOpen(true);
              }}
            >
              Add
            </Button>
          </Col>
        </Row>
        <Table
          columns={getCrewWagesEntryTable(
            handleWagesAction,
            deductionOptions,
            setHasChanges
          )}
          dataSource={crewWagesTableContent}
          size='small'
          bordered='true'
          scroll={{ x: true }}
          loading={isLoading}
          pagination={{
            defaultPageSize: 50,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} records`,
          }}
        />
        {selectedTemplate?.cw_format == 1 && (
          <>
            <Typography.Title level={3}>
              *** FOR UK WAGES ADJUSTMENTS ONLY ***
            </Typography.Title>
            <Form
              labelAlign='left'
              form={ukWagesForm}
              name='uk_wages_details_form'
              layout='vertical'
              initialValues={selectedCrew}
            >
              <Row gutter={16}>
                {getUkWagesDetailsFields(
                  isLoading,
                  selectedCrew,
                  setSelectedCrew,
                  setHasChanges,
                  selectedTemplate
                ).map((data, index) => (
                  <Col key={index} xs={24} sm={8}>
                    <FormInput
                      type={data.type}
                      name={data.name}
                      placeholder={data.placeholder}
                      label={data.label}
                      autoFocus={data.autofocus}
                      value={data.value}
                      onChange={data.setValue}
                      required={data.required}
                      disabled={data.disabled}
                      hidden={data.hidden}
                      dropdownOptions={data.dropdownOptions}
                      props={data.props}
                    />
                  </Col>
                ))}
              </Row>
            </Form>
          </>
        )}
      </CustomModal>
      <FormModal
        title={'Crew Wages entry'}
        isOpen={isFormModalOpen}
        setIsOpen={setIsFormModalOpen}
        isLoading={isLoading}
        props={{ width: '50vw' }}
        form={form}
        formName={'crew_wages_form'}
        initialState={wageEntryRecord}
        onSave={async () => {
          let newEntry = { ...wageEntryRecord };
          if (wageEntryRecord.wage_type === 'deduction') {
            const deduction = deductionOptions.find(
              (item) => item.id == wageEntryRecord.deduction_id
            );
            newEntry = {
              ...wageEntryRecord,
              salary_code: deduction.short_name,
              wage_other_desc: deduction.desc,
              editable: false,
              amount: wageEntryRecord.deduction_amount,
              prorate_amount: wageEntryRecord.deduction_amount,
              wage_code: deduction.short_name,
              fixed: 0,
              is_deleted: 0,
            };
          } else {
            newEntry = {
              ...wageEntryRecord,
              editable: false,
              prorate_amount: wageEntryRecord.amount,
              wage_code: wageEntryRecord.salary_code,
              deduction_id: 0,
              deduction_amount: 0,
              fixed: 0,
              is_deleted: 0,
            };
          }
          setSelectedCrewWages([...selectedCrewWages, newEntry]);
          setIsFormModalOpen(false);
        }}
        onCancel={() => {
          crewDetailsForm.resetFields();
          ukWagesForm.resetFields();
          form.resetFields();
          setIsFormModalOpen(false);
          // if (hasChanges) {
          //   // setIsUnsavedModalOpen(true);

          // } else {
          //   form.resetFields();
          //   setIsFormModalOpen(false);
          //   setHasChanges(false);
          // }
        }}
      >
        <Row gutter={16}>
          {getCrewWagesEtntryFields(
            isLoading,
            wageEntryRecord,
            setWageEntryRecord,
            setHasChanges,
            deductionOptions
          ).map((data, index) => (
            <Col key={index} xs={24} sm={12}>
              <FormInput
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                hidden={data.hidden}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            </Col>
          ))}
        </Row>
      </FormModal>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          try {
            await Promise.all(
              selectedCrewWages.map(async (record, index) => {
                const payload = {
                  ...selectedCrew,
                  ...record,
                  sign_on: dayjs(selectedCrew.sign_on).format('YYYY-MM-DD'),
                  embark: dayjs(selectedCrew.embark_date).format('YYYY-MM-DD'),
                  sign_off:
                    selectedCrew.sign_off != null
                      ? dayjs(selectedCrew.sign_off).format('YYYY-MM-DD')
                      : selectedCrew.sign_off,
                  disembark:
                    selectedCrew.disembark != null
                      ? dayjs(selectedCrew.disembark).format('YYYY-MM-DD')
                      : selectedCrew.disembark,
                  month: month,
                  year: year,
                  crew_wage_type: `${record.wage_type}s`,
                  other_desc: record.wage_other_desc,
                  wage_desc: record.salary_code,
                  process_amount: record.amount,
                };
                const { data: res, error } = await postAddCrewWageEntry(
                  payload
                );
                if (error) {
                  openNotification(
                    ERROR_NOTIFICATION_TYPE,
                    TAG,
                    `Saving ${record.salary_code} failed.`
                  );
                } else {
                  if (index + 1 === selectedCrewWages.length) {
                    openNotification(
                      SUCCESS_NOTIFICATION_TYPE,
                      TAG,
                      `Saving crew wages completed.`
                    );
                  }
                }
              })
            );
            const recomputePayload = {
              month: month,
              year: year,
              vessel_code: selectedVessel.vessel_code,
              crew_no: selectedCrew.crew_no,
            };
            const { data: recomputeRes, error } = await postRecomputeCrewWages(
              recomputePayload
            );
            if (error) {
              console.error('Error');
            } else {
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                TAG,
                'Successfully updated.'
              );
            }
          } catch (error) {
            openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Saving failed');
          }
          setIsLoading(false);
          setHasChanges(false);
          setIsConfirmationModalOpen(false);
          setIsWagesModalOpen(false);
          loadCrewsByVessel();
        }}
      />
      <ConfirmationModal
        title='You have unsaved changes! Confirming will lose all your changes. Confirm?'
        cancelText='Continue editing'
        isLoading={isLoading}
        isOpen={isUnsavedModalOpen}
        setIsOpen={setIsUnsavedModalOpen}
        onConfirm={() => {
          crewDetailsForm.resetFields();
          ukWagesForm.resetFields();
          form.resetFields();
          setHasChanges(false);
          setIsUnsavedModalOpen(false);
          setIsWagesModalOpen(false);
        }}
      />
      <CustomModal
        isLoading={isLoading}
        isOpen={isPrintModalOpen}
        setIsOpen={setIsPrintModalOpen}
        cancelText='Close'
        props={{ okButtonProps: { hidden: true }, width: '100vw' }}
        isClosable={true}
      >
        {selectedTemplate?.cw_format == 1 && (
          <CrewWagesAdvancedPDF
            crewList={printingCrewList}
            earningsHeader={printingEarningsHeader}
            deductionsHeader={printDeductionsHeader}
            accruedHeader={printAccruedHeader}
            selectedVessel={selectedVessel}
            month={month}
            year={year}
            withProvFund={withProvident}
            grandTotals={grandTotals}
          />
        )}
        {selectedTemplate?.cw_format == 2 && (
          <CrewWagesSMTPDF
            crewList={printingCrewList}
            earningsHeader={printingEarningsHeader}
            deductionsHeader={printDeductionsHeader}
            accruedHeader={printAccruedHeader}
            selectedVessel={selectedVessel}
            month={month}
            year={year}
            selectedTemplate={selectedTemplate}
          />
        )}
        {selectedTemplate?.cw_format == 3 && (
          <CrewWagesForestwavePDF
            crewList={printingCrewList}
            earningsHeader={printingEarningsHeader}
            deductionsHeader={printDeductionsHeader}
            accruedHeader={printAccruedHeader}
            selectedVessel={selectedVessel}
            month={month}
            year={year}
          />
        )}
      </CustomModal>
      <CustomModal
        isLoading={isLoading}
        isOpen={isIndividualPrintModalOpen}
        setIsOpen={setIsIndividualPrintModalOpen}
        cancelText='Close'
        props={{ okButtonProps: { hidden: true }, width: '100vw' }}
        isClosable={true}
      >
        <CrewWagesIndividualPayslipPDF
          payslipDetails={payslipForPrintRecord}
          month={month}
          year={year}
          withProvFund={withProvident}
        />
      </CustomModal>
      <CustomModal
        isLoading={isLoading}
        isOpen={isAllCrewIndividualPayslipOpen}
        setIsOpen={setIsAllCrewIndividualPayslipOpen}
        cancelText='Close'
        props={{ okButtonProps: { hidden: true }, width: '100vw' }}
        isClosable={true}
      >
        <CrewWagesAllIndividualPayslipPDF
          crewListPayslipDetails={allCrewIndividualPayslip}
          month={month}
          year={year}
          withProvFund={withProvident}
        />
      </CustomModal>
      <CustomModal
        isLoading={isLoading}
        isOpen={isCrewWagesHomeAllotmentOpen}
        setIsOpen={setIsCrewWagesHomeAllotmentOpen}
        cancelText='Close'
        props={{ okButtonProps: { hidden: true }, width: '100vw' }}
        isClosable={true}
      >
        <CrewWagesHomeAllotmentPDF
          crewList={homeAllotmentList}
          selectedVessel={selectedVessel}
          month={month}
          year={year}
          grandTotals={grandTotals}
        />
      </CustomModal>
      <CustomModal
        isLoading={isLoading}
        isOpen={isUkWagesPrintModalOpen}
        setIsOpen={setIsUkWagesPrintModalOpen}
        cancelText='Close'
        props={{ okButtonProps: { hidden: true }, width: '100vw' }}
        isClosable={true}
      >
        <CrewWagesUKWagesPDF
          crewList={printingCrewList}
          earningsHeader={printingEarningsHeader}
          selectedVessel={selectedVessel}
          month={month}
          year={year}
        />
      </CustomModal>
      <CustomModal
        isLoading={isLoading}
        isOpen={isCurrentPrintModalOpen}
        setIsOpen={setIsCurrentPrintModalOpen}
        cancelText='Close'
        props={{ okButtonProps: { hidden: true }, width: '100vw' }}
        isClosable={true}
      >
        <CrewWagesCurrentPDF
          crewList={printingCrewList}
          earningsHeader={printingEarningsHeader}
          earningsProRateHeader={printingEarningsProrateHeader}
          selectedVessel={selectedVessel}
          month={month}
          year={year}
          grandTotals={grandTotals}
        />
      </CustomModal>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isComputeConfirmationOpen}
        setIsOpen={setIsComputeConfirmationOpen}
        onConfirm={async () => {
          setIsLoading(true);
          const payload = {
            vessel_code: selectedVessel.vessel_code,
            month: month,
            year: year,
            template_id: selectedTemplate?.id,
          };
          const { data: res, error } = await postProcessCrewWages(payload);

          if (error) {
            if (error?.response?.status == 422) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                TAG,
                `${error?.response?.data?.message}.`
              );
            } else {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                TAG,
                'Processing failed.'
              );
            }
          } else {
            openNotification(
              SUCCESS_NOTIFICATION_TYPE,
              TAG,
              'Processing completed.'
            );
          }

          setIsLoading(false);
          setIsComputeConfirmationOpen(false);
          loadCrewsByVessel();
        }}
      />
      <CustomModal
        title='Select layout'
        isLoading={isLoading}
        isOpen={isFugroPrintModalOpen}
        setIsOpen={setIsFugroPrintModalOpen}
        props={{
          okButtonProps: { hidden: true },
          cancelButtonProps: { hidden: true },
          width: '20vw',
        }}
      >
        <Flex
          vertical
          gap='small'
          style={{
            width: '100%',
          }}
        >
          <Button
            type='primary'
            block
            disabled={
              isLoading ||
              month == null ||
              year == null ||
              selectedVessel.vessel_code == null ||
              selectedTemplate?.is_advanced == 0
            }
            onClick={async () => {
              setIsLoading(true);
              const { data: res, error } = await getAdvanceCrewWagesReport(
                selectedVessel.vessel_code,
                year,
                month
              );
              if (error) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  TAG,
                  'Processing failed.'
                );
              } else {
                if (res.length > 0) {
                  const grandTotal = res.pop();
                  setGrandTotals(grandTotal);
                  setPrintingCrewList(res);
                  const uniqueEarningsHeaders = [];
                  const uniqueDeductionsHeaders = [];
                  const uniqueAccruedHeaders = [];
                  const salaryCodesSetEarnings = new Set();
                  const salaryCodesSetDeductions = new Set();
                  const salaryCodesSetAccrued = new Set();

                  res.forEach((item) => {
                    if (item.is_multiple) {
                      item.records.map((record) => {
                        record.earnings
                          .slice(0, -1)
                          .forEach((earning, index) => {
                            const salary_code = earning.salary_code;
                            if (!salaryCodesSetEarnings.has(salary_code)) {
                              salaryCodesSetEarnings.add(salary_code);
                              uniqueEarningsHeaders.push(earning);
                            }
                          });
                      });
                    } else {
                      item.records[0].earnings
                        .slice(0, -1)
                        .forEach((earning, index) => {
                          const salary_code = earning.salary_code;
                          if (!salaryCodesSetEarnings.has(salary_code)) {
                            salaryCodesSetEarnings.add(salary_code);
                            uniqueEarningsHeaders.push(earning);
                          }
                        });
                    }
                  });
                  res.forEach((item) => {
                    if (item.is_multiple) {
                      item.records.map((record) => {
                        record.deductions.slice(0, -1).forEach((ded, index) => {
                          const salary_code = ded.salary_code;
                          if (!salaryCodesSetDeductions.has(salary_code)) {
                            salaryCodesSetDeductions.add(salary_code);
                            uniqueDeductionsHeaders.push(ded);
                          }
                        });
                      });
                    } else {
                      item.records[0].deductions
                        .slice(0, -1)
                        .forEach((ded, index) => {
                          const salary_code = ded.salary_code;
                          if (!salaryCodesSetDeductions.has(salary_code)) {
                            salaryCodesSetDeductions.add(salary_code);
                            uniqueDeductionsHeaders.push(ded);
                          }
                        });
                    }
                  });
                  res.forEach((item) => {
                    if (item.is_multiple) {
                      item.records.map((record) => {
                        record.accrued.forEach((acc, index) => {
                          const salary_code = acc.salary_code;
                          if (!salaryCodesSetAccrued.has(salary_code)) {
                            salaryCodesSetAccrued.add(salary_code);
                            uniqueAccruedHeaders.push(acc);
                          }
                        });
                      });
                    } else {
                      item.records[0].accrued.forEach((acc, index) => {
                        const salary_code = acc.salary_code;
                        if (!salaryCodesSetAccrued.has(salary_code)) {
                          salaryCodesSetAccrued.add(salary_code);
                          uniqueAccruedHeaders.push(acc);
                        }
                      });
                    }
                  });
                  setPrintingEarningsHeader(uniqueEarningsHeaders);
                  setPrintDeductionsHeader(uniqueDeductionsHeaders);
                  setPrintAccruedHeader(uniqueAccruedHeaders);

                  setIsPrintModalOpen(true);
                } else {
                  openNotification(
                    WARNING_NOTIFICATION_TYPE,
                    TAG,
                    'No records found'
                  );
                }
              }

              setIsLoading(false);
            }}
          >
            Schedule of crew wages (Advance)
          </Button>
          <Button
            block
            type='primary'
            disabled={
              isLoading ||
              month == null ||
              year == null ||
              selectedVessel.vessel_code == null ||
              selectedTemplate?.is_current == 0
            }
            onClick={async () => {
              setIsLoading(true);
              const { data: res, error } = await getCurrentCrewWages(
                selectedVessel.vessel_code,
                year,
                month
              );
              if (error) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  TAG,
                  'Processing failed.'
                );
              } else {
                if (res?.length > 0) {
                  const grandTotal = res.pop();
                  setGrandTotals(grandTotal);
                  setPrintingCrewList(res);
                  const uniqueEarningsHeaders = [];
                  const uniqueEarningsProrateHeaders = [];
                  const salaryCodesSetEarnings = new Set();
                  const salaryCodesSetEarningsProrate = new Set();

                  res?.forEach((item) => {
                    item.earnings.forEach((earning, index) => {
                      const salary_code = earning.salary_code;
                      if (!salaryCodesSetEarnings.has(salary_code)) {
                        salaryCodesSetEarnings.add(salary_code);
                        uniqueEarningsHeaders.push(earning);
                      }
                    });
                  });
                  res?.forEach((item) => {
                    item.earnings_prorate.forEach((earning, index) => {
                      const salary_code = earning.salary_code;
                      if (!salaryCodesSetEarningsProrate.has(salary_code)) {
                        salaryCodesSetEarningsProrate.add(salary_code);
                        uniqueEarningsProrateHeaders.push(earning);
                      }
                    });
                  });
                  setPrintingEarningsHeader(uniqueEarningsHeaders);
                  setPrintingEarningsProrateHeader(
                    uniqueEarningsProrateHeaders
                  );
                  setIsCurrentPrintModalOpen(true);
                } else {
                  openNotification(
                    WARNING_NOTIFICATION_TYPE,
                    TAG,
                    'No records found'
                  );
                }
              }

              setIsLoading(false);
            }}
          >
            Schedule of crew wages (Current)
          </Button>
          <Button
            type='primary'
            block
            disabled={
              isLoading ||
              month == null ||
              year == null ||
              selectedVessel.vessel_code == null ||
              selectedTemplate?.is_ukwages == 0
            }
            onClick={async () => {
              setIsLoading(true);
              const { data: res, error } = await getScheduleUKWages(
                selectedVessel.vessel_code,
                year,
                month
              );
              if (error) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  TAG,
                  'Processing failed.'
                );
              } else {
                if (res?.crew_list?.length > 0) {
                  setPrintingCrewList(res);
                  const uniqueEarningsHeaders = [];
                  const salaryCodesSetEarnings = new Set();
                  res?.crew_list?.forEach((item) => {
                    item.earnings.forEach((earning, index) => {
                      const salary_code = earning.salary_code;
                      if (!salaryCodesSetEarnings.has(salary_code)) {
                        salaryCodesSetEarnings.add(salary_code);
                        uniqueEarningsHeaders.push(earning);
                      }
                    });
                  });
                  setPrintingEarningsHeader(uniqueEarningsHeaders);

                  setIsUkWagesPrintModalOpen(true);
                }
              }

              setIsLoading(false);
            }}
          >
            Schedule of UK Wages
          </Button>
          <Button
            type='primary'
            block
            disabled={
              isLoading ||
              month == null ||
              year == null ||
              selectedVessel.vessel_code == null ||
              selectedTemplate?.is_home_allotment == 0
            }
            onClick={async () => {
              setIsLoading(true);
              const { data: res, error } = await getCrewHomeAllotment(
                selectedVessel.vessel_code,
                year,
                month
              );
              if (error) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  TAG,
                  'Fetching records failed.'
                );
              } else {
                if (res.length > 0) {
                  const grandTotal = res.pop();
                  setGrandTotals(grandTotal);
                  setHomeAllotmentList(res);
                  setIsCrewWagesHomeAllotmentOpen(true);
                } else {
                  openNotification(
                    WARNING_NOTIFICATION_TYPE,
                    TAG,
                    'No records found.'
                  );
                }
              }
              setIsLoading(false);
            }}
          >
            Home Allotment
          </Button>
          <Button
            block
            type='primary'
            disabled={
              isLoading ||
              month == null ||
              year == null ||
              selectedVessel.vessel_code == null ||
              crewList.length <= 0 ||
              includeCrewForPrint.length <= 0
            }
            onClick={async () => {
              setIsLoading(true);

              const payslips = [];
              try {
                await Promise.all(
                  crewList.map(async (crew) => {
                    const exists = includeCrewForPrint.some(
                      (record) => record.crew_no == crew.crew_no
                    );
                    if (exists) {
                      const { data: res, error } =
                        await getCrewIndividualPayslip(
                          crew.crew_no,
                          month,
                          year
                        );
                      if (error) {
                        openNotification(
                          ERROR_NOTIFICATION_TYPE,
                          TAG,
                          `Processing ${crew.crew_name} failed.`
                        );
                      } else {
                        if (res?.crew_no) {
                          payslips.push(res);
                        } else {
                          openNotification(
                            WARNING_NOTIFICATION_TYPE,
                            TAG,
                            `${crew.crew_name} payslip is not available.`
                          );
                        }
                      }
                    }
                  })
                );
              } catch (e) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  TAG,
                  'Processing payslips failed.'
                );
              }
              setIsLoading(false);
              if (payslips.length > 0) {
                setAllCrewIndividualPayslip(payslips);
                setIsAllCrewIndividualPayslipOpen(true);
              }
            }}
          >
            Individual payslip (All crew)
          </Button>
          <Button
            block
            danger
            disabled={isLoading}
            onClick={() => setIsFugroPrintModalOpen(false)}
          >
            Cancel
          </Button>
        </Flex>
      </CustomModal>
      <CustomModal
        isLoading={isLoading}
        isOpen={isForInitialModalOpen}
        setIsOpen={setIsForInitialModalOpen}
        cancelText='Close'
        props={{ width: '80vw' }}
        isClosable={true}
        onOk={() => {
          if (hasChanges) {
            setIsForInitialConfirmationOpen(true);
          } else {
            openNotification(
              SUCCESS_NOTIFICATION_TYPE,
              TAG,
              'No changes made.'
            );
          }
        }}
      >
        <Table
          columns={getCrewWagesForInitialTable(
            forInitialCrewList,
            setForInitialCrewList,
            setHasChanges,
            selectedTemplate
          )}
          dataSource={forInitialCrewList}
          className='m-3'
          size='small'
          bordered='true'
          scroll={{ x: true }}
          loading={isLoading}
          pagination={{
            defaultPageSize: 50,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} records`,
          }}
        />
      </CustomModal>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isForInitialConfirmationOpen}
        setIsOpen={setIsForInitialConfirmationOpen}
        onConfirm={async () => {
          let hasError = false;
          try {
            await Promise.all(
              forInitialCrewList.map(async (crew) => {
                switch (selectedTemplate?.cw_format) {
                  case 1:
                    const payload = {
                      ...crew,
                      month: month,
                      year: year,
                      initial_provf: crew.initial_prov,
                      disembark: crew.original_disembark,
                      embark: crew.embark_date,
                      beg_balance: crew.beg_balance,
                      initial_leave: crew.initial_leave
                        ? crew.initial_leave
                        : '0.00',
                    };
                    const { data: res, error } = await postUpdateLeaveProv(
                      payload
                    );
                    if (error) {
                      openNotification(
                        ERROR_NOTIFICATION_TYPE,
                        TAG,
                        `Updating ${crew.crew_name} failed.`
                      );
                      hasError = true;
                    }
                    break;
                  case 2:
                  case 3:
                    const fwPayload = {
                      ...crew,
                      final_pay: 0,
                      excess_ot: crew.excess_ot ? crew.excess_ot : 0,
                      return_bonus: crew.return_bonus ? crew.return_bonus : 0,
                      balance_transfer: crew.balance_transfer
                        ? crew.balance_transfer
                        : 0,
                      month: month,
                      year: year,
                    };
                    const { data: fwRes, fwError } =
                      await postAddCrewWagesInput(fwPayload);
                    if (fwError) {
                      openNotification(
                        ERROR_NOTIFICATION_TYPE,
                        TAG,
                        `Updating ${crew.crew_name} failed.`
                      );
                      hasError = true;
                    }
                    break;
                  default:
                    break;
                }
              })
            );
          } catch (e) {
            openNotification(
              ERROR_NOTIFICATION_TYPE,
              TAG,
              'Processing initial values failed.'
            );
            hasError = true;
          } finally {
            setIsLoading(false);
            if (!hasError) {
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                TAG,
                'Update successful'
              );
              setHasChanges(false);
              setIsForInitialConfirmationOpen(false);
              setIsForInitialModalOpen(false);
              loadCrewsByVessel();
            }
          }
        }}
        onCancel={() => {
          setForInitialCrewList(crewList);
          setIsForInitialConfirmationOpen(false);
        }}
      />
    </Content>
  );
}
