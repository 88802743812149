import axios from 'axios';
import { BASE_ENDPOINT, REQUEST_HEADERS } from './_constants';

const GET_ALL_PRINCIPAL_ENDPOINT = BASE_ENDPOINT + '/show_all_principal';
const POST_PROCESS_GOV_CONTRI_ENDPOINT = BASE_ENDPOINT + '/process_gov_contri';
const POST_SAVE_GOV_CONTRI_ENDPOINT = BASE_ENDPOINT + '/save_gov_contri';
const GET_PROCESSED_GOV_CONTRI_ENDPOINT =
  BASE_ENDPOINT + '/get_processed_govt_contri';

export async function getAllPrincipals(month, year) {
  try {
    const { data } = await axios.get(GET_ALL_PRINCIPAL_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getProcessedGovContri(pcode, month, year) {
  try {
    const { data } = await axios.get(
      `${GET_PROCESSED_GOV_CONTRI_ENDPOINT}/${pcode}/${month}/${year}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postProcessGovContri(payload) {
  try {
    const { data } = await axios.post(
      POST_PROCESS_GOV_CONTRI_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postSaveGovContri(payload) {
  try {
    const { data } = await axios.post(POST_SAVE_GOV_CONTRI_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
