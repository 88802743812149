import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Space, Table } from 'antd';
import Search from 'antd/es/input/Search';
import { IoIosRefresh } from 'react-icons/io';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { GlobalContext } from '../../../App';
import { UserContext } from '../../../utils/context/UserContext';
import DeductionType from '../../../utils/types/DeductionType';
import {
  getAllDeductions,
  postAddDeduction,
  postDeleteDeduction,
  postModifyDeduction,
} from '../../../utils/api/DeductionsAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../../utils/api/_constants';
import { getOtherDeductionsTable } from '../../../utils/tableheader/Maintenance/OtherDeductionsTable';
import FormModal from '../../../components/CustomModal/FormModal';
import { getOtherDeductionFields } from '../../../utils/inputfields/OtherDeductionsFields';
import FormInput from '../../../components/FormInput/FormInput';
import ConfirmationModal from '../../../components/CustomModal/ConfirmationModal';
import { getDeductionFields } from '../../../utils/inputfields/DeductionSetupFields';
import { getDeductionsTable } from '../../../utils/tableheader/Maintenance/DeductionsTable';

export default function DeductionsPage() {
  const { openNotification } = useContext(GlobalContext);
  const { doSearchInArray } = useContext(UserContext);

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [deductionsList, setDeductionsList] = useState([]);

  const [selectedDeduction, setSelectedDeduction] = useState(DeductionType);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);

  const tableContents = filteredList.map((item, index) => ({
    ...item,
    key: index,
  }));

  const handleAction = async (record, action) => {
    if (action == 'edit') {
      setSelectedDeduction(record);
      form.setFieldsValue(record);
      setIsFormModalOpen(true);
    } else if (action == 'delete') {
      setIsLoading(true);

      const { data: res, error } = await postDeleteDeduction(record);
      if (error) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Deduction',
          'Something went wrong!'
        );
      } else {
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          'Deduction',
          'Delete success'
        );
        fetchData();
      }
      setIsLoading(false);
    }
  };

  const getType = (record) => {
    let type = '';
    if (record.sss == 1) {
      type = 'sss';
    } else if (record.philhealth == 1) {
      type = 'philhealth';
    } else if (record.pag_ibig == 1) {
      type = 'pag_ibig';
    } else if (record.union == 1) {
      type = 'union';
    }
    return type;
  };

  const fetchData = async () => {
    setIsLoading(true);
    const { data: res, error } = await getAllDeductions();
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Deductions',
        'Loading deductions failed.'
      );
    } else {
      const recordWithType = res.map((item) => ({
        ...item,
        type: getType(item),
      }));
      setFilteredList(recordWithType);
      setDeductionsList(recordWithType);
      doSearchInArray(searchQuery, setFilteredList, recordWithType);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    form.setFieldsValue(selectedDeduction);
  }, [selectedDeduction]);

  return (
    <Content>
      <Row className='mb-4' justify={'end'}>
        <Col xs={24} sm={8} className='text-end'>
          <Search
            disabled={isLoading}
            placeholder='Search deduction!'
            onChange={(e) => {
              setSearchQuery(e.target.value);
              doSearchInArray(e.target.value, setFilteredList, deductionsList);
            }}
            onSearch={(value) => {
              setSearchQuery(value);
              doSearchInArray(value, setFilteredList, deductionsList);
            }}
            allowClear={true}
          />
        </Col>
        <Col xs={24} sm={3} className='text-end'>
          <Button
            className='mx-2'
            type='primary'
            icon={<AiOutlinePlusCircle size={18} />}
            disabled={isLoading}
            onClick={() => {
              setSelectedDeduction(DeductionType);
              form.setFieldsValue(DeductionType);
              setIsFormModalOpen(true);
            }}
          >
            Add
          </Button>
          <Button
            type='primary'
            icon={<IoIosRefresh size={18} />}
            disabled={isLoading}
            onClick={() => fetchData()}
          />
        </Col>
      </Row>
      <Table
        columns={getDeductionsTable(handleAction)}
        dataSource={tableContents}
        size='small'
        bordered='true'
        scroll={{ x: true }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <FormModal
        title={'Deductions Entry'}
        isOpen={isFormModalOpen}
        setIsOpen={setIsFormModalOpen}
        isLoading={isLoading}
        props={{ width: '50vw' }}
        form={form}
        formName={'deduction_form'}
        initialState={selectedDeduction}
        onSave={async () => {
          if (hasChanges) {
            try {
              await form.validateFields();
              setIsConfirmationModalOpen(true);
            } catch {}
          } else {
            openNotification(
              INFO_NOTIFICATION_TYPE,
              'Dedcution entry',
              'No changes made.'
            );
            setIsFormModalOpen(false);
          }
        }}
        onCancel={() => {
          if (hasChanges) {
            setIsUnsavedModalOpen(true);
          } else {
            form.resetFields();
            setIsFormModalOpen(false);
            setHasChanges(false);
          }
        }}
      >
        <Row gutter={16}>
          {getDeductionFields(
            isLoading,
            selectedDeduction,
            setSelectedDeduction,
            setHasChanges
          ).map((data, index) => (
            <Col key={index} xs={24} sm={12}>
              <FormInput
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            </Col>
          ))}
        </Row>
      </FormModal>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          if (selectedDeduction.id) {
            const { data: res, error } = await postModifyDeduction(
              selectedDeduction
            );
            if (error) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                'Deduction entry',
                'Error saving changes!'
              );
            } else {
              if (res.isSuccessful === 'false') {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'Adding Other deduction',
                  res.message
                );
              } else {
                openNotification(
                  SUCCESS_NOTIFICATION_TYPE,
                  'Deduction entry',
                  'Set up saved!'
                );
                setHasChanges(false);
                setIsConfirmationModalOpen(false);
                setIsFormModalOpen(false);
                fetchData();
              }
            }
          } else {
            const { data: res, error } = await postAddDeduction(
              selectedDeduction
            );
            if (error) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                'Deduction entry',
                'Error saving changes!'
              );
            } else {
              if (res.isSuccessful === 'false') {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'Adding Other deduction',
                  res.message
                );
              } else {
                openNotification(
                  SUCCESS_NOTIFICATION_TYPE,
                  'Deduction entry',
                  'Set up saved!'
                );
                setHasChanges(false);
                setIsConfirmationModalOpen(false);
                setIsFormModalOpen(false);
                fetchData();
              }
            }
          }

          setIsLoading(false);
        }}
      />
      <ConfirmationModal
        title='You have unsaved changes! Confirming will lose all your changes. Confirm?'
        cancelText='Continue editing'
        isLoading={isLoading}
        isOpen={isUnsavedModalOpen}
        setIsOpen={setIsUnsavedModalOpen}
        onConfirm={() => {
          form.resetFields();
          setIsUnsavedModalOpen(false);
          setIsFormModalOpen(false);
          setHasChanges(false);
        }}
      />
    </Content>
  );
}
