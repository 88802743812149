export const maintenanceTree = {
  title: 'Maintenance Module',
  key: 'main_maintenance',
  children: [
    {
      title: 'Vessel Parameters',
      key: 'maintenance_vessel_parameters',
      children: [
        {
          title: 'View',
          key: 'function_maintenance_vessel_parameters_view',
        },
        {
          title: 'Modify',
          key: 'function_maintenance_vessel_parameters_modify',
        },
      ],
    },
    {
      title: 'Define Various Tables',
      key: 'maintenance_dvt',
      children: [
        {
          title: 'Deductions',
          key: 'feature_maintenance_dvt_deductions',
          children: [
            {
              title: 'View',
              key: 'function_maintenance_dvt_deductions_view',
            },
            {
              title: 'Modify',
              key: 'function_maintenance_dvt_deductions_modify',
            },
            {
              title: 'Delete',
              key: 'function_maintenance_dvt_deductions_delete',
            },
          ],
        },
        {
          title: 'Other Deductions',
          key: 'feature_maintenance_dvt_other_deductions',
          children: [
            {
              title: 'View',
              key: 'function_maintenance_dvt_other_deductions_view',
            },
            {
              title: 'Modify',
              key: 'function_maintenance_dvt_other_deductions_modify',
            },
            {
              title: 'Delete',
              key: 'function_maintenance_dvt_other_deductions_delete',
            },
          ],
        },
        {
          title: 'Union Dues',
          key: 'feature_maintenance_dvt_other_union_dues',
          children: [
            {
              title: 'View',
              key: 'function_maintenance_dvt_other_union_dues_view',
            },
            {
              title: 'Modify',
              key: 'function_maintenance_dvt_other_union_dues_modify',
            },
            {
              title: 'Delete',
              key: 'function_maintenance_dvt_other_union_dues_delete',
            },
          ],
        },
      ],
    },
    {
      title: 'Government Contributions',
      key: 'maintenance_govt_contri',
      children: [
        {
          title: 'SSS',
          key: 'feature_maintenance_govt_contri_sss',
          children: [
            {
              title: 'View',
              key: 'function_maintenance_govt_contri_sss_view',
            },
            {
              title: 'Modify',
              key: 'function_maintenance_govt_contri_sss_modify',
            },
            {
              title: 'Add',
              key: 'function_maintenance_govt_contri_sss_add',
            },
            {
              title: 'Copy To',
              key: 'function_maintenance_govt_contri_sss_copy_to',
            },
            {
              title: 'Delete',
              key: 'function_maintenance_govt_contri_sss_delete',
            },
          ],
        },
        {
          title: 'Philhealth',
          key: 'feature_maintenance_govt_contri_philhealth',
          children: [
            {
              title: 'View',
              key: 'function_maintenance_govt_contri_philhealth_view',
            },
            {
              title: 'Modify',
              key: 'function_maintenance_govt_contri_philhealth_modify',
            },
            {
              title: 'Add',
              key: 'function_maintenance_govt_contri_philhealth_add',
            },
            {
              title: 'Copy To',
              key: 'function_maintenance_govt_contri_philhealth_copy_to',
            },
            {
              title: 'Delete',
              key: 'function_maintenance_govt_contri_philhealth_delete',
            },
          ],
        },
        {
          title: 'Pag-ibig',
          key: 'feature_maintenance_govt_contri_pagibig',
          children: [
            {
              title: 'View',
              key: 'function_maintenance_govt_contri_pagibig_view',
            },
            {
              title: 'Modify',
              key: 'function_maintenance_govt_contri_pagibig_modify',
            },
            {
              title: 'Add',
              key: 'function_maintenance_govt_contri_pagibig_add',
            },
            {
              title: 'Delete',
              key: 'function_maintenance_govt_contri_pagibig_delete',
            },
          ],
        },
      ],
    },
    {
      title: 'Exchange Rate',
      key: 'maintenance_exchange_rate',
      children: [
        {
          title: 'View',
          key: 'function_maintenance_exchange_rate_view',
        },
        {
          title: 'Modify',
          key: 'function_maintenance_exchange_rate_modify',
        },
        {
          title: 'Delete',
          key: 'function_maintenance_exchange_rate_delete',
        },
      ],
    },
    {
      title: 'Crew Wages',
      key: 'maintenance_crew_wages',
      children: [
        {
          title: 'View',
          key: 'function_maintenance_crew_wages_view',
        },
        {
          title: 'Modify',
          key: 'function_maintenance_crew_wages_modify',
        },
      ],
    },
  ],
};
