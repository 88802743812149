export default {
  crew_no: null,
  allottee_id: null,
  allottee_name: null,
  allt_on_board_crew_id: null,
  date_requested: null,
  mpo_amount: null,
  deduct_on_leave_final: null,
  apply: null,
};
