import React, { useContext, useEffect, useState } from 'react';
import CrewType from '../../../../../utils/types/CrewType';
import { Content } from 'antd/es/layout/layout';
import { Button, Col, Form, Row, Switch, Table, Tabs, Typography } from 'antd';
import { getSalaryBreakdownTable } from '../../../../../utils/tableheader/Transactions/SalaryBreakdownTable';
import { UserContext } from '../../../../../utils/context/UserContext';
import { getSalaryBreakdownFields } from '../../../../../utils/inputfields/SalaryBreakdownFields';
import FormInput from '../../../../../components/FormInput/FormInput';
import ConfirmationModal from '../../../../../components/CustomModal/ConfirmationModal';
import {
  getProcessAdjustmentEndpoint,
  postProcessAdjustmentPerCrewEndpoint,
  postSalaryBreakdownHeader,
} from '../../../../../utils/api/TransactionsAPI';
import { GlobalContext } from '../../../../../App';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../../../../utils/api/_constants';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

export default function EmbarkedSalaryBreakdown({
  selectedCrew = CrewType,
  isLoading,
  setIsLoading,
  hasChanges,
  setHasChanges,
  fetchDatas,
  date,
}) {
  const { inputToLocale } = useContext(UserContext);
  const { openNotification } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [allotment, setAllotment] = useState(CrewType.allotment);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [isProcessAdjustmentOn, setIsProcessAdjustmentOn] = useState(false);
  // const [totalAdjustmentAmount, setTotalAdjustmentAmount] = useState(0);
  // const [sideLetterBonusAmount, setSideLetterBonusAmount] = useState(0);

  const [salaryBreakdownTabs, setSalaryBreakdownTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const getProcessAdjustment = async () => {
    const { data: res, error } = await getProcessAdjustmentEndpoint(
      selectedCrew.crew_no
    );
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Process Adjustment',
        'Fetching adjustment failed'
      );
    } else {
      if (res) {
        if (res.process_adjustment == 1) {
          setIsProcessAdjustmentOn(true);
        } else {
          setIsProcessAdjustmentOn(false);
        }
      } else {
        setIsProcessAdjustmentOn(false);
      }
    }
  };

  const calculateTotalAdjustment = () => {
    const sum = selectedCrew.allotment?.allottee?.reduce(
      (accumulator, currentValue) => {
        return (
          accumulator +
          parseFloat(currentValue?.regular_allotment?.adjustment_amount)
        );
      },
      0
    );
    return sum;
  };

  const calculateSideLetterBonus = () => {
    const sum = selectedCrew.allotment?.allottee?.reduce(
      (accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue?.side_letter?.usd_amount);
      },
      0
    );
    return sum;
  };

  useEffect(() => {
    getProcessAdjustment();
    const totalAdjustmentAmount = calculateTotalAdjustment();
    const sideLetterBonusAmount = calculateSideLetterBonus();
    let allottt;
    if (selectedCrew.allotment?.id) {
      allottt = {
        ...selectedCrew.allotment,
        total_adjustment: inputToLocale(totalAdjustmentAmount),
        side_letter_bonus: inputToLocale(sideLetterBonusAmount),
        adjustment_date_from: selectedCrew.allotment.adjustment_date_from
          ? dayjs(selectedCrew.allotment.adjustment_date_from, 'YYYY-MM-DD')
          : null,
        adjustment_date_to: selectedCrew.allotment.adjustment_date_to
          ? dayjs(selectedCrew.allotment.adjustment_date_to, 'YYYY-MM-DD')
          : null,
      };
    } else {
      allottt = {
        ...selectedCrew.allotment,
        allotment_type: null,
        allotment_amount: null,
        allotment_percentage: null,
        total_adjustment: inputToLocale(totalAdjustmentAmount),
        side_letter_bonus: inputToLocale(sideLetterBonusAmount),
        adjustment_date_from: selectedCrew.allotment.adjustment_date_from
          ? dayjs(selectedCrew.allotment.adjustment_date_from, 'YYYY-MM-DD')
          : null,
        adjustment_date_to: selectedCrew.allotment.adjustment_date_to
          ? dayjs(selectedCrew.allotment.adjustment_date_to, 'YYYY-MM-DD')
          : null,
      };
    }
    form.setFieldsValue(allottt);
    setAllotment(allottt);

    const tabs = selectedCrew.salary_details
      .filter((rec) => {
        const isPast = dayjs(rec.date).isSameOrBefore(
          dayjs(`${date.year}-${date.month}`),
          'month'
        );

        return rec.transaction_type != 'disembarkation' && isPast;
      })
      .map((details, index) => ({
        key: index,
        label: details.transaction_type,
        children: (
          <>
            <Table
              columns={getSalaryBreakdownTable()}
              dataSource={details.salary.map((item, ind) => ({
                ...item,
                key: ind,
                amount: item.salary_code !== 'HOURLY-OT1' ? item.amount : '',
                hourly_rate:
                  item.salary_code === 'HOURLY-OT1' ? item.amount : '',
              }))}
              size='small'
              bordered='true'
              scroll={{ x: true }}
              loading={isLoading}
            />
            <Typography.Text strong>Date: {details.date}</Typography.Text>
          </>
        ),
      }));
    setSalaryBreakdownTabs(tabs);
  }, [selectedCrew, isProcessAdjustmentOn]);

  useEffect(() => {
    form.setFieldsValue(allotment);
  }, [allotment]);

  return (
    <Content>
      <Row>
        <Col xs={24} sm={12}>
          <Tabs
            items={salaryBreakdownTabs}
            centered
            defaultActiveKey='1'
            onChange={(key) => setActiveTab(key)}
          />
          <Typography.Text strong>
            Total:{' '}
            {inputToLocale(
              selectedCrew.salary_details[activeTab].salary
                .filter((item) => item.salary_code !== 'HOURLY-OT1')
                .map((rec) => parseFloat(rec.amount))
                .reduce((acc, value) => acc + value, 0)
            )}
          </Typography.Text>
        </Col>
        <Col xs={24} sm={12}>
          <FormInput
            props={{ className: 'ms-3' }}
            type='toggle'
            name='full_month_toggle'
            label={'Use adjustment header?'}
            required={false}
            disabled={isLoading}
            onChange={async (e) => {
              const payload = {
                crew_no: selectedCrew.crew_no,
                process_adjustment: e ? 1 : 0,
              };
              const { data: res, error } =
                await postProcessAdjustmentPerCrewEndpoint(payload);
              if (error) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'Process Adjustment',
                  'Updating process adjustment failed.'
                );
              } else {
                setIsProcessAdjustmentOn(e);
              }
            }}
            value={isProcessAdjustmentOn}
          />
          <Form
            className='m-3'
            labelAlign='left'
            form={form}
            name='salary_breakdown_form'
            initialValues={allotment}
            layout='vertical'
          >
            <Row gutter={16}>
              {getSalaryBreakdownFields(
                isLoading,
                allotment,
                setAllotment,
                setHasChanges,
                selectedCrew.salary_details[0].salary.find(
                  (item) => item.salary_code === 'BASIC-PAY'
                )?.amount,
                inputToLocale
              ).map((data, index) => (
                <Col xs={24} sm={12} key={index}>
                  <FormInput
                    type={data.type}
                    name={data.name}
                    placeholder={data.placeholder}
                    label={data.label}
                    autoFocus={data.autofocus}
                    value={data.value}
                    onChange={data.setValue}
                    required={data.required}
                    disabled={data.disabled}
                    dropdownOptions={data.dropdownOptions}
                    props={data.props}
                  />
                </Col>
              ))}
            </Row>
            {hasChanges && (
              <Row>
                <Button
                  danger
                  className='me-2'
                  onClick={() => {
                    form.resetFields();
                    const totalAdjustmentAmount = calculateTotalAdjustment();
                    const sideLetterBonusAmount = calculateSideLetterBonus();
                    const allottt = {
                      ...selectedCrew.allotment,
                      total_adjustment: inputToLocale(totalAdjustmentAmount),
                      side_letter_bonus: inputToLocale(sideLetterBonusAmount),
                      adjustment_date_from: selectedCrew.allotment
                        .adjustment_date_from
                        ? dayjs(
                            selectedCrew.allotment.adjustment_date_from,
                            'YYYY-MM-DD'
                          )
                        : null,
                      adjustment_date_to: selectedCrew.allotment
                        .adjustment_date_to
                        ? dayjs(
                            selectedCrew.allotment.adjustment_date_to,
                            'YYYY-MM-DD'
                          )
                        : null,
                    };
                    form.setFieldsValue(allottt);
                    setHasChanges(false);
                  }}
                >
                  Cancel
                </Button>

                <Button
                  type='primary'
                  onClick={() => setIsConfirmationModalOpen(true)}
                >
                  Save
                </Button>
              </Row>
            )}
          </Form>
        </Col>
      </Row>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onCancel={() => setIsConfirmationModalOpen(false)}
        onConfirm={async () => {
          await form.validateFields();
          setIsLoading(true);
          const payload = {
            ...allotment,
            datefrom: dayjs(allotment.adjustment_date_from).format(
              'YYYY-MM-DD'
            ),
            dateto: dayjs(allotment.adjustment_date_to).format('YYYY-MM-DD'),
            adjustment_date_to: dayjs(allotment.adjustment_date_to).format(
              'YYYY-MM-DD'
            ),
            adjustment_date_from: dayjs(allotment.adjustment_date_from).format(
              'YYYY-MM-DD'
            ),
          };
          const { data: res, error } = await postSalaryBreakdownHeader(payload);
          if (error) {
            openNotification(
              ERROR_NOTIFICATION_TYPE,
              'Allotment breakdown',
              'Error saving allotment breakdown.'
            );
          } else {
            openNotification(
              SUCCESS_NOTIFICATION_TYPE,
              'Allotment breakdown',
              'Saving breadown success.'
            );
            setHasChanges(false);
            setIsConfirmationModalOpen(false);
            fetchDatas();
          }
          setIsLoading(false);
        }}
      />
    </Content>
  );
}
