import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import React, { useContext } from 'react';
import logoImage from '../../assets/icon-name.png';
import dayjs from 'dayjs';
import { UserContext } from '../../utils/context/UserContext';

const styles = StyleSheet.create({
  headerImage: {
    height: 60,
    width: 60,
  },
  headerText: {
    fontSize: 9,
  },

  detailsText: {
    fontSize: 8,
  },

  container: { flexDirection: 'row', margin: 0, padding: 0 },
  row: { flexDirection: 'row', margin: 0, marginBottom: 2, padding: 0 },
  column: {
    flex: 1,
    margin: 0,
  },

  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
  },
  tableRow: {
    flexDirection: 'row',
    borderTopWidth: 0,
    paddingBottom: 0,
    margin: 0,
    padding: 0,
  },
  tableCell: {
    width: '6%',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    paddingTop: 5,
    paddingBottom: 0,
    paddingHorizontal: 1,
    fontSize: '7px',
    marginBottom: 0,
  },

  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: 10,
    paddingHorizontal: 40,
    marginBottom: 60,
  },
});

export default function CrewWagesIndividualPayslipPDF({
  payslipDetails,
  month,
  year,
  withProvFund,
}) {
  const { inputToLocale } = useContext(UserContext);

  const computeAccruedBroughtForward = (accruedArray) => {
    let total;
    if (withProvFund) {
      total = accruedArray.reduce(
        (accumulator, currentValue) => accumulator + currentValue.total,
        0
      );
    } else {
      total = accruedArray
        .filter((item) => item.salary_code !== 'PROVF')
        .reduce(
          (accumulator, currentValue) => accumulator + currentValue.total,
          0
        );
    }
    return inputToLocale(total);
  };

  return (
    <PDFViewer style={{ width: '98%', height: '100vh' }}>
      <Document>
        <Page size='A4' wrap style={{ margin: 5 }}>
          <View
            style={{
              ...styles.container,
            }}
            fixed
          >
            <View
              style={{
                paddingVertical: 0,
                marginRight: 0,
                marginTop: 4,
              }}
            >
              <Image src={logoImage} style={styles.headerImage} />
            </View>
            <View style={{ paddingTop: 39, marginLeft: 1 }}>
              <Text style={styles.headerText}>INDIVIDUAL SALARY SLIP</Text>
              <Text style={styles.headerText}>
                {`${dayjs(`${month}-${year}`, 'M-YYYY')
                  .subtract(1, 'month')
                  .format('MMMM YYYY')
                  .toUpperCase()}     <PERIOD: ${dayjs(
                  `${month}-${year}`,
                  'M-YYYY'
                )
                  .subtract(1, 'month')
                  .startOf('month')
                  .format('MMMM DD, YYYY')
                  .toUpperCase()} - ${dayjs(`${month}-${year}`, 'M-YYYY')
                  .subtract(1, 'month')
                  .endOf('month')
                  .format('MMMM DD, YYYY')
                  .toUpperCase()}>`}
              </Text>
            </View>
          </View>
          <View style={{ marginLeft: 3, marginTop: 10 }}>
            <Text
              style={{ fontSize: 12 }}
            >{`${payslipDetails.crew_name} <${payslipDetails.crew_no}>`}</Text>
            <Text
              style={{ fontSize: 8 }}
            >{`${payslipDetails.position} (POSITION)`}</Text>
            <Text
              style={{ fontSize: 8 }}
            >{`${payslipDetails.vessel_name} <${payslipDetails.vescode}>`}</Text>
          </View>

          <View
            style={{
              marginVertical: 5,
              height: 1,
              width: '95%',
              backgroundColor: 'black',
            }}
          />
          <View style={{ ...styles.row, marginLeft: 10 }}>
            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.detailsText }}>BASIC WAGE/MONTH</Text>
            </View>
            <View style={{ ...styles.column, paddingLeft: 20 }}>
              <Text style={{ ...styles.detailsText }}>$</Text>
            </View>
            <View style={{ ...styles.column, textAlign: 'right' }}>
              <Text style={{ ...styles.detailsText, paddingRight: 40 }}>
                {inputToLocale(payslipDetails.basic_pay)}
              </Text>
            </View>

            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.detailsText }}>Departure Date: </Text>
            </View>
            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.detailsText }}>
                {payslipDetails.sign_on != null
                  ? dayjs(payslipDetails.sign_on, 'YYYY-MM-DD').format(
                      'MM/DD/YYYY'
                    )
                  : ''}
              </Text>
            </View>

            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.detailsText }}>Sign-Off:</Text>
            </View>
            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.detailsText }}>
                {payslipDetails.crew_status != 'on-board'
                  ? dayjs(payslipDetails.disembark, 'YYYY-MM-DD').format(
                      'MM/DD/YYYY'
                    )
                  : ''}
              </Text>
            </View>
          </View>

          <View style={{ ...styles.row, marginLeft: 10 }}>
            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.detailsText }}>FIXED OVERTIME:</Text>
            </View>
            <View style={{ ...styles.column, paddingLeft: 20 }}>
              <Text style={{ ...styles.detailsText }}>$</Text>
            </View>
            <View style={{ ...styles.column, textAlign: 'right' }}>
              <Text style={{ ...styles.detailsText, paddingRight: 40 }}>
                {inputToLocale(payslipDetails.fixed_ot)}
              </Text>
            </View>

            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.detailsText }}>Sign-On: </Text>
            </View>
            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.detailsText }}>
                {payslipDetails.embark != null
                  ? dayjs(payslipDetails.embark, 'YYYY-MM-DD').format(
                      'MM/DD/YYYY'
                    )
                  : ''}
              </Text>
            </View>

            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.detailsText }}>Arrived Mla:</Text>
            </View>
            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.detailsText }}>
                {payslipDetails.crew_status != 'on-board'
                  ? dayjs(payslipDetails.sign_off, 'YYYY-MM-DD').format(
                      'MM/DD/YYYY'
                    )
                  : ''}
              </Text>
            </View>
          </View>

          <View style={{ ...styles.row, marginLeft: 10 }}>
            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.detailsText }}>LEAVE PAY/MONTH</Text>
            </View>
            <View style={{ ...styles.column, paddingLeft: 20 }}>
              <Text style={{ ...styles.detailsText }}>$</Text>
            </View>
            <View
              style={{
                ...styles.column,
                textAlign: 'right',
              }}
            >
              <Text style={{ ...styles.detailsText, paddingRight: 40 }}>
                {inputToLocale(payslipDetails.leave_pay)}
              </Text>
            </View>

            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.detailsText }}></Text>
            </View>
            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.detailsText }}></Text>
            </View>

            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.detailsText }}></Text>
            </View>
            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.detailsText }}></Text>
            </View>
          </View>

          <View
            style={{
              marginVertical: 5,
              height: 1,
              width: '95%',
              backgroundColor: 'black',
            }}
          />

          <View style={{ ...styles.row, marginLeft: 3 }}>
            <View style={{ ...styles.column }}>
              <Text
                style={{
                  ...styles.detailsText,
                  textDecoration: 'underline',
                }}
              >
                PREVIOUS MONTH BALANCE:
              </Text>
            </View>
            <View
              style={{
                ...styles.column,
                textAlign: 'right',
                paddingRight: 100,
              }}
            >
              <View
                style={{
                  marginLeft: '80%',
                  width: '20%',
                  marginBottom: '2',
                  borderBottomWidth: 1,
                }}
              >
                <Text
                  style={{
                    ...styles.detailsText,
                  }}
                >
                  {inputToLocale(payslipDetails.prev_balance)}
                </Text>
              </View>
            </View>
          </View>

          <View style={{ ...styles.row, marginLeft: 3, marginTop: 20 }}>
            <View style={{ ...styles.column }}>
              <Text
                style={{
                  ...styles.detailsText,
                  textDecoration: 'underline',
                }}
              >
                CURRENT MONTH EARNINGS AND DEDUCTIONS:
              </Text>
            </View>
          </View>

          <View style={{ ...styles.row, marginLeft: 3, marginTop: 20 }}>
            <View style={{ ...styles.column }}>
              <Text
                style={{
                  ...styles.detailsText,
                  textDecoration: 'underline',
                }}
              >
                EARNINGS:{' '}
                {parseFloat(payslipDetails.no_of_days) > 0
                  ? `${payslipDetails.no_of_days} DAY(S)`
                  : ''}
              </Text>
            </View>
          </View>

          {payslipDetails.earnings
            .map((earning, index) => (
              <>
                {parseFloat(earning.amount) > 0 && (
                  <View style={{ ...styles.row, marginLeft: 10, marginTop: 2 }}>
                    <View style={{ ...styles.column }}>
                      <Text
                        style={{
                          ...styles.detailsText,
                        }}
                      >
                        {earning.wage_other_desc}:
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.column,
                        textAlign: 'right',
                        paddingRight: 200,
                      }}
                    >
                      <Text
                        style={{
                          ...styles.detailsText,
                        }}
                      >
                        {inputToLocale(earning.amount)}
                      </Text>
                    </View>
                  </View>
                )}
              </>
            ))
            .slice(0, -1)}
          <View style={{ ...styles.row, marginLeft: 100, marginTop: 2 }}>
            <View style={{ ...styles.column }}>
              <Text
                style={{
                  ...styles.detailsText,
                }}
              >
                {`TOTAL EARNINGS ==>>`}
              </Text>
            </View>
            <View
              style={{
                ...styles.column,
                textAlign: 'right',
                paddingRight: 100,
              }}
            >
              <View
                style={{
                  marginLeft: '80%',
                  width: '20%',
                  marginBottom: '2',
                  borderBottomWidth: 1,
                }}
              >
                <Text
                  style={{
                    ...styles.detailsText,
                  }}
                >
                  {inputToLocale(
                    payslipDetails.earnings[payslipDetails.earnings.length - 1]
                      ?.total_earnings
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ ...styles.row, marginLeft: 100, marginTop: -3 }}>
            <View style={{ ...styles.column }}></View>
            <View
              style={{
                ...styles.column,
                textAlign: 'right',
                paddingRight: 100,
              }}
            >
              <Text
                style={{
                  ...styles.detailsText,
                }}
              >
                {inputToLocale(
                  parseFloat(
                    payslipDetails.earnings[payslipDetails.earnings.length - 1]
                      ?.total_earnings
                  ) + parseFloat(payslipDetails.prev_balance)
                )}
              </Text>
            </View>
          </View>

          <View style={{ ...styles.row, marginLeft: 3, marginTop: 20 }}>
            <View style={{ ...styles.column }}>
              <Text
                style={{
                  ...styles.detailsText,
                  textDecoration: 'underline',
                }}
              >
                DEDUCTIONS:
              </Text>
            </View>
          </View>

          {payslipDetails.deductions[payslipDetails.deductions.length - 1]
            ?.total_deductions != 0 && (
            <>
              {payslipDetails.deductions
                .map((earning, index) => (
                  <>
                    {parseFloat(earning.amount) > 0 && (
                      <View
                        style={{ ...styles.row, marginLeft: 10, marginTop: 2 }}
                      >
                        <View style={{ ...styles.column }}>
                          <Text
                            style={{
                              ...styles.detailsText,
                            }}
                          >
                            {earning.wage_other_desc}:
                          </Text>
                        </View>
                        <View
                          style={{
                            ...styles.column,
                            textAlign: 'right',
                            paddingRight: 200,
                          }}
                        >
                          <Text
                            style={{
                              ...styles.detailsText,
                            }}
                          >
                            {inputToLocale(earning.amount)}
                          </Text>
                        </View>
                      </View>
                    )}
                  </>
                ))
                .slice(0, -1)}
              <View style={{ ...styles.row, marginLeft: 100, marginTop: 2 }}>
                <View style={{ ...styles.column }}>
                  <Text
                    style={{
                      ...styles.detailsText,
                    }}
                  >
                    {`TOTAL DEDUCTIONS ==>>`}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.column,
                    textAlign: 'right',
                    paddingRight: 100,
                  }}
                >
                  <View
                    style={{
                      marginLeft: '80%',
                      width: '20%',
                      marginBottom: '2',
                      borderBottomWidth: 1,
                    }}
                  >
                    <Text
                      style={{
                        ...styles.detailsText,
                      }}
                    >
                      {inputToLocale(
                        payslipDetails.deductions[
                          payslipDetails.deductions.length - 1
                        ]?.total_deductions
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </>
          )}

          <View style={{ ...styles.row, marginLeft: 3, marginTop: 10 }}>
            <View style={{ ...styles.column }}>
              <Text
                style={{
                  ...styles.detailsText,
                  textDecoration: 'underline',
                }}
              >
                {`BROUGHT FORWARD TO NEXT MONTH ==>>`}
              </Text>
            </View>
            <View
              style={{
                ...styles.column,
                textAlign: 'right',
                paddingRight: 100,
              }}
            >
              <View
                style={{
                  marginLeft: '80%',
                  width: '20%',
                  marginBottom: '2',
                  borderTopWidth: 1,
                  borderBottomWidth: 1,
                }}
              >
                <Text
                  style={{
                    ...styles.detailsText,
                    marginBottom: 1,
                    borderBottomWidth: 1,
                  }}
                >
                  {inputToLocale(
                    parseFloat(
                      payslipDetails.earnings[
                        payslipDetails.earnings.length - 1
                      ]?.total_earnings
                    ) +
                      parseFloat(payslipDetails.prev_balance) -
                      parseFloat(
                        payslipDetails.deductions[
                          payslipDetails.deductions.length - 1
                        ]?.total_deductions
                      )
                  )}
                </Text>
              </View>
            </View>
          </View>
          {payslipDetails.accrued.length !== 0 && (
            <>
              <Text
                style={{
                  ...styles.detailsText,
                  textDecoration: 'underline',
                  marginLeft: 50,
                }}
              >
                {withProvFund
                  ? 'ACCUMULATED LEAVE & PROV. FUND:'
                  : 'ACCUMULATED LEAVE PAY:'}
              </Text>
              <View style={{ display: 'inline-flex', flexDirection: 'row' }}>
                {payslipDetails.accrued?.some(
                  (item) => item.salary_code === 'LEAVEPAY'
                ) !== null && (
                  <View style={{ width: '25%' }}>
                    <View style={{ ...styles.table }}>
                      <View style={{ ...styles.tableRow }}>
                        <View
                          style={{
                            ...styles.tableCell,
                            border: 0,
                            width: '60%',
                          }}
                        ></View>
                        <View
                          style={{
                            ...styles.tableCell,
                            width: '40%',
                            border: 0,
                            textAlign: 'right',
                            borderBottom: 1,
                          }}
                        >
                          <Text>LEAVE PAY</Text>
                        </View>
                      </View>
                      <View style={{ ...styles.tableRow }}>
                        <View
                          style={{
                            ...styles.tableCell,
                            border: 0,
                            width: '60%',
                          }}
                        >
                          <Text>Previous Month:</Text>
                        </View>
                        <View
                          style={{
                            ...styles.tableCell,
                            border: 0,
                            textAlign: 'right',
                            width: '40%',
                          }}
                        >
                          <Text>
                            {inputToLocale(
                              payslipDetails.accrued?.find(
                                (sal) => sal.salary_code === 'LEAVEPAY'
                              )?.prev_month
                            )}
                          </Text>
                        </View>
                      </View>
                      <View style={{ ...styles.tableRow }}>
                        <View
                          style={{
                            ...styles.tableCell,
                            border: 0,
                            width: '60%',
                          }}
                        >
                          <Text>
                            Current Month{' '}
                            {parseFloat(payslipDetails.no_of_days) > 0
                              ? `${payslipDetails.no_of_days} DAY(S)`
                              : ''}
                          </Text>
                        </View>
                        <View
                          style={{
                            ...styles.tableCell,
                            border: 0,
                            textAlign: 'right',
                            width: '40%',
                          }}
                        >
                          <Text>
                            {inputToLocale(
                              payslipDetails.accrued?.find(
                                (sal) => sal.salary_code === 'LEAVEPAY'
                              )?.current_month
                            )}
                          </Text>
                        </View>
                      </View>
                      <View style={{ ...styles.tableRow }}>
                        <View
                          style={{
                            ...styles.tableCell,
                            width: '60%',
                            border: 0,
                          }}
                        >
                          <Text>Prev Month Adjustment</Text>
                        </View>
                        <View
                          style={{
                            ...styles.tableCell,
                            border: 0,
                            textAlign: 'right',
                            width: '40%',
                          }}
                        >
                          <Text>
                            {inputToLocale(
                              payslipDetails.accrued?.find(
                                (sal) => sal.salary_code === 'LEAVEPAY'
                              )?.prev_month_adjustment
                            )}
                          </Text>
                        </View>
                      </View>
                      <View style={{ ...styles.tableRow }}>
                        <View
                          style={{
                            ...styles.tableCell,
                            width: '60%',
                            border: 0,
                          }}
                        ></View>
                        <View
                          style={{
                            ...styles.tableCell,
                            border: 0,
                            width: '40%',
                            borderTop: 1,
                            textAlign: 'right',
                          }}
                        >
                          <Text>
                            {inputToLocale(
                              payslipDetails.accrued?.find(
                                (sal) => sal.salary_code === 'LEAVEPAY'
                              )?.total
                            )}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                )}
                <View style={{ width: '15' }}></View>
                {payslipDetails.accrued?.some(
                  (item) => item.salary_code === 'PROVF'
                ) &&
                  withProvFund && (
                    <View style={{ width: '10%' }}>
                      <View style={{ ...styles.table }}>
                        <View style={{ ...styles.tableRow }}>
                          <View
                            style={{
                              ...styles.tableCell,
                              width: '100%',
                              border: 0,
                              textAlign: 'right',
                              borderBottom: 1,
                            }}
                          >
                            <Text>PROV. FUND</Text>
                          </View>
                        </View>
                        <View style={{ ...styles.tableRow }}>
                          <View
                            style={{
                              ...styles.tableCell,
                              border: 0,
                              textAlign: 'right',
                              width: '100%',
                            }}
                          >
                            <Text>
                              {inputToLocale(
                                payslipDetails.accrued?.find(
                                  (sal) => sal.salary_code === 'PROVF'
                                )?.prev_month
                              )}
                            </Text>
                          </View>
                        </View>
                        <View style={{ ...styles.tableRow }}>
                          <View
                            style={{
                              ...styles.tableCell,
                              border: 0,
                              textAlign: 'right',
                              width: '100%',
                            }}
                          >
                            <Text>
                              {inputToLocale(
                                payslipDetails.accrued?.find(
                                  (sal) => sal.salary_code === 'PROVF'
                                )?.current_month
                              )}
                            </Text>
                          </View>
                        </View>
                        <View style={{ ...styles.tableRow }}>
                          <View
                            style={{
                              ...styles.tableCell,
                              border: 0,
                              textAlign: 'right',
                              width: '100%',
                            }}
                          >
                            <Text>
                              {inputToLocale(
                                payslipDetails.accrued?.find(
                                  (sal) => sal.salary_code === 'PROVF'
                                )?.prev_month_adjustment
                              )}
                            </Text>
                          </View>
                        </View>
                        <View style={{ ...styles.tableRow }}>
                          <View
                            style={{
                              ...styles.tableCell,
                              border: 0,
                              width: '100%',
                              borderTop: 1,
                              textAlign: 'right',
                            }}
                          >
                            <Text>
                              {inputToLocale(
                                payslipDetails.accrued?.find(
                                  (sal) => sal.salary_code === 'PROVF'
                                )?.total
                              )}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  )}
                {payslipDetails.accrued?.map((item) => {
                  if (
                    item.salary_code != 'PROVF' &&
                    item.salary_code != 'LEAVEPAY'
                  ) {
                    return (
                      <>
                        <View style={{ width: '15' }}></View>
                        <View style={{ width: '10%' }}>
                          <View style={{ ...styles.table }}>
                            <View style={{ ...styles.tableRow }}>
                              <View
                                style={{
                                  ...styles.tableCell,
                                  width: '100%',
                                  border: 0,
                                  textAlign: 'right',
                                  borderBottom: 1,
                                }}
                              >
                                <Text>{item.salary_code}</Text>
                              </View>
                            </View>
                            <View style={{ ...styles.tableRow }}>
                              <View
                                style={{
                                  ...styles.tableCell,
                                  border: 0,
                                  textAlign: 'right',
                                  width: '100%',
                                }}
                              >
                                <Text>{inputToLocale(item?.prev_month)}</Text>
                              </View>
                            </View>
                            <View style={{ ...styles.tableRow }}>
                              <View
                                style={{
                                  ...styles.tableCell,
                                  border: 0,
                                  textAlign: 'right',
                                  width: '100%',
                                }}
                              >
                                <Text>
                                  {inputToLocale(item?.current_month)}
                                </Text>
                              </View>
                            </View>
                            <View style={{ ...styles.tableRow }}>
                              <View
                                style={{
                                  ...styles.tableCell,
                                  border: 0,
                                  textAlign: 'right',
                                  width: '100%',
                                }}
                              >
                                <Text>
                                  {inputToLocale(item?.prev_month_adjustment)}
                                </Text>
                              </View>
                            </View>
                            <View style={{ ...styles.tableRow }}>
                              <View
                                style={{
                                  ...styles.tableCell,
                                  border: 0,
                                  width: '100%',
                                  borderTop: 1,
                                  textAlign: 'right',
                                }}
                              >
                                <Text>{inputToLocale(item?.total)}</Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </>
                    );
                  } else return null;
                })}
              </View>
              <View style={{ ...styles.row, marginLeft: 3, marginTop: 10 }}>
                <View style={{ ...styles.column }}>
                  <Text
                    style={{
                      ...styles.detailsText,
                      textDecoration: 'underline',
                    }}
                  >
                    {`BROUGHT FORWARD TO NEXT MONTH ==>>`}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.column,
                    textAlign: 'right',
                    paddingRight: 100,
                  }}
                >
                  <View
                    style={{
                      marginLeft: '80%',
                      width: '20%',
                      marginBottom: '2',
                      borderBottomWidth: 1,
                    }}
                  >
                    <Text
                      style={{
                        ...styles.detailsText,
                        marginBottom: 1,
                        borderBottomWidth: 1,
                      }}
                    >
                      {computeAccruedBroughtForward(payslipDetails.accrued)}
                    </Text>
                  </View>
                </View>
              </View>
            </>
          )}
          {payslipDetails.uk_wages !== null && (
            <>
              <View
                style={{
                  borderBottom: 2,
                  margin: 0,
                  padding: 0,
                  marginBottom: 2,
                  width: '95%',
                }}
              />
              <Text
                style={{
                  fontSize: 6,
                  marginBottom: 2,
                }}
              >
                NOTE: UK COMPUTATION***
              </Text>

              <View style={{ ...styles.table, marginLeft: 3 }}>
                <View
                  style={{ ...styles.tableRow, border: 0, marginBottom: 2 }}
                >
                  <View
                    style={{
                      ...styles.tableCell,
                      border: 0,
                      width: '20%',
                    }}
                  >
                    <Text>Total Contract Salary:</Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableCell,
                      width: '20%',
                      border: 0,
                    }}
                  >
                    <Text>
                      {inputToLocale(
                        payslipDetails.uk_wages?.contracted_salary
                      )}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableCell,
                      width: '20%',
                      border: 0,
                    }}
                  >
                    <Text>
                      {parseInt(payslipDetails.uk_wages?.uk_days)} Days
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.tableRow }}>
                  <View
                    style={{
                      ...styles.tableCell,
                      borderTop: 1,
                      borderLeft: 1,
                      width: '20%',
                    }}
                  >
                    <Text>Particulars</Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableCell,
                      borderTop: 1,
                      width: '20%',
                    }}
                  >
                    <Text>Amount</Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableCell,
                      borderTop: 1,
                      width: '20%',
                    }}
                  >
                    <Text>Remarks</Text>
                  </View>
                </View>
                <View style={{ ...styles.tableRow }}>
                  <View
                    style={{ ...styles.tableCell, borderLeft: 1, width: '20%' }}
                  >
                    <Text>UK Salary</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text>
                      {inputToLocale(payslipDetails.uk_wages?.uk_salary)}
                    </Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text>
                      {parseInt(payslipDetails.uk_wages?.uk_hrs)}Hrs x GBP
                      {inputToLocale(payslipDetails.uk_wages?.uk_gbp_hrs)}
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.tableRow }}>
                  <View
                    style={{ ...styles.tableCell, width: '20%', borderLeft: 1 }}
                  >
                    <Text>Accomodation per day (Offset)</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text>
                      {inputToLocale(payslipDetails.uk_wages?.accom_per_day)}
                    </Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text>
                      {parseInt(payslipDetails.uk_wages?.uk_days)}Days x GBP
                      {inputToLocale(payslipDetails.uk_wages?.uk_gbp_days)}
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.tableRow }}>
                  <View
                    style={{ ...styles.tableCell, width: '20%', borderLeft: 1 }}
                  >
                    <Text>NET UK Salary</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text>
                      {inputToLocale(payslipDetails.uk_wages?.net_uk_salary)}
                    </Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text></Text>
                  </View>
                </View>
                <View style={{ ...styles.tableRow }}>
                  <View
                    style={{ ...styles.tableCell, width: '20%', borderLeft: 1 }}
                  >
                    <Text>UK SALARY IN DOLLAR</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text>
                      {inputToLocale(payslipDetails.uk_wages?.uk_salary_dollar)}
                    </Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text>
                      GBP{inputToLocale(payslipDetails.uk_wages?.net_uk_salary)}{' '}
                      x {inputToLocale(payslipDetails.uk_wages?.uk_usd_hrs)}/
                      {inputToLocale(payslipDetails.uk_wages?.uk_usd_days)}
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.tableRow }}>
                  <View
                    style={{ ...styles.tableCell, width: '20%', borderLeft: 1 }}
                  >
                    <Text>Contracted Salary in Dollar</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text>
                      {inputToLocale(
                        payslipDetails.uk_wages?.contracted_salary_dollar
                      )}
                    </Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text></Text>
                  </View>
                </View>
                <View style={{ ...styles.tableRow }}>
                  <View
                    style={{ ...styles.tableCell, width: '20%', borderLeft: 1 }}
                  >
                    <Text>Excess Overtime</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text>
                      {inputToLocale(payslipDetails.uk_wages?.excess_ot)}
                    </Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text>
                      {inputToLocale(payslipDetails.uk_wages?.uk_excess_ot)} x{' '}
                      {inputToLocale(
                        payslipDetails.uk_wages?.uk_excess_ot_rate
                      )}
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.tableRow }}>
                  <View
                    style={{ ...styles.tableCell, width: '20%', borderLeft: 1 }}
                  >
                    <Text>Wage Adjustment</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text>
                      {inputToLocale(payslipDetails.uk_wages?.wage_adjustment)}
                    </Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text></Text>
                  </View>
                </View>
              </View>
            </>
          )}
          <View style={styles.footer} wrap={false}>
            <View style={{ ...styles.row }}>
              <View style={styles.column}>
                <Text style={{ fontSize: 8 }}></Text>
              </View>
              <View style={styles.column}>
                <Text style={{ fontSize: 8 }}></Text>
              </View>
              <View
                style={{
                  ...styles.column,
                  textAlign: 'center',
                }}
              >
                <Text style={{ fontSize: 10, borderBottom: 1 }}>
                  {payslipDetails.crew_name}
                </Text>
                <Text style={{ fontSize: 8 }}>CREW MEMBER</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
