import { Button, Input, Popconfirm, Space, Switch } from 'antd';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';

export function getOtherDeductionsTable(handleAction) {
  return [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      fixed: 'left',
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      sorter: (a, b) => a.desc.localeCompare(b.desc),
    },
    {
      title: 'Short Name',
      dataIndex: 'short_name',
      key: 'short_name',
      sorter: (a, b) => a.short_name.localeCompare(b.short_name),
    },
    {
      title: 'SSS Loan',
      key: 'sss_loan',
      render: (text, record) => (
        <Switch
          disabled
          size="small"
          checked={record.sss_loan == 1 ? true : false}
        />
      ),
    },
    {
      title: 'PAGIBIG Loan',
      key: 'pagibig_loan',
      render: (text, record) => (
        <Switch
          size="small"
          disabled
          checked={record.pagibig_loan == 1 ? true : false}
        />
      ),
    },
    {
      title: 'Account Code',
      dataIndex: 'account_code',
      key: 'account_code',
      sorter: (a, b) => a.account_code.localeCompare(b.account_code),
    },
    {
      title: 'SL Account Code',
      dataIndex: 'sl_acct_code',
      key: 'sl_acct_code',
      sorter: (a, b) => a.sl_acct_code.localeCompare(b.sl_acct_code),
    },
    {
      title: 'Expense Account Code',
      dataIndex: 'expense_acct_code',
      key: 'expense_acct_code',
      sorter: (a, b) => a.expense_acct_code.localeCompare(b.expense_acct_code),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => handleAction(record)}
            icon={<FaWrench size={15} />}
          />
          <Popconfirm
            title="Delete record"
            description="Are you sure to delete this record?"
            onConfirm={() => handleAction(record, 'delete')}
            okText="Yes"
            cancelText="No"
          >
            <Button danger icon={<AiFillDelete size={15} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
}
