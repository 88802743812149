import {
  Button,
  Col,
  Form,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
  Tabs,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import CrewType from '../../../../../utils/types/CrewType';
import { getHomeAllotmentTable } from '../../../../../utils/tableheader/Transactions/HomeAllotmentTable';
import { UserContext } from '../../../../../utils/context/UserContext';
import { getSideLetterBonusTable } from '../../../../../utils/tableheader/Transactions/SideLetterBonusTable';
import { getMPOTable } from '../../../../../utils/tableheader/Transactions/MPOTable';
import { getAllotmentDistributionFields } from '../../../../../utils/inputfields/AllotmentDistributionFields';
import { GlobalContext } from '../../../../../App';
import FormModal from '../../../../../components/CustomModal/FormModal';
import AllotteeType from '../../../../../utils/types/AllotteeType';
import {
  ERROR_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../../../../utils/api/_constants';
import FormInput from '../../../../../components/FormInput/FormInput';
import ConfirmationModal from '../../../../../components/CustomModal/ConfirmationModal';
import {
  getAllBanks,
  getAllMpoRequestAllottee,
  getAllSlbRequestAllottee,
  postAddAllottee,
  postAddMpoRequestAllottee,
  postAddSlbRequestAllottee,
  postDeleteAllottee,
  postDeleteMpoRequestAllottee,
  postDeleteSlbRequestAllottee,
  postUpdateHomeAllotment,
  postUpdateMPO,
  postUpdateSideLetteTotalAmount,
  postUpdateSideLetter,
} from '../../../../../utils/api/AllotteeAPI';
import {
  getCrewFullMonth,
  postModifyCrewFullMonth,
} from '../../../../../utils/api/CutOffAPI';
import dayjs from 'dayjs';

export default function EmbarkedAllotmentDistribution({
  selectedCrew = CrewType,
  isLoading,
  setIsLoading,
  hasChanges,
  setHasChanges,
  fetchDatas,
  selectedMonth,
}) {
  const { inputToLocale } = useContext(UserContext);
  const { openNotification } = useContext(GlobalContext);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isNewFormModalOpen, setIsNewFormModalOpen] = useState(false);

  const [form] = Form.useForm();

  const [selectedAllottee, setSelectedAllottee] = useState(AllotteeType);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [selectedType, setSelectedType] = useState('');

  const [bankList, setBankList] = useState([]);

  const [isFullMonth, setIsFullMonth] = useState(false);

  const [mpoRequestsAllottees, setmpoRequestsAllottees] = useState([]);
  const [mpoDate, setMpoDate] = useState(null);
  const [mpoState, setMpoState] = useState(null);

  const [slbRequestsAllottees, setSlbRequestsAllottees] = useState([]);
  const [slbDate, setSlbDate] = useState(null);
  const [slbState, setSlbState] = useState(null);

  const [isAddDisabled, setIsAddDisabled] = useState(false);

  const [activeTab, setActiveTab] = useState('1');

  const handleAction = async (record = AllotteeType, type) => {
    if (type === 'delete') {
      const { data: res, error } = await postDeleteAllottee(record);
      if (error) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Deleting Allottee',
          res.message
        );
      } else {
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          'Deleting Allottee',
          res.message
        );
        fetchDatas();
      }
    } else {
      setSelectedType(type);

      const selectedMPO = mpoRequestsAllottees.find(
        (mpoRequest) => record.crew_allottee_id == mpoRequest.allottee_id
        // &&
        // dayjs(mpoRequest.date_requested, 'YYYY-MM-DD').month() ==
        //   selectedMonth
      );

      const selectedSlb = slbRequestsAllottees.find(
        (slbRequest) => record.crew_allottee_id == slbRequest.allottee_id
      );
      const parseRecord = {
        ...record,
        regular_allotment_percentage: record.regular_allotment.percentage,
        regular_allotment_usd_amount: record.regular_allotment.usd_amount,
        regular_allotment_peso_amount: record.regular_allotment.peso_amount,
        regular_allotment_applied_deduction:
          record.regular_allotment.applied_deduction,
        regular_allotment_adjustment_amount:
          record.regular_allotment.adjustment_amount,
        regular_allotment_is_checked: record.regular_allotment.is_checked,
        regular_allotment_hold_allotment:
          record.regular_allotment.hold_allotment,
        side_letter_percentage: record.side_letter.percentage,
        side_letter_usd_amount: record.side_letter.usd_amount,
        side_letter_peso_amount: record.side_letter.peso_amount,
        side_letter_applied_deduction: record.side_letter.applied_deduction,
        side_letter_adjustment_amount: record.side_letter.adjustment_amount,
        side_letter_is_checked: record.side_letter.is_checked,
        side_letter_hold_allotment: record.side_letter.hold_allotment,
        mpo_percentage: record.mpo.percentage,
        mpo_usd_amount: record.mpo.usd_amount,
        mpo_peso_amount: record.mpo.peso_amount,
        mpo_applied_deduction: record.mpo.applied_deduction,
        mpo_adjustment_amount: record.mpo.adjustment_amount,
        mpo_is_checked: record.mpo.is_checked,
        mpo_hold_allotment: record.mpo.hold_allotment,
      };
      if (selectedMPO) {
        setMpoState(selectedMPO);
        setMpoDate(dayjs(selectedMPO.date_requested, 'YYYY-MM-DD'));
        parseRecord.mpoDate = dayjs(selectedMPO.date_requested, 'YYYY-MM-DD');
      } else {
        setMpoDate(dayjs());
        parseRecord.mpoDate = dayjs();
        parseRecord.mpo_is_checked = 1;
        if (parseFloat(parseRecord.mpo_usd_amount) <= 0) {
          parseRecord.mpo_usd_amount = null;
        }
      }

      if (selectedSlb) {
        setSlbState(selectedSlb);
        setSlbDate(dayjs(selectedSlb.date_requested, 'YYYY-MM-DD'));
        parseRecord.slbDate = dayjs(selectedSlb.date_requested, 'YYYY-MM-DD');
      } else {
        setSlbDate(dayjs());
        parseRecord.slbDate = dayjs();
        parseRecord.side_letter_is_checked = 1;
        if (parseFloat(parseRecord.side_letter_usd_amount) <= 0) {
          parseRecord.side_letter_usd_amount = null;
        }
      }

      setSelectedAllottee(parseRecord);
      form.setFieldsValue(parseRecord);
      setIsFormModalOpen(true);
    }
  };

  const calculateSideLetterBonus = () => {
    const sum = selectedCrew.allotment?.allottee?.reduce(
      (accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue?.side_letter?.usd_amount);
      },
      0
    );
    return sum;
  };

  const fetchBankCodes = async () => {
    const { data: res, error } = await getAllBanks();
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Fetching banks',
        'Fetching banks failed.'
      );
    } else {
      setBankList(res.result);
    }
  };

  const fetchCrewFullMonth = async () => {
    const { data: res, error } = await getCrewFullMonth(selectedCrew.crew_no);
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Allotment Distribution',
        'Failed'
      );
    } else {
      if (res) {
        setIsFullMonth(res.full_month === 1 ? true : false);
      }
    }
  };

  const fetchMpoRequestDate = async () => {
    const { data: res, error } = await getAllMpoRequestAllottee(
      selectedCrew.crew_no
    );
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Allotment Distribution',
        'Failed'
      );
    } else {
      setmpoRequestsAllottees(res);
    }
  };

  const fetchSlbRequestDate = async () => {
    const { data: res, error } = await getAllSlbRequestAllottee(
      selectedCrew.crew_no
    );
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Allotment Distribution',
        'Failed'
      );
    } else {
      setSlbRequestsAllottees(res);
    }
  };

  const checkDeleteButtonDisabled = () => {
    if (activeTab == 3) {
      const selectedMPO = mpoRequestsAllottees.find(
        (mpoRequest) =>
          selectedAllottee.crew_allottee_id == mpoRequest.allottee_id
        //  &&
        // dayjs(mpoRequest.date_requested, 'YYYY-MM-DD').month() ==
        //   selectedMonth
      );
      if (selectedMPO) {
        return false;
      } else {
        return true;
      }
    } else if (activeTab == 2) {
      const selectedSLB = slbRequestsAllottees.find(
        (slbRequest) =>
          selectedAllottee.crew_allottee_id == slbRequest.allottee_id
        //  &&
        // dayjs(mpoRequest.date_requested, 'YYYY-MM-DD').month() ==
        //   selectedMonth
      );
      if (selectedSLB) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    form.setFieldsValue(selectedAllottee);
  }, [selectedAllottee]);

  useEffect(() => {
    fetchBankCodes();
    fetchCrewFullMonth();
    fetchMpoRequestDate();
    fetchSlbRequestDate();

    if (selectedCrew.allotment?.id) {
      setIsAddDisabled(false);
    } else {
      setIsAddDisabled(true);
    }
  }, [selectedCrew]);

  return (
    <>
      <Content>
        <Row justify={'end'}>
          <Button
            disabled={isAddDisabled}
            type='primary'
            onClick={() => {
              const newAllottee = AllotteeType;
              newAllottee.mpo_is_checked = 1;
              newAllottee.mpoDate = dayjs();
              newAllottee.side_letter_is_checked = 1;
              newAllottee.slbDate = dayjs();
              setMpoDate(dayjs());
              setSlbDate(dayjs());
              form.setFieldsValue(newAllottee);
              setSelectedAllottee(newAllottee);
              setIsNewFormModalOpen(true);
            }}
          >
            Add new
          </Button>
        </Row>
        <Tabs
          onChange={(activeKey) => {
            setActiveTab(activeKey);
          }}
          items={[
            {
              key: '1',
              label: 'Home Allotment',
              children: (
                <Content>
                  <FormInput
                    type='toggle'
                    name='full_month_toggle'
                    label={
                      'Full month on first allotment or first month of promotion or month of disembarkation?'
                    }
                    required={false}
                    disabled={isLoading}
                    onChange={async (e) => {
                      const payload = {
                        crew_no: selectedCrew.crew_no,
                        full_month: e ? 1 : 0,
                      };
                      const { data: res, error } =
                        await postModifyCrewFullMonth(payload);
                      if (error) {
                        openNotification(
                          ERROR_NOTIFICATION_TYPE,
                          'Full month',
                          'Updating full month failed.'
                        );
                      } else {
                        setIsFullMonth(e);
                      }
                    }}
                    value={isFullMonth}
                  />
                  <Table
                    columns={getHomeAllotmentTable(handleAction)}
                    dataSource={selectedCrew.allotment?.allottee?.map(
                      (item, index) => {
                        return {
                          ...item,
                          key: index,
                          regular_allotment_percentage: inputToLocale(
                            item.regular_allotment.percentage
                          ),
                          regular_allotment_usd_amount: inputToLocale(
                            item.regular_allotment.usd_amount
                          ),
                          regular_allotment_peso_amount: inputToLocale(
                            item.regular_allotment.peso_amount
                          ),
                          regular_allotment_applied_deduction:
                            item.regular_allotment.applied_deduction,
                          regular_allotment_adjustment_amount:
                            item.regular_allotment.adjustment_amount,
                          regular_allotment_is_checked:
                            item.regular_allotment.is_checked,
                        };
                      }
                    )}
                    size='small'
                    bordered='true'
                    scroll={{ x: true }}
                  />
                </Content>
              ),
            },
            {
              key: '2',
              label: 'Side Letter Bonus',
              children: (
                <Content>
                  <Table
                    columns={getSideLetterBonusTable(handleAction)}
                    dataSource={selectedCrew.allotment?.allottee?.map(
                      (item, index) => {
                        return {
                          ...item,
                          key: index,
                          side_letter_percentage: inputToLocale(
                            item.side_letter.percentage
                          ),
                          side_letter_usd_amount: inputToLocale(
                            item.side_letter.usd_amount
                          ),
                          side_letter_peso_amount: inputToLocale(
                            item.side_letter.peso_amount
                          ),
                          side_letter_applied_deduction:
                            item.side_letter.applied_deduction,
                          side_letter_request_date: slbRequestsAllottees.find(
                            (slb) => item.crew_allottee_id == slb.allottee_id
                          )?.date_requested,
                          side_letter_is_checked: item.side_letter.is_checked,
                        };
                      }
                    )}
                    size='small'
                    bordered='true'
                    scroll={{ x: true }}
                  />
                </Content>
              ),
            },
            {
              key: '3',
              label: 'MPO',
              children: (
                <Content>
                  <Table
                    columns={getMPOTable(handleAction)}
                    dataSource={selectedCrew.allotment?.allottee?.map(
                      (item, index) => {
                        return {
                          ...item,
                          key: index,
                          mpo_percentage: inputToLocale(item.mpo.percentage),
                          mpo_usd_amount: inputToLocale(item.mpo.usd_amount),
                          mpo_peso_amount: inputToLocale(item.mpo.peso_amount),
                          mpo_applied_deduction: item.mpo.applied_deduction,
                          mpo_request_date: mpoRequestsAllottees.find(
                            (slb) => item.crew_allottee_id == slb.allottee_id
                          )?.date_requested,
                          mpo_is_checked: item.mpo.is_checked,
                        };
                      }
                    )}
                    size='small'
                    bordered='true'
                    scroll={{ x: true }}
                  />
                </Content>
              ),
            },
          ]}
          centered
          size='small'
          defaultActiveKey='1'
        />
        <FormModal
          title={`${selectedAllottee.last_name}, ${selectedAllottee.first_name} ${selectedAllottee.middle_name}`}
          isOpen={isFormModalOpen}
          setIsOpen={setIsFormModalOpen}
          isLoading={isLoading}
          props={{ width: '50vw' }}
          footer={[
            <Popconfirm
              title='Delete record?'
              description='Are you sure to delete this record?'
              onConfirm={async () => {
                setIsLoading(true);
                if (activeTab == 3) {
                  const { data: res, error } =
                    await postDeleteMpoRequestAllottee({
                      ...selectedAllottee,
                      allottee_id: selectedAllottee.crew_allottee_id,
                      crew_no: selectedCrew.crew_no,
                      id: mpoState.id,
                    });
                  if (error) {
                    openNotification(
                      ERROR_NOTIFICATION_TYPE,
                      'MPO Request',
                      'Deleting MPO Request failed.'
                    );
                  } else {
                    openNotification(
                      SUCCESS_NOTIFICATION_TYPE,
                      'MPO Request',
                      'Deleting MPO Request success.'
                    );
                    setIsFormModalOpen(false);
                    fetchDatas();
                  }
                } else if (activeTab == 2) {
                  const { data: res, error } =
                    await postDeleteSlbRequestAllottee({
                      ...selectedAllottee,
                      allottee_id: selectedAllottee.crew_allottee_id,
                      crew_no: selectedCrew.crew_no,
                      id: slbState.id,
                    });
                  if (error) {
                    openNotification(
                      ERROR_NOTIFICATION_TYPE,
                      'SLB Request',
                      'Deleting SLB Request failed.'
                    );
                  } else {
                    openNotification(
                      SUCCESS_NOTIFICATION_TYPE,
                      'SLB Request',
                      'Deleting SLB Request success.'
                    );
                    setIsFormModalOpen(false);
                    fetchDatas();
                  }
                }
                setIsLoading(false);
              }}
              okText='Yes'
              cancelText='No'
            >
              <Button
                key='delete'
                danger
                hidden={activeTab == 1}
                disabled={checkDeleteButtonDisabled()}
                className='me-2'
              >
                Delete
              </Button>
            </Popconfirm>,
            <Button
              key='back'
              onClick={() => {
                form.resetFields();
                setIsFormModalOpen(false);
                setHasChanges(false);
              }}
            >
              Cancel
            </Button>,
            <Button
              key='submit'
              type='primary'
              loading={isLoading}
              onClick={async () => {
                try {
                  await form.validateFields();
                  if (hasChanges) {
                    setIsConfirmationModalOpen(true);
                  } else {
                    openNotification(
                      INFO_NOTIFICATION_TYPE,
                      'Allotte Allotment Distribution',
                      'No changes made.'
                    );
                    setIsFormModalOpen(false);
                  }
                } catch {}
              }}
            >
              Save
            </Button>,
          ]}
          form={form}
          formName={'allotment_distribution_form'}
          initialState={selectedAllottee}
          onSave={async () => {
            if (hasChanges) {
              try {
                await form.validateFields();
                setIsConfirmationModalOpen(true);
              } catch {}
            } else {
              openNotification(
                INFO_NOTIFICATION_TYPE,
                'Allotte Allotment Distribution',
                'No changes made.'
              );
              setIsFormModalOpen(false);
            }
          }}
          onCancel={() => {
            form.resetFields();
            setIsFormModalOpen(false);
            setHasChanges(false);
          }}
        >
          <Row gutter={16}>
            {getAllotmentDistributionFields(
              isLoading,
              selectedAllottee,
              setSelectedAllottee,
              setHasChanges,
              bankList,
              activeTab
            ).map((data, index) => (
              <>
                {data.shouldRender && (
                  <Col key={index} xs={24} sm={8}>
                    <FormInput
                      type={data.type}
                      name={data.name}
                      placeholder={data.placeholder}
                      label={data.label}
                      autoFocus={data.autofocus}
                      value={data.value}
                      hidden={data.hidden}
                      onChange={data.setValue}
                      required={data.required}
                      disabled={data.disabled}
                      dropdownOptions={data.dropdownOptions}
                      props={data.props}
                    />
                  </Col>
                )}
              </>
            ))}
            <Col xs={24} sm={8}>
              <FormInput
                type='date'
                name={'slbDate'}
                placeholder={'SLB request date'}
                label={'SLB request date'}
                autoFocus={false}
                required={false}
                // disabled={isLoading || selectedAllottee.mpo_is_checked == 0}
                disabled={isLoading}
                hidden={activeTab != '2'}
                value={slbDate}
                allowClear={false}
                onChange={(_, dateString) => {
                  setHasChanges(true);
                  if (dateString) {
                    const date = dayjs(dateString, 'YYYY-MM-DD');
                    setSlbDate(date);
                  } else {
                    setSlbDate(null);
                  }
                }}
              />
              {/* <FormInput
                type={'text'}
                name={'hiddenText'}
                hidden={true}
                value={'e'}
                onChange={(e) => console.log(e)}
                required={false}
              /> */}
            </Col>
            <Col xs={24} sm={8}>
              <FormInput
                type='date'
                name={'mpoDate'}
                placeholder={'MPO request date'}
                label={'MPO request date'}
                autoFocus={false}
                required={false}
                // disabled={isLoading || selectedAllottee.mpo_is_checked == 0}
                disabled={isLoading}
                hidden={activeTab != '3'}
                value={mpoDate}
                allowClear={false}
                onChange={(_, dateString) => {
                  setHasChanges(true);
                  if (dateString) {
                    const date = dayjs(dateString, 'YYYY-MM-DD');
                    setMpoDate(date);
                  } else {
                    setMpoDate(null);
                  }
                }}
              />
            </Col>
          </Row>
        </FormModal>
        <FormModal
          title={'Add new allottee'}
          isOpen={isNewFormModalOpen}
          setIsOpen={setIsNewFormModalOpen}
          isLoading={isLoading}
          props={{ width: '50vw' }}
          form={form}
          formName={'new_allotment_distribution_form'}
          initialState={AllotteeType}
          onSave={async () => {
            if (hasChanges) {
              try {
                await form.validateFields();
                setIsConfirmationModalOpen(true);
              } catch {}
            } else {
              openNotification(
                INFO_NOTIFICATION_TYPE,
                'New Allotte Allotment',
                'No changes made.'
              );
              setIsNewFormModalOpen(false);
            }
          }}
          onCancel={() => {
            form.resetFields();
            setIsNewFormModalOpen(false);
            setHasChanges(false);
          }}
        >
          <Row gutter={16}>
            {getAllotmentDistributionFields(
              isLoading,
              selectedAllottee,
              setSelectedAllottee,
              setHasChanges,
              bankList,
              activeTab
            ).map((data, index) => (
              <>
                <Typography.Text>{data.shouldRender}</Typography.Text>
                {data.shouldRender && (
                  <Col key={index} xs={24} sm={8}>
                    <FormInput
                      type={data.type}
                      name={data.name}
                      placeholder={data.placeholder}
                      label={data.label}
                      autoFocus={data.autofocus}
                      value={data.value}
                      onChange={data.setValue}
                      required={data.required}
                      disabled={data.disabled}
                      dropdownOptions={data.dropdownOptions}
                      hidden={data.hidden}
                      props={data.props}
                    />
                  </Col>
                )}
              </>
            ))}
            <Col xs={24} sm={8}>
              <FormInput
                type='date'
                name={'slbDate'}
                placeholder={'SLB request date'}
                label={'SLB request date'}
                autoFocus={false}
                required={false}
                // disabled={isLoading || selectedAllottee.mpo_is_checked == 0}
                disabled={isLoading}
                hidden={activeTab != '2'}
                value={slbDate}
                allowClear={false}
                onChange={(_, dateString) => {
                  setHasChanges(true);
                  if (dateString) {
                    const date = dayjs(dateString, 'YYYY-MM-DD');
                    setSlbDate(date);
                  } else {
                    setSlbDate(null);
                  }
                }}
              />
              {/* <FormInput
                type={'text'}
                name={'hiddenText'}
                hidden={true}
                value={'e'}
                onChange={(e) => console.log(e)}
                required={false}
              /> */}
            </Col>
            <Col xs={24} sm={8}>
              <FormInput
                type='date'
                name={'mpoDate'}
                placeholder={'MPO request date'}
                label={'MPO request date'}
                autoFocus={false}
                required={false}
                hidden={activeTab != '3'}
                disabled={isLoading || selectedAllottee.mpo_is_checked == 0}
                value={mpoDate}
                allowClear={false}
                onChange={(_, dateString) => {
                  setHasChanges(true);
                  if (dateString) {
                    const date = dayjs(dateString, 'YYYY-MM-DD');
                    setMpoDate(date);
                  } else {
                    setMpoDate(null);
                  }
                }}
              />
            </Col>
          </Row>
        </FormModal>
      </Content>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onCancel={() => setIsConfirmationModalOpen(false)}
        onConfirm={async () => {
          setIsLoading(true);
          if (selectedAllottee.crew_allottee_id === null) {
            // NEW
            const payload = {
              ...selectedAllottee,
              crew_no: selectedCrew.crew_no,
              regular_allotment: selectedAllottee.regular_allotment_is_checked,
              account_type: selectedAllottee.acct_type,
              account_number: selectedAllottee.acct_no,
              bank_code: selectedAllottee.bankcode,
              bank_description: selectedAllottee.bankdesc,
              bank_branch: selectedAllottee.bankbranch,
            };
            const { data: res, error } = await postAddAllottee(payload);
            if (error) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                'Home Allotment',
                'Failed'
              );
            } else {
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                'Home Allotment',
                'Success saving allottee.'
              );

              const crewAllotteeId = res.crew_allottee_id;
              if (selectedAllottee.regular_allotment_is_checked) {
                selectedCrew?.allotment?.allottee?.map(async (record) => {
                  if (
                    record.crew_allottee_id != selectedAllottee.crew_allottee_id
                  ) {
                    const payload = {
                      ...record,
                      regular_allotment_old: record.regular_allotment,
                      regular_allotment: record.regular_allotment.is_checked,
                      account_type: record.acct_type,
                      account_number: record.acct_no,
                      bank_code: record.bankcode,
                      bank_description: record.bankdesc,
                      bank_branch: record.bankbranch,
                      amount: record.regular_allotment.usd_amount,
                      fixpeso: record.regular_allotment.peso_amount,
                      adjustment_amount:
                        record.regular_allotment.adjustment_amount,
                      hold_allotment: record.regular_allotment.hold_allotment,
                      remaining_salary: 0,
                    };

                    const { data: res, error } = await postUpdateHomeAllotment(
                      payload
                    );
                    if (error) {
                      openNotification(
                        ERROR_NOTIFICATION_TYPE,
                        'Home Allotment',
                        'Updating Allottee Details failed.'
                      );
                    }
                  }
                });
                const payload = {
                  ...selectedAllottee,
                  crew_allottee_id: crewAllotteeId,
                  regular_allotment:
                    selectedAllottee.regular_allotment_is_checked,
                  account_type: selectedAllottee.acct_type,
                  account_number: selectedAllottee.acct_no,
                  bank_code: selectedAllottee.bankcode,
                  bank_description: selectedAllottee.bankdesc,
                  bank_branch: selectedAllottee.bankbranch,
                  amount: selectedAllottee.regular_allotment_usd_amount,
                  fixpeso: selectedAllottee.regular_allotment_peso_amount,
                  home: selectedAllottee.home_allotment_hold_allotment,
                  remaining_salary:
                    selectedAllottee.regular_allotment_applied_deduction,
                };
                const { data: res, error } = await postUpdateHomeAllotment(
                  payload
                );
                if (error) {
                  openNotification(
                    ERROR_NOTIFICATION_TYPE,
                    'Home Allotment',
                    'Updating Allottee Details failed.'
                  );
                }
              }
              if (selectedAllottee.side_letter_is_checked) {
                const payload = {
                  ...selectedAllottee,
                  crew_allottee_id: crewAllotteeId,
                  sideletter: selectedAllottee.side_letter_is_checked,
                  account_type: selectedAllottee.acct_type,
                  account_number: selectedAllottee.acct_no,
                  bank_code: selectedAllottee.bankcode,
                  bank_description: selectedAllottee.bankdesc,
                  bank_branch: selectedAllottee.bankbranch,
                  amount: selectedAllottee.side_letter_usd_amount,
                  fixpeso: selectedAllottee.side_letter_peso_amount,
                  remaining_salary:
                    selectedAllottee.side_letter_applied_deduction,
                  hold_allotment: selectedAllottee.side_letter_hold_allotment,
                  date_requested: dayjs(slbDate).format('YYYY-MM-DD'),
                };
                const { data: res, error } = await postUpdateSideLetter(
                  payload
                );
                if (error) {
                  openNotification(
                    ERROR_NOTIFICATION_TYPE,
                    'Home Allotment',
                    'Updating Allottee Details failed.'
                  );
                } else {
                  const payload = {
                    crew_no: selectedCrew.crew_no,
                    allottee_id: crewAllotteeId,
                    allottee_name: `${selectedAllottee.last_name}, ${selectedAllottee.first_name}`,
                    allt_on_board_crew_id: crewAllotteeId,
                    date_requested: dayjs(mpoDate).format('YYYY-MM-DD'),
                    sideletter_amount: selectedAllottee.side_letter_usd_amount,
                    deduct_on_leave_final: 0,
                    apply: selectedAllottee.side_letter_is_checked,
                  };
                  const { data: res, error: errorRequest } =
                    await postAddSlbRequestAllottee(payload);
                  if (!errorRequest) {
                    openNotification(
                      SUCCESS_NOTIFICATION_TYPE,
                      'Side Letter',
                      'Success'
                    );
                  }
                }
              }
              if (selectedAllottee.mpo_is_checked) {
                const payload = {
                  ...selectedAllottee,
                  crew_allottee_id: crewAllotteeId,
                  mpo: selectedAllottee.mpo_is_checked,
                  account_type: selectedAllottee.acct_type,
                  account_number: selectedAllottee.acct_no,
                  bank_code: selectedAllottee.bankcode,
                  bank_description: selectedAllottee.bankdesc,
                  bank_branch: selectedAllottee.bankbranch,
                  amount: selectedAllottee.mpo_usd_amount,
                  fixpeso: selectedAllottee.mpo_peso_amount,
                  remaining_salary: selectedAllottee.mpo_applied_deduction,
                  hold_allotment: selectedAllottee.mpo_hold_allotment,
                  date_requested: dayjs(mpoDate).format('YYYY-MM-DD'),
                };
                const { data: res, error } = await postUpdateMPO(payload);
                if (error) {
                  openNotification(
                    ERROR_NOTIFICATION_TYPE,
                    'MPO',
                    'Updating Allottee Details failed.'
                  );
                } else {
                  const payload = {
                    crew_no: selectedCrew.crew_no,
                    allottee_id: crewAllotteeId,
                    allottee_name: `${selectedAllottee.last_name}, ${selectedAllottee.first_name}`,
                    allt_on_board_crew_id: crewAllotteeId,
                    date_requested: dayjs(mpoDate).format('YYYY-MM-DD'),
                    mpo_amount: selectedAllottee.mpo_usd_amount,
                    deduct_on_leave_final: 0,
                    apply: selectedAllottee.mpo_is_checked,
                  };
                  const { data: res, error } = await postAddMpoRequestAllottee(
                    payload
                  );
                  if (!error) {
                    if (res.isSuccessful === 'true') {
                      openNotification(
                        SUCCESS_NOTIFICATION_TYPE,
                        'MPO',
                        res.message
                      );
                    }
                  }
                }
              }
              setHasChanges(false);
              setIsConfirmationModalOpen(false);
              setIsNewFormModalOpen(false);
              fetchDatas();
            }
          } else {
            // EDIT
            if (selectedType === 'home_allotment') {
              if (selectedAllottee.regular_allotment_is_checked == 1) {
                selectedCrew?.allotment?.allottee?.map(async (record) => {
                  if (
                    record.crew_allottee_id != selectedAllottee.crew_allottee_id
                  ) {
                    const payload = {
                      ...record,
                      regular_allotment_old: record.regular_allotment,
                      regular_allotment: record.regular_allotment.is_checked,
                      account_type: record.acct_type,
                      account_number: record.acct_no,
                      bank_code: record.bankcode,
                      bank_description: record.bankdesc,
                      bank_branch: record.bankbranch,
                      amount: record.regular_allotment.usd_amount,
                      fixpeso: record.regular_allotment.peso_amount,
                      adjustment_amount:
                        record.regular_allotment.adjustment_amount,
                      hold_allotment: record.regular_allotment.hold_allotment,
                      remaining_salary: 0,
                    };

                    const { data: res, error } = await postUpdateHomeAllotment(
                      payload
                    );
                    if (error) {
                      openNotification(
                        ERROR_NOTIFICATION_TYPE,
                        'Home Allotment',
                        'Updating Allottee Details failed.'
                      );
                    }
                  }
                });
              }

              const payload = {
                ...selectedAllottee,
                regular_allotment:
                  selectedAllottee.regular_allotment_is_checked,
                account_type: selectedAllottee.acct_type,
                account_number: selectedAllottee.acct_no,
                bank_code: selectedAllottee.bankcode,
                bank_description: selectedAllottee.bankdesc,
                bank_branch: selectedAllottee.bankbranch,
                amount: selectedAllottee.regular_allotment_usd_amount,
                fixpeso: selectedAllottee.regular_allotment_peso_amount,
                adjustment_amount:
                  selectedAllottee.regular_allotment_adjustment_amount,
                remaining_salary:
                  selectedAllottee.regular_allotment_applied_deduction,
                hold_allotment:
                  selectedAllottee.regular_allotment_hold_allotment,
              };
              const { data: res, error } = await postUpdateHomeAllotment(
                payload
              );
              if (error) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'Home Allotment',
                  'Updating Allottee Details failed.'
                );
              } else {
                openNotification(
                  SUCCESS_NOTIFICATION_TYPE,
                  'Home Allotment',
                  res.message
                );
                setHasChanges(false);
                setIsConfirmationModalOpen(false);
                setIsFormModalOpen(false);
                fetchDatas();
              }
            } else if (selectedType === 'side_letter') {
              const payload = {
                ...selectedAllottee,
                sideletter: selectedAllottee.side_letter_is_checked,
                account_type: selectedAllottee.acct_type,
                account_number: selectedAllottee.acct_no,
                bank_code: selectedAllottee.bankcode,
                bank_description: selectedAllottee.bankdesc,
                bank_branch: selectedAllottee.bankbranch,
                amount: selectedAllottee.side_letter_usd_amount,
                fixpeso: selectedAllottee.side_letter_peso_amount,
                adjustment_amount:
                  selectedAllottee.side_letter_adjustment_amount,
                remaining_salary:
                  selectedAllottee.side_letter_applied_deduction,
                hold_allotment: selectedAllottee.side_letter_hold_allotment,
                date_requested: dayjs(slbDate).format('YYYY-MM-DD'),
              };
              const { data: res, error } = await postUpdateSideLetter(payload);
              if (error) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'Side Letter',
                  'Updating Allottee Details failed.'
                );
              } else {
                const payload = {
                  crew_no: selectedCrew.crew_no,
                  allottee_id: selectedAllottee.crew_allottee_id,
                  allottee_name: `${selectedAllottee.last_name}, ${selectedAllottee.first_name}`,
                  allt_on_board_crew_id: selectedAllottee.crew_allottee_id,
                  date_requested: dayjs(slbDate).format('YYYY-MM-DD'),
                  sideletter_amount: selectedAllottee.side_letter_usd_amount,
                  deduct_on_leave_final: 0,
                  apply: selectedAllottee.side_letter_is_checked,
                  remaining_salary:
                    selectedAllottee.side_letter_applied_deduction,
                };
                const { data: res, error: errorRequest } =
                  await postAddSlbRequestAllottee(payload);
                if (!errorRequest) {
                  openNotification(
                    SUCCESS_NOTIFICATION_TYPE,
                    'Side Letter',
                    'Success'
                  );
                  setHasChanges(false);
                  fetchSlbRequestDate();
                  setIsConfirmationModalOpen(false);
                  setIsFormModalOpen(false);
                  fetchDatas();
                }
              }
            } else if (selectedType === 'mpo') {
              const payload = {
                ...selectedAllottee,
                mpo: selectedAllottee.mpo_is_checked,
                account_type: selectedAllottee.acct_type,
                account_number: selectedAllottee.acct_no,
                bank_code: selectedAllottee.bankcode,
                bank_description: selectedAllottee.bankdesc,
                bank_branch: selectedAllottee.bankbranch,
                amount: selectedAllottee.mpo_usd_amount,
                fixpeso: selectedAllottee.mpo_peso_amount,
                adjustment_amount: selectedAllottee.mpo_adjustment_amount,
                remaining_salary: selectedAllottee.mpo_applied_deduction,
                hold_allotment: selectedAllottee.mpo_hold_allotment,
                date_requested: dayjs(mpoDate).format('YYYY-MM-DD'),
              };
              const { data: res, error } = await postUpdateMPO(payload);
              if (error) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'MPO',
                  'Updating Allottee Details failed.'
                );
              } else {
                const payload = {
                  crew_no: selectedCrew.crew_no,
                  allottee_id: selectedAllottee.crew_allottee_id,
                  allottee_name: `${selectedAllottee.last_name}, ${selectedAllottee.first_name}`,
                  allt_on_board_crew_id: selectedAllottee.crew_allottee_id,
                  date_requested: dayjs(mpoDate).format('YYYY-MM-DD'),
                  mpo_amount: selectedAllottee.mpo_usd_amount,
                  deduct_on_leave_final: 0,
                  apply: selectedAllottee.mpo_is_checked,
                  remaining_salary: selectedAllottee.mpo_applied_deduction,
                };
                const { data: res, error } = await postAddMpoRequestAllottee(
                  payload
                );
                if (error) {
                } else {
                  if (res.isSuccessful === 'true') {
                    openNotification(
                      SUCCESS_NOTIFICATION_TYPE,
                      'MPO',
                      res.message
                    );
                    setHasChanges(false);
                    fetchMpoRequestDate();
                    setIsFormModalOpen(false);
                    setIsConfirmationModalOpen(false);
                    // setIsMpoRequestConfirmationOpen(false);
                    fetchDatas();
                  }
                }
              }
            }
          }
          setIsLoading(false);
        }}
      />
      {/* <ConfirmationModal
        isLoading={isLoading}
        isOpen={isMpoRequestConfirmationOpen}
        setIsOpen={setIsMpoRequestConfirmationOpen}
        onCancel={() => setIsMpoRequestConfirmationOpen(false)}
        onConfirm={async () => {
          setIsLoading(true);
          const payload = {
            crew_no: selectedCrew.crew_no,
            allottee_id: selectedAllottee.crew_allottee_id,
            allottee_name: `${selectedAllottee.last_name}, ${selectedAllottee.first_name}`,
            allt_on_board_crew_id: selectedAllottee.crew_allottee_id,
            date_requested: dayjs(mpoDate).format('YYYY-MM-DD'),
            mpo_amount: selectedAllottee.mpo_usd_amount,
            deduct_on_leave_final: 0,
            apply: selectedAllottee.mpo_is_checked,
          };

          const { data: res, error } = await postAddMpoRequestAllottee(payload);
          if (error) {
          } else {
            if (res.isSuccessful === 'true') {
              fetchMpoRequestDate();
              setIsMpoRequestConfirmationOpen(false);
              fetchDatas();
            }
          }
          setIsLoading(false);
        }}
      /> */}
    </>
  );
}
