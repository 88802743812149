import { Content } from 'antd/es/layout/layout';
import React, { useContext } from 'react';
import SSSContributionPage from './GovContributionTabs/SSSContributionPage';
import PhilHealthContributionPage from './GovContributionTabs/PhilHealthContributionPage';
import PagIbigContributionPage from './GovContributionTabs/PagIbigContributionPage';
import { Tabs } from 'antd';
import { UserContext } from '../../utils/context/UserContext';

export default function GovContributionPage() {
  const { validateAccessToFeature } = useContext(UserContext);

  const checkAvailableTabs = () => {
    const tabs = [];
    if (validateAccessToFeature('feature_maintenance_govt_contri_sss')) {
      tabs.push({
        key: '1',
        label: 'SSS',
        children: <SSSContributionPage />,
        disabled: !validateAccessToFeature(
          'feature_maintenance_govt_contri_sss'
        ),
      });
    }
    if (validateAccessToFeature('feature_maintenance_govt_contri_philhealth')) {
      tabs.push({
        key: '2',
        label: 'PhilHealth',
        children: <PhilHealthContributionPage />,
        disabled: !validateAccessToFeature(
          'feature_maintenance_govt_contri_philhealth'
        ),
      });
    }
    if (validateAccessToFeature('feature_maintenance_govt_contri_pagibig')) {
      tabs.push({
        key: '3',
        label: 'Pag-Ibig',
        children: <PagIbigContributionPage />,
        disabled: !validateAccessToFeature(
          'feature_maintenance_govt_contri_pagibig'
        ),
      });
    }
    // }
    return tabs;
  };

  return (
    <Content>
      <Tabs items={checkAvailableTabs()} centered />
    </Content>
  );
}
