import { format } from 'date-fns-tz';
import { createContext, useState } from 'react';

export const UserContext = createContext();

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export const UserProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [accessLevelDefinition, setAccessLevelDefinition] = useState(null);

  const getCurrentDate = () => {
    return format(new Date(), 'yyyy-MM-dd', {
      timeZone: 'Asia/Manila',
    });
  };

  const doSearchInArray = (searchQuery, setFilteredList, originalList) => {
    if (searchQuery === '') {
      setFilteredList(originalList);
    }
    const lowerCaseQuery = searchQuery.toLowerCase();
    const results = originalList.filter((item) => {
      for (const key in item) {
        if (item[key] === undefined || item[key] === null) {
          return false;
        }
        if (item[key].toString().toLowerCase().includes(lowerCaseQuery)) {
          return true;
        }
      }
      return false;
    });
    setFilteredList(results);
  };

  const parseToFloat = (value) => {
    return parseFloat(value).toFixed(2);
  };

  const inputToLocale = (input) => {
    const number = typeof input === 'string' ? parseFloat(input) : input;

    if (isNaN(number)) {
      return input;
    }

    const formattedNumber = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedNumber;
  };

  const generateFiveNumber = () => {
    return Math.floor(Math.random() * 90000) + 10000;
  };

  const localeToFloat = (value) => {
    if (typeof value === 'string') {
      return parseToFloat(value.replace(',', ''));
    }
    return value;
  };

  const generateRandomPassword = () => {
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-=';
    let password = '';
    for (let i = 0; i < 11; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const validateAccessToFeature = (field) => {
    if (field == 'log_out' || field == 'reports_bank_downloading') {
      return true;
    }
    return (
      accessLevelDefinition[field] == 'T' || accessLevelDefinition[field] == 'H'
    );
  };

  const value = {
    profile,
    setProfile,
    formItemLayout,
    getCurrentDate,
    doSearchInArray,
    parseToFloat,
    inputToLocale,
    generateFiveNumber,
    localeToFloat,
    generateRandomPassword,
    validateAccessToFeature,
    setAccessLevelDefinition,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
