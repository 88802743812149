import dayjs from 'dayjs';
import CrewType from '../types/CrewType';
import DeductionType from '../types/DeductionType';
import OtherDeductionType from '../types/OtherDeductionType';

export const getSalaryBreakdownFields = (
  isLoading,
  state = CrewType.allotment,
  setState,
  setHasChanges,
  basicPay,
  inputToLocale
) => {
  return [
    {
      type: 'dropdown',
      name: 'allotment_type',
      placeholder: 'Allotment Type',
      label: 'Allotment Type',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: state.allotment_type,
      dropdownOptions: [
        { id: 'fixed', description: 'Fixed' },
        { id: 'percentage', description: 'Percentage' },
      ],
      setValue: (value, option) => {
        if (value === 'fixed') {
          setState({
            ...state,
            allotment_type: value,
            allotment_percentage: 0,
          });
        } else if (value === 'percentage') {
          setState({
            ...state,
            allotment_type: value,
            allotment_amount: 0,
          });
        }
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'allotment_percentage',
      placeholder: '',
      label: 'Allotment % of Basic Pay',
      autofocus: false,
      required: true,
      disabled: isLoading || state.allotment_type === 'fixed',
      value: state.allotment_percentage,
      setValue: (e) => {
        const value = e.target.value;
        const allotAmount = basicPay * (value / 100);
        setState({
          ...state,
          allotment_percentage: value,
          allotment_amount: allotAmount,
        });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'allotment_amount',
      placeholder: '',
      label: 'Allotment amount',
      autofocus: false,
      required: true,
      disabled: isLoading || state.allotment_type === 'percentage',
      value: state.allotment_amount,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, allotment_amount: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'salary_for_allotment',
      placeholder: 'As per Letter to Master',
      label: 'As per Letter to Master',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.salary_for_allotment,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, salary_for_allotment: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'side_letter_bonus',
      placeholder: 'Side Letter Bonus',
      label: 'Side Letter Bonus',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.side_letter_bonus,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, side_letter_bonus: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'total_adjustment',
      placeholder: '',
      label: 'Adjustment',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.total_adjustment,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, total_adjustment: value });
        setHasChanges(true);
      },
    },
    {
      type: 'date',
      name: 'adjustment_date_from',
      placeholder: 'Adjustment date from',
      label: 'Adjustment date from',
      required: false,
      disabled: isLoading,
      value: state.adjustment_date_from,
      setValue: (_, dateString) => {
        if (dateString) {
          const date = dayjs(dateString, 'YYYY-MM-DD');
          setState({
            ...state,
            adjustment_date_from: date,
          });
        } else {
          setState({
            ...state,
            adjustment_date_from: null,
          });
        }
        setHasChanges(true);
      },
    },
    {
      type: 'date',
      name: 'adjustment_date_to',
      placeholder: 'Adjustment date to',
      label: 'Adjustment date to',
      required: false,
      disabled: isLoading,
      value: state.adjustment_date_to,
      setValue: (_, dateString) => {
        if (dateString) {
          const date = dayjs(dateString, 'YYYY-MM-DD');
          setState({
            ...state,
            adjustment_date_to: date,
          });
        } else {
          setState({
            ...state,
            adjustment_date_to: null,
          });
        }
        setHasChanges(true);
      },
    },
  ];
};
