import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../App';
import { UserContext } from '../../../utils/context/UserContext';
import { Button, Col, Form, Row, Table } from 'antd';
import PagibigContributionType from '../../../utils/types/PagibigContributionType';
import dayjs from 'dayjs';
import {
  getAllPagIbigContribution,
  postAddPagibigContribution,
  postDeletePagIbigContribution,
  postDeletePhilHealthContribution,
  postModifyPagIbigContribution,
} from '../../../utils/api/GovtContributionAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../../utils/api/_constants';
import { Content } from 'antd/es/layout/layout';
import Search from 'antd/es/input/Search';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { IoIosRefresh } from 'react-icons/io';
import { getPagibigContributionTable } from '../../../utils/tableheader/Maintenance/PagibiContributionTable';
import FormModal from '../../../components/CustomModal/FormModal';
import { getPagibigContributionFields } from '../../../utils/inputfields/PagibigContributionFields';
import FormInput from '../../../components/FormInput/FormInput';
import ConfirmationModal from '../../../components/CustomModal/ConfirmationModal';

export default function PagIbigContributionPage() {
  const { openNotification } = useContext(GlobalContext);
  const { doSearchInArray } = useContext(UserContext);

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [contribList, setContribList] = useState([]);

  const [selectedContrib, setSelectedContrib] = useState(
    PagibigContributionType
  );

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);

  const tableContents = filteredList.map((item, index) => ({
    ...item,
    key: index,
  }));

  const handleAction = async (record, action = 'edit') => {
    if (action === 'delete') {
      setIsLoading(true);
      const { data: res, error } = await postDeletePagIbigContribution(record);
      if (error) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Pag ibig Contributions',
          'Error deleting changes!'
        );
      } else {
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          'Pag ibig Contributions',
          'Record deleted!'
        );
        fetchData();
      }
      setIsLoading(false);
    } else {
      setSelectedContrib(record);
      form.setFieldsValue(record);
      setIsFormModalOpen(true);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    const { data: res, error } = await getAllPagIbigContribution();
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'PagIbig Contributions',
        'Loading contributions failed.'
      );
    } else {
      setFilteredList(res);
      setContribList(res);
      doSearchInArray(searchQuery, setFilteredList, res);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    form.setFieldsValue(selectedContrib);
  }, [selectedContrib]);

  return (
    <Content>
      <Row className='mb-4' justify={'end'}>
        <Col xs={24} sm={8} className='text-end'>
          <Search
            disabled={isLoading}
            placeholder='Search Pagibig contribution!'
            onChange={(e) => {
              setSearchQuery(e.target.value);
              doSearchInArray(e.target.value, setFilteredList, contribList);
            }}
            onSearch={(value) => {
              setSearchQuery(value);
              doSearchInArray(value, setFilteredList, contribList);
            }}
            allowClear={true}
          />
        </Col>
        <Col xs={24} sm={3} className='text-end'>
          <Button
            className='mx-2'
            type='primary'
            icon={<AiOutlinePlusCircle size={18} />}
            disabled={isLoading}
            onClick={() => {
              form.setFieldsValue(PagibigContributionType);
              setSelectedContrib(PagibigContributionType);
              setIsFormModalOpen(true);
            }}
          >
            Add
          </Button>
          <Button
            type='primary'
            icon={<IoIosRefresh size={18} />}
            disabled={isLoading}
            onClick={() => fetchData()}
          />
        </Col>
      </Row>
      <Table
        columns={getPagibigContributionTable(handleAction)}
        dataSource={tableContents}
        size='small'
        bordered='true'
        scroll={{ x: true }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <FormModal
        title={'PagIbig Contribution entry'}
        isOpen={isFormModalOpen}
        setIsOpen={setIsFormModalOpen}
        isLoading={isLoading}
        props={{ width: '50vw' }}
        form={form}
        formName={'deduction_form'}
        initialState={selectedContrib}
        onSave={async () => {
          if (hasChanges) {
            try {
              await form.validateFields();
              if (
                parseFloat(selectedContrib.sal_range_to) >
                parseFloat(selectedContrib.sal_range_from)
              ) {
                setIsConfirmationModalOpen(true);
              } else {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'PagIbig Contributions',
                  'Salary range from cannot be higher than Salary range to.'
                );
              }
            } catch {}
          } else {
            openNotification(
              INFO_NOTIFICATION_TYPE,
              'PagIbig Contributions',
              'No changes made.'
            );
            setIsFormModalOpen(false);
          }
        }}
        onCancel={() => {
          if (hasChanges) {
            setIsUnsavedModalOpen(true);
          } else {
            form.resetFields();
            setIsFormModalOpen(false);
            setHasChanges(false);
          }
        }}
      >
        <Row gutter={16}>
          {getPagibigContributionFields(
            isLoading,
            selectedContrib,
            setSelectedContrib,
            setHasChanges
          ).map((data, index) => (
            <Col key={index} xs={24} sm={12}>
              <FormInput
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            </Col>
          ))}
        </Row>
      </FormModal>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          if (selectedContrib.id) {
            const { data: res, error } = await postModifyPagIbigContribution(
              selectedContrib
            );
            if (error) {
              if (error?.request?.status == 422) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'Pag ibig Contributions',
                  'The provided range overlaps with an existing range.'
                );
              } else {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'PagIbig Contributions',
                  'Error saving changes!'
                );
              }
            } else {
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                'PagIbig Contributions',
                'Set up saved!'
              );
              fetchData();
              setHasChanges(false);
              setIsFormModalOpen(false);
            }
          } else {
            const { data: res, error } = await postAddPagibigContribution(
              selectedContrib
            );
            if (error) {
              if (error?.request?.status == 422) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'Pag ibig Contributions',
                  'The provided range overlaps with an existing range.'
                );
              } else {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'PagIbig Contributions',
                  'Error saving changes!'
                );
              }
            } else {
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                'PagIbig Contributions',
                'Set up saved!'
              );
              fetchData();
              setHasChanges(false);
              setIsFormModalOpen(false);
            }
          }

          setIsConfirmationModalOpen(false);
          setIsLoading(false);
        }}
      />
      <ConfirmationModal
        title='You have unsaved changes! Confirming will lose all your changes. Confirm?'
        cancelText='Continue editing'
        isLoading={isLoading}
        isOpen={isUnsavedModalOpen}
        setIsOpen={setIsUnsavedModalOpen}
        onConfirm={() => {
          form.resetFields();
          setIsUnsavedModalOpen(false);
          setIsFormModalOpen(false);
          setHasChanges(false);
        }}
      />
    </Content>
  );
}
