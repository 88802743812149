import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../App';
import { UserContext } from '../../../../utils/context/UserContext';
import VesselType from '../../../../utils/types/VesselType';
import MpoRequestType from '../../../../utils/types/MpoRequestType';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import {
  ERROR_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  WARNING_NOTIFICATION_TYPE,
} from '../../../../utils/api/_constants';
import {
  getShowAllMpoRequestAllottee,
  getShowAllSlbRequestAllottee,
  postAddMpoRequestAllottee,
  postAddSlbRequestAllottee,
  postDeleteMpoRequestAllottee,
  postDeleteSlbRequestAllottee,
} from '../../../../utils/api/AllotteeAPI';
import dayjs from 'dayjs';
import { getCrewByVessel } from '../../../../utils/api/TransactionsAPI';
import Search from 'antd/es/input/Search';
import { IoIosRefresh } from 'react-icons/io';
import { getVessels } from '../../../../utils/api/VesselAPI';
import { getMpoRequestTable } from '../../../../utils/tableheader/Transactions/MPORequestTable';
import FormModal from '../../../../components/CustomModal/FormModal';
import { getMpoRequestFields } from '../../../../utils/inputfields/MPORequestFields';
import FormInput from '../../../../components/FormInput/FormInput';
import ConfirmationModal from '../../../../components/CustomModal/ConfirmationModal';
import SlbRequestType from '../../../../utils/types/SlbRequestType';
import { getSlbRequestTable } from '../../../../utils/tableheader/Transactions/SLBRequestTable';
import { getSlbRequestFields } from '../../../../utils/inputfields/SLBRequestFields';

export default function SLBRequestPage() {
  const TAG = 'SLB Request';
  const {
    getCurrentDate,
    doSearchInArray,
    profile,
    parseToFloat,
    inputToLocale,
  } = useContext(UserContext);
  const { openNotification } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const [month, setMonth] = useState(new Date(getCurrentDate()).getMonth() + 1);
  const [year, setYear] = useState(new Date(getCurrentDate()).getFullYear());

  const [vesselList, setVesselList] = useState([]);
  const [selectedVessel, setSelectedVessel] = useState(VesselType);

  const [selectedSlbRequest, setSelectedSlbRequest] = useState(SlbRequestType);

  const [slbRequestsList, setSlbRequestsList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const tableContents = slbRequestsList.map((item, index) => ({
    ...item,
    key: index,
  }));

  const handleAction = async (record, type) => {
    if (type === 'delete') {
      const { data: res, error } = await postDeleteSlbRequestAllottee(record);
      if (error) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          TAG,
          'Deleting SLB Request failed.'
        );
      } else {
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          TAG,
          'Deleting SLB Request success.'
        );
        loadSlbRequestList();
      }
    } else {
      const parseRecord = {
        ...record,
        date_requested: dayjs(record.date_requested, 'YYYY-MM-DD'),
      };
      setSelectedSlbRequest(parseRecord);
      form.setFieldsValue(parseRecord);
      setIsFormModalOpen(true);
    }
  };

  const loadSlbRequestList = async () => {
    setIsLoading(true);
    const { data: res, error } = await getShowAllSlbRequestAllottee(
      selectedVessel.vessel_code,
      year,
      month
    );
    if (error) {
      openNotification(ERROR_NOTIFICATION_TYPE, 'SLB Requests', 'Failed');
    } else {
      if (res.length === 0) {
        openNotification(
          WARNING_NOTIFICATION_TYPE,
          TAG,
          'No existing requests for this vessel.'
        );
      }
      setFilteredList(res);
      setSlbRequestsList(res);
    }
    setIsLoading(false);
  };

  const fetchData = async () => {
    setIsLoading(true);
    const { data: res, error } = await getVessels(profile.user_id);
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'SLB Requests',
        'Error fetching vessels.'
      );
    } else {
      setVesselList(res);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Content>
      <Row>
        <Col xs={24} sm={6} className='me-2'>
          <Typography.Text strong className='me-2'>
            Month/Year:
          </Typography.Text>
          <DatePicker
            allowClear={false}
            style={{ width: '70%' }}
            picker='month'
            defaultValue={dayjs(`${month}/${year}`, 'M/YYYY')}
            onChange={(_, dateString) => {
              const [dateMonth, dateYear] = dateString.split('/');
              setMonth(dateMonth);
              setYear(dateYear);
            }}
            format='M/YYYY'
            disabled={isLoading}
          />
        </Col>
        <Col xs={24} sm={8} className='text-start'>
          <Typography.Text strong className='me-2'>
            Vessel:
          </Typography.Text>
          <Select
            style={{ width: '80%' }}
            placeholder={'Search vessel!'}
            value={selectedVessel.vessel_code}
            onChange={(value, option) => {
              const vessel = vesselList.find(
                (item) => item.vessel_code === value
              );
              setSelectedVessel(vessel);
            }}
            disabled={isLoading}
            showSearch
            filterOption={(inputValue, option) => {
              const optionDescription = option.children.toLowerCase();
              return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
            }}
          >
            {vesselList.map((option) => (
              <Select.Option
                key={option.vessel_code}
                value={option.vessel_code}
              >
                {option.vessel_name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Space>
          <Button
            type='primary'
            disabled={isLoading || selectedVessel.id === null}
            onClick={() => loadSlbRequestList()}
          >
            Filter
          </Button>
        </Space>
      </Row>
      <Row className='mb-4' justify={'end'}>
        <Col xs={24} sm={8}>
          <Space.Compact block>
            <Search
              disabled={isLoading || slbRequestsList.length === 0}
              placeholder='Search allottee!'
              onChange={(e) => {
                setSearchQuery(e.target.value);
                doSearchInArray(
                  e.target.value,
                  setFilteredList,
                  slbRequestsList
                );
              }}
              onSearch={(value) => {
                setSearchQuery(value);
                doSearchInArray(value, setFilteredList, slbRequestsList);
              }}
              allowClear={true}
            />
            <Button
              type='primary'
              icon={<IoIosRefresh />}
              loading={isLoading}
              onClick={() => fetchData()}
            />
          </Space.Compact>
        </Col>
      </Row>
      <Table
        columns={getSlbRequestTable(handleAction)}
        dataSource={tableContents}
        size='small'
        bordered='true'
        scroll={{ x: true }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <FormModal
        title={'SLB Request entry'}
        isOpen={isFormModalOpen}
        setIsOpen={setIsFormModalOpen}
        isLoading={isLoading}
        props={{ width: '50vw' }}
        form={form}
        formName={'slb_request_form'}
        initialState={selectedSlbRequest}
        onSave={async () => {
          if (hasChanges) {
            try {
              await form.validateFields();
              setIsConfirmationModalOpen(true);
            } catch {}
          } else {
            openNotification(
              INFO_NOTIFICATION_TYPE,
              'MPO request entry',
              'No changes made.'
            );
            setIsFormModalOpen(false);
          }
        }}
        onCancel={() => {
          form.resetFields();
          setIsFormModalOpen(false);
          // if (hasChanges) {
          //   // setIsUnsavedModalOpen(true);

          // } else {
          //   form.resetFields();
          //   setIsFormModalOpen(false);
          //   setHasChanges(false);
          // }
        }}
      >
        <Row gutter={16}>
          {getSlbRequestFields(
            isLoading,
            selectedSlbRequest,
            setSelectedSlbRequest,
            setHasChanges
          ).map((data, index) => (
            <Col key={index} xs={24} sm={12}>
              <FormInput
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            </Col>
          ))}
        </Row>
      </FormModal>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          await form.validateFields();
          const payload = {
            ...selectedSlbRequest,
            date_requested: dayjs(selectedSlbRequest.date_requested).format(
              'YYYY-MM-DD'
            ),
          };
          const { data: res, error } = await postAddSlbRequestAllottee(payload);
          if (error) {
            openNotification(
              ERROR_NOTIFICATION_TYPE,
              'Update SLB request',
              'Failed'
            );
          } else {
            openNotification(
              SUCCESS_NOTIFICATION_TYPE,
              'Update SLB request',
              'Success'
            );
            loadSlbRequestList();
            setIsConfirmationModalOpen(false);
            setIsFormModalOpen(false);
          }
        }}
      />
    </Content>
  );
}
