import dayjs from 'dayjs';
import SSSContributionType from '../types/SSSContributionType';

export const getSSSContributionFields = (
  isLoading,
  state = SSSContributionType,
  setState,
  setHasChanges
) => {
  return [
    {
      type: 'number',
      name: 'sal_range_from',
      placeholder: '',
      label: 'Salary range from',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: state.sal_range_from,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, sal_range_from: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'sal_range_to',
      placeholder: '',
      label: 'Salary range to',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.sal_range_to,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, sal_range_to: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'employee_sss',
      placeholder: '',
      label: 'Employee share',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.employee_sss,
      setValue: (e) => {
        const value = e.target.value;
        const totalContrib =
          parseFloat(value ? value : 0) +
          parseFloat(state.employer_sss ? state.employer_sss : 0);
        setState({ ...state, employee_sss: value, total_contri: totalContrib });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'employer_sss',
      placeholder: '',
      label: 'Employer share',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.employer_sss,
      setValue: (e) => {
        const value = e.target.value;
        const totalContrib =
          parseFloat(value ? value : 0) +
          parseFloat(state.employee_sss ? state.employee_sss : 0);
        setState({ ...state, employer_sss: value, total_contri: totalContrib });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'provi_employe_sss',
      placeholder: '',
      label: 'Provident employee share',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.provi_employe_sss,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, provi_employe_sss: value });
      },
    },
    {
      type: 'number',
      name: 'provi_employer_sss',
      placeholder: '',
      label: 'Provident employer share',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.provi_employer_sss,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, provi_employer_sss: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'ec',
      placeholder: '',
      label: 'E.C.',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.ec,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, ec: value });
        setHasChanges(true);
      },
    },
    {
      type: 'date-year',
      name: 'year',
      placeholder: '',
      label: 'Year',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.year,
      setValue: (_, dateString) => {
        if (dateString) {
          const date = dayjs(dateString, 'YYYY');
          setState({
            ...state,
            year: date,
          });
        } else {
          setState({
            ...state,
            year: null,
          });
        }
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'total_contri',
      placeholder: '',
      label: 'Total contribution',
      autofocus: false,
      required: true,
      disabled: true,
      value: state.total_contri,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, total_contri: value });
        setHasChanges(true);
      },
    },
  ];
};
