export default {
  id: null,
  title: null,
  description: null,
  created_at: null,
  updated: null,
  main_transaction: null,
  tran_payroll_process: null,
  feature_tran_pp_transactions: null,
  sub_feature_tran_pp_embarked: null,
  function_tran_pp_emb_filter: null,
  function_tran_pp_emb_print: null,
  function_tran_pp_emb_action: null,
  function_tran_pp_emb_salary_breakdown_view: null,
  function_tran_pp_emb_salary_breakdown_modify: null,
  function_tran_pp_emb_allotment_distrib_home_allotment_view: null,
  function_tran_pp_emb_allotment_distrib_home_allotment_modify: null,
  function_tran_pp_emb_allotment_distrib_slb_view: null,
  function_tran_pp_emb_allotment_distrib_slb_modify: null,
  function_tran_pp_emb_allotment_distrib_mpo_view: null,
  function_tran_pp_emb_allotment_distrib_mpo_modify: null,
  function_tran_pp_emb_sched_of_deduc_view: null,
  function_tran_pp_emb_deduc_entry_view: null,
  function_tran_pp_emb_deduc_entry_modify: null,
  function_tran_pp_emb_others_view: null,
  function_tran_pp_emb_others_modify: null,
  sub_feature_tran_pp_disembarked: null,
  function_tran_pp_disemb_filter: null,
  function_tran_pp_disemb_print: null,
  function_tran_pp_disemb_action: null,
  function_tran_pp_disemb_salary_breakdown_view: null,
  function_tran_pp_disemb_salary_breakdown_modify: null,
  function_tran_pp_disemb_allotment_distrib_home_allotment_view: null,
  function_tran_pp_disemb_allotment_distrib_home_allotment_modify: null,
  function_tran_pp_disemb_allotment_distrib_slb_view: null,
  function_tran_pp_disemb_allotment_distrib_slb_modify: null,
  function_tran_pp_disemb_allotment_distrib_mpo_view: null,
  function_tran_pp_disemb_allotment_distrib_mpo_modify: null,
  function_tran_pp_disemb_sched_of_deduc_view: null,
  function_tran_pp_disemb_deduc_entry_view: null,
  function_tran_pp_disemb_deduc_entry_modify: null,
  function_tran_pp_disemb_others_view: null,
  function_tran_pp_disemb_others_modify: null,
  sub_feature_tran_pp_post_records: null,
  function_tran_pp_post_record_post: null,
  function_tran_pp_post_record_home_allotment: null,
  function_tran_pp_post_record_side_letter: null,
  function_tran_pp_post_record_mpo: null,
  function_tran_pp_post_record_crew_wages: null,
  feature_tran_pp_compute: null,
  sub_feature_tran_pp_compute_home_allotment: null,
  function_tran_pp_compute_home_allotment_process: null,
  function_tran_pp_compute_home_allotment_delete: null,
  function_tran_pp_compute_home_allotment_print: null,
  sub_feature_tran_pp_compute_mpo: null,
  function_tran_pp_compute_mpo_process: null,
  function_tran_pp_compute_mpo_delete: null,
  function_tran_pp_compute_mpo_print: null,
  sub_feature_tran_pp_compute_slb: null,
  function_tran_pp_compute_slb_process: null,
  function_tran_pp_compute_slb_delete: null,
  function_tran_pp_compute_slb_print: null,
  sub_feature_tran_pp_compute_crew_wages: null,
  function_tran_pp_compute_crew_wages_filter: null,
  function_tran_pp_compute_crew_wages_print: null,
  function_tran_pp_compute_crew_wages_template_fugro: null,
  function_tran_pp_compute_crew_wages_template_smt: null,
  function_tran_pp_compute_crew_wages_template_forestwave: null,
  function_tran_pp_compute_crew_wages_compute: null,
  function_tran_pp_compute_crew_wages_edit_initial_view: null,
  function_tran_pp_compute_crew_wages_edit_initial_modify: null,
  function_tran_pp_compute_crew_wages_action_view: null,
  function_tran_pp_compute_crew_wages_action_modify: null,
};
