import axios from 'axios';
import {
  BASE_ENDPOINT,
  GET_ALL_CREW_WAGES_ENDPOINT,
  GET_ALL_WAGES_ENDPOINT,
  REQUEST_HEADERS,
} from './_constants';

const GET_ALL_CREW_WAGES_PER_VESSEL_ENDPOINT =
  BASE_ENDPOINT + '/show_all_crew_wages_maintenance';

const ADD_CREW_WAGE_PER_VESSEL_ENDPOINT =
  BASE_ENDPOINT + '/add_crew_maintenance';

const GET_CREW_WAGES_PER_VESSEL_ENDPOINT =
  BASE_ENDPOINT + '/show_crew_wages_maintenance';

const GET_CREW_WAGES_FORESTWAVE_ENDPOINT =
  BASE_ENDPOINT + '/show_current_wages_forestwave';

const GET_CREW_WAGES_SMT_ENDPOINT = BASE_ENDPOINT + '/show_current_wages_smt';

const GET_ADVANCE_CREW_WAGES_ENDPOINT =
  BASE_ENDPOINT + '/advance_schedule_crew_wages';

const POST_ADD_CREW_WAGES_ENDPOINT = BASE_ENDPOINT + '/add_crew_wages_added';

const GET_SAVED_CREW_WAGES_ENDPOINT = BASE_ENDPOINT + '/show_crew_wages_added';
const GET_ALL_SAVED_CREW_WAGES_ENDPOINT =
  BASE_ENDPOINT + '/show_crew_wages_added_all';

const GET_CREW_INDIVIDUAL_PAYSLIP_ENDPOINT =
  BASE_ENDPOINT + '/get_payslip_crew_wages';

const GET_CREW_HOME_ALLOTMENT_ENDPOINT =
  BASE_ENDPOINT + '/crew_wages_home_allotment';

const GET_UK_WAGES_ENDPOINT = BASE_ENDPOINT + '/show_uk_wages';

const GET_CURRENT_CREW_WAGES_ENDPOINT = BASE_ENDPOINT + '/show_current_wages';

const POST_PROCESS_CREW_WAGES_ENDPOINT = BASE_ENDPOINT + '/process_crew_wages';

const POST_UPDATE_INITIAL_LEAVE_PROV = BASE_ENDPOINT + '/crew_wages_leave_prov';

const POST_RECOMPUTE_CREW_WAGES = BASE_ENDPOINT + '/recompute_crew_wages';

const GET_CREW_WAGES_INPUT_ENDPOINT = BASE_ENDPOINT + '/get_crew_wages_input';
const POST_ADD_CREW_WAGES_INPUT_ENDPOINT =
  BASE_ENDPOINT + '/add_crew_wages_input';

const GET_TEMPLATES_ENDPOINT = BASE_ENDPOINT + '/get_crew_wages_template';

export async function getAllWages() {
  try {
    const { data } = await axios.get(GET_ALL_WAGES_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getAllCrewWages() {
  try {
    const { data } = await axios.get(GET_ALL_CREW_WAGES_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getCrewWagesPerVessel() {
  try {
    const { data } = await axios.get(GET_ALL_CREW_WAGES_PER_VESSEL_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddCrewWagePerVessel(payload) {
  try {
    const { data } = await axios.post(
      ADD_CREW_WAGE_PER_VESSEL_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getCrewWagesSetupPerVessel(
  vescode,
  year,
  month,
  template
) {
  try {
    const { data } = await axios.get(
      `${GET_CREW_WAGES_PER_VESSEL_ENDPOINT}/${vescode}/${year}/${month}/${template}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getCrewWagesSetupPerVesselForestwave(
  vescode,
  year,
  month
) {
  try {
    const { data } = await axios.get(
      `${GET_CREW_WAGES_FORESTWAVE_ENDPOINT}/${vescode}/${year}/${month}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getCrewWagesSetupPerVesselSMT(vescode, year, month) {
  try {
    const { data } = await axios.get(
      `${GET_CREW_WAGES_SMT_ENDPOINT}/${vescode}/${year}/${month}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getAdvanceCrewWagesReport(vescode, year, month) {
  try {
    const { data } = await axios.get(
      `${GET_ADVANCE_CREW_WAGES_ENDPOINT}/${vescode}/${year}/${month}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddCrewWageEntry(payload) {
  try {
    const { data } = await axios.post(POST_ADD_CREW_WAGES_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getSavedCrewWages(crew_no) {
  try {
    const { data } = await axios.get(GET_SAVED_CREW_WAGES_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getAllSavedCrewWages(vescode, month, year) {
  try {
    const { data } = await axios.get(
      `${GET_ALL_SAVED_CREW_WAGES_ENDPOINT}/${vescode}/${month}/${year}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getCrewIndividualPayslip(crew_no, month, year) {
  try {
    const { data } = await axios.get(
      `${GET_CREW_INDIVIDUAL_PAYSLIP_ENDPOINT}/${crew_no}/${month}/${year}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getCrewHomeAllotment(vescode, year, month) {
  try {
    const { data } = await axios.get(
      `${GET_CREW_HOME_ALLOTMENT_ENDPOINT}/${vescode}/${year}/${month}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getScheduleUKWages(vescode, year, month) {
  try {
    const { data } = await axios.get(
      `${GET_UK_WAGES_ENDPOINT}/${vescode}/${year}/${month}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getCurrentCrewWages(vescode, year, month) {
  try {
    const { data } = await axios.get(
      `${GET_CURRENT_CREW_WAGES_ENDPOINT}/${vescode}/${year}/${month}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postProcessCrewWages(payload) {
  try {
    const { data } = await axios.post(
      POST_PROCESS_CREW_WAGES_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postUpdateLeaveProv(payload) {
  try {
    const { data } = await axios.post(POST_UPDATE_INITIAL_LEAVE_PROV, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postRecomputeCrewWages(payload) {
  try {
    const { data } = await axios.post(POST_RECOMPUTE_CREW_WAGES, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddCrewWagesInput(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_CREW_WAGES_INPUT_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getCrewWagesInput(vescode, month, year) {
  try {
    const { data } = await axios.get(
      `${GET_CREW_WAGES_INPUT_ENDPOINT}/${vescode}/${month}/${year}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getCrewWageTemplates() {
  try {
    const { data } = await axios.get(GET_TEMPLATES_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
