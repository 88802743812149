import dayjs from 'dayjs';
import PhilhealthContributionType from '../types/PhilhealthContributionType';
import PagibigContributionType from '../types/PagibigContributionType';

export const getPagibigContributionFields = (
  isLoading,
  state = PagibigContributionType,
  setState,
  setHasChanges
) => {
  return [
    {
      type: 'number',
      name: 'sal_range_from',
      placeholder: '',
      label: 'Salary range from',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: state.sal_range_from,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, sal_range_from: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'sal_range_to',
      placeholder: '',
      label: 'Salary range to',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.sal_range_to,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, sal_range_to: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'employee_share',
      placeholder: '',
      label: 'Employee share',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.employee_share,
      setValue: (e) => {
        const value = e.target.value;
        const total =
          parseFloat(value ? value : 0) +
          parseFloat(state.employer_share ? state.employer_share : 0);
        setState({ ...state, employee_share: value, total_contri: total });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'employer_share',
      placeholder: '',
      label: 'Employer share',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.employer_share,
      setValue: (e) => {
        const value = e.target.value;
        const total =
          parseFloat(value ? value : 0) +
          parseFloat(state.employee_share ? state.employee_share : 0);
        setState({ ...state, employer_share: value, total_contri: total });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'employee_share_percent',
      placeholder: '',
      label: 'Employee share %',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.employee_share_percent,
      setValue: (e) => {
        const value = e.target.value;
        setState({
          ...state,
          employee_share_percent: value,
        });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'employer_share_percent',
      placeholder: '',
      label: 'Employer share %',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.employer_share_percent,
      setValue: (e) => {
        const value = e.target.value;
        setState({
          ...state,
          employer_share_percent: value,
        });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'total_contri',
      placeholder: '',
      label: 'Total contribution',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.total_contri,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, total_contri: value });
        setHasChanges(true);
      },
    },
  ];
};
