export const systemTree = {
  title: 'System Setup Module',
  key: 'main_system_setup',
  children: [
    {
      title: 'User Setup',
      key: 'system_setup_user_setup',
      children: [
        {
          title: 'View',
          key: 'function_system_setup_user_setup_view',
        },
        {
          title: 'Modify',
          key: 'function_system_setup_user_setup_modify',
        },
        {
          title: 'Deactivate User',
          key: 'function_system_setup_user_setup_deactivate_user',
        },
        {
          title: 'Add',
          key: 'function_system_setup_user_setup_add',
        },
        {
          title: 'Unban',
          key: 'function_system_setup_user_setup_unban_user',
        },
        {
          title: 'Delete',
          key: 'function_system_setup_user_setup_delete',
        },
      ],
    },
    {
      title: 'Access Level',
      key: 'system_setup_access_level_setup',
      children: [
        {
          title: 'View',
          key: 'function_system_setup_access_level_setup_view',
        },
        {
          title: 'Modify',
          key: 'function_system_setup_access_level_setup_modify',
        },
        {
          title: 'Add',
          key: 'function_system_setup_access_level_setup_add',
        },
        {
          title: 'Delete',
          key: 'function_system_setup_access_level_setup_delete',
        },
      ],
    },
  ],
};
