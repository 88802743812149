import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Row,
  Select,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../utils/context/UserContext';
import { GlobalContext } from '../../../App';
import VesselType from '../../../utils/types/VesselType';
import { getVessels } from '../../../utils/api/VesselAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  WARNING_NOTIFICATION_TYPE,
} from '../../../utils/api/_constants';
import { getPayslipPerVessel } from '../../../utils/api/PayslipAPI';
import CustomModal from '../../../components/CustomModal/CustomModal';
import PayslipPerVesselPDF from '../../../components/PDFReports/PayslipPerVesselPDF';

export default function ReportPayslipPerVesselPage() {
  const TAG = 'Payslip per Vessel';
  const {
    getCurrentDate,
    profile,
    doSearchInArray,
    parseToFloat,
    inputToLocale,
  } = useContext(UserContext);

  const { openNotification } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);

  const [month, setMonth] = useState(new Date(getCurrentDate()).getMonth() + 1);
  const [year, setYear] = useState(new Date(getCurrentDate()).getFullYear());

  const [vesselList, setVesselList] = useState([]);

  const [selectedVessel, setSelectedVessel] = useState(VesselType);

  const [vesselRecords, setVesselRecords] = useState([]);

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const toggleOptions = [
    {
      label: 'Home Allotment',
      value: 'home_allotment',
    },
    {
      label: 'Side Letter',
      value: 'side_letter',
    },
    {
      label: 'Special Allotment',
      value: 'special_allotment',
    },
    {
      label: 'Remaining Wages',
      value: 'remaining_wages',
    },
  ];

  const fetchDatas = async () => {
    setIsLoading(true);
    const { data: res, error } = await getVessels(profile.user_id);

    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        TAG,
        'Fetching crew list failed.'
      );
    } else {
      setVesselList(res);
    }
    setIsLoading(false);
  };

  const fetchCrewPayslip = async () => {
    setIsLoading(true);

    const payload = {
      month: month,
      year: year,
      vescode: selectedVessel.vessel_code,
      home: selectedOptions.includes('home_allotment') ? 1 : 0,
      mpo: selectedOptions.includes('special_allotment') ? 1 : 0,
      slb: selectedOptions.includes('side_letter') ? 1 : 0,
      rem_wages: selectedOptions.includes('remaining_wages') ? 1 : 0,
    };

    const { data: res, error } = await getPayslipPerVessel(payload);

    if (error) {
      openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Fetching payslip failed');
    } else {
      if (res.length > 0) {
        setVesselRecords(res);
        setIsPrintModalOpen(true);
      } else {
        openNotification(WARNING_NOTIFICATION_TYPE, TAG, 'No records found.');
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <Content>
      <Row>
        <Col xs={24} sm={6} className='me-2'>
          <Typography.Text strong className='me-2'>
            Month/Year:
          </Typography.Text>
          <DatePicker
            allowClear={false}
            style={{ width: '70%' }}
            picker='month'
            defaultValue={dayjs(`${month}/${year}`, 'M/YYYY')}
            onChange={(_, dateString) => {
              const [dateMonth, dateYear] = dateString.split('/');
              setMonth(dateMonth);
              setYear(dateYear);
            }}
            format='M/YYYY'
            disabled={isLoading}
          />
        </Col>
        <Col xs={24} sm={8} className='text-start'>
          <Typography.Text strong className='me-2'>
            Vessel:
          </Typography.Text>
          <Select
            style={{ width: '80%' }}
            placeholder={'Search vessel!'}
            value={selectedVessel.vessel_code}
            onChange={(value, option) => {
              const vessel = vesselList.find(
                (item) => item.vessel_code === value
              );
              setSelectedVessel(vessel);
            }}
            disabled={isLoading}
            showSearch
            filterOption={(inputValue, option) => {
              const optionDescription = option.children.toLowerCase();
              return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
            }}
          >
            {vesselList.map((option) => (
              <Select.Option
                key={option.vessel_code}
                value={option.vessel_code}
              >
                {option.vessel_name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={8} className='text-start'>
          <Button
            type='primary'
            disabled={
              isLoading ||
              selectedVessel.vessel_code === null ||
              selectedOptions.length === 0
            }
            onClick={() => fetchCrewPayslip()}
          >
            Process
          </Button>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Typography.Text strong className='me-2'>
          Options:
        </Typography.Text>
        <Checkbox.Group
          disabled={isLoading}
          options={toggleOptions}
          onChange={(checkedValues) => {
            setSelectedOptions(checkedValues);
          }}
        />
      </Row>
      <CustomModal
        isLoading={isLoading}
        isOpen={isPrintModalOpen}
        setIsOpen={setIsPrintModalOpen}
        cancelText='Close'
        props={{ okButtonProps: { hidden: true }, width: '100vw' }}
        isClosable={true}
      >
        <PayslipPerVesselPDF vesselRecords={vesselRecords} />
      </CustomModal>
    </Content>
  );
}
