import axios from 'axios';
import { BASE_ENDPOINT, REQUEST_HEADERS } from './_constants';

const GET_ALL_USERS_ENDPOINT = BASE_ENDPOINT + '/show_all_user';
const IS_EMAIL_AVAILABLE_ENDPOINT = BASE_ENDPOINT + '/is_email_available';
const IS_USERNAME_AVAILABLE_ENDPOINT = BASE_ENDPOINT + '/is_username_available';
const POST_ADD_USER_ENDPOINT = BASE_ENDPOINT + '/add_user';
const DEACTIVATE_USER_ENDPOINT = BASE_ENDPOINT + '/deactivate_user';
const REACTIVATE_USER_ENDPOINT = BASE_ENDPOINT + '/reactivate_user';
const UNBAN_USER_ENDPOINT = BASE_ENDPOINT + '/clear_ban_time';
const DELETE_USER_ENDPOINT = BASE_ENDPOINT + '/delete_user';

const GET_ALLOWED_PRINCIPALS_USER_ENDPOINT =
  BASE_ENDPOINT + '/get_allowed_principals';
const POST_UPDATE_ALLOWED_PRINCIPALS_USER_ENDPOINT =
  BASE_ENDPOINT + '/update_allowed_principals';

export async function getAllUserSystem() {
  try {
    const { data } = await axios.get(GET_ALL_USERS_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postCheckEmailAvailability(payload) {
  try {
    const { data } = await axios.post(IS_EMAIL_AVAILABLE_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postCheckUsernameAvailability(payload) {
  try {
    const { data } = await axios.post(IS_USERNAME_AVAILABLE_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddUser(payload) {
  try {
    const { data } = await axios.post(POST_ADD_USER_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeactivateUser(payload) {
  try {
    const { data } = await axios.post(DEACTIVATE_USER_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postReactivateUser(payload) {
  try {
    const { data } = await axios.post(REACTIVATE_USER_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postUnbanUser(payload) {
  try {
    const { data } = await axios.post(UNBAN_USER_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeleteUser(userId) {
  try {
    const { data } = await axios.post(
      `${DELETE_USER_ENDPOINT}/${userId}`,
      {},
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getAllowedPrincipals(userId) {
  try {
    const { data } = await axios.get(
      `${GET_ALLOWED_PRINCIPALS_USER_ENDPOINT}/${userId}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postUpdateAllowedPrincipal(payload) {
  try {
    const { data } = await axios.post(
      POST_UPDATE_ALLOWED_PRINCIPALS_USER_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
