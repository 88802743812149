import { Tabs } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext } from 'react';
import DeductionsPage from './DefineVariousTableTabs/DeductionsPage';
import UnionDuesPage from './DefineVariousTableTabs/UnionDuesPage';
import OtherDeductionsPage from './DefineVariousTableTabs/OtherDeductionsPage';
import { UserContext } from '../../utils/context/UserContext';

export default function DefineVariousTablePage() {
  const { validateAccessToFeature } = useContext(UserContext);

  const checkAvailableTabs = () => {
    const tabs = [];
    if (validateAccessToFeature('feature_maintenance_dvt_deductions')) {
      tabs.push({
        key: '1',
        label: 'Deductions',
        children: <DeductionsPage />,
        disabled: !validateAccessToFeature(
          'feature_maintenance_dvt_deductions'
        ),
      });
    }
    if (validateAccessToFeature('feature_maintenance_dvt_other_deductions')) {
      tabs.push({
        key: '2',
        label: 'Other Deductions',
        children: <OtherDeductionsPage />,
        disabled: !validateAccessToFeature(
          'feature_maintenance_dvt_other_deductions'
        ),
      });
    }
    if (validateAccessToFeature('feature_maintenance_dvt_other_union_dues')) {
      tabs.push({
        key: '3',
        label: 'Union Dues',
        children: <UnionDuesPage />,
        disabled: !validateAccessToFeature(
          'feature_maintenance_dvt_other_union_dues'
        ),
      });
    }
    // if (validateAccessToFeature('')) {
    tabs.push({
      key: '4',
      label: 'Welfare & Mutual Benefit Plan',
      children: '',
      disabled: !validateAccessToFeature(''),
    });
    // }
    return tabs;
  };

  return (
    <Content>
      <Tabs items={checkAvailableTabs()} centered />
    </Content>
  );
}
