import OtherDeductionType from '../types/OtherDeductionType';

export const getOtherDeductionFields = (
  isLoading,
  state = OtherDeductionType,
  setState,
  setHasChanges
) => {
  return [
    {
      type: 'text',
      name: 'code',
      placeholder: 'Code',
      label: 'Code',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: state.code,
      setValue: (e) => {
        const value = e.target.value.toUpperCase();
        setState({ ...state, code: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'desc',
      placeholder: 'Description',
      label: 'Description',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.desc,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, desc: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'short_name',
      placeholder: 'Short Name',
      label: 'Short Name',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.short_name,
      setValue: (e) => {
        const value = e.target.value.toUpperCase();
        setState({ ...state, short_name: value });
        setHasChanges(true);
      },
    },
    {
      type: 'dropdown',
      name: 'loan_type',
      placeholder: 'Loan Type',
      label: 'Loan Type',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.loan_type,
      dropdownOptions: [
        { id: 0, description: 'N/A' },
        { id: 1, description: 'SSS' },
        { id: 2, description: 'PagIbig' },
      ],
      setValue: (value, option) => {
        if (value === 0) {
          setState({
            ...state,
            pagibig_loan: 0,
            sss_loan: 0,
          });
        } else if (value === 1) {
          setState({
            ...state,
            pagibig_loan: 0,
            sss_loan: 1,
          });
        } else if (value === 2) {
          setState({
            ...state,
            pagibig_loan: 1,
            sss_loan: 0,
          });
        }
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'account_code',
      placeholder: 'Account Code',
      label: 'Account Code',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.account_code,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, account_code: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'sl_acct_code',
      placeholder: 'SL Account Code',
      label: 'SL Account Code',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.sl_acct_code,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, sl_acct_code: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'expense_acct_code',
      placeholder: 'Expense Account Code',
      label: 'Expense Account Code',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.expense_acct_code,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, expense_acct_code: value });
        setHasChanges(true);
      },
    },
  ];
};
