import CrewType from '../types/CrewType';
import DeductionType from '../types/DeductionType';
import OtherDeductionType from '../types/OtherDeductionType';

export const getAllotmentDistributionFields = (
  isLoading,
  state = CrewType.allotment,
  setState,
  setHasChanges,
  bankListOptions,
  activeTab
) => {
  return [
    {
      type: 'name',
      name: 'last_name',
      placeholder: 'Last Name',
      label: 'Last Name',
      autofocus: true,
      required: true,
      shouldRender: true,
      disabled: isLoading,
      value: state.last_name,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, last_name: value });
        setHasChanges(true);
      },
    },
    {
      type: 'name',
      name: 'first_name',
      placeholder: 'First Name',
      label: 'First Name',
      autofocus: false,
      required: true,
      shouldRender: true,
      disabled: isLoading,
      value: state.first_name,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, first_name: value });
        setHasChanges(true);
      },
    },
    {
      type: 'name',
      name: 'middle_name',
      placeholder: 'Middle Name',
      label: 'Middle Name',
      autofocus: false,
      required: false,
      shouldRender: true,
      disabled: isLoading,
      value: state.middle_name,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, middle_name: value });
        setHasChanges(true);
      },
    },
    {
      type: 'dropdown',
      name: 'acct_type',
      placeholder: 'Account Type',
      label: 'Account Type',
      autofocus: false,
      required: true,
      shouldRender: true,
      disabled: isLoading,
      value: state.acct_type,
      dropdownOptions: [
        { id: 'SA', description: 'SA' },
        { id: 'CA', description: 'CA' },
      ],
      setValue: (value, option) => {
        setState({ ...state, acct_type: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'acct_no',
      placeholder: 'Account Number',
      label: 'Account Number',
      autofocus: false,
      required: true,
      shouldRender: true,
      disabled: isLoading,
      value: state.acct_no,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, acct_no: value });
        setHasChanges(true);
      },
    },
    {
      type: 'dropdown',
      name: 'is_dollar_account',
      placeholder: 'Dollar Account',
      label: 'Dollar Account',
      autofocus: false,
      required: true,
      shouldRender: true,
      disabled: isLoading,
      value: state.is_dollar_account,
      dropdownOptions: [
        { id: 0, description: 'No' },
        { id: 1, description: 'Yes' },
      ],
      setValue: (value, option) => {
        setState({ ...state, is_dollar_account: value });
        setHasChanges(true);
      },
    },
    {
      type: 'searchable-dropdown',
      name: 'bankcode',
      placeholder: 'Bank Code',
      label: 'Bank Code',
      autofocus: false,
      required: true,
      shouldRender: true,
      disabled: isLoading,
      value: state.bankcode,
      dropdownOptions: bankListOptions.map((item) => ({
        id: item.code,
        description: item.descr,
      })),
      setValue: (value, option) => {
        setState({
          ...state,
          bankcode: option.value,
          bankdesc: option.children,
        });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'bankdesc',
      placeholder: 'Bank Description',
      label: 'Bank Description',
      autofocus: false,
      required: false,
      shouldRender: true,
      disabled: true,
      value: state.bankdesc,
    },
    {
      type: 'text',
      name: 'bankbranch',
      placeholder: 'Bank Branch',
      label: 'Bank Branch',
      autofocus: false,
      required: true,
      shouldRender: true,
      disabled: isLoading,
      value: state.bankbranch,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, bankbranch: value });
        setHasChanges(true);
      },
    },
    {
      type: 'toggle',
      name: 'regular_allotment_is_checked',
      placeholder: '',
      label: 'Regular Allotment',
      required: false,
      disabled: isLoading,
      hidden: activeTab != '1',
      shouldRender: activeTab == '1',
      value: state.regular_allotment_is_checked === 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          regular_allotment_is_checked: checked ? 1 : 0,
          regular_allotment_usd_amount: checked
            ? state.regular_allotment_usd_amount
            : null,
          regular_allotment_peso_amount: checked
            ? state.regular_allotment_peso_amount
            : null,
          regular_allotment_percentage: checked
            ? state.regular_allotment_percentage
            : null,
          regular_allotment_adjustment_amount: checked
            ? state.regular_allotment_adjustment_amount
            : null,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        className: 'text-start',
      },
    },
    {
      type: 'toggle',
      name: 'regular_allotment_hold_allotment',
      placeholder: '',
      label: 'Hold Allotment',
      required: false,
      hidden: activeTab != '1',
      shouldRender: activeTab == '1',
      disabled: isLoading,
      value: state.regular_allotment_hold_allotment == 1 ? true : false,
      setValue: (checked) => {
        setState((prevState) => ({
          ...state,
          regular_allotment_hold_allotment: checked ? 1 : 0,
          regular_allotment: {
            ...prevState.regular_allotment,
            home: checked ? 1 : 0,
          },
        }));
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        className: 'text-start',
      },
    },
    {
      type: 'toggle',
      name: 'side_letter_applied_deduction',
      placeholder: '',
      label: 'Apply deduction',
      required: false,
      hidden: activeTab != '2',
      shouldRender: activeTab == '2',
      disabled: isLoading,
      value: state.side_letter_applied_deduction == 1 ? true : false,
      setValue: (checked) => {
        setState((prevState) => ({
          ...state,
          side_letter_applied_deduction: checked ? 1 : 0,
          side_letter: {
            ...prevState.side_letter,
            applied_deduction: checked ? 1 : 0,
          },
        }));
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        className: 'text-start',
      },
    },
    {
      type: 'toggle',
      name: 'side_letter_is_checked',
      placeholder: '',
      label: 'Apply Side Letter',
      required: false,
      disabled: isLoading,
      hidden: activeTab != '2',
      shouldRender: activeTab == '2',
      value: state.side_letter_is_checked === 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          side_letter_is_checked: checked ? 1 : 0,
          // side_letter_usd_amount: checked ? state.side_letter_usd_amount : null,
          // side_letter_peso_amount: checked
          //   ? state.side_letter_peso_amount
          //   : null,
          // side_letter_percentage: checked ? state.side_letter_percentage : null,
          // side_letter_adjustment_amount: checked
          //   ? state.side_letter_adjustment_amount
          //   : null,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        className: 'text-start',
      },
    },
    {
      type: 'toggle',
      name: 'side_letter_hold_allotment',
      placeholder: '',
      label: 'Hold Allotment',
      required: false,
      hidden: activeTab != '2',
      shouldRender: activeTab == '2',
      disabled: isLoading,
      value: state.side_letter_hold_allotment == 1 ? true : false,
      setValue: (checked) => {
        setState((prevState) => ({
          ...state,
          side_letter_hold_allotment: checked ? 1 : 0,
          side_letter: {
            ...prevState.side_letter,
            hold_allotment: checked ? 1 : 0,
          },
        }));
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        className: 'text-start',
      },
    },
    {
      type: 'toggle',
      name: 'mpo_applied_deduction',
      placeholder: '',
      label: 'Apply deduction',
      required: false,
      shouldRender: activeTab == '3',
      hidden: activeTab != '3',
      disabled: isLoading,
      value: state.mpo_applied_deduction == 1 ? true : false,
      setValue: (checked) => {
        setState((prevState) => ({
          ...state,
          mpo_applied_deduction: checked ? 1 : 0,
          mpo: {
            ...prevState.side_letter,
            applied_deduction: checked ? 1 : 0,
          },
        }));
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        className: 'text-start',
      },
    },
    {
      type: 'toggle',
      name: 'mpo_hold_allotment',
      placeholder: '',
      label: 'Hold Allotment',
      required: false,
      hidden: activeTab != '3',
      shouldRender: activeTab == '3',
      disabled: isLoading,
      value: state.mpo_hold_allotment == 1 ? true : false,
      setValue: (checked) => {
        setState((prevState) => ({
          ...state,
          mpo_hold_allotment: checked ? 1 : 0,
          mpo: {
            ...prevState.mpo,
            hold_allotment: checked ? 1 : 0,
          },
        }));
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        className: 'text-start',
      },
    },
    {
      type: 'toggle',
      name: 'mpo_is_checked',
      placeholder: '',
      label: 'Apply MPO',
      required: false,
      disabled: isLoading,
      hidden: activeTab != '3',
      shouldRender: activeTab == '3',
      value: state.mpo_is_checked === 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          mpo_is_checked: checked ? 1 : 0,
          // mpo_usd_amount: checked ? state.mpo_usd_amount : null,
          // mpo_peso_amount: checked ? state.mpo_peso_amount : null,
          // mpo_percentage: checked ? state.mpo_percentage : null,
          // mpo_adjustment_amount: checked ? state.mpo_adjustment_amount : null,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        className: 'text-start',
      },
    },
    {
      type: 'text',
      name: 'hiddenText',
      hidden: true,
      shouldRender: activeTab == '1',
      required: false,
    },
    {
      type: 'text',
      name: 'hiddenText',
      hidden: true,
      shouldRender: activeTab == '1',
      required: false,
    },
    {
      type: 'number',
      name: 'regular_allotment_usd_amount',
      placeholder: '',
      label: 'Amount',
      autofocus: false,
      required: false,
      shouldRender: true,
      disabled: isLoading || state.regular_allotment_is_checked !== 1,
      hidden: activeTab != '1',
      value: state.regular_allotment_usd_amount,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, regular_allotment_usd_amount: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'side_letter_usd_amount',
      placeholder: '',
      label: 'Amount Side Letter',
      autofocus: false,
      required: state.side_letter_is_checked == 1 && activeTab == '2',
      shouldRender: true,
      // disabled: isLoading || state.side_letter_is_checked !== 1,
      disabled: isLoading,
      hidden: activeTab != '2',
      value: state.side_letter_usd_amount,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, side_letter_usd_amount: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'mpo_usd_amount',
      placeholder: '',
      label: 'MPO Amount',
      autofocus: false,
      required: state.mpo_is_checked == 1 && activeTab == '3',
      shouldRender: true,
      // disabled: isLoading || state.mpo_is_checked !== 1,
      disabled: isLoading,
      hidden: activeTab != '3',
      value: state.mpo_usd_amount,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, mpo_usd_amount: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'regular_allotment_peso_amount',
      placeholder: '',
      label: 'Fixed Peso',
      autofocus: false,
      required: false,
      shouldRender: true,
      disabled: isLoading || state.regular_allotment_is_checked !== 1,
      hidden: activeTab != '1',
      value: state.regular_allotment_peso_amount,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, regular_allotment_peso_amount: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'side_letter_peso_amount',
      placeholder: '',
      label: 'Fixed Peso Side Letter',
      autofocus: false,
      required: false,
      shouldRender: true,
      disabled: isLoading || state.side_letter_is_checked !== 1,
      // hidden: activeTab != '2',
      hidden: true,
      value: state.side_letter_peso_amount,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, side_letter_peso_amount: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'mpo_peso_amount',
      placeholder: '',
      label: 'Fixed Peso MPO',
      autofocus: false,
      required: false,
      shouldRender: true,
      // disabled: isLoading || state.mpo_is_checked !== 1,
      disabled: isLoading,
      // hidden: activeTab != '3',
      hidden: true,
      value: state.mpo_peso_amount,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, mpo_peso_amount: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'regular_allotment_percentage',
      placeholder: '',
      label: 'Percentage',
      autofocus: false,
      required: false,
      shouldRender: true,
      disabled: isLoading || state.regular_allotment_is_checked !== 1,
      hidden: activeTab != '1',
      value: state.regular_allotment_percentage,
      setValue: (e) => {
        let value = e.target.value;
        if (parseFloat(value) > 100) {
          value = 100;
        }
        setState({ ...state, regular_allotment_percentage: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'side_letter_percentage',
      placeholder: '',
      label: 'Percentage Side Letter',
      autofocus: false,
      required: false,
      shouldRender: true,
      disabled: isLoading || state.side_letter_is_checked !== 1,
      // hidden: activeTab != '2',
      hidden: true,
      value: state.side_letter_percentage,
      setValue: (e) => {
        let value = e.target.value;
        if (parseFloat(value) > 100) {
          value = 100;
        }
        setState({ ...state, side_letter_percentage: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'mpo_percentage',
      placeholder: '',
      label: 'Percentage MPO',
      autofocus: false,
      required: false,
      shouldRender: true,
      // disabled: isLoading || state.mpo_is_checked !== 1,
      disabled: isLoading,
      // hidden: activeTab != '3',
      hidden: true,
      value: state.mpo_percentage,
      setValue: (e) => {
        let value = e.target.value;
        if (parseFloat(value) > 100) {
          value = 100;
        }
        setState({ ...state, mpo_percentage: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'regular_allotment_adjustment_amount',
      placeholder: '',
      label: 'Adjustment',
      autofocus: false,
      required: false,
      shouldRender: true,
      disabled: isLoading || state.regular_allotment_is_checked !== 1,
      hidden: activeTab != '1',
      value: state.regular_allotment_adjustment_amount,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, regular_allotment_adjustment_amount: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'side_letter_adjustment_amount',
      placeholder: '',
      // label: 'Adjustment Side Letter',
      label: '',
      autofocus: false,
      required: false,
      hidden: true,
      shouldRender: true,
      disabled: isLoading || state.side_letter_is_checked !== 1,
      value: state.side_letter_adjustment_amount,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, side_letter_adjustment_amount: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'mpo_adjustment_amount',
      placeholder: '',
      // label: 'Adjustment MPO',
      label: '',
      autofocus: false,
      required: false,
      hidden: true,
      shouldRender: true,
      disabled: isLoading || state.mpo_is_checked !== 1,
      value: state.mpo_adjustment_amount,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, mpo_adjustment_amount: value });
        setHasChanges(true);
      },
    },
    {
      type: 'toggle',
      name: 'applied_deduction',
      placeholder: '',
      label: 'Rem. Salary',
      required: false,
      shouldRender: true,
      hidden: activeTab != '1',
      disabled: isLoading,
      value: state.regular_allotment_applied_deduction == 1 ? true : false,
      setValue: (checked) => {
        setState((prevState) => ({
          ...state,
          regular_allotment_applied_deduction: checked ? 1 : 0,
          regular_allotment: {
            ...prevState.regular_allotment,
            applied_deduction: checked ? 1 : 0,
          },
          regular_allotment_usd_amount: checked
            ? 0
            : state.regular_allotment_usd_amount,
          regular_allotment_peso_amount: checked
            ? 0
            : state.regular_allotment_peso_amount,
          regular_allotment_percentage: checked
            ? 0
            : state.regular_allotment_percentage,
        }));
        // if (state.regular_allotment_is_checked == 1) {
        //   setState((prevState) => ({
        //     ...state,
        //     regular_allotment_applied_deduction: checked ? 1 : 0,
        //     regular_allotment: {
        //       ...prevState.regular_allotment,
        //       applied_deduction: checked ? 1 : 0,
        //     },
        //     regular_allotment_usd_amount: checked
        //       ? 0
        //       : state.regular_allotment_usd_amount,
        //     regular_allotment_peso_amount: checked
        //       ? 0
        //       : state.regular_allotment_peso_amount,
        //     regular_allotment_percentage: checked
        //       ? 0
        //       : state.regular_allotment_percentage,
        //   }));
        // }
        // if (state.side_letter_is_checked == 1) {
        //   setState((prevState) => ({
        //     ...state,
        //     side_letter_applied_deduction: checked ? 1 : 0,
        //     side_letter: {
        //       ...prevState.side_letter,
        //       applied_deduction: checked ? 1 : 0,
        //     },
        //     side_letter_usd_amount: checked ? 0 : state.side_letter_usd_amount,
        //     side_letter_peso_amount: checked
        //       ? 0
        //       : state.side_letter_peso_amount,
        //     side_letter_percentage: checked ? 0 : state.side_letter_percentage,
        //   }));
        // }
        // if (state.mpo_is_checked == 1) {
        //   setState((prevState) => ({
        //     ...state,
        //     mpo_applied_deduction: checked ? 1 : 0,
        //     mpo: {
        //       ...prevState.mpo,
        //       applied_deduction: checked ? 1 : 0,
        //     },
        //     mpo_usd_amount: checked ? 0 : state.mpo_usd_amount,
        //     mpo_peso_amount: checked ? 0 : state.mpo_peso_amount,
        //     mpo_percentage: checked ? 0 : state.mpo_percentage,
        //   }));
        // }
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        className: 'text-start',
      },
    },
  ];
};
