import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { HOME_ROUTE } from './routes';
import LoginPage from '../../pages/LoginPage/LoginPage';

export default function AuthRoute() {
  return (
    <Routes>
      <Route path={HOME_ROUTE} element={<LoginPage />}></Route>
      <Route path="*" element={<Navigate to="/" />}></Route>
    </Routes>
  );
}
