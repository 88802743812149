import axios from 'axios';
import {
  GET_ALL_PAGIBIG_CONTRIBUTION_ENDPOINT,
  GET_ALL_PHILHEALTH_CONTRIBUTION_ENDPOINT,
  GET_ALL_SSS_CONTRIBUTION_ENDPOINT,
  GET_PHILHEALTH_PER_YEAR_CONTRIBUTION_ENDPOINT,
  GET_SSS_PER_YEAR_CONTRIBUTION_ENDPOINT,
  POST_ADD_PAGIBIG_CONTRIBUTION_ENDPOINT,
  POST_ADD_PHILHEALTH_CONTRIBUTION_ENDPOINT,
  POST_ADD_SSS_CONTRIBUTION_ENDPOINT,
  POST_COPY_PHILHEALTH_CONTRIBUTION_ENDPOINT,
  POST_COPY_SSS_CONTRIBUTION_ENDPOINT,
  POST_DELETE_PAGIBIG_CONTRIBUTION_ENDPOINT,
  POST_DELETE_PHILHEALTH_CONTRIBUTION_ENDPOINT,
  POST_DELETE_SSS_CONTRIBUTION_ENDPOINT,
  POST_MODIFY_PAGIBIG_CONTRIBUTION_ENDPOINT,
  POST_MODIFY_PHILHEALTH_CONTRIBUTION_ENDPOINT,
  POST_MODIFY_SSS_CONTRIBUTION_ENDPOINT,
  REQUEST_HEADERS,
} from './_constants';

export async function getAllSSSContribution() {
  try {
    const { data } = await axios.get(GET_ALL_SSS_CONTRIBUTION_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getSSSPerYearContribution(year) {
  try {
    const { data } = await axios.get(GET_SSS_PER_YEAR_CONTRIBUTION_ENDPOINT, {
      params: {
        year: year,
      },
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddSSSContribution(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_SSS_CONTRIBUTION_ENDPOINT,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postModifySSSContribution(payload) {
  try {
    const { data } = await axios.post(
      `${POST_MODIFY_SSS_CONTRIBUTION_ENDPOINT}/${payload.id}`,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postCopySSSContribution(payload) {
  try {
    const { data } = await axios.post(
      POST_COPY_SSS_CONTRIBUTION_ENDPOINT,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeleteSSSContribution(payload) {
  try {
    const { data } = await axios.post(
      `${POST_DELETE_SSS_CONTRIBUTION_ENDPOINT}/${payload.id}`,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getAllPhilHealthContribution() {
  try {
    const { data } = await axios.get(GET_ALL_PHILHEALTH_CONTRIBUTION_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getPhilHealthPerYearContribution(year) {
  try {
    const { data } = await axios.get(
      GET_PHILHEALTH_PER_YEAR_CONTRIBUTION_ENDPOINT,
      {
        params: {
          year: year,
        },
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddPhilHealthContribution(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_PHILHEALTH_CONTRIBUTION_ENDPOINT,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postModifyPhilHealthContribution(payload) {
  try {
    const { data } = await axios.post(
      `${POST_MODIFY_PHILHEALTH_CONTRIBUTION_ENDPOINT}/${payload.id}`,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postCopyPhilHealthContribution(payload) {
  try {
    const { data } = await axios.post(
      POST_COPY_PHILHEALTH_CONTRIBUTION_ENDPOINT,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeletePhilHealthContribution(payload) {
  try {
    const { data } = await axios.post(
      `${POST_DELETE_PHILHEALTH_CONTRIBUTION_ENDPOINT}/${payload.id}`,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getAllPagIbigContribution() {
  try {
    const { data } = await axios.get(GET_ALL_PAGIBIG_CONTRIBUTION_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddPagibigContribution(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_PAGIBIG_CONTRIBUTION_ENDPOINT,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postModifyPagIbigContribution(payload) {
  try {
    const { data } = await axios.post(
      `${POST_MODIFY_PAGIBIG_CONTRIBUTION_ENDPOINT}/${payload.id}`,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeletePagIbigContribution(payload) {
  try {
    const { data } = await axios.post(
      `${POST_DELETE_PAGIBIG_CONTRIBUTION_ENDPOINT}/${payload.id}`,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
