import React, { useContext } from 'react';
import {
  PDFViewer,
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from '@react-pdf/renderer';
import HomeAllotmentHeaderType from '../../utils/types/HomeAllotmentHeaderType';
import dayjs from 'dayjs';
import logoImage from '../../assets/icon-name.png';
import { UserContext } from '../../utils/context/UserContext';

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 10,
    paddingBottom: 20,
  },
  container: {
    flex: 1,
    alignItems: 'center',
  },
  headerImage: {
    height: 60,
    width: 60,
  },
  headerText: {
    fontSize: 6,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
  },
  tableRow: {
    flexDirection: 'row',
    borderTopWidth: 1,
    paddingBottom: 0,
  },
  tableCell: {
    width: '6%',
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    paddingTop: 5,
    paddingBottom: 0,
    paddingHorizontal: 1,
    fontSize: '6px',
    marginBottom: 0,
  },

  pageNumber: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 10,
    fontSize: 6,
    textAlign: 'left',
    paddingHorizontal: 10,
  },
  legends: {
    //   position: 'absolute',
    //   bottom: 30,
    //   left: 80,
    //   right: 0,
    fontSize: 8,
    textAlign: 'left',
  },

  bottomContainer: { flexDirection: 'row', margin: 0, padding: 0 },
  column: {
    flex: 1,
    margin: 10,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderTopWidth: 2,
    // textAlign: 'center',
    // backgroundColor: 'lightgray',
    paddingTop: 10,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
});

export default function MPOComputePDF({
  mpoHeader,
  mpoList,
  legends,
  grandTotals,
}) {
  const { inputToLocale } = useContext(UserContext);

  return (
    <PDFViewer style={{ width: '98%', height: '100vh' }}>
      <Document>
        <Page size="A4" style={styles.page} orientation="landscape" wrap>
          <View style={{ ...styles.bottomContainer, marginBottom: -15 }} fixed>
            <View
              style={{
                ...styles.column,
                alignItems: 'flex-end',
                paddingVertical: 0,
                marginRight: 0,
                marginTop: 4,
              }}
            >
              <Image src={logoImage} style={styles.headerImage} />
            </View>
            <View style={{ ...styles.column, paddingTop: 20, marginLeft: 1 }}>
              <Text style={styles.headerText}>MPO REPORT</Text>
              <Text style={styles.headerText}>{mpoHeader.vessel_name}</Text>
              <Text style={styles.headerText}>{mpoHeader.principal_name}</Text>
              <Text style={styles.headerText}>
                {dayjs(`${mpoHeader.month}-${mpoHeader.year}`, 'M-YYYY')
                  .format('MMMM YYYY')
                  .toUpperCase()}
              </Text>
              <Text
                style={{
                  ...styles.headerText,
                  alignSelf: 'flex-end',
                  marginTop: -14,
                }}
              >
                AC-21 REV. (0)
              </Text>
              <Text style={{ ...styles.headerText, alignSelf: 'flex-end' }}>
                Conversion rate: US$ 1.00 - PHP{' '}
                {inputToLocale(mpoHeader.exrate)}
              </Text>
            </View>
          </View>
          <View style={styles.table}>
            <View style={{ ...styles.tableRow, borderBottomWidth: 1 }} fixed>
              <View style={{ ...styles.tableCell, width: '2%' }}>
                <Text>SEQ NO.</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '15%' }}>
                <Text>CREW NAME</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text>POSITION</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>USD</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>PESO</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>SSS/PH</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>PAG IBIG</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>OTHER</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>TOTAL</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>NET PAY</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '15%' }}>
                <Text>ALLOTTEE</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '15%' }}>
                <Text>ALLOTTEE BANK</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '2.5%' }}>
                <Text>BANK</Text>
              </View>
              <View style={{ ...styles.tableCell }}>
                <Text>ACCOUNT NO</Text>
              </View>
            </View>
            {mpoList?.map((record, index) => (
              <View key={`${index}-a-${index}`} style={styles.tableRow}>
                <View style={{ ...styles.tableCell, width: '2%' }}>
                  <Text>{index + 1}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: '15%' }}>
                  <Text>{record.crew_name}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: '10%' }}>
                  <Text>{record.p_name}</Text>
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  {record.allottee.map((allottee, index) => (
                    <Text>{`${inputToLocale(allottee.allot_amount)}\n`}</Text>
                  ))}
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  {record.allottee.map((allottee, index) => (
                    <Text>{`${inputToLocale(allottee.total_peso)}\n`}</Text>
                  ))}
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  {record.allottee.map((allottee, index) => (
                    <Text>{`${
                      parseFloat(allottee.total_govt) > 0
                        ? inputToLocale(allottee.total_govt)
                        : ''
                    }\n`}</Text>
                  ))}
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  {record.allottee.map((allottee, index) => (
                    <Text>{`${
                      parseFloat(allottee.total_pagibig) > 0
                        ? inputToLocale(allottee.total_pagibig)
                        : ''
                    }\n`}</Text>
                  ))}
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  {record.allottee.map((allottee, index) => (
                    <>
                      {allottee.mpo_allotment_allottee.length > 0 && (
                        <>
                          {allottee.mpo_allotment_allottee.map(
                            (mpoAllotment, index) => (
                              <Text key={index}>
                                {parseFloat(
                                  mpoAllotment.other_deduction_amount
                                ) != 0
                                  ? `${mpoAllotment.code}/${inputToLocale(
                                      mpoAllotment.other_deduction_amount
                                    )}`
                                  : ''}
                              </Text>
                            )
                          )}
                        </>
                      )}
                      {parseFloat(allottee.total_union_dues) > 0 && (
                        <Text>{`${allottee.ud_id}/${inputToLocale(
                          allottee.total_union_dues
                        )}`}</Text>
                      )}
                    </>
                  ))}
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  {record.allottee.map((allottee, index) => (
                    <Text>{`${
                      parseFloat(allottee.total_deduction) > 0
                        ? inputToLocale(allottee.total_deduction)
                        : ''
                    }\n`}</Text>
                  ))}
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  {record.allottee.map((allottee, index) => (
                    <Text>{`${inputToLocale(allottee.total_net)}\n`}</Text>
                  ))}
                </View>
                <View style={{ ...styles.tableCell, width: '15%' }}>
                  {record.allottee.map((allottee, index) => (
                    <Text>{`${allottee.allottee}\n`}</Text>
                  ))}
                </View>
                <View style={{ ...styles.tableCell, width: '15%' }}>
                  {record.allottee.map((allottee, index) => (
                    <Text>{`${allottee.bankdesc}\n`}</Text>
                  ))}
                </View>
                <View style={{ ...styles.tableCell, width: '2.5%' }}>
                  {record.allottee.map((allottee, index) => (
                    <Text>{`${allottee.bankcode}\n`}</Text>
                  ))}
                </View>
                <View style={{ ...styles.tableCell }}>
                  {record.allottee.map((allottee, index) => (
                    <Text
                      style={{
                        fontSize:
                          allottee.hold_allotment == '1' ? '4.5' : '6px',
                        marginBottom: allottee.hold_allotment == '1' ? 2 : '',
                      }}
                    >
                      {allottee.hold_allotment == '1'
                        ? 'HOLD ALLOTMENT'
                        : `${allottee.acct_no}\n`}
                    </Text>
                  ))}
                </View>
              </View>
            ))}
            <View
              style={{
                ...styles.tableRow,
                paddingBottom: 2,
                marginBottom: 3,
              }}
            >
              <View style={{ ...styles.tableCell, width: '2%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '15%' }}>
                <Text>GRAND TOTALS:</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text></Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>{inputToLocale(grandTotals.total_usd)}</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>{inputToLocale(grandTotals.total_peso)}</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>{inputToLocale(grandTotals.total_gov_deduction)}</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>
                    {inputToLocale(grandTotals.total_gov_deduction_pagibig)}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>{inputToLocale(grandTotals.total_others)}</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>
                    {inputToLocale(grandTotals.grand_total_deduction)}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>{inputToLocale(grandTotals.grand_total_net)}</Text>
                </View>
              </View>
              <View style={{ ...styles.tableCell, width: '15%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '15%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '2.5%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell }}>
                <Text></Text>
              </View>
            </View>
          </View>
          <Text>{'\n\n\n\n\n\n\n\n\n\n\n'}</Text>
          <View style={styles.footer}>
            <Text style={{ ...styles.legends, marginBottom: 15 }}>
              **LEGENDS: {legends}
            </Text>
            <View style={{ ...styles.bottomContainer, paddingLeft: 100 }}>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Prepared By:{`\n\n_____________________\n`} {`        `}
                  Administrator
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Checked By:{`\n\n_____________________\n`} {`           `}
                  Accountant
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Approved By:{`\n\n_____________________\n`} {`             `}
                  President
                </Text>
              </View>
            </View>
          </View>
          <Text
            style={{ ...styles.pageNumber, textAlign: 'right' }}
            render={({ pageNumber, totalPages }) =>
              `Page: ${pageNumber} / ${totalPages}`
            }
            fixed
          />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `Date printed: [${dayjs().format('YYYY-MM-DD HH:mm:ss')}]`
            }
            fixed
          />
        </Page>
      </Document>
    </PDFViewer>
  );
}
