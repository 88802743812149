import React, { useContext } from 'react';
import {
  PDFViewer,
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from '@react-pdf/renderer';
import HomeAllotmentHeaderType from '../../utils/types/HomeAllotmentHeaderType';
import dayjs from 'dayjs';
import logoImage from '../../assets/icon-name.png';
import { UserContext } from '../../utils/context/UserContext';

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 10,
    paddingBottom: 20,
  },
  container: {
    flex: 1,
    alignItems: 'center',
  },
  headerImage: {
    height: 60,
    width: 60,
  },
  headerText: {
    fontSize: 6,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
  },
  tableRow: {
    flexDirection: 'row',
    borderTopWidth: 0.5,
    paddingBottom: 0,
  },
  tableCell: {
    width: '6%',
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    paddingTop: 5,
    paddingBottom: 0,
    paddingHorizontal: 1,
    fontSize: 4,
    marginBottom: 0,
  },

  pageNumber: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 10,
    fontSize: 6,
    textAlign: 'left',
    paddingHorizontal: 10,
  },
  legends: {
    //   position: 'absolute',
    //   bottom: 30,
    //   left: 80,
    //   right: 0,
    fontSize: 8,
    textAlign: 'left',
  },

  row: { flexDirection: 'row', margin: 0, padding: 0 },
  column: {
    flex: 1,
    margin: 10,
  },

  insideRow: {
    flexDirection: 'row',
    margin: 0,
    padding: 0,
  },
  insideColumn: {
    flex: 1,
    margin: 0,
    padding: 0,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    // borderTopWidth: 1,
    // textAlign: 'center',
    // backgroundColor: 'lightgray',
    paddingTop: 10,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
});

export default function CrewWagesCurrentPDF({
  crewList,
  earningsHeader,
  earningsProRateHeader,
  selectedVessel,
  month,
  year,
  grandTotals,
}) {
  const { inputToLocale, generateFiveNumber } = useContext(UserContext);

  const getEarningValue = (header, earnings) => {
    let amount = 0;
    const record = earnings.find(
      (earning) => earning.salary_code == header.salary_code
    );
    if (record) {
      amount = record.amount;
    }
    return parseFloat(amount) != 0 ? inputToLocale(amount) : '';
  };

  const parseValue = (value) => {
    if (parseFloat(value) != 0) {
      return inputToLocale(value);
    } else {
      return '';
    }
  };

  const getEarningsGrandTotal = (header) => {
    const record = grandTotals.grand_total_earnings[header.salary_code];
    return parseFloat(record) != 0 ? inputToLocale(record) : '';
  };

  const getEarningsProrateGrandTotal = (header) => {
    const record = grandTotals.grand_total_earnings_prorate[header.salary_code];
    return parseFloat(record) != 0 ? inputToLocale(record) : '';
  };

  const computeTotalWages = (field) => {
    const value = crewList.reduce((acc, item) => acc + item[field], 0);
    return parseFloat(value) != 0 ? inputToLocale(value) : '';
  };

  return (
    <PDFViewer style={{ width: '98%', height: '100vh' }}>
      <Document>
        <Page size='LEGAL' style={styles.page} orientation='landscape' wrap>
          <View style={{ ...styles.row, marginBottom: -15 }} fixed>
            <View
              style={{
                ...styles.column,
                alignItems: 'flex-end',
                paddingVertical: 0,
                marginRight: 0,
                marginTop: 4,
              }}
            >
              <Image src={logoImage} style={styles.headerImage} />
            </View>
            <View style={{ ...styles.column, paddingTop: 28, marginLeft: 1 }}>
              <Text style={styles.headerText}>
                {`Payroll for the month of ${dayjs(`${month}-${year}`, 'M-YYYY')
                  .subtract(1, 'month')
                  .format('MMMM YYYY')}`}
              </Text>
              <Text style={styles.headerText}>
                {`(${selectedVessel.vessel_code}) ${selectedVessel.vessel_name}`}
              </Text>
              <Text style={styles.headerText}>in USD</Text>
            </View>
          </View>
          <View style={styles.table}>
            <View style={{ ...styles.tableRow, borderBottomWidth: 0.5 }} fixed>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text></Text>
              </View>
              {earningsHeader.map((earning, index) => (
                <View
                  key={index}
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    borderLeft: index == 0 ? 0.5 : 0,
                    width: '3%',
                  }}
                >
                  <Text>{earning.wage_other_desc}</Text>
                </View>
              ))}
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  width: '3%',
                  backgroundColor: '#c5c9c7',
                }}
              >
                <Text>TOTAL WAGES</Text>
              </View>
              {earningsProRateHeader.map((earning, index) => (
                <View
                  key={index}
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    borderLeft: index == 0 ? 0.5 : 0,
                    width: '3%',
                  }}
                >
                  <Text>{earning.wage_other_desc}</Text>
                </View>
              ))}
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  width: '3%',
                  backgroundColor: '#c5c9c7',
                }}
              >
                <Text>TOTAL WAGES PRO RATE</Text>
              </View>
            </View>
            {crewList?.map((crew, index) => (
              <View
                key={index * generateFiveNumber()}
                style={{
                  ...styles.tableRow,
                  borderBottom: index + 1 == crewList?.length ? 0.5 : 0,
                }}
              >
                <View style={{ ...styles.tableCell, width: '10%' }}>
                  <Text>{crew.crew_name}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: '10%' }}>
                  <Text>{crew.position}</Text>
                </View>
                {earningsHeader.map((header, ind) => (
                  <View
                    key={`${ind * generateFiveNumber()}`}
                    style={{
                      ...styles.tableCell,
                      textAlign: 'right',
                      borderLeft: ind == 0 ? 0.5 : 0,
                      width: '3%',
                    }}
                  >
                    <Text>{getEarningValue(header, crew.earnings)}</Text>
                  </View>
                ))}
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    backgroundColor: '#c5c9c7',
                    width: '3%',
                  }}
                >
                  <Text>{parseValue(crew.total_wages)}</Text>
                </View>
                {earningsProRateHeader.map((header, ind) => (
                  <View
                    key={`${ind * generateFiveNumber()}`}
                    style={{
                      ...styles.tableCell,
                      textAlign: 'right',
                      borderLeft: ind == 0 ? 0.5 : 0,
                      width: '3%',
                    }}
                  >
                    <Text>
                      {getEarningValue(header, crew.earnings_prorate)}
                    </Text>
                  </View>
                ))}
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    backgroundColor: '#c5c9c7',
                    width: '3%',
                  }}
                >
                  <Text>{parseValue(crew.total_wages_prorate)}</Text>
                </View>
              </View>
            ))}
            <View style={{ ...styles.tableRow }}>
              <View style={{ ...styles.tableCell, width: '20%' }}>
                <Text></Text>
              </View>
              {earningsHeader.map((earning, index) => (
                <View
                  key={index}
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    borderBottomWidth: 0.5,
                    width: '3%',
                  }}
                >
                  <Text>{getEarningsGrandTotal(earning)}</Text>
                </View>
              ))}
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '3%',
                  backgroundColor: '#c5c9c7',
                  borderBottomWidth: 0.5,
                }}
              >
                <Text>{computeTotalWages('total_wages')}</Text>
              </View>
              {earningsProRateHeader.map((earning, index) => (
                <View
                  key={index}
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    borderLeft: index == 0 ? 0.5 : 0,
                    borderBottomWidth: 0.5,
                    width: '3%',
                  }}
                >
                  <Text>{getEarningsProrateGrandTotal(earning)}</Text>
                </View>
              ))}
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '3%',
                  backgroundColor: '#c5c9c7',
                  borderBottomWidth: 0.5,
                }}
              >
                <Text>{computeTotalWages('total_wages_prorate')}</Text>
              </View>
            </View>
          </View>
          <View style={styles.footer} wrap={false}>
            <View style={{ ...styles.row, paddingLeft: 100 }}>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Prepared By:{`\n\n_____________________\n`} {`        `}
                  Administrator
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Checked By:{`\n\n_____________________\n`} {`           `}
                  Accountant
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Approved By:{`\n\n_____________________\n`} {`             `}
                  President
                </Text>
              </View>
            </View>
          </View>
          <Text
            style={{ ...styles.pageNumber, textAlign: 'right' }}
            render={({ pageNumber, totalPages }) =>
              `Page: ${pageNumber} / ${totalPages}`
            }
            fixed
          />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `Date printed: [${dayjs().format('YYYY-MM-DD HH:mm:ss')}]`
            }
            fixed
          />
        </Page>
      </Document>
    </PDFViewer>
  );
}
