export default {
  id: null,
  month: null,
  year: null,
  exrate: null,
  created_at: null,
  updated_at: null,
  vessel_code: null,
  vessel_name: null,
  principal_name: null,
};
