import React, { useContext } from 'react';
import {
  PDFViewer,
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from '@react-pdf/renderer';
import HomeAllotmentHeaderType from '../../utils/types/HomeAllotmentHeaderType';
import dayjs from 'dayjs';
import logoImage from '../../assets/icon-name.png';
import { UserContext } from '../../utils/context/UserContext';

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 10,
    paddingBottom: 40,
  },
  container: {
    flex: 1,
    alignItems: 'center',
  },
  headerImage: {
    height: 60,
    width: 60,
  },
  headerText: {
    fontSize: 6,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
  },
  tableRow: {
    flexDirection: 'row',
    borderTopWidth: 0.5,
    paddingBottom: 0,
  },
  tableCell: {
    width: '6%',
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    paddingTop: 5,
    paddingBottom: 0,
    paddingHorizontal: 1,
    fontSize: '6px',
    marginBottom: 0,
  },

  pageNumber: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 10,
    fontSize: 6,
    textAlign: 'left',
    paddingHorizontal: 10,
  },
  legends: {
    //   position: 'absolute',
    //   bottom: 30,
    //   left: 80,
    //   right: 0,
    fontSize: 8,
    textAlign: 'left',
  },

  bottomContainer: { flexDirection: 'row', margin: 0, padding: 0 },
  column: {
    flex: 1,
    margin: 10,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    // borderTopWidth: 1,
    // textAlign: 'center',
    // backgroundColor: 'lightgray',
    paddingTop: 10,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
});

export default function CrewWagesAdvancedPDF({
  crewList,
  earningsHeader,
  deductionsHeader,
  accruedHeader,
  selectedVessel,
  month,
  year,
  withProvFund,
  grandTotals,
}) {
  const { inputToLocale, generateFiveNumber } = useContext(UserContext);

  const getEarningValue = (header, earnings) => {
    let amount = 0;
    const record = earnings.find(
      (earning) => earning.salary_code == header.salary_code
    );
    if (record) {
      amount = record.amount;
    }
    return parseFloat(amount) != 0 ? inputToLocale(amount) : '';
  };

  const getDeductionValue = (header, deductions) => {
    let amount = 0;
    const record = deductions.find(
      (ded) => ded.salary_code == header.salary_code
    );
    if (record) {
      amount = record.amount;
    }
    return parseFloat(amount) != 0 ? inputToLocale(amount) : '';
  };

  const getAccruedValue = (header, accrued) => {
    let amount = 0;
    const record = accrued.find((acc) => acc.salary_code == header.salary_code);
    if (record) {
      amount = record.current_month;
    }
    return parseFloat(amount) != 0 ? inputToLocale(amount) : '';
  };

  const getEarningGrandTotal = (header) => {
    const record = grandTotals.grand_total_earnings[header.salary_code];
    return parseFloat(record) != 0 ? inputToLocale(record) : '';
  };

  const getDeductionGrandTotal = (header) => {
    const record = grandTotals.grand_total_deductions[header.salary_code];
    return parseFloat(record) != 0 ? inputToLocale(record) : '';
  };

  const getAccruedGrandTotal = (header) => {
    const record =
      grandTotals.grand_total_accrued[header.salary_code][0].curr_grand_total;
    return parseFloat(record) != 0 ? inputToLocale(record) : '';
  };

  const parseValue = (value) => {
    if (parseFloat(value) != 0) {
      return inputToLocale(value);
    } else {
      return '';
    }
  };

  return (
    <PDFViewer style={{ width: '98%', height: '100vh' }}>
      <Document>
        <Page size='LEGAL' style={styles.page} orientation='landscape' wrap>
          <View style={{ ...styles.bottomContainer, marginBottom: -15 }} fixed>
            <View
              style={{
                ...styles.column,
                alignItems: 'flex-end',
                paddingVertical: 0,
                marginRight: 0,
                marginTop: 4,
              }}
            >
              <Image src={logoImage} style={styles.headerImage} />
            </View>
            <View style={{ ...styles.column, paddingTop: 28, marginLeft: 1 }}>
              <Text style={styles.headerText}>SCHEDULE OF CREW WAGES</Text>
              <Text style={styles.headerText}>
                {`(${selectedVessel.vessel_code}) ${selectedVessel.vessel_name}`}
              </Text>
              <Text style={styles.headerText}>
                FOR THE MONTH OF{' '}
                {dayjs(`${month}-${year}`, 'M-YYYY')
                  .format('MMMM YYYY')
                  .toUpperCase()}
              </Text>
            </View>
          </View>
          <View style={styles.table}>
            <View style={{ ...styles.tableRow }}>
              <View style={{ ...styles.tableCell, width: '2%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '4%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '4%' }}>
                <Text></Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  width: '2%',
                }}
              >
                <Text></Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: `${(earningsHeader.length + 1) * 3}%`,
                }}
              >
                <Text>EARNINGS</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  borderRight: 0.5,
                  width: `${(deductionsHeader.length + 1) * 3}%`,
                }}
              >
                <Text>DEDUCTIONS</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '12%' }}>
                <Text></Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  borderLeft: 0.5,
                  width: `${
                    withProvFund
                      ? accruedHeader.length * 4 + 8
                      : accruedHeader.some((acc) => acc.salary_code == 'PROVF')
                      ? (accruedHeader.length - 1) * 4 + 8
                      : accruedHeader.length * 4 + 8
                  }%`,
                }}
              >
                <Text>ACCRUED LEAVE PAY</Text>
              </View>
              {/* {withProvFund && (
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'center',
                    borderLeft: 0.5,
                    width: '12%',
                  }}
                >
                  <Text>PROVIDENT FUND</Text>
                </View>
              )} */}
              <View
                style={{ ...styles.tableCell, borderLeft: 0.5, width: '8%' }}
              >
                <Text></Text>
              </View>
            </View>
            <View style={{ ...styles.tableRow, borderBottomWidth: 0.5 }} fixed>
              <View style={{ ...styles.tableCell, width: '2%' }}>
                <Text>NO.</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text>CREW NAME</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text>POSITION</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '4%' }}>
                <Text>SIGN ON</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '4%' }}>
                <Text>SIGN OFF</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  width: '2%',
                  backgroundColor: '#c5c9c7',
                }}
              >
                <Text>NO OF DAYS</Text>
              </View>
              {earningsHeader.map((earning, index) => (
                <View
                  key={index}
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    borderLeft: index == 0 ? 0.5 : 0,
                    width: '3%',
                    fontSize: 6,
                  }}
                >
                  <Text>{earning.wage_other_desc}</Text>
                </View>
              ))}
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  width: '3%',
                  fontSize: 6,
                  borderRight: 0.5,
                  backgroundColor: '#c5c9c7',
                }}
              >
                <Text>TOTAL CURRENT</Text>
              </View>
              {deductionsHeader.map((ded, index) => (
                <View
                  key={index}
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    borderLeft: index == 0 ? 0.5 : 0,
                    width: '3%',
                    fontSize: 6,
                  }}
                >
                  <Text>{ded.wage_other_desc}</Text>
                </View>
              ))}
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  width: '3%',
                  fontSize: 6,
                  borderRight: 0.5,
                  backgroundColor: '#c5c9c7',
                }}
              >
                <Text>TOTAL DEDUCTIONS</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '4%',
                }}
              >
                <Text>NET TOTAL CURRENT MONTH</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '4%',
                }}
              >
                <Text>BAL FROM PREVIOUS MONTH</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '4%',
                }}
              >
                <Text>BALANCE C/F NEXT MONTH</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  borderLeft: 0.5,
                  width: '4%',
                }}
              >
                <Text>PREV MONTH</Text>
              </View>
              {accruedHeader.map(
                (acc, index) =>
                  (withProvFund || acc.salary_code !== 'PROVF') && (
                    <View
                      key={index}
                      style={{
                        ...styles.tableCell,
                        textAlign: 'right',
                        width: '4%',
                        fontSize: 6,
                      }}
                    >
                      <Text>THIS MONTH {`\n ${acc.wage_other_desc}`}</Text>
                    </View>
                  )
              )}
              {/* <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '4%',
                }}
              >
                <Text>THIS MONTH LEAVE PAY</Text>
              </View> */}
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '4%',
                }}
              >
                <Text>TOTAL</Text>
              </View>
              {/* {withProvFund && (
                <>
                  <View
                    style={{
                      ...styles.tableCell,
                      textAlign: 'right',
                      borderLeft: 0.5,
                      width: '4%',
                    }}
                  >
                    <Text>PREV MONTH</Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableCell,
                      textAlign: 'right',
                      width: '4%',
                    }}
                  >
                    <Text>THIS MONTH PROVF</Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableCell,
                      textAlign: 'right',
                      width: '4%',
                    }}
                  >
                    <Text>C/F PROVF</Text>
                  </View>
                </>
              )} */}
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  borderLeft: 0.5,
                  width: '4%',
                }}
              >
                <Text>NET FINAL PAYMENT IN MANILA</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '4%',
                }}
              >
                <Text>TOTAL VOYAGE</Text>
              </View>
            </View>
            {crewList.map((crew, index) => (
              <>
                {crew.is_multiple ? (
                  <>
                    {crew.records.map((record, recordIndex) => (
                      <View
                        key={index}
                        style={{
                          ...styles.tableRow,
                          borderBottom: index + 1 == crewList.length ? 0.5 : 0,
                        }}
                      >
                        <View style={{ ...styles.tableCell, width: '2%' }}>
                          {recordIndex == 0 && <Text>{index + 1}</Text>}
                        </View>
                        <View style={{ ...styles.tableCell, width: '10%' }}>
                          {recordIndex == 0 && <Text>{record.crew_name}</Text>}
                        </View>
                        <View style={{ ...styles.tableCell, width: '10%' }}>
                          {recordIndex == 0 && (
                            <Text>{record.position_name}</Text>
                          )}
                        </View>
                        <View style={{ ...styles.tableCell, width: '4%' }}>
                          {recordIndex == 0 && <Text>{record.sign_on}</Text>}
                        </View>
                        <View style={{ ...styles.tableCell, width: '4%' }}>
                          {recordIndex == 0 && <Text>{record.sign_off}</Text>}
                        </View>
                        <View
                          style={{
                            ...styles.tableCell,
                            textAlign: 'center',
                            width: '2%',
                            backgroundColor: '#c5c9c7',
                          }}
                        >
                          <Text>{record.no_of_days}</Text>
                        </View>
                        {earningsHeader.map((header, ind) => (
                          <View
                            key={`${ind * generateFiveNumber()}`}
                            style={{
                              ...styles.tableCell,
                              textAlign: 'right',
                              borderLeft: ind == 0 ? 0.5 : 0,
                              width: '3%',
                              fontSize: 6,
                            }}
                          >
                            <Text>
                              {getEarningValue(
                                header,
                                record.earnings.slice(0, -1)
                              )}
                            </Text>
                          </View>
                        ))}
                        <View
                          style={{
                            ...styles.tableCell,
                            textAlign: 'right',
                            width: '3%',
                            fontSize: 6,
                            borderRight: 0.5,
                            backgroundColor: '#c5c9c7',
                          }}
                        >
                          <Text>{parseValue(record.total_current)}</Text>
                        </View>
                        {deductionsHeader.map((header, ind) => (
                          <View
                            key={`${ind * generateFiveNumber()}`}
                            style={{
                              ...styles.tableCell,
                              textAlign: 'right',
                              borderLeft: ind == 0 ? 0.5 : 0,
                              width: '3%',
                              fontSize: 6,
                            }}
                          >
                            <Text>
                              {getDeductionValue(
                                header,
                                record.deductions.slice(0, -1)
                              )}
                            </Text>
                          </View>
                        ))}
                        <View
                          style={{
                            ...styles.tableCell,
                            textAlign: 'right',
                            width: '3%',
                            fontSize: 6,
                            borderRight: 0.5,
                            backgroundColor: '#c5c9c7',
                          }}
                        >
                          <Text>{parseValue(record.total_deduction)}</Text>
                        </View>
                        <View
                          style={{
                            ...styles.tableCell,
                            textAlign: 'right',
                            width: '4%',
                          }}
                        >
                          <Text>
                            {parseValue(record.total_net_current_month)}
                          </Text>
                        </View>
                        <View
                          style={{
                            ...styles.tableCell,
                            textAlign: 'right',
                            width: '4%',
                          }}
                        >
                          <Text>{parseValue(record.previous_balance)}</Text>
                        </View>
                        <View
                          style={{
                            ...styles.tableCell,
                            textAlign: 'right',
                            width: '4%',
                          }}
                        >
                          <Text>{parseValue(record.cf_next_month)}</Text>
                        </View>
                        <View
                          style={{
                            ...styles.tableCell,
                            textAlign: 'right',
                            borderLeft: 0.5,
                            width: '4%',
                          }}
                        >
                          <Text>
                            {parseValue(
                              withProvFund
                                ? record.total_leave_previous_with_prov
                                : record.total_leave_previous
                            )}
                          </Text>
                        </View>
                        {accruedHeader
                          .filter(
                            (acc) => withProvFund || acc.salary_code !== 'PROVF'
                          )
                          .map((acc, index) => (
                            <View
                              key={`${index * generateFiveNumber()}`}
                              style={{
                                ...styles.tableCell,
                                textAlign: 'right',
                                // borderLeft: ind == 0 ? 0.5 : 0,
                                width: '4%',
                                fontSize: 6,
                              }}
                            >
                              <Text>
                                {getAccruedValue(acc, record.accrued)}
                              </Text>
                            </View>
                          ))}
                        <View
                          style={{
                            ...styles.tableCell,
                            textAlign: 'right',
                            width: '4%',
                          }}
                        >
                          <Text>
                            {parseValue(
                              withProvFund
                                ? record.total_leave_current_with_prov
                                : record.total_leave_current
                            )}
                          </Text>
                        </View>
                        {/* {withProvFund && (
                          <>
                            <View
                              style={{
                                ...styles.tableCell,
                                textAlign: 'right',
                                borderLeft: 0.5,
                                width: '4%',
                              }}
                            >
                              <Text>{parseValue(record.previous_prov)}</Text>
                            </View>
                            <View
                              style={{
                                ...styles.tableCell,
                                textAlign: 'right',
                                width: '4%',
                              }}
                            >
                              <Text>{parseValue(record.prov_fund)}</Text>
                            </View>
                            <View
                              style={{
                                ...styles.tableCell,
                                textAlign: 'right',
                                width: '4%',
                              }}
                            >
                              <Text>{parseValue(record.cf_prov)}</Text>
                            </View>
                          </>
                        )} */}
                        <View
                          style={{
                            ...styles.tableCell,
                            textAlign: 'right',
                            borderLeft: 0.5,
                            width: '4%',
                          }}
                        >
                          <Text>{parseValue(record.net_final_manila)}</Text>
                        </View>
                        <View
                          style={{
                            ...styles.tableCell,
                            textAlign: 'right',
                            width: '4%',
                          }}
                        >
                          <Text>{parseValue(record.total_voyage)}</Text>
                        </View>
                      </View>
                    ))}
                  </>
                ) : (
                  <View
                    key={index}
                    style={{
                      ...styles.tableRow,
                      borderBottom: index + 1 == crewList.length ? 0.5 : 0,
                    }}
                  >
                    <View style={{ ...styles.tableCell, width: '2%' }}>
                      <Text>{index + 1}</Text>
                    </View>
                    <View style={{ ...styles.tableCell, width: '10%' }}>
                      <Text>{crew.records[0].crew_name}</Text>
                    </View>
                    <View style={{ ...styles.tableCell, width: '10%' }}>
                      <Text>{crew.records[0].position_name}</Text>
                    </View>
                    <View style={{ ...styles.tableCell, width: '4%' }}>
                      <Text>{crew.records[0].sign_on}</Text>
                    </View>
                    <View style={{ ...styles.tableCell, width: '4%' }}>
                      <Text>{crew.records[0].sign_off}</Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableCell,
                        textAlign: 'center',
                        width: '2%',
                        backgroundColor: '#c5c9c7',
                      }}
                    >
                      <Text>{crew.records[0].no_of_days}</Text>
                    </View>
                    {earningsHeader.map((header, ind) => (
                      <View
                        key={`${ind * generateFiveNumber()}`}
                        style={{
                          ...styles.tableCell,
                          textAlign: 'right',
                          borderLeft: ind == 0 ? 0.5 : 0,
                          width: '3%',
                          fontSize: 6,
                        }}
                      >
                        <Text>
                          {getEarningValue(
                            header,
                            crew.records[0].earnings.slice(0, -1)
                          )}
                        </Text>
                      </View>
                    ))}
                    <View
                      style={{
                        ...styles.tableCell,
                        textAlign: 'right',
                        width: '3%',
                        fontSize: 6,
                        borderRight: 0.5,
                        backgroundColor: '#c5c9c7',
                      }}
                    >
                      <Text>{parseValue(crew.records[0].total_current)}</Text>
                    </View>
                    {deductionsHeader.map((header, ind) => (
                      <View
                        key={`${ind * generateFiveNumber()}`}
                        style={{
                          ...styles.tableCell,
                          textAlign: 'right',
                          borderLeft: ind == 0 ? 0.5 : 0,
                          width: '3%',
                          fontSize: 6,
                        }}
                      >
                        <Text>
                          {getDeductionValue(
                            header,
                            crew.records[0].deductions.slice(0, -1)
                          )}
                        </Text>
                      </View>
                    ))}
                    <View
                      style={{
                        ...styles.tableCell,
                        textAlign: 'right',
                        width: '3%',
                        fontSize: 6,
                        borderRight: 0.5,
                        backgroundColor: '#c5c9c7',
                      }}
                    >
                      <Text>{parseValue(crew.records[0].total_deduction)}</Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableCell,
                        textAlign: 'right',
                        width: '4%',
                      }}
                    >
                      <Text>
                        {parseValue(crew.records[0].total_net_current_month)}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableCell,
                        textAlign: 'right',
                        width: '4%',
                      }}
                    >
                      <Text>
                        {parseValue(crew.records[0].previous_balance)}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableCell,
                        textAlign: 'right',
                        width: '4%',
                      }}
                    >
                      <Text>{parseValue(crew.records[0].cf_next_month)}</Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableCell,
                        textAlign: 'right',
                        borderLeft: 0.5,
                        width: '4%',
                      }}
                    >
                      <Text>
                        {parseValue(
                          withProvFund
                            ? crew.records[0].total_leave_previous_with_prov
                            : crew.records[0].total_leave_previous
                        )}
                      </Text>
                    </View>
                    {accruedHeader
                      .filter(
                        (acc) => withProvFund || acc.salary_code !== 'PROVF'
                      )
                      .map((acc, index) => (
                        <View
                          key={`${index * generateFiveNumber()}`}
                          style={{
                            ...styles.tableCell,
                            textAlign: 'right',
                            // borderLeft: ind == 0 ? 0.5 : 0,
                            width: '4%',
                            fontSize: 6,
                          }}
                        >
                          <Text>
                            {getAccruedValue(acc, crew.records[0].accrued)}
                          </Text>
                        </View>
                      ))}
                    <View
                      style={{
                        ...styles.tableCell,
                        textAlign: 'right',
                        width: '4%',
                      }}
                    >
                      <Text>
                        {parseValue(
                          withProvFund
                            ? crew.records[0].total_leave_current_with_prov
                            : crew.records[0].total_leave_current
                        )}
                      </Text>
                    </View>
                    {/* {withProvFund && (
                      <>
                        <View
                          style={{
                            ...styles.tableCell,
                            textAlign: 'right',
                            borderLeft: 0.5,
                            width: '4%',
                          }}
                        >
                          <Text>
                            {parseValue(crew.records[0].previous_prov)}
                          </Text>
                        </View>
                        <View
                          style={{
                            ...styles.tableCell,
                            textAlign: 'right',
                            width: '4%',
                          }}
                        >
                          <Text>{parseValue(crew.records[0].prov_fund)}</Text>
                        </View>
                        <View
                          style={{
                            ...styles.tableCell,
                            textAlign: 'right',
                            width: '4%',
                          }}
                        >
                          <Text>{parseValue(crew.records[0].cf_prov)}</Text>
                        </View>
                      </>
                    )} */}
                    <View
                      style={{
                        ...styles.tableCell,
                        textAlign: 'right',
                        borderLeft: 0.5,
                        width: '4%',
                      }}
                    >
                      <Text>
                        {parseValue(crew.records[0].net_final_manila)}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableCell,
                        textAlign: 'right',
                        width: '4%',
                      }}
                    >
                      <Text>{parseValue(crew.records[0].total_voyage)}</Text>
                    </View>
                  </View>
                )}
              </>
            ))}
            <View style={{ ...styles.tableRow }}>
              <View style={{ ...styles.tableCell, width: '2%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '4%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '4%' }}>
                <Text></Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  width: '2%',
                }}
              >
                <Text></Text>
              </View>
              {earningsHeader.map((header, ind) => (
                <View
                  key={`${ind * generateFiveNumber()}`}
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '3%',
                    borderBottom: 1,
                    fontSize: 6,
                  }}
                >
                  <Text>{getEarningGrandTotal(header)}</Text>
                </View>
              ))}
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  width: '3%',
                  textAlign: 'right',
                }}
              >
                <Text>
                  {parseValue(
                    grandTotals.total_net_and_dedduction.grand_current_month
                  )}
                </Text>
              </View>
              {deductionsHeader.map((header, ind) => (
                <View
                  key={`${ind * generateFiveNumber()}`}
                  style={{
                    ...styles.tableCell,
                    textAlign: 'right',
                    width: '3%',
                    borderBottom: 1,
                    fontSize: 6,
                  }}
                >
                  <Text>{getDeductionGrandTotal(header)}</Text>
                </View>
              ))}
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  width: '3%',
                  textAlign: 'right',
                }}
              >
                <Text>
                  {parseValue(
                    grandTotals.total_net_and_dedduction.grand_total_deductions
                  )}
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  width: '4%',
                  textAlign: 'right',
                }}
              >
                <Text>
                  {parseValue(
                    grandTotals.total_net_and_dedduction
                      .grand_total_net_current_month
                  )}
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  width: '4%',
                  textAlign: 'right',
                }}
              >
                <Text>
                  {parseValue(
                    grandTotals.total_net_and_dedduction
                      .grand_total_previous_balance
                  )}
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  width: '4%',
                  textAlign: 'right',
                }}
              >
                <Text>
                  {parseValue(
                    grandTotals.total_net_and_dedduction
                      .grand_total_balance_carry_forward
                  )}
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '4%',
                  borderBottom: 1,
                }}
              >
                <Text>
                  {parseValue(
                    withProvFund
                      ? grandTotals.total_net_and_dedduction
                          .grand_total_accrued_previous_with_provf
                      : grandTotals.total_net_and_dedduction
                          .grand_total_accrued_previous
                  )}
                </Text>
              </View>
              {accruedHeader
                .filter((acc) => withProvFund || acc.salary_code !== 'PROVF')
                .map((header, index) => (
                  <View
                    key={`${index * generateFiveNumber()}`}
                    style={{
                      ...styles.tableCell,
                      textAlign: 'right',
                      borderBottom: 1,
                      width: '4%',
                      fontSize: 6,
                    }}
                  >
                    <Text>{getAccruedGrandTotal(header)}</Text>
                  </View>
                ))}
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '4%',
                  borderBottom: 1,
                }}
              >
                <Text>
                  {parseValue(
                    withProvFund
                      ? grandTotals.total_net_and_dedduction
                          .grand_total_accrued_current_with_provf
                      : grandTotals.total_net_and_dedduction
                          .grand_total_accrued_current
                  )}
                </Text>
              </View>
              {/* <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'center',
                  width: '12%',
                  borderBottom: 1,
                }}
              >
              </View>
              {withProvFund && (
                <View
                  style={{
                    ...styles.tableCell,
                    textAlign: 'center',
                    width: '12%',
                    borderBottom: 1,
                  }}
                >
                </View>
              )} */}
              <View
                style={{ ...styles.tableCell, borderBottom: 1, width: '8%' }}
              >
                <Text></Text>
              </View>
            </View>
          </View>
          <View style={styles.footer} wrap={false}>
            <View style={{ ...styles.bottomContainer, paddingLeft: 100 }}>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Prepared By:{`\n\n_____________________\n`} {`        `}
                  Administrator
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Checked By:{`\n\n_____________________\n`} {`           `}
                  Accountant
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Approved By:{`\n\n_____________________\n`} {`             `}
                  President
                </Text>
              </View>
            </View>
          </View>
          <Text
            style={{ ...styles.pageNumber, textAlign: 'right' }}
            render={({ pageNumber, totalPages }) =>
              `Page: ${pageNumber} / ${totalPages}`
            }
            fixed
          />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `Date printed: [${dayjs().format('YYYY-MM-DD HH:mm:ss')}]`
            }
            fixed
          />
        </Page>
      </Document>
    </PDFViewer>
  );
}
