import { Button, Input, Popconfirm, Space, Switch } from 'antd';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';

export function getVesselExchangeRateTable(handleAction) {
  return [
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      sorter: (a, b) => a.group.localeCompare(b.group),
      fixed: 'left',
    },
    {
      title: 'Vessel Code',
      dataIndex: 'vessel_code',
      key: 'vessel_code',
      sorter: (a, b) => a.vessel_code.localeCompare(b.vessel_code),
    },
    {
      title: 'Vessel Name',
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      sorter: (a, b) => a.vessel_name.localeCompare(b.vessel_name),
    },
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
      sorter: (a, b) => a.month - b.month,
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      sorter: (a, b) => a.year - b.year,
    },
    {
      title: 'Exchange Rate',
      dataIndex: 'exchange_rate',
      key: 'exchange_rate',
      sorter: (a, b) => a.exchange_rate - b.exchange_rate,
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => handleAction(record)}
            icon={<FaWrench size={15} />}
          />
          <Popconfirm
            title="Delete record"
            description="Are you sure to delete this record?"
            onConfirm={() => handleAction(record, 'delete')}
            okText="Yes"
            cancelText="No"
          >
            <Button danger icon={<AiFillDelete size={15} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
}
