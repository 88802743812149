import axios from 'axios';
import {
  GET_ALL_DEDUCTIONS_ENDPOINT,
  GET_ALL_OTHER_DEDUCTIONS_ENDPOINT,
  GET_FIXED_PAGIBIG_PER_CREW_ENDPOINT,
  GET_SHOW_ALL_OTHER_DEDUCTIONS_PER_CREW_ENDPOINT,
  GET_SHOW_ALL_TRANSACTION_DEDUCTIONS_ENDPOINT,
  GET_SHOW_SCHEDULE_OF_DEDUCTIONS_PER_CREW_ENDPOINT,
  POST_ADD_ALLOTTEE_HEADER_OTHER_DEDUCTIONS_ENDPOINT,
  POST_ADD_ALLOTTEE_PER_ALLOTTEE_OTHER_DEDUCTIONS_ENDPOINT,
  POST_ADD_DEDUCTIONS_ENDPOINT,
  POST_ADD_FIXED_PAGIBIG_PER_CREW_ENDPOINT,
  POST_ADD_OTHER_DEDUCTIONS_ENDPOINT,
  POST_ADD_SCHEDULE_OF_DEDUCTION_ENDPOINT,
  POST_DELETE_ALLOTTEE_HEADER_OTHER_DEDUCTIONS_ENDPOINT,
  POST_DELETE_DEDUCTIONS_ENDPOINT,
  POST_DELETE_OTHER_DEDUCTIONS_ENDPOINT,
  POST_MODIFY_ALLOTTEE_HEADER_OTHER_DEDUCTIONS_ENDPOINT,
  POST_MODIFY_ALLOTTEE_PER_ALLOTTEE_OTHER_DEDUCTIONS_ENDPOINT,
  POST_MODIFY_DEDUCTIONS_ENDPOINT,
  POST_MODIFY_OTHER_DEDUCTIONS_ENDPOINT,
  POST_UPDATE_FIXED_PAGIBIG_PER_CREW_ENDPOINT,
  REQUEST_HEADERS,
} from './_constants';

export async function getAllDeductions() {
  try {
    const { data } = await axios.get(GET_ALL_DEDUCTIONS_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddDeduction(payload) {
  try {
    const { data } = await axios.post(POST_ADD_DEDUCTIONS_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postModifyDeduction(payload) {
  try {
    const { data } = await axios.post(
      `${POST_MODIFY_DEDUCTIONS_ENDPOINT}/${payload.id}`,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeleteDeduction(payload) {
  try {
    const { data } = await axios.post(
      `${POST_DELETE_DEDUCTIONS_ENDPOINT}/${payload.id}`,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getAllOtherDeductions() {
  try {
    const { data } = await axios.get(GET_ALL_OTHER_DEDUCTIONS_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddOtherDeduction(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_OTHER_DEDUCTIONS_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postModifyOtherDeduction(payload) {
  try {
    const { data } = await axios.post(
      `${POST_MODIFY_OTHER_DEDUCTIONS_ENDPOINT}/${payload.id}`,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeleteOtherDeduction(payload) {
  try {
    const { data } = await axios.post(
      `${POST_DELETE_OTHER_DEDUCTIONS_ENDPOINT}/${payload.id}`,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getOtherDeductionsPerCrew(crewNo) {
  try {
    const { data } = await axios.get(
      `${GET_SHOW_ALL_OTHER_DEDUCTIONS_PER_CREW_ENDPOINT}/${crewNo}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddOtherDeductionsAllottee(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_ALLOTTEE_HEADER_OTHER_DEDUCTIONS_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeleteOtherDeductionsAllottee(payload) {
  try {
    const { data } = await axios.post(
      `${POST_DELETE_ALLOTTEE_HEADER_OTHER_DEDUCTIONS_ENDPOINT}/${payload.id}`,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postModifyOtherDeductionsAllottee(payload) {
  try {
    const { data } = await axios.post(
      `${POST_MODIFY_ALLOTTEE_HEADER_OTHER_DEDUCTIONS_ENDPOINT}/${payload.id}`,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddOtherDeductionsAllotteePerAllottee(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_ALLOTTEE_PER_ALLOTTEE_OTHER_DEDUCTIONS_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postModifyOtherDeductionsAllotteePerAllottee(payload) {
  try {
    const { data } = await axios.post(
      `${POST_MODIFY_ALLOTTEE_PER_ALLOTTEE_OTHER_DEDUCTIONS_ENDPOINT}/${payload.allotte_othd_id}`,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getFixedPagibigPerCrew(crewNo) {
  try {
    const { data } = await axios.get(
      `${GET_FIXED_PAGIBIG_PER_CREW_ENDPOINT}/${crewNo}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddFixedPagibig(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_FIXED_PAGIBIG_PER_CREW_ENDPOINT,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postUpdateFixedPagibig(payload) {
  try {
    const { data } = await axios.post(
      `${POST_UPDATE_FIXED_PAGIBIG_PER_CREW_ENDPOINT}/${payload.id}`,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getPayrollDeductions() {
  try {
    const { data } = await axios.get(
      GET_SHOW_ALL_TRANSACTION_DEDUCTIONS_ENDPOINT
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getScheduleOfDeduction(crewNo) {
  try {
    const { data } = await axios.get(
      `${GET_SHOW_SCHEDULE_OF_DEDUCTIONS_PER_CREW_ENDPOINT}/${crewNo}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddScheduleOfDeduction(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_SCHEDULE_OF_DEDUCTION_ENDPOINT,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
