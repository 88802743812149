import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Input,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../../../utils/context/UserContext';
import dayjs from 'dayjs';
import { getHomeAllotmentTable } from '../../../../utils/tableheader/Transactions/HomeAllotmentComputeTable';
import {
  getAllPostedVessel,
  getProcessHomeAllotmentPerMonth,
  getProcessedDeductionLegends,
  getProcessedHomeAllotmentPerMonth,
  getProcessedHomeAllotmentPerVessel,
  postDeleteProcessHomeAllotment,
  postProcessHomeAllotment,
} from '../../../../utils/api/TransactionsAPI';
import { getVessels } from '../../../../utils/api/VesselAPI';
import { GlobalContext } from '../../../../App';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  WARNING_NOTIFICATION_TYPE,
} from '../../../../utils/api/_constants';
import VesselType from '../../../../utils/types/VesselType';
import Search from 'antd/es/input/Search';
import { IoIosRefresh } from 'react-icons/io';
import ConfirmationModal from '../../../../components/CustomModal/ConfirmationModal';
import CustomModal from '../../../../components/CustomModal/CustomModal';
import HomeAllotmentComputePDF from '../../../../components/PDFReports/HomeAllotmentComputePDF';
import HomeAllotmentHeaderType from '../../../../utils/types/HomeAllotmentHeaderType';

export default function HomeAllotmentPage() {
  const {
    getCurrentDate,
    doSearchInArray,
    profile,
    parseToFloat,
    inputToLocale,
  } = useContext(UserContext);
  const { openNotification } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);

  const [exchangeRate, setExchangeRate] = useState(0.0);
  const [month, setMonth] = useState(new Date(getCurrentDate()).getMonth() + 1);
  const [year, setYear] = useState(new Date(getCurrentDate()).getFullYear());

  const [vesselList, setVesselList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const [selectedToCompute, setSelectedToCompute] = useState([]);
  const [selectedToDelete, setSelectedToDelete] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const [failedRequests, setFailedRequests] = useState([]);

  const [homeAllotmentReportList, setHomeAllotmentReportList] = useState([]);
  const [homeAllotmentDetails, setHomeAllotmentDetails] = useState(
    HomeAllotmentHeaderType
  );
  const [grandTotals, setGrandTotals] = useState({});
  const [allotmentLegends, setAllotmentLegends] = useState('');

  const [postedVessels, setPostedVessels] = useState([]);

  const getRowClassName = (record, index) => {
    const exists =
      postedVessels.find((item) => item.vescode == record.vessel_code)
        ?.home_allotment == 1;
    return exists ? 'bg-warning' : '';
  };

  const handleAction = async (record) => {
    setIsLoading(true);
    const { data: res, error } = await getProcessHomeAllotmentPerMonth(
      month,
      year,
      record.vessel_code
    );
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Loading Home Allotment',
        'Loading failed.'
      );
    } else {
      if (res.length > 0) {
        const { data: processDetails, errorDetails } =
          await getProcessedHomeAllotmentPerMonth(
            month,
            year,
            record.vessel_code
          );
        if (errorDetails) {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            'Loading Home Allotment',
            'Loading failed.'
          );
        } else {
          if (processDetails.length > 0) {
            const details = processDetails.find(
              (item) => item.vessel_code === record.vessel_code
            );
            if (details) {
              const { data: legendsDetails, legendsError } =
                await getProcessedDeductionLegends(
                  month,
                  year,
                  record.vessel_code
                );
              if (legendsError) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'Loading Home Allotment',
                  'Loading failed.'
                );
              } else {
                setAllotmentLegends(
                  legendsDetails
                    .map((item) => `${item.other_deduction_id}-${item.desc}`)
                    .join(', ')
                );
                const totals = res.pop();
                setHomeAllotmentDetails(details);
                setHomeAllotmentReportList(res);
                setGrandTotals(totals);
                setIsPrintModalOpen(true);
              }
            } else {
              openNotification(
                WARNING_NOTIFICATION_TYPE,
                'Loading Home Allotment',
                'No record found.'
              );
            }
          } else {
            openNotification(
              WARNING_NOTIFICATION_TYPE,
              'Loading Home Allotment',
              'No record found.'
            );
          }
        }
      } else {
        openNotification(
          WARNING_NOTIFICATION_TYPE,
          'Loading Home Allotment',
          'No record found.'
        );
      }
    }
    setIsLoading(false);
  };

  const handleProcess = async () => {
    setIsLoading(true);
    setFailedRequests([]);
    const failedArray = [];
    const successfulProcess = [];
    try {
      await Promise.all(
        selectedToCompute.map(async (record) => {
          const payload = {
            vescode: record.vessel_code,
            month: month,
            year: year,
            exrate: record.exrate,
          };
          if (payload.exrate == 0) {
            openNotification(
              ERROR_NOTIFICATION_TYPE,
              'Home Allotment Process',
              `Please input ex-rate for ${record.vessel_name}.`,
              0
            );
          } else {
            const { data: res, error } = await postProcessHomeAllotment(
              payload
            );
            if (error) {
              if (error?.response?.status == 422) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'Home Allotment Process',
                  `${error?.response?.data?.message} for ${record.vessel_name}.`
                );
              } else {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'Home Allotment Process',
                  `Processing ${record.vessel_name} failed.`
                );
              }
              const existingRecord = failedArray.find(
                (item) => item.vessel_code === record.vessel_code
              );
              if (!existingRecord) {
                failedArray.push(record);
              }
            } else {
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                'Home Allotment Process',
                `Processing ${record.vessel_name} complete.`
              );
              successfulProcess.push(record);
              fetchDatas(month, year);
              setIsConfirmationModalOpen(false);
            }
          }
        })
      );
    } catch (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Home Allotment Process',
        'Processing failed'
      );
    }
    setSelectedToCompute([]);
    setFailedRequests(failedArray);
    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await Promise.all(
        selectedToDelete.map(async (record) => {
          const payload = {
            vescode: record.vessel_code,
            month: month,
            year: year,
          };
          const { data: res, error } = await postDeleteProcessHomeAllotment(
            payload
          );
          if (error) {
            openNotification(
              ERROR_NOTIFICATION_TYPE,
              'Home Allotment Process',
              `Deleting ${record.vessel_name} failed.`
            );
          } else {
            openNotification(
              SUCCESS_NOTIFICATION_TYPE,
              'Home Allotment Process',
              `Deleting ${record.vessel_name} complete.`
            );
            fetchDatas(month, year);
            setIsDeleteModalOpen(false);
          }
        })
      );
    } catch (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Home Allotment Process',
        'Processing failed'
      );
    }
    setIsLoading(false);
  };

  const fetchDatas = async (mMonth, mYear) => {
    setIsLoading(true);
    await Promise.all([
      getProcessedHomeAllotmentPerMonth(mMonth, mYear),
      getVessels(profile.user_id),
      getAllPostedVessel(mMonth, mYear),
    ])
      .then((response) => {
        const vesselTableData = response[1].data.map(
          (record = VesselType, index) => {
            const processedRecord = response[0].data.find(
              (processedRecord) =>
                processedRecord.vessel_code === record.vessel_code
            );
            if (processedRecord) {
              return {
                key: index,
                vessel_id: record.id,
                process_id: processedRecord.id,
                vessel_code: record.vessel_code,
                vessel_name: record.vessel_name,
                principal_name: record.principal_name,
                exrate: processedRecord.exrate,
                union_dues: processedRecord.type_of_ud,
                updated_at: dayjs(processedRecord.updated_at).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
              };
            } else {
              return {
                key: index,
                vessel_id: record.id,
                process_id: 0,
                vessel_code: record.vessel_code,
                vessel_name: record.vessel_name,
                principal_name: record.principal_name,
                exrate: 0,
                union_dues: '',
                updated_at: '',
              };
            }
          }
        );
        setPostedVessels(response[2].data);
        setVesselList(vesselTableData);
        setFilteredList(vesselTableData);
        doSearchInArray(searchQuery, setFilteredList, vesselTableData);
      })
      .catch((error) => {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Home Allotment Process',
          'Error loading data.'
        );
      });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDatas(month, year);
  }, []);

  return (
    <Content>
      <Space className='me-2'>
        <Typography.Text strong>Month/Year:</Typography.Text>
        <DatePicker
          picker='month'
          defaultValue={dayjs(`${month}/${year}`, 'M/YYYY')}
          onChange={(_, dateString) => {
            const [dateMonth, dateYear] = dateString.split('/');
            setMonth(dateMonth);
            setYear(dateYear);
            fetchDatas(dateMonth, dateYear);
            setSelectedToCompute([]);
            setSelectedToDelete([]);
          }}
          format='M/YYYY'
          disabled={isLoading}
          allowClear={false}
        />
      </Space>
      <Space>
        <Input
          type='number'
          placeholder={'Exchange Rate'}
          disabled={isLoading}
          value={exchangeRate}
          onChange={(e) => {
            const value = e.target.value;
            setExchangeRate(value);
          }}
          allowClear={true}
        />
        <Button
          type='primary'
          disabled={isLoading || selectedToCompute.length <= 0}
          onClick={() => setIsConfirmationModalOpen(true)}
        >
          Process
        </Button>
        <Button
          danger
          disabled={isLoading || selectedToDelete.length <= 0}
          onClick={() => setIsDeleteModalOpen(true)}
        >
          Delete
        </Button>
      </Space>
      <Row className='mb-4' justify={'end'}>
        <Col xs={24} sm={8}>
          <Space.Compact block>
            <Search
              disabled={isLoading}
              placeholder='Search vessel!'
              onChange={(e) => {
                setSearchQuery(e.target.value);
                doSearchInArray(e.target.value, setFilteredList, vesselList);
              }}
              onSearch={(value) => {
                setSearchQuery(value);
                doSearchInArray(value, setFilteredList, vesselList);
              }}
              allowClear={true}
            />
            <Button
              type='primary'
              icon={<IoIosRefresh />}
              loading={isLoading}
              onClick={() => fetchDatas(month, year)}
            />
          </Space.Compact>
        </Col>
      </Row>
      <Table
        columns={getHomeAllotmentTable(
          handleAction,
          selectedToCompute,
          setSelectedToCompute,
          selectedToDelete,
          setSelectedToDelete,
          vesselList,
          setVesselList,
          setFilteredList,
          openNotification,
          postedVessels,
          'home_allotment'
        )}
        dataSource={filteredList}
        size='small'
        bordered='true'
        scroll={{ x: true }}
        loading={isLoading}
        rowClassName={getRowClassName}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          handleProcess();
        }}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        onConfirm={() => {
          handleDelete();
        }}
      />
      <CustomModal
        isLoading={isLoading}
        isOpen={isPrintModalOpen}
        setIsOpen={setIsPrintModalOpen}
        cancelText='Close'
        props={{ okButtonProps: { hidden: true }, width: '100vw' }}
        isClosable={true}
      >
        <HomeAllotmentComputePDF
          homeAllotmentHeader={homeAllotmentDetails}
          homeAllotmentList={homeAllotmentReportList}
          legends={allotmentLegends}
          grandTotals={grandTotals}
        />
      </CustomModal>
    </Content>
  );
}
