import dayjs from 'dayjs';

export const getUkWagesDetailsFields = (
  isLoading,
  state,
  setState,
  setHasChanges,
  template
) => {
  const isUkWagesHasValues = () => {
    if (
      parseFloat(state.uk_hrs) != 0 ||
      parseFloat(state.uk_gbp_hrs) != 0 ||
      parseFloat(state.uk_usd_hrs) != 0 ||
      parseFloat(state.uk_days) != 0 ||
      parseFloat(state.uk_gbp_days) != 0 ||
      parseFloat(state.uk_usd_days) != 0 ||
      parseFloat(state.uk_excess_ot) != 0 ||
      parseFloat(state.uk_excess_ot_rate) != 0 ||
      parseFloat(state.uk_adjustment) != 0 ||
      parseFloat(state.uk_divisor) != 0 ||
      parseFloat(state.prorate_exclu_fot) != 0 ||
      parseFloat(state.prorate_fot) != 0
      // parseFloat(state.excess_ot2) != 0 ||
      // parseFloat(state.initial_leave) != 0
    ) {
      return true;
    }
    return false;
  };

  const isUkWagesRequired = () => {
    if (
      state.uk_hrs != null ||
      state.uk_gbp_hrs != null ||
      state.uk_usd_hrs != null ||
      state.uk_days != null ||
      state.uk_gbp_days != null ||
      state.uk_usd_days != null ||
      state.uk_excess_ot != null ||
      state.uk_excess_ot_rate != null ||
      state.uk_adjustment != null ||
      state.uk_divisor != null ||
      state.prorate_exclu_fot != null ||
      state.prorate_fot != null
      // state.excess_ot2 != null ||
      // state.initial_leave != null
    ) {
      return true;
    }
    return false;
  };

  return [
    {
      type: 'number',
      name: 'uk_hrs',
      placeholder: 'Hours',
      label: 'Hours',
      autofocus: false,
      required: isUkWagesRequired() && isUkWagesHasValues(),
      disabled: isLoading || template?.cw_format != 1,
      value: state.uk_hrs,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, uk_hrs: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'uk_gbp_hrs',
      placeholder: 'GBP/Hr',
      label: 'GBP/Hr',
      autofocus: false,
      required: isUkWagesRequired() && isUkWagesHasValues(),
      disabled: isLoading || template?.cw_format != 1,
      value: state.uk_gbp_hrs,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, uk_gbp_hrs: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'uk_usd_hrs',
      placeholder: 'GBP/USD Rate1',
      label: 'GBP/USD Rate1',
      autofocus: false,
      required: isUkWagesRequired() && isUkWagesHasValues(),
      disabled: isLoading || template?.cw_format != 1,
      value: state.uk_usd_hrs,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, uk_usd_hrs: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'uk_days',
      placeholder: 'Days',
      label: 'Days',
      autofocus: false,
      required: isUkWagesRequired() && isUkWagesHasValues(),
      disabled: isLoading || template?.cw_format != 1,
      value: state.uk_days,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, uk_days: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'uk_gbp_days',
      placeholder: 'GBP/Day',
      label: 'GBP/Day',
      autofocus: false,
      required: isUkWagesRequired() && isUkWagesHasValues(),
      disabled: isLoading || template?.cw_format != 1,
      value: state.uk_gbp_days,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, uk_gbp_days: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'uk_usd_days',
      placeholder: 'GBP/USD Rate2',
      label: 'GBP/USD Rate2',
      autofocus: false,
      required: isUkWagesRequired() && isUkWagesHasValues(),
      disabled: isLoading || template?.cw_format != 1,
      value: state.uk_usd_days,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, uk_usd_days: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'uk_excess_ot',
      placeholder: 'Excess OT Hours',
      label: 'Excess OT Hours',
      autofocus: false,
      required: isUkWagesRequired() && isUkWagesHasValues(),
      disabled: isLoading || template?.cw_format != 1,
      value: state.uk_excess_ot,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, uk_excess_ot: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'uk_excess_ot_rate',
      placeholder: 'Excess OT Rate',
      label: 'Excess OT Rate',
      autofocus: false,
      required: isUkWagesRequired() && isUkWagesHasValues(),
      disabled: true,
      value: state.uk_excess_ot_rate,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, uk_excess_ot_rate: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number-negative',
      name: 'uk_adjustment',
      placeholder: 'Adjustment',
      label: 'Adjustment',
      autofocus: false,
      required: isUkWagesRequired() && isUkWagesHasValues(),
      disabled: isLoading || template?.cw_format != 1,
      value: state.uk_adjustment,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, uk_adjustment: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'uk_divisor',
      placeholder: 'Divisor',
      label: 'Divisor',
      autofocus: false,
      required: isUkWagesRequired() && isUkWagesHasValues(),
      disabled: isLoading || template?.cw_format != 1,
      value: state.uk_divisor,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, uk_divisor: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'prorate_exclu_fot',
      placeholder: 'Pro-rate exclude FOT',
      label: 'Pro-rate exclude FOT',
      autofocus: false,
      required: isUkWagesRequired() && isUkWagesHasValues(),
      disabled: isLoading || template?.cw_format != 1,
      value: state.prorate_exclu_fot,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, prorate_exclu_fot: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'prorate_fot',
      placeholder: 'Pro-rate FOT',
      label: 'Pro-rate FOT',
      autofocus: false,
      required: isUkWagesRequired() && isUkWagesHasValues(),
      disabled: isLoading || template?.cw_format != 1,
      value: state.prorate_fot,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, prorate_fot: value });
        setHasChanges(true);
      },
    },
    // {
    //   type: 'number',
    //   name: 'excess_ot2',
    //   placeholder: 'Excess OT 2',
    //   label: 'Excess OT 2',
    //   autofocus: false,
    //   required: isUkWagesRequired() && isUkWagesHasValues(),
    //   disabled: isLoading || template != '1',
    //   value: state.excess_ot2,
    //   setValue: (e) => {
    //     const value = e.target.value;
    //     setState({ ...state, excess_ot2: value });
    //     setHasChanges(true);
    //   },
    // },
    // {
    //   type: 'number',
    //   name: 'initial_leave',
    //   placeholder: 'Initial Leave',
    //   label: 'Initial Leave',
    //   autofocus: false,
    //   required: isUkWagesRequired() && isUkWagesHasValues(),
    //   disabled: isLoading || template != '1',
    //   value: state.initial_leave,
    //   setValue: (e) => {
    //     const value = e.target.value;
    //     setState({ ...state, initial_leave: value });
    //     setHasChanges(true);
    //   },
    // },
  ];
};
