export default {
  crew_no: null,
  crew_name: null,
  vescode: null,
  vesname: null,
  position: null,
  allottee: null,
  account_no: null,
  bank_code: null,
  bank_branch: null,
  bank_desc: null,
  amount_usd: null,
  month: null,
  year: null,
  deduction_list: null,
};
