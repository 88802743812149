import React, { useContext, useEffect, useState } from 'react';
import './LoginPage.css';
import { GlobalContext } from '../../App';
import { Button, Checkbox, Col, Form, Input, Row, message } from 'antd';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { login } from '../../utils/api/AuthAPI';
import { HOME_ROUTE } from '../../utils/router/routes';
import { UserContext } from '../../utils/context/UserContext';
import { ERROR_NOTIFICATION_TYPE } from '../../utils/api/_constants';

export default function LoginPage() {
  const { setIsLoggedIn, encryptValue, decryptValue, openNotification } =
    useContext(GlobalContext);

  const { setProfile, setAccessLevelDefinition } = useContext(UserContext);

  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const [isLoading, setIsLoading] = useState(false);
  const [shouldRemember, setShouldRemember] = useState(false);

  // Define your regex pattern
  const regexPattern = /<\s*script[^>]*>.*?<\s*\/\s*script\s*>/gi;

  // Custom validation function
  const validateInput = (rule, value, callback) => {
    if (regexPattern.test(value)) {
      callback('Invalid input. Please use only letters, numbers, and spaces.'); // Validation fails
    } else {
      callback(); // Validation passes
    }
  };

  const cookiesSettings = {
    expires: new Date().getTime() + 2 * 60 * 60 * 1000,
    secure: true,
    sameSite: 'strict',
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    const res = await login(values.username, values.password);
    if (res.isSuccessful) {
      if (shouldRemember) {
        Cookies.set('username', encryptValue(values.username), cookiesSettings);
        Cookies.set('password', encryptValue(values.password), cookiesSettings);
        Cookies.set('is-logged-in', true, cookiesSettings);
      }

      setProfile(res.user);
      setAccessLevelDefinition(res.access_level);
      navigate(HOME_ROUTE);
      setIsLoggedIn(true);
    } else {
      switch (res.statusCode) {
        case 401:
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            'Sign In',
            openNotification(ERROR_NOTIFICATION_TYPE, 'Sign In', `${res.error}`)
          );
          break;

        case 422:
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            'Sign In',
            'Input validation error.'
          );
          break;

        default:
          break;
      }
    }
    setIsLoading(false);
  };

  const handleAutoLogin = async () => {
    setIsLoading(true);
    const isLoggedIn = Cookies.get('is-logged-in');
    if (isLoggedIn) {
      const username = decryptValue(Cookies.get('username'));
      const password = decryptValue(Cookies.get('password'));
      const res = await login(username, password);
      if (res.isSuccessful) {
        setProfile(res.user);
        setAccessLevelDefinition(res.access_level);
        const location = decryptValue(Cookies.get('location'));
        if (location) {
          navigate(location);
        } else {
          navigate(HOME_ROUTE);
        }
        setIsLoggedIn(true);
      } else {
        openNotification(ERROR_NOTIFICATION_TYPE, 'Sign In', res.error);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleAutoLogin();
  }, []);

  return (
    <div className='Auth-form-container'>
      {contextHolder}
      <Form
        name='basic'
        layout='vertical'
        onFinish={onFinish}
        autoComplete='on'
        size='large'
        className='Auth-form p-5'
        disabled={isLoading}
      >
        <h3 className='text-white text-center'>Scanmar Crew Payroll</h3>
        <h5 className='text-white text-center'>
          Sign in to start your session
        </h5>
        <Form.Item
          label='Username'
          name='username'
          rules={[
            { required: true, message: 'Please input your username!' },
            { validator: validateInput },
          ]}
        >
          <Input placeholder='Username' className='p-3' autoFocus={true} />
        </Form.Item>
        <Form.Item
          label='Password'
          name='password'
          rules={[
            { required: true, message: 'Please input your password!' },
            { validator: validateInput },
          ]}
        >
          <Input.Password placeholder='Password' className='p-3' />
        </Form.Item>
        <Checkbox onChange={(e) => setShouldRemember(e.target.checked)}>
          Remember Me!
        </Checkbox>
        <Form.Item>
          <Button type='primary' block htmlType='submit' className='mt-3'>
            Sign In
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
