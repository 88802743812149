import React, { useContext, useEffect, useState } from 'react';
import CrewType from '../../../../../utils/types/CrewType';
import { Content } from 'antd/es/layout/layout';
import { Button, Col, Form, Row, Table, Tag } from 'antd';
import { UserContext } from '../../../../../utils/context/UserContext';
import { GlobalContext } from '../../../../../App';
import PayrollOtherDeductionType from '../../../../../utils/types/PayrollOtherDeductionType';
import { getOtherDeductionsTable } from '../../../../../utils/tableheader/Maintenance/OtherDeductionsTable';
import { getEmbarkationOtherDeductionTable } from '../../../../../utils/tableheader/Transactions/EmbarkationOtherDeductionsTable';
import {
  getAllOtherDeductions,
  getOtherDeductionsPerCrew,
  getPayrollDeductions,
  postAddOtherDeductionsAllottee,
  postAddOtherDeductionsAllotteePerAllottee,
  postDeleteOtherDeductionsAllottee,
  postModifyOtherDeductionsAllottee,
  postModifyOtherDeductionsAllotteePerAllottee,
} from '../../../../../utils/api/DeductionsAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../../../../utils/api/_constants';
import FormModal from '../../../../../components/CustomModal/FormModal';
import { getPayrollOtherDeductionFields } from '../../../../../utils/inputfields/PayrollOtherDeductionFields';
import FormInput from '../../../../../components/FormInput/FormInput';
import ConfirmationModal from '../../../../../components/CustomModal/ConfirmationModal';
import dayjs from 'dayjs';
import { getPayrollOtherDeductionsTable } from '../../../../../utils/tableheader/Transactions/PayrollOtherDeductionsTable';

export default function EmbarkationDeductionEntryPage({
  selectedCrew = CrewType,
  isLoading,
  setIsLoading,
  hasChanges,
  setHasChanges,
  fetchDatas,
}) {
  const { inputToLocale } = useContext(UserContext);
  const { openNotification } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [isInternalLoading, setIsInternalLoading] = useState(false);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);

  const [selectedOtherDeduction, setSelectedOtherDeduction] = useState(
    PayrollOtherDeductionType
  );
  const [allotteesState, setAllotteesState] = useState([]);

  const [typeOptions, setTypeOptions] = useState([]);

  const [crewDeductions, setCrewDeductions] = useState([]);
  const [tableContents, setTableContents] = useState([]);

  const handleAction = async (action, record) => {
    if (action === 'delete') {
      setIsLoading(true);
      const { data: res, error } = await postDeleteOtherDeductionsAllottee(
        record
      );
      if (error) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Deleting record.',
          'Deleting record failed.'
        );
      } else {
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          'Deleting record.',
          'Success deleting record.'
        );
        fetchDatas();
        setIsLoading(false);
      }
    } else {
      const allottees = selectedCrew.allotment?.allottee?.map((item1) => {
        const corresspondingItem = record?.allottee?.find(
          (item2) => item2.allottee_id == item1.crew_allottee_id
        );

        return {
          ...item1,
          ...corresspondingItem,
          allotte_othd_id: corresspondingItem?.allotte_othd_id,
          allottee_name: `${item1.last_name}, ${item1.first_name} ${item1.middle_name}`,
          allotee_amount: corresspondingItem?.amount
            ? parseFloat(corresspondingItem.amount)
            : 0,
        };
      });
      setAllotteesState(allottees);

      const parseRecord = {
        ...record,
        assign_to: parseInt(record.assign_to),
        transdate: dayjs(record.transdate, 'YYYY-MM-DD'),
        startdeduct: dayjs(record.startdeduct, 'YYYY-MM-DD'),
      };

      form.setFieldsValue(parseRecord);
      setSelectedOtherDeduction(parseRecord);
      setIsFormModalOpen(true);
    }
  };

  const fetchOtherDeduction = async () => {
    const { data: resTable, error } = await getOtherDeductionsPerCrew(
      selectedCrew.crew_no
    );

    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Other deductions',
        resTable.message
      );
    } else {
      const contents = resTable.map((item, index) => ({
        ...item,
        key: index,
      }));
      setTableContents(contents);
    }
  };

  const fetchDeductionTypes = async () => {
    const { data: res, error } = await getAllOtherDeductions();
    if (!error) {
      const list = res.map((item) => ({
        id: item.id,
        description: item.desc,
      }));
      setTypeOptions(list);
    }
  };

  const fetchPayrollDeductions = async () => {
    setIsInternalLoading(true);
    const { data: res, error } = await getPayrollDeductions();

    if (!error) {
      const crewDeduc = res.filter(
        (item) => item.crew_no == selectedCrew.crew_no
      );
      setCrewDeductions(crewDeduc);
    }
    setIsInternalLoading(false);
  };

  useEffect(() => {
    fetchOtherDeduction();
    fetchDeductionTypes();
    fetchPayrollDeductions();

    const allottees = selectedCrew.allotment?.allottee?.map((item, index) => ({
      ...item,
      key: index,
      allottee_id: item.crew_allottee_id,
      allottee_name: `${item.last_name}, ${item.first_name}`,
      accountno: item.acct_type,
      assign: 1,
      allotee_amount: 0,
      api_allottee_amount: 0,
      bankname: item.bankdesc,
    }));

    setAllotteesState(allottees);
  }, [selectedCrew]);

  useEffect(() => {
    form.setFieldsValue(selectedOtherDeduction);
  }, [selectedOtherDeduction]);

  return (
    <Content>
      <Row justify={'end'} className='mb-2'>
        <Button
          type='primary'
          onClick={() => {
            fetchDeductionTypes();
            const initialLoad = {
              ...PayrollOtherDeductionType,
              crew_no: selectedCrew.crew_no,
              crew_name: `${selectedCrew.last_name}, ${selectedCrew.first_name} ${selectedCrew.middle_name}`,
              transdate: dayjs(),
            };
            setAllotteesState(
              allotteesState.map((item) => ({
                ...item,
                allotee_amount: 0,
              }))
            );
            form.setFieldsValue(initialLoad);
            setSelectedOtherDeduction(initialLoad);
            setIsFormModalOpen(true);
          }}
        >
          Add new
        </Button>
      </Row>
      <FormModal
        title={'Other deductions entry'}
        isOpen={isFormModalOpen}
        setIsOpen={setIsFormModalOpen}
        isLoading={isLoading}
        props={{ width: '70vw' }}
        form={form}
        formName={'other_deduction_form'}
        initialState={selectedOtherDeduction}
        onSave={async () => {
          if (hasChanges) {
            try {
              await form.validateFields();
              setIsConfirmationModalOpen(true);
            } catch {}
          } else {
            openNotification(
              INFO_NOTIFICATION_TYPE,
              'Other Dedcution entry',
              'No changes made.'
            );
            setIsFormModalOpen(false);
          }
        }}
        onCancel={() => {
          if (hasChanges) {
            setIsUnsavedModalOpen(true);
          } else {
            form.resetFields();
            setIsFormModalOpen(false);
            setHasChanges(false);
          }
        }}
      >
        <Row gutter={16}>
          {getPayrollOtherDeductionFields(
            isLoading,
            selectedOtherDeduction,
            setSelectedOtherDeduction,
            setHasChanges,
            [
              {
                crew_no: selectedCrew.crew_no,
                last_name: selectedCrew.last_name,
                first_name: selectedCrew.first_name,
              },
            ],
            typeOptions,
            allotteesState,
            setAllotteesState
          ).map((data, index) => (
            <Col key={index} xs={24} sm={12}>
              <FormInput
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            </Col>
          ))}
        </Row>
        <Table
          columns={getEmbarkationOtherDeductionTable(
            selectedOtherDeduction,
            setSelectedOtherDeduction,
            allotteesState,
            setAllotteesState,
            setHasChanges,
            typeOptions
          )}
          dataSource={allotteesState}
          size='small'
          bordered='true'
          scroll={{ x: true }}
          loading={isLoading}
          pagination={{
            defaultPageSize: 50,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} records`,
          }}
        />
      </FormModal>
      <Table
        columns={getPayrollOtherDeductionsTable(handleAction)}
        dataSource={crewDeductions.map((item, index) => ({
          ...item,
          key: index,
        }))}
        size='small'
        bordered='true'
        scroll={{ x: true }}
        loading={isInternalLoading}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          await form.validateFields();
          if (
            parseFloat(selectedOtherDeduction.amounttoded) >
            parseFloat(selectedOtherDeduction.amount)
          ) {
            openNotification(
              ERROR_NOTIFICATION_TYPE,
              'Adding Other Deduction',
              'Amount to be deducted cannot be more than Amount allotted.'
            );
            setIsLoading(false);
          } else {
            const payload = {
              ...selectedOtherDeduction,
              crew_name: `${selectedCrew.last_name}, ${selectedCrew.first_name} ${selectedCrew.middle_name}`,
              transdate: dayjs(selectedOtherDeduction.transdate).format(
                'YYYY-MM-DD'
              ),
              startdeduct: dayjs(selectedOtherDeduction.startdeduct).format(
                'YYYY-MM-DD'
              ),
            };
            let hasValidationError = false;
            allotteesState.map((record) => {
              if (parseFloat(record?.allotee_amount) > 0) {
                if (
                  !record?.other_deduction_allottee_od_id ||
                  !record?.other_deduction_type
                ) {
                  hasValidationError = true;
                }
              }
            });
            if (hasValidationError) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                'Deduction Entry',
                'Please enter Type and/or Deduction Type.'
              );
              setIsConfirmationModalOpen(false);
              setIsLoading(false);
            } else {
              if (payload.id) {
                const { data: res, error } =
                  await postModifyOtherDeductionsAllottee(payload);
                if (error) {
                  openNotification(
                    ERROR_NOTIFICATION_TYPE,
                    'Adding Other Deduction',
                    'Failed.'
                  );

                  setIsLoading(false);
                } else {
                  let hasError = false;
                  await Promise.all(
                    allotteesState.map(async (record) => {
                      const payl = {
                        ...record,
                        other_deduction_config_id: payload.id,
                        other_deduction_allottee_od_id:
                          record.other_deduction_allottee_od_id
                            ? record.other_deduction_allottee_od_id
                            : '',
                        other_deduction_type: record.other_deduction_type
                          ? record.other_deduction_type
                          : '',
                      };
                      if (parseFloat(payl.allotee_amount) > 0) {
                        const { data: res, error } =
                          await postModifyOtherDeductionsAllotteePerAllottee(
                            payl
                          );
                        if (error) {
                          hasError = true;
                          openNotification(
                            ERROR_NOTIFICATION_TYPE,
                            'Adding Other Deduction',
                            'Failed'
                          );
                          setIsLoading(false);
                        }
                      }
                    })
                  );
                  if (!hasError) {
                    openNotification(
                      SUCCESS_NOTIFICATION_TYPE,
                      'Adding Other Deduction',
                      'Success'
                    );
                    setHasChanges(false);
                    setIsConfirmationModalOpen(false);
                    setIsFormModalOpen(false);
                    fetchDatas();
                    setIsLoading(false);
                  }
                }
              } else {
                const { data: res, error } =
                  await postAddOtherDeductionsAllottee(payload);
                if (error) {
                  openNotification(
                    ERROR_NOTIFICATION_TYPE,
                    'Adding Other Deduction',
                    'Failed.'
                  );
                } else {
                  await Promise.all(
                    allotteesState.map(async (record) => {
                      const payl = {
                        ...record,
                        other_deduction_allottee_od_id:
                          record.other_deduction_allottee_od_id
                            ? record.other_deduction_allottee_od_id
                            : '',
                        other_deduction_type: record.other_deduction_type
                          ? record.other_deduction_type
                          : '',
                      };
                      if (parseFloat(payl.allotee_amount) > 0) {
                        const { data: res, error } =
                          await postAddOtherDeductionsAllotteePerAllottee(payl);
                        if (error) {
                          openNotification(
                            ERROR_NOTIFICATION_TYPE,
                            'Adding Other Deduction',
                            'Failed'
                          );
                        }
                      }
                    })
                  );
                  setHasChanges(false);
                  setIsConfirmationModalOpen(false);
                  setIsFormModalOpen(false);
                  fetchDatas();
                  setIsLoading(false);
                }
              }
            }
          }
        }}
      />
      <ConfirmationModal
        title='You have unsaved changes! Confirming will lose all your changes. Confirm?'
        cancelText='Continue editing'
        isLoading={isLoading}
        isOpen={isUnsavedModalOpen}
        setIsOpen={setIsUnsavedModalOpen}
        onConfirm={() => {
          form.resetFields();
          setIsUnsavedModalOpen(false);
          setIsFormModalOpen(false);
          setHasChanges(false);
        }}
      />
    </Content>
  );
}
