import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../utils/context/UserContext';
import { GlobalContext } from '../../App';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Row,
  Space,
  Table,
  Tree,
  Typography,
} from 'antd';
import {
  getAllAccessLevels,
  postDeleteAccessLevel,
  postModifyAccessLevel,
} from '../../utils/api/AccessLevelAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../utils/api/_constants';
import { IoIosAddCircle, IoIosRefresh } from 'react-icons/io';
import AccessLevelSetupType from '../../utils/types/AccessLevelSetupType';
import Search from 'antd/es/input/Search';
import { getAccessLevelTable } from '../../utils/tableheader/Transactions/AccessLevelSetupSystemtable';
import FormModal from '../../components/CustomModal/FormModal';
import { getAccessLevelSetupFields } from '../../utils/inputfields/AccessLevelSetupFields';
import FormInput from '../../components/FormInput/FormInput';
import ConfirmationModal from '../../components/CustomModal/ConfirmationModal';
import { transactionTree } from './AccessLevelHelper/AccessLevelTransactionTree';
import { reportsTree } from './AccessLevelHelper/AccessLevelReportsTree';
import { systemTree } from './AccessLevelHelper/AccessLevelSystemTree';
import { maintenanceTree } from './AccessLevelHelper/AccessLevelMaintenanceTree';

export default function AccessLevelSetupv2Page() {
  const TAG = 'Access Level Setup';

  const { getCurrentDate, doSearchInArray, parseToFloat, inputToLocale } =
    useContext(UserContext);
  const { openNotification } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [searchQuery, setSearchQuery] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const [accessLevelsList, setAccessLevelsList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const [selectedAccessLevel, setSelectedAccessLevel] =
    useState(AccessLevelSetupType);

  //MODALS - FORMS
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);

  const [halfCheckedKeys, setHalfCheckedKeys] = useState({
    transaction: [],
    reports: [],
    maintenance: [],
    system: [],
  });
  const [checkedKeys, setCheckedKeys] = useState({
    transaction: [],
    reports: [],
    maintenance: [],
    system: [],
  });
  // const [selectedKeys, setSelectedKeys] = useState([]);

  const handleAction = async (record = AccessLevelSetupType, action) => {
    switch (action) {
      case 'edit':
        const checkedValues = Object.entries(record)
          .filter(
            ([key, value]) =>
              value !== null &&
              key != 'id' &&
              key != 'title' &&
              key != 'description' &&
              key != 'created_at' &&
              key != 'updated_at' &&
              key != 'key' &&
              value == 'T'
          )
          .map(([key, value]) => key);

        const halfCheckedValues = Object.entries(record)
          .filter(
            ([key, value]) =>
              value !== null &&
              key != 'id' &&
              key != 'title' &&
              key != 'description' &&
              key != 'created_at' &&
              key != 'updated_at' &&
              key != 'key' &&
              value == 'H'
          )
          .map(([key, value]) => key);

        setCheckedKeys({
          transaction: checkedValues,
          reports: checkedValues,
          maintenance: checkedValues,
          system: checkedValues,
        });
        setHalfCheckedKeys({
          transaction: halfCheckedValues,
          reports: halfCheckedValues,
          maintenance: halfCheckedValues,
          system: halfCheckedValues,
        });

        setSelectedAccessLevel(record);
        form.setFieldsValue(record);
        setIsFormModalOpen(true);
        break;
      case 'delete':
        setIsLoading(true);
        const { data: res, error } = await postDeleteAccessLevel(record.id);
        if (error) {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            TAG,
            'Something went wrong!.'
          );
        } else {
          openNotification(
            SUCCESS_NOTIFICATION_TYPE,
            TAG,
            'Access Definition deleted.'
          );
          fetchDatas();
        }
        setIsLoading(false);
        break;
      default:
        break;
    }
  };

  const fetchDatas = async () => {
    setIsLoading(true);
    const { data: res, error } = await getAllAccessLevels();
    if (error) {
      openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Something went wrong.');
    } else {
      const resWithKey = res.map((rec, ind) => ({
        ...rec,
        key: ind,
      }));
      setAccessLevelsList(resWithKey);
      setFilteredList(resWithKey);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <Content>
      <Row className='mb-4 mt-2' justify={'space-between'}>
        <Col xs={24} sm={16}>
          <Button
            type='primary'
            icon={<IoIosAddCircle size={15} />}
            onClick={() => {
              setHalfCheckedKeys({
                transaction: [],
                reports: [],
                maintenance: [],
                system: [],
              });
              setCheckedKeys({
                transaction: [],
                reports: [],
                maintenance: [],
                system: [],
              });
              setSelectedAccessLevel(AccessLevelSetupType);
              form.setFieldsValue(AccessLevelSetupType);
              setIsFormModalOpen(true);
            }}
          >
            Add Level
          </Button>
        </Col>
        <Col xs={24} sm={8} className='text-end'>
          <Space.Compact block>
            <Search
              disabled={isLoading || accessLevelsList.length === 0}
              placeholder='Search!'
              onChange={(e) => {
                setSearchQuery(e.target.value);
                doSearchInArray(
                  e.target.value,
                  setFilteredList,
                  accessLevelsList
                );
              }}
              onSearch={(value) => {
                setSearchQuery(value);
                doSearchInArray(value, setFilteredList, accessLevelsList);
              }}
              allowClear={true}
            />
            <Button
              type='primary'
              icon={<IoIosRefresh />}
              loading={isLoading}
              onClick={() => fetchDatas()}
            />
          </Space.Compact>
        </Col>
      </Row>
      <Table
        columns={getAccessLevelTable(handleAction)}
        dataSource={filteredList}
        size={'small'}
        bordered={false}
        scroll={{ x: true }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <FormModal
        title={'Access Level Entry'}
        isOpen={isFormModalOpen}
        setIsOpen={setIsFormModalOpen}
        isLoading={isLoading}
        props={{ width: '80vw' }}
        form={form}
        formName={'access_level_form'}
        initialState={selectedAccessLevel}
        formLayout={'horizontal'}
        formProps={{ className: 'm-0' }}
        onSave={async () => {
          if (hasChanges) {
            try {
              await form.validateFields();
              setIsConfirmationModalOpen(true);
            } catch {}
          } else {
            openNotification(INFO_NOTIFICATION_TYPE, TAG, 'No changes made.');
            setIsFormModalOpen(false);
          }
        }}
        onCancel={() => {
          if (hasChanges) {
            setIsUnsavedModalOpen(true);
          } else {
            form.resetFields();
            setIsFormModalOpen(false);
            setHasChanges(false);
          }
        }}
      >
        <Row gutter={16}>
          {getAccessLevelSetupFields(
            isLoading,
            selectedAccessLevel,
            setSelectedAccessLevel,
            setHasChanges
          ).map((data, index) => (
            <Col key={index} xs={24} sm={12}>
              <FormInput
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                hidden={data.hidden}
                withGeneratePassword={data.withGeneratePassword}
                emailValidatorAPI={data.emailValidatorAPI}
                usernameValidatorAPI={data.usernameValidatorAPI}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            </Col>
          ))}
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={6}>
            <Tree
              checkable
              defaultExpandAll={true}
              treeData={[transactionTree]}
              checkedKeys={{
                checked: checkedKeys.transaction,
                halfChecked: halfCheckedKeys.transaction,
              }}
              onCheck={(checkedKeysValue, e) => {
                setHasChanges(true);
                setCheckedKeys({
                  ...checkedKeys,
                  transaction: checkedKeysValue,
                });
                setHalfCheckedKeys({
                  ...halfCheckedKeys,
                  transaction: e.halfCheckedKeys,
                });
                const newAccessKeys = { ...selectedAccessLevel };
                e.halfCheckedKeys.forEach((key) => {
                  newAccessKeys[key] = 'H';
                });
                newAccessKeys[e.node.key] = e.node.checked ? 'F' : 'T';
                if (e?.node?.children) {
                  e.node.children.forEach((child) => {
                    newAccessKeys[child.key] = e.node.checked ? 'F' : 'T';
                  });
                }
                setSelectedAccessLevel(newAccessKeys);
              }}
            />
          </Col>
          <Col xs={24} sm={6}>
            <Tree
              checkable
              defaultExpandAll={true}
              treeData={[reportsTree]}
              checkedKeys={{
                checked: checkedKeys.reports,
                halfChecked: halfCheckedKeys.reports,
              }}
              onCheck={(checkedKeysValue, e) => {
                setHasChanges(true);
                setCheckedKeys({
                  ...checkedKeys,
                  reports: checkedKeysValue,
                });
                setHalfCheckedKeys({
                  ...halfCheckedKeys,
                  reports: e.halfCheckedKeys,
                });
                const newAccessKeys = { ...selectedAccessLevel };
                e.halfCheckedKeys.forEach((key) => {
                  newAccessKeys[key] = 'H';
                });
                newAccessKeys[e.node.key] = e.node.checked ? 'F' : 'T';
                if (e?.node?.children) {
                  e.node.children.forEach((child) => {
                    newAccessKeys[child.key] = e.node.checked ? 'F' : 'T';
                  });
                }
                setSelectedAccessLevel(newAccessKeys);
              }}
            />
          </Col>
          <Col xs={24} sm={6}>
            <Tree
              checkable
              defaultExpandAll={true}
              treeData={[maintenanceTree]}
              checkedKeys={{
                checked: checkedKeys.maintenance,
                halfChecked: halfCheckedKeys.maintenance,
              }}
              onCheck={(checkedKeysValue, e) => {
                setHasChanges(true);
                setCheckedKeys({
                  ...checkedKeys,
                  maintenance: checkedKeysValue,
                });
                setHalfCheckedKeys({
                  ...halfCheckedKeys,
                  maintenance: e.halfCheckedKeys,
                });
                const newAccessKeys = { ...selectedAccessLevel };
                e.halfCheckedKeys.forEach((key) => {
                  newAccessKeys[key] = 'H';
                });
                newAccessKeys[e.node.key] = e.node.checked ? 'F' : 'T';
                if (e?.node?.children) {
                  e.node.children.forEach((child) => {
                    newAccessKeys[child.key] = e.node.checked ? 'F' : 'T';
                  });
                }
                setSelectedAccessLevel(newAccessKeys);
              }}
            />
          </Col>
          <Col xs={24} sm={6}>
            <Tree
              checkable
              defaultExpandAll={true}
              treeData={[systemTree]}
              checkedKeys={{
                checked: checkedKeys.system,
                halfChecked: halfCheckedKeys.system,
              }}
              onCheck={(checkedKeysValue, e) => {
                setHasChanges(true);
                setCheckedKeys({
                  ...checkedKeys,
                  system: checkedKeysValue,
                });
                setHalfCheckedKeys({
                  ...halfCheckedKeys,
                  system: e.halfCheckedKeys,
                });
                const newAccessKeys = { ...selectedAccessLevel };
                e.halfCheckedKeys.forEach((key) => {
                  newAccessKeys[key] = 'H';
                });
                newAccessKeys[e.node.key] = e.node.checked ? 'F' : 'T';
                if (e?.node?.children) {
                  e.node.children.forEach((child) => {
                    newAccessKeys[child.key] = e.node.checked ? 'F' : 'T';
                  });
                }
                setSelectedAccessLevel(newAccessKeys);
              }}
            />
          </Col>
        </Row>
      </FormModal>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          const fieldsToUpdate = Object.entries(selectedAccessLevel)
            .filter(
              ([key, value]) =>
                value !== null &&
                key != 'id' &&
                key != 'title' &&
                key != 'description' &&
                key != 'created_at' &&
                key != 'updated_at' &&
                key != 'key'
            )
            .map(([key, value]) => ({ field: key, value }));

          const payload = {
            id: selectedAccessLevel.id ? selectedAccessLevel.id : 0,
            title: selectedAccessLevel.title,
            description: selectedAccessLevel.description,
            fields_to_update: fieldsToUpdate,
          };
          const { data: res, error } = await postModifyAccessLevel(payload);
          if (error) {
            setIsLoading(false);
            setIsConfirmationModalOpen(false);
            openNotification(
              ERROR_NOTIFICATION_TYPE,
              TAG,
              'Something went wrong!'
            );
          } else {
            if (res.isSuccessful) {
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                TAG,
                'Changes success. Please reload the page for changes to take effect.'
              );
              setIsLoading(false);
              setHasChanges(false);
              setIsConfirmationModalOpen(false);
              setIsFormModalOpen(false);
              fetchDatas();
              // window.location.reload();
            } else {
              setIsLoading(false);
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                TAG,
                'Something went wrong!'
              );
            }
          }
        }}
      />
      <ConfirmationModal
        title='You have unsaved changes! Confirming will lose all your changes. Confirm?'
        cancelText='Continue editing'
        isLoading={isLoading}
        isOpen={isUnsavedModalOpen}
        setIsOpen={setIsUnsavedModalOpen}
        onConfirm={() => {
          form.resetFields();
          setIsUnsavedModalOpen(false);
          setIsFormModalOpen(false);
          setHasChanges(false);
        }}
      />
    </Content>
  );
}
