import { Button, Modal, Result } from 'antd';
import React from 'react';
import { AiOutlineWarning } from 'react-icons/ai';

export default function ConfirmationModal({
  title = 'Please confirm your action!',
  okText = 'Confirm',
  cancelText = 'Cancel',
  isClosable = false,
  isLoading,
  isOpen,
  setIsOpen,
  onConfirm,
  onCancel = () => setIsOpen(false),
  children,
  props,
}) {
  return (
    <Modal
      centered
      zIndex={999999999}
      closable={isClosable}
      maskClosable={isClosable}
      footer={null}
      open={isOpen}
      onCancel={onCancel}
      {...props}
    >
      <Result
        title={title}
        extra={
          <>
            <Button size="large" onClick={onCancel} loading={isLoading}>
              {cancelText}
            </Button>
            <Button size="large" danger loading={isLoading} onClick={onConfirm}>
              {okText}
            </Button>
          </>
        }
      />
      {children}
    </Modal>
  );
}
