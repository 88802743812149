import { Button, Input, Popconfirm, Space, Switch } from 'antd';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';

export function getPagibigContributionTable(handleAction) {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      fixed: 'left',
    },
    {
      title: 'SAL. RANGE FROM',
      dataIndex: 'sal_range_from',
      key: 'sal_range_from',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.sal_range_from - b.sal_range_from,
    },
    {
      title: 'SAL. RANGE TO',
      dataIndex: 'sal_range_to',
      key: 'sal_range_to',
      sorter: (a, b) => a.sal_range_to - b.sal_range_to,
    },
    {
      title: 'EMPLOYEE SHARE',
      dataIndex: 'employee_share',
      key: 'employee_share',
      sorter: (a, b) => a.employee_share - b.employee_share,
    },
    {
      title: 'EMPLOYER SHARE',
      dataIndex: 'employer_share',
      key: 'employer_share',
      sorter: (a, b) => a.employer_share - b.employer_share,
    },
    {
      title: 'EMPLOYEE SHARE %',
      dataIndex: 'employee_share_percent',
      key: 'employee_share_percent',
      sorter: (a, b) => a.employee_share_percent - b.employee_share_percent,
    },
    {
      title: 'EMPLOYER SHARE %',
      dataIndex: 'employer_share_percent',
      key: 'employer_share_percent',
      sorter: (a, b) => a.employer_share_percent - b.employer_share_percent,
    },
    {
      title: 'TOTAL CONTRIBUTION',
      dataIndex: 'total_contri',
      key: 'total_contri',
      sorter: (a, b) => a.total_contri - b.total_contri,
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            type='primary'
            onClick={() => handleAction(record)}
            icon={<FaWrench size={15} />}
          />
          <Popconfirm
            title='Delete record'
            description='Are you sure to delete this record?'
            onConfirm={() => handleAction(record, 'delete')}
            okText='Yes'
            cancelText='No'
          >
            <Button danger icon={<AiFillDelete size={15} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
}
