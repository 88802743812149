import { Button, Input, Popconfirm, Space, Switch } from 'antd';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';

export function getUnionDuesTable(handleAction) {
  return [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      fixed: 'left',
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      sorter: (a, b) => a.desc.localeCompare(b.desc),
      fixed: 'left',
    },
    {
      title: 'PESO (RATINGS)',
      dataIndex: 'peso_ratings',
      key: 'peso_ratings',
      sorter: (a, b) => a.peso_ratings - b.peso_ratings,
    },
    {
      title: 'PESO (OFFICER)',
      dataIndex: 'peso_officer',
      key: 'peso_officer',
      sorter: (a, b) => a.peso_officer - b.peso_officer,
    },
    {
      title: 'FMDCP (RATINGS)',
      dataIndex: 'fmdcp_ratings',
      key: 'fmdcp_ratings',
      sorter: (a, b) => a.fmdcp_ratings - b.fmdcp_ratings,
    },
    {
      title: 'FMDCP (OFFICER)',
      dataIndex: 'fmdcp_officer',
      key: 'fmdcp_officer',
      sorter: (a, b) => a.fmdcp_officer - b.fmdcp_officer,
    },
    {
      title: 'PTGWO (RATINGS)',
      dataIndex: 'ptgwo_ratings',
      key: 'ptgwo_ratings',
      sorter: (a, b) => a.ptgwo_ratings - b.ptgwo_ratings,
    },
    {
      title: 'PTGWO (OFFICER)',
      dataIndex: 'ptgwo_officer',
      key: 'ptgwo_officer',
      sorter: (a, b) => a.ptgwo_officer - b.ptgwo_officer,
    },
    {
      title: 'TDAF (RATINGS)',
      dataIndex: 'tdaf_ratings',
      key: 'tdaf_ratings',
      sorter: (a, b) => a.tdaf_ratings - b.tdaf_ratings,
    },
    {
      title: 'TDAF (OFFICER)',
      dataIndex: 'tdaf_officer',
      key: 'tdaf_officer',
      sorter: (a, b) => a.tdaf_officer - b.tdaf_officer,
    },
    {
      title: 'UD (RATINGS)',
      dataIndex: 'ud_ratings',
      key: 'ud_ratings',
      sorter: (a, b) => a.ud_ratings - b.ud_ratings,
    },
    {
      title: 'UD (OFFICER)',
      dataIndex: 'ud_officer',
      key: 'ud_officer',
      sorter: (a, b) => a.ud_officer - b.ud_officer,
    },
    {
      title: 'FMDP (RATINGS)',
      dataIndex: 'fmdp_ratings',
      key: 'fmdp_ratings',
      sorter: (a, b) => a.fmdp_ratings - b.fmdp_ratings,
    },
    {
      title: 'FMDP (OFFICER)',
      dataIndex: 'fmdp_officer',
      key: 'fmdp_officer',
      sorter: (a, b) => a.fmdp_officer - b.fmdp_officer,
    },
    {
      title: 'WMBP (RATINGS)',
      dataIndex: 'wmbp_ratings',
      key: 'wmbp_ratings',
      sorter: (a, b) => a.wmbp_ratings - b.wmbp_ratings,
    },
    {
      title: 'WMBP (OFFICER)',
      dataIndex: 'wmbp_officer',
      key: 'wmbp_officer',
      sorter: (a, b) => a.wmbp_officer - b.wmbp_officer,
    },
    {
      title: 'MAAP (RATINGS)',
      dataIndex: 'maap_ratings',
      key: 'maap_ratings',
      sorter: (a, b) => a.maap_ratings - b.maap_ratings,
    },
    {
      title: 'MAAP (OFFICER)',
      dataIndex: 'maap_officer',
      key: 'maap_officer',
      sorter: (a, b) => a.maap_officer - b.maap_officer,
    },
    {
      title: 'TRAINING LEVY (RATINGS)',
      dataIndex: 'training_levy_ratings',
      key: 'training_levy_ratings',
      sorter: (a, b) => a.training_levy_ratings - b.training_levy_ratings,
    },
    {
      title: 'TRAINING LEVY (OFFICER)',
      dataIndex: 'training_levy_officer',
      key: 'training_levy_officer',
      sorter: (a, b) => a.training_levy_officer - b.training_levy_officer,
    },
    {
      title: 'SURVIVORSHIP (RATINGS)',
      dataIndex: 'survivorship_ratings',
      key: 'survivorship_ratings',
      sorter: (a, b) => a.survivorship_ratings - b.survivorship_ratings,
    },
    {
      title: 'SURVIVORSHIP (OFFICER)',
      dataIndex: 'survivorship_officer',
      key: 'survivorship_officer',
      sorter: (a, b) => a.survivorship_officer - b.survivorship_officer,
    },
    {
      title: 'RATING 1 - 15',
      dataIndex: 'rating1',
      key: 'rating1',
      sorter: (a, b) => a.rating1 - b.rating1,
    },
    {
      title: 'RATING 16 - 30',
      dataIndex: 'rating2',
      key: 'rating2',
      sorter: (a, b) => a.rating2 - b.rating2,
    },
    {
      title: 'OFFICER 1 - 15',
      dataIndex: 'officer1',
      key: 'officer1',
      sorter: (a, b) => a.officer1 - b.officer1,
    },
    {
      title: 'OFFICER 16 - 30',
      dataIndex: 'officer2',
      key: 'officer2',
      sorter: (a, b) => a.officer2 - b.officer2,
    },
    {
      title: 'PERCENTAGE',
      dataIndex: 'percentage',
      key: 'percentage',
      sorter: (a, b) => a.percentage - b.percentage,
    },
    {
      title: 'ACCOUNT CODE',
      dataIndex: 'account_code',
      key: 'account_code',
      sorter: (a, b) => a.account_code - b.account_code,
    },
    {
      title: 'SL CODE',
      dataIndex: 'sl_code',
      key: 'sl_code',
      sorter: (a, b) => a.sl_code - b.sl_code,
    },
    {
      title: 'EXPENSE ACCOUNT',
      dataIndex: 'expense_acct',
      key: 'expense_acct',
      sorter: (a, b) => a.expense_acct - b.expense_acct,
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => handleAction(record)}
            icon={<FaWrench size={15} />}
          />
          <Popconfirm
            title="Delete record"
            description="Are you sure to delete this record?"
            onConfirm={() => handleAction(record, 'delete')}
            okText="Yes"
            cancelText="No"
          >
            <Button danger icon={<AiFillDelete size={15} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
}
