import axios from 'axios';
import {
  GET_ALL_CREW_CUT_OFF_ENDPOINT,
  GET_ALL_FULL_MONTH_ENDPOINT,
  POST_DELETE_CREW_CUT_OFF_ENDPINT,
  POST_MODIFY_CREW_CUT_OFF_ENDPINT,
  POST_MODIFY_CREW_FULL_MONTH_ENDPOINT,
  REQUEST_HEADERS,
} from './_constants';

export async function getAllCrewCutoff() {
  try {
    const { data } = await axios.get(GET_ALL_CREW_CUT_OFF_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postModifyCrewCutOff(payload) {
  try {
    const { data } = await axios.post(
      POST_MODIFY_CREW_CUT_OFF_ENDPINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeleteCrewCutOff(payload) {
  try {
    const { data } = await axios.post(
      `${POST_DELETE_CREW_CUT_OFF_ENDPINT}/${payload.crew_no}`,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getCrewFullMonth(crewNo) {
  try {
    const { data } = await axios.get(
      `${GET_ALL_FULL_MONTH_ENDPOINT}/${crewNo}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postModifyCrewFullMonth(payload) {
  try {
    const { data } = await axios.post(
      POST_MODIFY_CREW_FULL_MONTH_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
