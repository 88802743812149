import axios from 'axios';
import {
  BASE_ENDPOINT,
  GET_ALL_CREW_WAGES_ENDPOINT,
  GET_ALL_WAGES_ENDPOINT,
  REQUEST_HEADERS,
} from './_constants';

const GET_ALL_REMAINING_WAGES_PER_VESSEL_ENDPOINT =
  BASE_ENDPOINT + '/get_rem_wages';

const POST_ADD_CREW_REMAINING_WAGES_ENDPOINT =
  BASE_ENDPOINT + '/add_remaining_wages_allottee';

const POST_PROCESS_CREW_REMAINING_WAGES_ENDPOINT =
  BASE_ENDPOINT + '/process_rem_wages';

const GET_REMAINING_WAGES_REPORT_PER_MONTH_ENDPOINT =
  BASE_ENDPOINT + '/show_rem_wages_per_month';

export async function getAllRemainingWages(payload) {
  try {
    const { data } = await axios.post(
      GET_ALL_REMAINING_WAGES_PER_VESSEL_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddCrewRemainingWages(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_CREW_REMAINING_WAGES_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postProcessCrewRemainingWages(payload) {
  try {
    const { data } = await axios.post(
      POST_PROCESS_CREW_REMAINING_WAGES_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getRemainingWagesReportPerMonth(month, year, vescode) {
  try {
    const { data } = await axios.get(
      `${GET_REMAINING_WAGES_REPORT_PER_MONTH_ENDPOINT}/${month}/${year}/${vescode}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
