import {
  dashboardMenuNavigation,
  logoutNavigation,
  maintenanceSubMenuNavigation,
  reportSubMenuNavigation,
  systemSettingsSubMenuNavigation,
  transactionSubMenuNavigation,
} from './navigationMenus';

function getItem(label, key, icon, children, type, access_key) {
  return {
    key,
    icon,
    children,
    label,
    type,
    access_key,
  };
}

export const items = [
  getItem(
    dashboardMenuNavigation.title,
    dashboardMenuNavigation.path,
    dashboardMenuNavigation.icon,
    null,
    null,
    dashboardMenuNavigation.access_key
  ),
  getItem(
    transactionSubMenuNavigation.title,
    transactionSubMenuNavigation.id,
    transactionSubMenuNavigation.icon,
    transactionSubMenuNavigation.items.map((menuItem) =>
      getItem(
        menuItem.title,
        menuItem.path,
        null,
        null,
        null,
        menuItem.access_key
      )
    ),
    null,
    transactionSubMenuNavigation.access_key
  ),
  getItem(
    reportSubMenuNavigation.title,
    reportSubMenuNavigation.id,
    reportSubMenuNavigation.icon,
    reportSubMenuNavigation.items.map((menuItem) =>
      getItem(
        menuItem.title,
        menuItem.path,
        null,
        null,
        null,
        menuItem.access_key
      )
    ),
    null,
    reportSubMenuNavigation.access_key
  ),
  getItem(
    maintenanceSubMenuNavigation.title,
    maintenanceSubMenuNavigation.id,
    maintenanceSubMenuNavigation.icon,
    maintenanceSubMenuNavigation.items.map((menuItem) =>
      getItem(
        menuItem.title,
        menuItem.path,
        null,
        null,
        null,
        menuItem.access_key
      )
    ),
    null,
    maintenanceSubMenuNavigation.access_key
  ),
  getItem(
    systemSettingsSubMenuNavigation.title,
    systemSettingsSubMenuNavigation.id,
    systemSettingsSubMenuNavigation.icon,
    systemSettingsSubMenuNavigation.items.map((menuItem) =>
      getItem(
        menuItem.title,
        menuItem.path,
        null,
        null,
        null,
        menuItem.access_key
      )
    ),
    null,
    maintenanceSubMenuNavigation.access_key
  ),
  getItem(
    logoutNavigation.title,
    logoutNavigation.path,
    logoutNavigation.icon,
    null,
    null,
    logoutNavigation.access_key
  ),
];
