export const reportsTree = {
  title: 'Reports Module',
  key: 'main_reports',
  children: [
    {
      title: 'Crew Payslips',
      key: 'reports_crew_payslips',
      children: [
        {
          title: 'Home Allotment',
          key: 'function_reports_crew_payslips_home_allotment',
        },
        {
          title: 'Side Letter',
          key: 'function_reports_crew_payslips_side_letter',
        },
        {
          title: 'Special Allotment',
          key: 'function_reports_crew_payslips_special_allotment',
        },
        {
          title: 'Remaining Wages',
          key: 'function_reports_crew_payslips_remaining_wages',
        },
        {
          title: 'Process',
          key: 'function_reports_crew_payslips_process',
        },
      ],
    },
    {
      title: 'Vessel Payslips',
      key: 'reports_vessel_payslips',
      children: [
        {
          title: 'Home Allotment',
          key: 'function_reports_vessel_payslips_home_allotment',
        },
        {
          title: 'Side Letter',
          key: 'function_reports_vessel_payslips_side_letter',
        },
        {
          title: 'Special Allotment',
          key: 'function_reports_vessel_payslips_special_allotment',
        },
        {
          title: 'Remaining Wages',
          key: 'function_reports_vessel_payslips_remaining_wages',
        },
        {
          title: 'Process',
          key: 'function_reports_vessel_payslips_process',
        },
      ],
    },
  ],
};
