import UserSetupType from "../types/UserSetupType";

export const getAccessLevelSetupFields = (
  isLoading,
  state = UserSetupType,
  setState,
  setHasChanges
) => {
  return [
    {
      type: "text",
      name: "id",
      placeholder: "0",
      label: "ID",
      autofocus: false,
      required: false,
      disabled: true,
      value: state.user_id,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, user_id: value });
        setHasChanges(true);
      },
    },
    {
      type: "text",
      name: "title",
      placeholder: "Title",
      label: "Title",
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: state.title,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, title: value });
        setHasChanges(true);
      },
    },
    {
      type: "text",
      name: "description",
      placeholder: "Description",
      label: "Description",
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.description,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, description: value });
        setHasChanges(true);
      },
    },
  ];
};
