import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../App';
import { UserContext } from '../../utils/context/UserContext';
import { Button, Col, Form, Row, Table } from 'antd';
import VesselExchangeRateType from '../../utils/types/VesselExchangeRateType';
import {
  getAllExchangeRates,
  postAddExchangeRate,
  postDeleteExchangeRate,
  postModifyExchangeRate,
} from '../../utils/api/ExchangeRateAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../utils/api/_constants';
import Search from 'antd/es/input/Search';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { IoIosRefresh } from 'react-icons/io';
import { getVesselExchangeRateTable } from '../../utils/tableheader/Maintenance/VesselExchangeRateTable';
import FormModal from '../../components/CustomModal/FormModal';
import { getVesselExchangeRateFields } from '../../utils/inputfields/VesselExchangeRateFields';
import { getVessels } from '../../utils/api/VesselAPI';
import FormInput from '../../components/FormInput/FormInput';
import ConfirmationModal from '../../components/CustomModal/ConfirmationModal';
import dayjs from 'dayjs';

export default function VesselExchangeRatePage() {
  const { openNotification } = useContext(GlobalContext);
  const { doSearchInArray, profile } = useContext(UserContext);

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [exchangeRateList, setExchangeRateList] = useState([]);

  const [selectedExchangeRate, setSelectedExchangeRate] = useState(
    VesselExchangeRateType
  );

  const [vesselList, setVesselList] = useState([]);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);

  const tableContents = filteredList.map((item, index) => ({
    ...item,
    key: index,
  }));

  const handleAction = async (record, action = 'edit') => {
    if (action === 'delete') {
      setIsLoading(true);
      const { data: res, error } = await postDeleteExchangeRate(record);
      if (error) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Exchange Rates',
          'Error deleting record!'
        );
      } else {
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          'Deleting Exchange Rate',
          'Record deleted!'
        );
        fetchData();
      }
      setIsLoading(false);
    } else {
      const parseRecord = {
        ...record,
        year: dayjs().year(record.year),
      };
      form.setFieldsValue(parseRecord);
      setSelectedExchangeRate(parseRecord);
      setIsFormModalOpen(true);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    const { data: res, error } = await getAllExchangeRates();
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Exchange Rates',
        'Loading exchange rates failed.'
      );
    } else {
      const { data: vesselRes, vesselError } = await getVessels(
        profile.user_id
      );
      if (vesselError) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Exchange Rates',
          'Loading exchange rates failed.'
        );
      } else {
        setVesselList(vesselRes);
        setFilteredList(res);
        setExchangeRateList(res);
        doSearchInArray(searchQuery, setFilteredList, res);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Content>
      <Row className='mb-4' justify={'end'}>
        <Col xs={24} sm={8} className='text-end'>
          <Search
            disabled={isLoading}
            placeholder='Search exchange rates!'
            onChange={(e) => {
              setSearchQuery(e.target.value);
              doSearchInArray(
                e.target.value,
                setFilteredList,
                exchangeRateList
              );
            }}
            onSearch={(value) => {
              setSearchQuery(value);
              doSearchInArray(value, setFilteredList, exchangeRateList);
            }}
            allowClear={true}
          />
        </Col>
        <Col xs={24} sm={3} className='text-end'>
          <Button
            className='mx-2'
            type='primary'
            icon={<AiOutlinePlusCircle size={18} />}
            disabled={isLoading}
            onClick={() => {
              setSelectedExchangeRate(VesselExchangeRateType);
              form.setFieldsValue(VesselExchangeRateType);
              setIsFormModalOpen(true);
            }}
          >
            Add
          </Button>
          <Button
            type='primary'
            icon={<IoIosRefresh size={18} />}
            disabled={isLoading}
            onClick={() => fetchData()}
          />
        </Col>
      </Row>
      <Table
        columns={getVesselExchangeRateTable(handleAction)}
        dataSource={tableContents}
        size='small'
        bordered='true'
        scroll={{ x: true }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <FormModal
        title={'Exchange Rate Entry'}
        isOpen={isFormModalOpen}
        setIsOpen={setIsFormModalOpen}
        isLoading={isLoading}
        props={{ width: '50vw' }}
        form={form}
        formName={'exchange_rate_form'}
        initialState={selectedExchangeRate}
        onSave={async () => {
          if (hasChanges) {
            try {
              await form.validateFields();
              setIsConfirmationModalOpen(true);
            } catch {}
          } else {
            openNotification(
              INFO_NOTIFICATION_TYPE,
              'Exchange Rates',
              'No changes made.'
            );
            setIsFormModalOpen(false);
          }
        }}
        onCancel={() => {
          if (hasChanges) {
            setIsUnsavedModalOpen(true);
          } else {
            form.resetFields();
            setIsFormModalOpen(false);
            setHasChanges(false);
          }
        }}
      >
        <Row gutter={16}>
          {getVesselExchangeRateFields(
            isLoading,
            selectedExchangeRate,
            setSelectedExchangeRate,
            setHasChanges,
            vesselList
          ).map((data, index) => (
            <Col key={index} xs={24} sm={12}>
              <FormInput
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            </Col>
          ))}
        </Row>
      </FormModal>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          if (selectedExchangeRate.id) {
            const { data: res, error } = await postModifyExchangeRate({
              ...selectedExchangeRate,
              year: dayjs(selectedExchangeRate.year).format('YYYY'),
            });
            if (error) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                'Exchange Rates',
                'Error saving changes!'
              );
            } else {
              if (res.isSuccessful === 'false') {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'Exchange Rates',
                  'Error saving changes.'
                );
              } else {
                openNotification(
                  SUCCESS_NOTIFICATION_TYPE,
                  'Exchange Rates',
                  'Changes saved!'
                );
                setHasChanges(false);
                setIsConfirmationModalOpen(false);
                setIsFormModalOpen(false);
                fetchData();
              }
            }
          } else {
            const { data: res, error } = await postAddExchangeRate({
              ...selectedExchangeRate,
              year: dayjs(selectedExchangeRate.year).format('YYYY'),
            });
            if (error) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                'Exchange Rates',
                'Error saving changes!'
              );
            } else {
              if (res.isSuccessful === 'false') {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'Exchange Rates',
                  'Error saving changes.'
                );
              } else {
                openNotification(
                  SUCCESS_NOTIFICATION_TYPE,
                  'Exchange Rates',
                  'Changes saved!'
                );
                setHasChanges(false);
                setIsConfirmationModalOpen(false);
                setIsFormModalOpen(false);
                fetchData();
              }
            }
          }
          setIsLoading(false);
        }}
      />
      <ConfirmationModal
        title='You have unsaved changes! Confirming will lose all your changes. Confirm?'
        cancelText='Continue editing'
        isLoading={isLoading}
        isOpen={isUnsavedModalOpen}
        setIsOpen={setIsUnsavedModalOpen}
        onConfirm={() => {
          form.resetFields();
          setIsUnsavedModalOpen(false);
          setIsFormModalOpen(false);
          setHasChanges(false);
        }}
      />
    </Content>
  );
}
