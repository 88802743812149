import React, { useContext } from 'react';
import {
  PDFViewer,
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from '@react-pdf/renderer';
import HomeAllotmentHeaderType from '../../utils/types/HomeAllotmentHeaderType';
import dayjs from 'dayjs';
import logoImage from '../../assets/icon-name.png';
import { UserContext } from '../../utils/context/UserContext';

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 10,
    paddingBottom: 15,
  },
  container: {
    flex: 1,
    // justifyContent: 'space-evenly',
    alignItems: 'center',
    // marginBottom: -10,
    // marginBottom: 10,
    // flex: 1,
    // flexDirection: 'row', // Use row to center horizontally
    // alignItems: 'center', // Center content vertically
    // justifyContent: 'center', // Center content horizontally
  },
  headerImage: {
    height: 60,
    width: 60,
  },
  headerText: {
    fontSize: 6,
  },

  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    // borderBottomWidth: 1,
  },
  tableRow: {
    flexDirection: 'row',
    borderTopWidth: 1,
    paddingBottom: 0,
  },
  tableCell: {
    width: '6%',
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    paddingTop: 5,
    paddingBottom: 0,
    paddingHorizontal: 1,
    fontSize: '6px',
    marginBottom: 0,
  },

  pageNumber: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 10,
    fontSize: 6,
    textAlign: 'left',
    paddingHorizontal: 10,
  },
  legends: {
    //   position: 'absolute',
    //   bottom: 30,
    //   left: 80,
    //   right: 0,
    fontSize: 8,
    textAlign: 'left',
  },

  bottomContainer: { flexDirection: 'row', margin: 0, padding: 0 },
  column: {
    flex: 1,
    margin: 10,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderTopWidth: 2,
    // textAlign: 'center',
    // backgroundColor: 'lightgray',
    paddingTop: 10,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
});

export default function HomeAllotmentComputePDF({
  homeAllotmentHeader = HomeAllotmentHeaderType,
  homeAllotmentList,
  legends,
  grandTotals,
}) {
  const { inputToLocale } = useContext(UserContext);

  const isNumberAndNotZero = (value) => {
    return typeof value === 'number' && value !== 0;
  };

  return (
    <PDFViewer style={{ width: '98%', height: '100vh' }}>
      <Document>
        <Page size='A4' style={styles.page} orientation='landscape' wrap>
          <View
            style={{
              ...styles.bottomContainer,
              marginBottom: -15,
            }}
            fixed
          >
            <View
              style={{
                ...styles.column,
                alignItems: 'flex-end',
                paddingVertical: 0,
                marginRight: 0,
                marginTop: 4,
              }}
            >
              <Image src={logoImage} style={styles.headerImage} />
            </View>
            <View style={{ ...styles.column, paddingTop: 20, marginLeft: 1 }}>
              <Text style={styles.headerText}>HOME ALLOTMENT REGISTER</Text>
              <Text style={styles.headerText}>
                {homeAllotmentHeader.vessel_name}
              </Text>
              <Text style={styles.headerText}>
                {homeAllotmentHeader.principal_name}
              </Text>
              <Text style={styles.headerText}>
                {dayjs(
                  `${homeAllotmentHeader.month}-${homeAllotmentHeader.year}`,
                  'M-YYYY'
                )
                  .format('MMMM YYYY')
                  .toUpperCase()}
              </Text>
              <Text
                style={{
                  ...styles.headerText,
                  alignSelf: 'flex-end',
                  marginTop: -14,
                }}
              >
                AC-21 REV. (0)
              </Text>
              <Text style={{ ...styles.headerText, alignSelf: 'flex-end' }}>
                Conversion rate: US$ 1.00 - PHP{' '}
                {inputToLocale(homeAllotmentHeader.exrate)}
              </Text>
            </View>
          </View>
          <View style={{ ...styles.table }} fixed>
            <View style={{ ...styles.tableRow, borderBottomWidth: 1 }}>
              <View style={{ ...styles.tableCell, width: '2%' }}>
                <Text>SEQ {'\n'}NO.</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text>CREW NAME</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text>POSITION</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>BASIC SALARY IN USD</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>ALLOTMENT SCHEME</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>80%</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>ADDTL</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>TOTAL</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>TOTAL PESO</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>SSS/PH/PROV</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>PAG IBIG</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>OTHER</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>TOTAL</Text>
              </View>
              <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                <Text>NET PAY</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '12%' }}>
                <Text>ALLOTTEE NAME</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>BANK</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>ACCOUNT NO</Text>
              </View>
            </View>
          </View>
          <View style={styles.table}>
            {homeAllotmentList?.map((record, index) => (
              <View
                key={`${index}-a-${index}`}
                style={{
                  ...styles.tableRow,
                  marginBottom: 0,
                  paddingBottom: 0,
                }}
              >
                <View style={{ ...styles.tableCell, width: '2%' }}>
                  <Text>{index + 1}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: '10%' }}>
                  <Text>{record.crew_name}</Text>
                  <Text>
                    <br />
                    {record.trans_type &&
                      `[${record.trans_type}:${record.trans_date}]`}
                  </Text>
                </View>
                <View style={{ ...styles.tableCell, width: '10%' }}>
                  <Text>{record.p_name}</Text>
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  <Text>{inputToLocale(record.basic_salary)}</Text>
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  <Text>
                    {record.allotment_type === 'fixed'
                      ? inputToLocale(record.allotscheme)
                      : record.allotment_type === 'percentage'
                      ? `${inputToLocale(record.allotscheme_percentage)}%`
                      : ''}
                  </Text>
                  <Text>{'\n'}</Text>
                  {record.allottee.map((allottee, index) => (
                    <>
                      <Text>
                        {record.allottee.length === index + 1
                          ? isNumberAndNotZero(
                              parseFloat(allottee.adjustment_amount_peso)
                            )
                            ? `\n\n\nBALANCE: `
                            : `BALANCE: `
                          : allottee.allot_type === 'percentage'
                          ? isNumberAndNotZero(
                              parseFloat(allottee.adjustment_amount_peso)
                            )
                            ? `${inputToLocale(allottee.allot_amount)}%\n\n\n\n`
                            : `${inputToLocale(allottee.allot_amount)}%\n`
                          : isNumberAndNotZero(
                              parseFloat(allottee.adjustment_amount_peso)
                            )
                          ? `${inputToLocale(allottee.allot_amount)}\n\n\n\n`
                          : `${inputToLocale(allottee.allot_amount)}\n`}
                      </Text>
                      <Text>
                        {'\n'}
                        {/* {record.allottee.length === index + 1 ? '\n' : '\n'}  SPACE IN HERE*/}
                      </Text>
                    </>
                  ))}
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  <Text>
                    {record.allotment_type === 'percentage'
                      ? `${inputToLocale(record.allot_percentage)}`
                      : `${inputToLocale(record.total)}`}
                  </Text>
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  <Text>
                    {inputToLocale(record.additional)}
                    {/* {record.allotment_type === 'fixed'
                      ? inputToLocale(record.additional)
                      : record.allotment_type === 'percentage'
                      ? `${inputToLocale(record.allot_percentage)}`
                      : ''} */}
                  </Text>
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  <Text>{inputToLocale(record.total)}</Text>
                  {record.allottee.map((allottee, index) => (
                    <>
                      {/* {record.allottee.length === index + 1 &&
                        parseFloat(allottee.adjustment_amount_peso) > 0 && (
                          <Text>{'\n\n'}</Text>
                        )} */}
                      <Text>
                        {isNumberAndNotZero(
                          parseFloat(allottee.adjustment_amount)
                        )
                          ? isNumberAndNotZero(
                              parseFloat(allottee.adjustment_amount_peso)
                            )
                            ? `\n\n${inputToLocale(
                                allottee.adjustment_amount
                              )}\n\n`
                            : `\n${inputToLocale(allottee.adjustment_amount)}\n`
                          : '\n'}
                      </Text>
                      <Text>{'\n'}</Text>
                    </>
                  ))}
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  <Text>{'\n\n'}</Text>
                  {record.allottee.map((allottee, index) => (
                    <>
                      {record.allottee.length === index + 1 &&
                        parseFloat(allottee.adjustment_amount_peso) === 0 && (
                          <Text>{''}</Text>
                        )}
                      <Text>
                        {isNumberAndNotZero(
                          parseFloat(allottee.adjustment_amount_peso)
                        )
                          ? `${inputToLocale(allottee.total_peso)}`
                          : null}
                      </Text>
                      <Text>
                        {isNumberAndNotZero(
                          parseFloat(allottee.adjustment_amount_peso)
                        )
                          ? inputToLocale(allottee.adjustment_amount_peso)
                          : null}
                      </Text>
                      <Text>
                        {isNumberAndNotZero(
                          parseFloat(allottee.adjustment_amount_peso)
                        )
                          ? '___________'
                          : null}
                      </Text>
                      <Text>
                        {inputToLocale(
                          parseFloat(allottee.total_peso) +
                            parseFloat(allottee.adjustment_amount_peso)
                        )}
                      </Text>
                      <Text>
                        {'\n'}
                        {/* {record.allottee.length === index + 1 ? `\n` : '\n'} SPACE IN HERE*/}
                      </Text>
                    </>
                  ))}
                  {isNumberAndNotZero(
                    parseFloat(record.prev_deduction_entry?.total_govt)
                  ) ||
                  isNumberAndNotZero(
                    parseFloat(record.prev_deduction_entry?.total_pag)
                  ) ||
                  isNumberAndNotZero(
                    parseFloat(record.prev_union_dues?.amount)
                  ) ? (
                    <Text>
                      [
                      {dayjs(
                        `${homeAllotmentHeader.month}-${homeAllotmentHeader.year}`,
                        'M-YYYY'
                      )
                        .subtract(1, 'month')
                        .format('M/YYYY')}
                      ]
                    </Text>
                  ) : null}
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  <Text>{'\n\n'}</Text>
                  {record.allottee.map((allottee, index) => (
                    <>
                      {record.allottee.length === index + 1 && (
                        <Text>
                          {isNumberAndNotZero(
                            parseFloat(allottee.adjustment_amount_peso)
                          )
                            ? `\n`
                            : ``}
                        </Text>
                      )}
                      <Text key={index}>
                        {isNumberAndNotZero(parseFloat(allottee.total_govt))
                          ? isNumberAndNotZero(
                              parseFloat(allottee.adjustment_amount_peso)
                            )
                            ? `\n\n${inputToLocale(allottee.total_govt)}`
                            : `${inputToLocale(allottee.total_govt)}`
                          : isNumberAndNotZero(
                              parseFloat(allottee.adjustment_amount_peso)
                            )
                          ? `\n\n\n\n\n`
                          : `\n\n`}
                      </Text>
                    </>
                  ))}
                  {isNumberAndNotZero(
                    parseFloat(record.prev_deduction_entry?.total_govt)
                  ) && (
                    <Text>
                      {`\n${inputToLocale(
                        record.prev_deduction_entry?.total_govt
                      )}`}
                    </Text>
                  )}
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  <Text>{'\n\n'}</Text>
                  {record.allottee.map((allottee, index) => (
                    <>
                      {record.allottee.length === index + 1 && (
                        <Text>
                          {isNumberAndNotZero(
                            parseFloat(allottee.adjustment_amount_peso)
                          )
                            ? `\n`
                            : ``}
                        </Text>
                      )}
                      <Text>
                        {isNumberAndNotZero(parseFloat(allottee.total_pagibig))
                          ? isNumberAndNotZero(
                              parseFloat(allottee.adjustment_amount_peso)
                            )
                            ? `\n\n${inputToLocale(allottee.total_pagibig)}`
                            : `${inputToLocale(allottee.total_pagibig)}`
                          : isNumberAndNotZero(
                              parseFloat(allottee.adjustment_amount_peso)
                            )
                          ? record.allottee.length === index + 1
                            ? ``
                            : `\n\n\n\n\n`
                          : record.allottee.length === index + 1
                          ? ``
                          : `\n\n`}
                      </Text>
                    </>
                  ))}
                  {isNumberAndNotZero(
                    parseFloat(record.prev_deduction_entry?.total_pag)
                  ) && (
                    <Text>
                      {'\n'}
                      {inputToLocale(record.prev_deduction_entry?.total_pag)}
                    </Text>
                  )}
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  <Text>{'\n\n'}</Text>
                  {record.allottee.map((allottee, index) => (
                    <React.Fragment key={index}>
                      {allottee.home_allotment_allottee.length > 0 && (
                        <>
                          <Text>
                            {record.allottee.length === index + 1 &&
                            isNumberAndNotZero(
                              parseFloat(allottee.adjustment_amount_peso)
                            )
                              ? `\n`
                              : ``}
                          </Text>
                          {allottee.home_allotment_allottee.map(
                            (homeAllotee, index) => (
                              <Text key={index + record.master_id}>
                                {isNumberAndNotZero(
                                  parseFloat(allottee.adjustment_amount_peso)
                                )
                                  ? `${homeAllotee.code}/${inputToLocale(
                                      homeAllotee.other_deduction_amount
                                    )}`
                                  : `${homeAllotee.code}/${inputToLocale(
                                      homeAllotee.other_deduction_amount
                                    )}`}
                              </Text>
                            )
                          )}
                        </>
                      )}
                      <Text>
                        {record.allottee.length === index + 1
                          ? allottee.home_allotment_allottee.length === 0
                            ? isNumberAndNotZero(
                                parseFloat(allottee.adjustment_amount_peso)
                              )
                              ? parseFloat(allottee.total_union_dues)
                                ? `\n\n\n${allottee.ud_id}/${inputToLocale(
                                    allottee.total_union_dues
                                  )}`
                                : `\n\n\n`
                              : parseFloat(allottee.total_union_dues)
                              ? `${allottee.ud_id}/${inputToLocale(
                                  allottee.total_union_dues
                                )}`
                              : ``
                            : parseFloat(allottee.total_union_dues)
                            ? `${allottee.ud_id}/${inputToLocale(
                                allottee.total_union_dues
                              )}`
                            : ``
                          : isNumberAndNotZero(
                              parseFloat(allottee.total_union_dues)
                            )
                          ? isNumberAndNotZero(
                              parseFloat(allottee.adjustment_amount_peso)
                            )
                            ? `${allottee.ud_id}/${inputToLocale(
                                allottee.total_union_dues
                              )}\n\n\n\n\n`
                            : `${allottee.ud_id}/${inputToLocale(
                                allottee.total_union_dues
                              )}\n\n\n`
                          : isNumberAndNotZero(
                              parseFloat(allottee.adjustment_amount_peso)
                            )
                          ? `\n\n\n\n\n`
                          : allottee.home_allotment_allottee.length === 0
                          ? `\n\n`
                          : `\n`}
                      </Text>
                    </React.Fragment>
                  ))}
                  {parseFloat(
                    isNumberAndNotZero(record.prev_union_dues?.amount)
                  ) > 0 && (
                    <Text>
                      {'\n'}
                      {record.prev_union_dues?.ud_id}/
                      {inputToLocale(record.prev_union_dues?.amount)}
                    </Text>
                  )}
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  <Text>{'\n\n'}</Text>
                  {record.allottee.map((allottee, index) => (
                    <Text key={index}>
                      {record.allottee.length === index + 1
                        ? isNumberAndNotZero(
                            parseFloat(allottee.adjustment_amount_peso)
                          )
                          ? `\n\n\n${inputToLocale(allottee.total_deduction)}`
                          : `${inputToLocale(allottee.total_deduction)}`
                        : isNumberAndNotZero(
                            parseFloat(allottee.total_deduction)
                          )
                        ? isNumberAndNotZero(
                            parseFloat(allottee.adjustment_amount_peso)
                          )
                          ? `${inputToLocale(
                              allottee.total_deduction
                            )}\n\n\n\n\n`
                          : `${inputToLocale(allottee.total_deduction)}\n\n`
                        : isNumberAndNotZero(
                            parseFloat(allottee.adjustment_amount_peso)
                          )
                        ? `\n\n\n\n\n`
                        : `\n\n`}
                    </Text>
                  ))}
                </View>
                <View style={{ ...styles.tableCell, textAlign: 'right' }}>
                  <Text>{'\n\n'}</Text>
                  {record.allottee.map((allottee, index) => (
                    <Text key={index}>
                      {record.allottee.length === index + 1
                        ? isNumberAndNotZero(
                            parseFloat(allottee.adjustment_amount_peso)
                          )
                          ? `\n\n\n${inputToLocale(allottee.total_net)}`
                          : `${inputToLocale(allottee.total_net)}`
                        : isNumberAndNotZero(parseFloat(allottee.total_net))
                        ? isNumberAndNotZero(
                            parseFloat(allottee.adjustment_amount_peso)
                          )
                          ? `${inputToLocale(allottee.total_net)}\n\n\n\n\n`
                          : `${inputToLocale(allottee.total_net)}\n\n`
                        : isNumberAndNotZero(
                            parseFloat(allottee.adjustment_amount_peso)
                          )
                        ? `\n\n\n\n\n`
                        : `\n\n`}
                    </Text>
                  ))}
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '12%',
                  }}
                >
                  <Text>{'\n\n'}</Text>
                  {record.allottee.map((allottee, index) => (
                    <Text>
                      {record.allottee.length === index + 1
                        ? isNumberAndNotZero(
                            parseFloat(allottee.adjustment_amount_peso)
                          )
                          ? allottee.allottee.length > 25
                            ? `\n\n${allottee.allottee}`
                            : `\n\n\n${allottee.allottee}`
                          : `${allottee.allottee}`
                        : isNumberAndNotZero(
                            parseFloat(allottee.adjustment_amount_peso)
                          )
                        ? allottee.allottee.length > 25
                          ? `${allottee.allottee}\n\n\n\n`
                          : `${allottee.allottee}\n\n\n\n\n`
                        : allottee.allottee.length > 25
                        ? `${allottee.allottee}\n`
                        : `${allottee.allottee}\n\n`}
                    </Text>
                  ))}
                </View>
                <View style={styles.tableCell}>
                  <Text>{'\n\n'}</Text>
                  {record.allottee.map((allottee, index) => (
                    <Text>
                      {record.allottee.length === index + 1
                        ? isNumberAndNotZero(
                            parseFloat(allottee.adjustment_amount_peso)
                          )
                          ? `\n\n\n${allottee.bankcode}`
                          : `${allottee.bankcode}`
                        : isNumberAndNotZero(
                            parseFloat(allottee.adjustment_amount_peso)
                          )
                        ? `${allottee.bankcode}\n\n\n\n\n`
                        : `${allottee.bankcode}\n\n`}
                    </Text>
                  ))}
                </View>
                <View style={styles.tableCell}>
                  <Text>{'\n\n'}</Text>
                  {record.allottee.map((allottee, index) => (
                    <Text
                      style={{
                        fontSize:
                          allottee.hold_allotment == '1' ? '4.5' : '6px',
                        marginBottom: allottee.hold_allotment == '1' ? 2 : '',
                      }}
                    >
                      {allottee.hold_allotment == '1'
                        ? 'HOLD ALLOTMENT'
                        : record.allottee.length === index + 1
                        ? isNumberAndNotZero(
                            parseFloat(allottee.adjustment_amount_peso)
                          )
                          ? `\n\n\n${allottee.acct_no}`
                          : `${allottee.acct_no}`
                        : isNumberAndNotZero(
                            parseFloat(allottee.adjustment_amount_peso)
                          )
                        ? `${allottee.acct_no}\n\n\n\n\n`
                        : `${allottee.acct_no}\n\n`}
                    </Text>
                  ))}
                </View>
              </View>
            ))}
            <Text style={{ marginBottom: 5 }}></Text>
            <View
              style={{
                ...styles.tableRow,
                paddingBottom: 2,
                marginBottom: 3,
              }}
            >
              <View style={{ ...styles.tableCell, width: '2%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text>GRAND TOTALS:</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text></Text>
              </View>
              <View style={styles.tableCell}>
                <Text></Text>
              </View>
              <View style={styles.tableCell}>
                <Text></Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>{inputToLocale(grandTotals.total_80)}</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>{inputToLocale(grandTotals.total_addtional)}</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>{inputToLocale(grandTotals.total_allotment)}</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>{inputToLocale(grandTotals.total_peso)}</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>{inputToLocale(grandTotals.total_gov_deduction)}</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>
                    {inputToLocale(grandTotals.total_gov_deduction_pagibig)}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>{inputToLocale(grandTotals.total_others)}</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>
                    {inputToLocale(grandTotals.grand_total_deduction)}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  borderBottom: 1,
                  marginHorizontal: 0,
                  paddingHorizontal: 0,
                  paddingBottom: 1,
                }}
              >
                <View
                  style={{
                    borderBottom: 1,
                    marginHorizontal: 0,
                    paddingBottom: 2,
                    textAlign: 'right',
                  }}
                >
                  <Text>{inputToLocale(grandTotals.grand_total_net)}</Text>
                </View>
              </View>
              <View style={{ ...styles.tableCell, width: '12%' }}>
                <Text></Text>
              </View>
              <View style={styles.tableCell}>
                <Text></Text>
              </View>
              <View style={styles.tableCell}>
                <Text></Text>
              </View>
            </View>
          </View>
          {/*  */}
          {/* <View
            render={({ pageNumber, totalPages }) =>
              pageNumber % 2 === 0 && (
                <View style={{ background: 'red' }}>
                  <Text>I'm only visible in odd pages!</Text>
                </View>
              )
            }
          /> */}
          <Text>{'\n\n\n'}</Text>
          <View style={styles.footer} wrap={false}>
            <Text style={{ ...styles.legends, marginBottom: 15 }}>
              **LEGENDS: {legends}
            </Text>
            <View style={{ ...styles.bottomContainer, paddingLeft: 100 }}>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Prepared By:{`\n\n_____________________\n`} {`        `}
                  Administrator
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Checked By:{`\n\n_____________________\n`} {`           `}
                  Accountant
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Approved By:{`\n\n_____________________\n`} {`             `}
                  President
                </Text>
              </View>
            </View>
          </View>
          <Text
            style={{ ...styles.pageNumber, textAlign: 'right' }}
            render={({ pageNumber, totalPages }) =>
              `Page: ${pageNumber} / ${totalPages}`
            }
            fixed
          />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `Date printed: [${dayjs().format('YYYY-MM-DD HH:mm:ss')}]`
            }
            fixed
          />
          {/* <Text
            style={styles.legends}
            render={({ pageNumber, totalPages }) => (
              <>{pageNumber === totalPages && `**LEGENDS: ${legends}`}</>
            )}
            fixed
          /> */}
        </Page>
      </Document>
    </PDFViewer>
  );
}
