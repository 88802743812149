import {
  Button,
  Col,
  DatePicker,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../utils/context/UserContext';
import dayjs from 'dayjs';
import {
  getVesselSetupDetails,
  getVessels,
} from '../../../../utils/api/VesselAPI';
import { GlobalContext } from '../../../../App';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../../../utils/api/_constants';
import VesselType from '../../../../utils/types/VesselType';
import Search from 'antd/es/input/Search';
import { IoIosRefresh } from 'react-icons/io';
import { getCrewByVessel } from '../../../../utils/api/TransactionsAPI';
import CrewType from '../../../../utils/types/CrewType';
import {
  getAllCrewCutoff,
  postDeleteCrewCutOff,
  postModifyCrewCutOff,
} from '../../../../utils/api/CutOffAPI';
import { getEmbarkedTable } from '../../../../utils/tableheader/Transactions/EmbarkedTable.js';
import ConfirmationModal from '../../../../components/CustomModal/ConfirmationModal';
import EmbarkedModal from './EmbarkedModal';
import { FaPrint } from 'react-icons/fa';
import CustomModal from '../../../../components/CustomModal/CustomModal.js';
import AllotmentConfirmationPDF from '../../../../components/PDFReports/AllotmentConfirmationPDF.js';
import VesselSetupAllotmentType from '../../../../utils/types/VesselSetupAllotmentType.js';

export default function EmbarkedPage() {
  const {
    getCurrentDate,
    doSearchInArray,
    parseToFloat,
    inputToLocale,
    validateAccessToFeature,
    profile,
  } = useContext(UserContext);

  const { openNotification } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const [month, setMonth] = useState(new Date(getCurrentDate()).getMonth() + 1);
  const [year, setYear] = useState(new Date(getCurrentDate()).getFullYear());

  const [vesselList, setVesselList] = useState([]);
  const [selectedVessel, setSelectedVessel] = useState(VesselType);

  const [selectedCrew, setSelectedCrew] = useState(CrewType);
  const [crewCutOffList, setCrewCutOffList] = useState([]);

  const [crewList, setCrewList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const [selectedCutOffCrew, setSelectedCutOffCrew] = useState({});

  const [isEmbarkedModalOpen, setIsEmbarkedModalOpen] = useState(false);

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [printHeaderDetails, setPrintHeaderDetails] = useState(
    VesselSetupAllotmentType
  );
  const [crewListPrinting, setCrewListPrinting] = useState([]);

  const calculateTotalAllotmentSalary = () => {
    const totalAllotmentSalary = crewListPrinting.reduce((acc, obj) => {
      if (obj?.allotment?.allotment_amount) {
        return (
          acc + parseFloat(obj?.allotment?.allotment_amount?.replace(',', ''))
        );
      }
      return acc + 0;
    }, 0);
    return totalAllotmentSalary;
  };

  const handleAction = async (record) => {
    setSelectedCrew({ ...record });
    setIsEmbarkedModalOpen(true);
  };

  const onCrewCutOffChange = async (record, date) => {
    if (date != '') {
      setSelectedCutOffCrew({
        ...record,
        cut_off_date: dayjs(date).format('YYYY-MM-DD'),
      });
    } else {
      setSelectedCutOffCrew({ ...record, cut_off_date: 'delete' });
    }
    setIsConfirmationModalOpen(true);
  };

  const loadCrewsByVessel = async () => {
    setIsLoading(true);
    setFilteredList([]);
    setCrewList([]);
    setCrewListPrinting([]);
    const payload = {
      vescode: selectedVessel.vessel_code,
      year: year,
      month: month,
    };
    const { data: res, error } = await getCrewByVessel(payload);
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Crew list',
        'Error fetching crew list.'
      );
    } else {
      const embarkedCrews = res.filter(
        (crew) =>
          dayjs(`${year}-${month}`).month() <
            dayjs(crew.vessel_sign_off_date).month() ||
          crew.is_disembark === 'N'
      );
      const { data: cutOffs, errorCutOff } = await getAllCrewCutoff();
      if (!errorCutOff) {
        const crewsWithCutOff = embarkedCrews.map((crew, index) => {
          const cutOff = cutOffs.find((ref) => ref.crew_no === crew.crew_no);

          return {
            ...crew,
            key: index,
            crew_cut_off: cutOff ? cutOff.cutoff_date : 0,
            allotment_salary: inputToLocale(
              crew.allotment.salary_for_allotment
            ),
            basic_pay: crew.salary_details[0].salary.find(
              (sal) => sal.salary_code === 'BASIC-PAY'
            )?.amount,
          };
        });
        const allCrewsWithCutOff = res.map((crew, index) => {
          const cutOff = cutOffs.find((ref) => ref.crew_no === crew.crew_no);

          return {
            ...crew,
            key: index,
            crew_cut_off: cutOff ? cutOff.cutoff_date : 0,
            allotment_salary: inputToLocale(
              crew.allotment.salary_for_allotment
            ),
            basic_pay: crew.salary_details[0].salary.find(
              (sal) => sal.salary_code === 'BASIC-PAY'
            )?.amount,
          };
        });
        setFilteredList(crewsWithCutOff);
        setCrewList(crewsWithCutOff);
        setCrewListPrinting(
          allCrewsWithCutOff.sort((a, b) => {
            const nameA = parseFloat(a.arrangeme);
            const nameB = parseFloat(b.arrangeme);

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
        );
        doSearchInArray(searchQuery, setFilteredList, crewsWithCutOff);
        if (selectedCrew.id !== null) {
          const crewSelected = crewsWithCutOff.find(
            (item) => item.id === selectedCrew.id
          );
          setSelectedCrew(crewSelected);
        }
      }
    }
    setIsLoading(false);
  };

  const fetchDatas = async () => {
    setIsLoading(true);
    const { data: res, error } = await getVessels(profile.user_id);
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Embarked',
        'Error fetching vessels.'
      );
    } else {
      setVesselList(res);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <Content>
      <Spin spinning={isLoading} fullscreen />
      <Row>
        <Col xs={24} sm={6} className='me-2'>
          <Typography.Text strong className='me-2'>
            Month/Year:
          </Typography.Text>
          <DatePicker
            allowClear={false}
            style={{ width: '70%' }}
            picker='month'
            defaultValue={dayjs(`${month}/${year}`, 'M/YYYY')}
            onChange={(_, dateString) => {
              const [dateMonth, dateYear] = dateString.split('/');
              setMonth(dateMonth);
              setYear(dateYear);
            }}
            format='M/YYYY'
            disabled={isLoading}
          />
        </Col>
        <Col xs={24} sm={8} className='text-start'>
          <Typography.Text strong className='me-2'>
            Vessel:
          </Typography.Text>
          <Select
            style={{ width: '80%' }}
            placeholder={'Search vessel!'}
            value={selectedVessel.vessel_code}
            onChange={(value, option) => {
              const vessel = vesselList.find(
                (item) => item.vessel_code === value
              );
              setSelectedVessel(vessel);
            }}
            disabled={isLoading}
            showSearch
            filterOption={(inputValue, option) => {
              const optionDescription = option.children.toLowerCase();
              return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
            }}
          >
            {vesselList.map((option) => (
              <Select.Option
                key={option.vessel_code}
                value={option.vessel_code}
              >
                {option.vessel_name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Space>
          <Button
            type='primary'
            disabled={isLoading || selectedVessel.id === null}
            onClick={() => loadCrewsByVessel()}
          >
            Filter
          </Button>
        </Space>
      </Row>
      <Row className='mb-4 mt-2'>
        <Col xs={24} sm={16}>
          <Button
            type='primary'
            loading={isLoading}
            disabled={crewList.length === 0}
            icon={<FaPrint />}
            onClick={async () => {
              setIsLoading(true);
              const { data: res, error } = await getVesselSetupDetails(
                selectedVessel.vessel_code
              );
              if (error) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'Print allotment details',
                  'Allotment details loading failed.'
                );
              } else {
                setPrintHeaderDetails(res);
                setIsPrintModalOpen(true);
              }
              setIsLoading(false);
            }}
          >
            Print
          </Button>
        </Col>
        <Col xs={24} sm={8}>
          <Space.Compact block>
            <Search
              disabled={isLoading || crewList.length === 0}
              placeholder='Search crew!'
              onChange={(e) => {
                setSearchQuery(e.target.value);
                doSearchInArray(e.target.value, setFilteredList, crewList);
              }}
              onSearch={(value) => {
                setSearchQuery(value);
                doSearchInArray(value, setFilteredList, crewList);
              }}
              allowClear={true}
            />
            <Button
              type='primary'
              icon={<IoIosRefresh />}
              loading={isLoading}
              onClick={() => fetchDatas()}
            />
          </Space.Compact>
        </Col>
      </Row>
      <Table
        columns={getEmbarkedTable(handleAction, onCrewCutOffChange)}
        dataSource={filteredList}
        size='small'
        bordered='true'
        scroll={{ x: true }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onCancel={async () => {
          setIsConfirmationModalOpen(false);
          loadCrewsByVessel();
        }}
        onConfirm={async () => {
          setIsLoading(true);
          if (selectedCutOffCrew.cut_off_date === 'delete') {
            const payload = {
              crew_no: selectedCutOffCrew.crew_no,
              cutoff_date: selectedCutOffCrew.cut_off_date,
            };
            const { data: res, error } = await postDeleteCrewCutOff(payload);
            if (error) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                'Crew cut off',
                'Error saving crew cut off date.'
              );
            } else {
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                'Crew cut off',
                'Removing saving crew cut off date success.'
              );
              setIsConfirmationModalOpen(false);
              fetchDatas();
            }
          } else {
            const payload = {
              crew_no: selectedCutOffCrew.crew_no,
              cutoff_date: selectedCutOffCrew.cut_off_date,
            };
            const { data: res, error } = await postModifyCrewCutOff(payload);
            if (error) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                'Crew cut off',
                'Error saving crew cut off date.'
              );
            } else {
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                'Crew cut off',
                'Saving crew cut off date success.'
              );
              setIsConfirmationModalOpen(false);
              fetchDatas();
            }
          }
          setIsLoading(false);
        }}
      />
      <EmbarkedModal
        isOpen={isEmbarkedModalOpen}
        setIsOpen={setIsEmbarkedModalOpen}
        selectedCrew={selectedCrew}
        fetchDatas={loadCrewsByVessel}
        selectedMonth={month}
        selectedYear={year}
      />
      <CustomModal
        isLoading={isLoading}
        isOpen={isPrintModalOpen}
        setIsOpen={setIsPrintModalOpen}
        cancelText='Close'
        props={{ okButtonProps: { hidden: true }, width: '100vw' }}
        isClosable={true}
      >
        <AllotmentConfirmationPDF
          headerDetails={printHeaderDetails}
          crewList={crewListPrinting}
          month={month}
          year={year}
          grandTotal={calculateTotalAllotmentSalary()}
        />
      </CustomModal>
    </Content>
  );
}
