import axios from 'axios';
import { BASE_ENDPOINT, REQUEST_HEADERS } from './_constants';

const GET_CREW_PAYSLIP_ENDPOINT = BASE_ENDPOINT + '/get_payslip';
const GET_CREW_PAYSLIP_MPO_ENDPOINT = BASE_ENDPOINT + '/get_payslip_mpo';
const GET_CREW_PAYSLIP_SIDE_LETTER_ENDPOINT =
  BASE_ENDPOINT + '/get_payslip_side_letter';
const GET_CREW_PAYSLIP_REMAINING_WAGES_ENDPOINT =
  BASE_ENDPOINT + '/get_payslip_rem_wages';
const POST_CREW_PAYSLIP_BY_VESSEL_ENDPOINT =
  BASE_ENDPOINT + '/get_payslip_perves';

export async function getPayslipPerCrew(
  crew_no,
  month,
  year,
  toMonth,
  toYear,
  vescode
) {
  try {
    const { data } = await axios.get(
      `${GET_CREW_PAYSLIP_ENDPOINT}/${crew_no}/${month}/${year}/${toMonth}/${toYear}/${vescode}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getPayslipMpoPerCrew(
  crew_no,
  month,
  year,
  toMonth,
  toYear,
  vescode
) {
  try {
    const { data } = await axios.get(
      `${GET_CREW_PAYSLIP_MPO_ENDPOINT}/${crew_no}/${month}/${year}/${toMonth}/${toYear}/${vescode}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getPayslipSideLetterPerCrew(
  crew_no,
  month,
  year,
  toMonth,
  toYear,
  vescode
) {
  try {
    const { data } = await axios.get(
      `${GET_CREW_PAYSLIP_SIDE_LETTER_ENDPOINT}/${crew_no}/${month}/${year}/${toMonth}/${toYear}/${vescode}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getPayslipRemainingPerCrew(
  crew_no,
  month,
  year,
  toMonth,
  toYear,
  vescode
) {
  try {
    const { data } = await axios.get(
      `${GET_CREW_PAYSLIP_REMAINING_WAGES_ENDPOINT}/${crew_no}/${month}/${year}/${toMonth}/${toYear}/${vescode}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getPayslipPerVessel(payload) {
  try {
    const { data } = await axios.post(
      POST_CREW_PAYSLIP_BY_VESSEL_ENDPOINT,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
