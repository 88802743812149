import { Form, Modal } from 'antd';
import React from 'react';

export default function FormModal({
  children,
  title,
  isClosable = false,
  saveText = 'Save',
  isOpen,
  setIsOpen,
  onSave,
  onCancel = () => setIsOpen(false),
  isLoading,
  props,
  formLabelAlign = 'left',
  form,
  formName,
  initialState,
  formLayout = 'vertical',
  formProps,
  footer,
}) {
  return (
    <Modal
      title={title}
      centered
      closable={isClosable}
      maskClosable={isClosable}
      open={isOpen}
      onOk={onSave}
      okText={saveText}
      confirmLoading={isLoading}
      onCancel={onCancel}
      footer={footer}
      {...props}
    >
      <Form
        labelAlign={formLabelAlign}
        form={form}
        name={formName}
        initialValues={initialState}
        layout={formLayout}
        {...formProps}
      >
        {children}
      </Form>
    </Modal>
  );
}
