import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../utils/context/UserContext';
import { GlobalContext } from '../../../../App';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Radio,
  Row,
  Table,
  Typography,
} from 'antd';
import {
  getAllPostedVessel,
  getProcessedHomeAllotmentPerMonth,
  getProcessedMpoPerMonth,
  getProcessedSlbperMonth,
} from '../../../../utils/api/TransactionsAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  WARNING_NOTIFICATION_TYPE,
} from '../../../../utils/api/_constants';
import { getBankDownloadingVesselTable } from '../../../../utils/tableheader/Transactions/BankDownloadingVesselTable';
import { postBankDownload } from '../../../../utils/api/BankDownloadingAPI';
import { utils, writeFile } from 'xlsx';

export default function BankDownloadingBDOPage() {
  const TAG = 'Bank Downloading - BDO';

  const headers = [
    'D_TRNDATE',
    'C_SENDER',
    'C_BFNAME',
    'C_BFADDR1',
    'C_BFADDR2',
    'N_ALLOT',
    'C_BKNAME',
    'C_BRNAME',
    'C_BKACCT',
    'C_VSLCODE',
  ];

  const {
    getCurrentDate,
    doSearchInArray,
    parseToFloat,
    inputToLocale,
    validateAccessToFeature,
  } = useContext(UserContext);

  const { openNotification } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);

  const [date, setDate] = useState({
    month: dayjs().month() + 1,
    year: dayjs().year(),
  });

  const [postedVessels, setPostedVessels] = useState([]);

  const [homeAllotmentVessels, setHomeAllotmentVessels] = useState([]);
  const [slbVessels, setSlbVessels] = useState([]);
  const [mpoVessels, setMpoVessels] = useState([]);

  const [selectedOption, setSelectedOption] = useState(0);
  const [selectedTypeOfAccount, setSelectedTypeOfAccount] = useState(0);

  const [includeVessel, setIncludeVessel] = useState([]);
  const [includeVesselKeys, setIncludeVesselKeys] = useState([]);

  const [inputValues, setInputValues] = useState({
    date: dayjs(),
    dateString: dayjs().format('MM/DD/YYYY'),
    savingsAccount: '4290040708',
    currentAccount: '4290060539',
  });

  const tableContents = () => {
    switch (selectedOption) {
      case 0:
        return homeAllotmentVessels.map((rec, ind) => ({ ...rec, key: ind }));
      case 1:
        return slbVessels.map((rec, ind) => ({ ...rec, key: ind }));
      case 2:
        return mpoVessels.map((rec, ind) => ({ ...rec, key: ind }));
      default:
        return [];
    }
  };

  const downloadExcel = (data) => {
    const formattedData = data.map((item) => [
      item?.d_trndate,
      item?.c_sender, // crew
      item?.c_bfname, // allottee
      item?.c_bfaddr1,
      item?.c_bfaddr2,
      item?.n_allot, // amount
      item?.c_bkname,
      item?.c_brname,
      item?.c_bkacct,
      item?.c_vslcode,
    ]);

    formattedData.unshift(headers);

    // Create a new workbook and worksheet
    const ws = utils.aoa_to_sheet(formattedData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');

    // Write the workbook to a file and trigger a download
    writeFile(wb, 'bdo.xlsx');
  };

  const downloadTextfile = (data) => {
    const headerString = headers.join('\t');

    const dataString = data
      .map(
        (item) =>
          `${item?.d_trndate}\t${item?.c_sender}\t${item?.c_bfname}\t${item?.c_bfaddr1}\t${item?.c_bfaddr2}\t${item?.n_allot}\t${item?.c_bkname}\t${item?.c_bkacct}\t${item?.c_vslcode}`
      )
      .join('\n');

    const finalString = `${headerString}\n${dataString}`;

    const blob = new Blob([finalString], { type: 'text/plain;charset=utf-8' });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'bdo.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const fetchDatas = async (month, year) => {
    setIsLoading(true);
    await Promise.all([
      getAllPostedVessel(month, year),
      getProcessedHomeAllotmentPerMonth(month, year),
      getProcessedSlbperMonth(month, year),
      getProcessedMpoPerMonth(month, year),
    ]).then((response) => {
      if (
        (response[0].error,
        response[1].error,
        response[2].error,
        response[3].error)
      ) {
        openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Something went wrong!');
      } else {
        const availHA = response[1].data.filter((rec) => {
          const postRecord = response[0].data.find(
            (ves) => ves.vescode == rec.vessel_code
          );
          if (postRecord?.home_allotment == 0) {
            return rec;
          }
          return false;
        });

        const availSlb = response[2].data.filter((rec) => {
          const postRecord = response[0].data.find(
            (ves) => ves.vescode == rec.vessel_code
          );
          if (postRecord?.slb_allotment == 0) {
            return rec;
          }
          return false;
        });

        const availMpo = response[3].data.filter((rec) => {
          const postRecord = response[0].data.find(
            (ves) => ves.vescode == rec.vessel_code
          );
          if (postRecord?.mpo_allotment == 0) {
            return rec;
          }
          return false;
        });

        setHomeAllotmentVessels(availHA);
        setSlbVessels(availSlb);
        setMpoVessels(availMpo);
        setPostedVessels(response[0].data);
      }
    });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <Content>
      <Row gutter={8}>
        <Col xs={24} sm={12}>
          <Card hoverable disabled={isLoading}>
            <Row>
              <Col xs={24} sm={4}>
                <Typography.Text strong className='me-2'>
                  Month/Year:
                </Typography.Text>
              </Col>
              <Col xs={24} sm={6}>
                <DatePicker
                  allowClear={false}
                  className='me-2'
                  picker='month'
                  defaultValue={dayjs(`${date.month}/${date.year}`, 'M/YYYY')}
                  onChange={(_, dateString) => {
                    const [dateMonth, dateYear] = dateString.split('/');
                    setIncludeVessel([]);
                    setIncludeVesselKeys([]);
                    setDate({ month: dateMonth, year: dateYear });
                    fetchDatas(dateMonth, dateYear);
                  }}
                  format='M/YYYY'
                  disabled={isLoading}
                />
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col xs={24} sm={4}>
                <Typography.Text strong className='me-2'>
                  Options:
                </Typography.Text>
              </Col>
              <Col xs={24} sm={20}>
                <Radio.Group
                  disabled={isLoading}
                  onChange={(e) => setSelectedOption(e.target.value)}
                  value={selectedOption}
                >
                  <Radio value={0}>Home Allotment</Radio>
                  <Radio value={1}>Side Letter Bonus</Radio>
                  <Radio value={2}>MPO</Radio>
                  <Radio value={3}>Remaining Wages</Radio>
                </Radio.Group>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col xs={24} sm={4}>
                <Typography.Text strong className='me-2'>
                  Type of Account:
                </Typography.Text>
              </Col>
              <Col xs={24} sm={12}>
                <Radio.Group
                  disabled={isLoading}
                  onChange={(e) => setSelectedTypeOfAccount(e.target.value)}
                  value={selectedTypeOfAccount}
                >
                  <Radio value={0}>Peso</Radio>
                  <Radio value={1}>Dollar</Radio>
                </Radio.Group>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card hoverable disabled={isLoading}>
            <Row align={'middle'}>
              <Col xs={24} sm={6}>
                <Typography.Text strong className='me-2'>
                  Payroll Date:
                </Typography.Text>
              </Col>
              <Col xs={24} sm={12}>
                <DatePicker
                  disabled={isLoading}
                  format='MM/DD/YYYY'
                  value={inputValues?.date}
                  onChange={(date, dateString) => {
                    setInputValues({
                      ...inputValues,
                      date: date,
                      dateString: dateString,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row className='mt-2' align={'middle'}>
              <Col xs={24} sm={6}>
                <Typography.Text strong className='me-2'>
                  Peso Account No:
                </Typography.Text>
              </Col>
              <Col xs={24} sm={12}>
                <Input
                  allowClear
                  disabled={isLoading || selectedTypeOfAccount == 1}
                  value={inputValues?.savingsAccount}
                  onChange={(e) => {
                    setInputValues({
                      ...inputValues,
                      savingsAccount: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row className='mt-2' align={'middle'}>
              <Col xs={24} sm={6}>
                <Typography.Text strong className='me-2'>
                  Dollar Account No:
                </Typography.Text>
              </Col>
              <Col xs={24} sm={12}>
                <Input
                  allowClear
                  disabled={isLoading || selectedTypeOfAccount == 0}
                  value={inputValues?.currentAccount}
                  onChange={(e) => {
                    setInputValues({
                      ...inputValues,
                      currentAccount: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row className='mt-2'>
              <Button
                type='primary'
                disabled={
                  isLoading ||
                  selectedOption == null ||
                  selectedTypeOfAccount == null ||
                  includeVessel.length == 0
                }
                onClick={async () => {
                  setIsLoading(true);
                  if (
                    inputValues?.dateString == null ||
                    inputValues?.savingsAccount == '' ||
                    inputValues?.currentAccount == ''
                  ) {
                    openNotification(
                      WARNING_NOTIFICATION_TYPE,
                      TAG,
                      'Please complete details above.'
                    );
                    setIsLoading(false);
                    return;
                  }
                  const payload = {
                    ...date,
                    payroll_type: selectedOption,
                    currency: selectedTypeOfAccount,
                    bank_code: 'bdo',
                    vessel_code: includeVessel.map((rec) => rec.vessel_code),
                  };
                  const { data: res, error } = await postBankDownload(payload);
                  if (error) {
                    openNotification(
                      ERROR_NOTIFICATION_TYPE,
                      TAG,
                      'Something went wrong!'
                    );
                  } else {
                    // downloadTextfile(res);
                    downloadExcel(res);
                  }
                  setIsLoading(false);
                }}
              >
                Download
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>

      <Table
        className='m-2'
        columns={getBankDownloadingVesselTable()}
        dataSource={tableContents()}
        size='small'
        bordered='true'
        scroll={{ x: true }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
        rowSelection={{
          selectedRowKeys: includeVesselKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setIncludeVessel(newSelectedRows);
            setIncludeVesselKeys(newSelectedRowKeys);
          },
        }}
      />
    </Content>
  );
}
