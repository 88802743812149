import React, { useContext, useState } from 'react';
import CrewType from '../../../../utils/types/CrewType';
import { Descriptions, Modal, Tabs } from 'antd';
import EmbarkedSalaryBreakdown from './EmbarkedTabs/EmbarkedSalaryBreakdown';
import EmbarkedAllotmentDistribution from './EmbarkedTabs/EmbarkedAllotmentDistribution';
import ConfirmationModal from '../../../../components/CustomModal/ConfirmationModal';
import EmbarkationScheduleOfDeductionsPage from './EmbarkedTabs/EmbarkationScheduleOfDeductionsPage';
import EmbarkationDeductionEntryPage from './EmbarkedTabs/EmbarkationDeductionEntryPage';
import EmbarkationOthersPage from './EmbarkedTabs/EmbarkationOthersPage';
import EmbarkationMpoRequestsPage from './EmbarkedTabs/EmbarkationMpoRequestsPage';
import { UserContext } from '../../../../utils/context/UserContext';

export default function EmbarkedModal({
  isOpen,
  setIsOpen,
  selectedCrew = CrewType,
  fetchDatas,
  selectedMonth,
  selectedYear,
}) {
  const { validateAccessToFeature } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);

  const checkAvailableTabs = () => {
    const tabs = [];
    if (
      validateAccessToFeature('function_tran_pp_emb_salary_breakdown_modify')
    ) {
      tabs.push({
        key: '1',
        label: 'Salary Breakdown',
        children: (
          <EmbarkedSalaryBreakdown
            selectedCrew={selectedCrew}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hasChanges={hasChanges}
            setHasChanges={setHasChanges}
            fetchDatas={fetchDatas}
            date={{ month: selectedMonth, year: selectedYear }}
          />
        ),
        disabled: !validateAccessToFeature(
          'function_tran_pp_emb_salary_breakdown_modify'
        ),
      });
    }
    if (validateAccessToFeature('sub_feature_tran_pp_disembarked')) {
      tabs.push({
        key: '2',
        label: 'Allotment Distribution',
        children: (
          <EmbarkedAllotmentDistribution
            selectedCrew={selectedCrew}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hasChanges={hasChanges}
            setHasChanges={setHasChanges}
            fetchDatas={fetchDatas}
            selectedMonth={selectedMonth}
          />
        ),
        disabled: !validateAccessToFeature('sub_feature_tran_pp_disembarked'),
      });
    }
    // if (validateAccessToFeature('')) {
    tabs.push({
      key: '3',
      label: 'Schedule of Deductions',
      children: (
        <EmbarkationScheduleOfDeductionsPage
          selectedCrew={selectedCrew}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          hasChanges={hasChanges}
          setHasChanges={setHasChanges}
          fetchDatas={fetchDatas}
        />
      ),
      // disabled: !validateAccessToFeature(''),
    });
    // }
    if (validateAccessToFeature('sub_feature_tran_pp_post_records')) {
      tabs.push({
        key: '4',
        label: 'Deduction Entry',
        children: (
          <EmbarkationDeductionEntryPage
            selectedCrew={selectedCrew}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            hasChanges={hasChanges}
            setHasChanges={setHasChanges}
            fetchDatas={fetchDatas}
          />
        ),
        disabled: !validateAccessToFeature('sub_feature_tran_pp_post_records'),
      });
    }
    // if (validateAccessToFeature('')) {
    tabs.push({
      key: '5',
      label: 'Others',
      children: (
        <EmbarkationOthersPage
          selectedCrew={selectedCrew}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          hasChanges={hasChanges}
          setHasChanges={setHasChanges}
          fetchDatas={fetchDatas}
        />
      ),
      // disabled: !validateAccessToFeature(''),
    });
    // }
    return tabs;
  };

  return (
    <React.Fragment>
      <Modal
        title={`${selectedCrew.last_name}, ${selectedCrew.first_name} ${selectedCrew.middle_name}`}
        centered
        open={isOpen}
        onCancel={() => {
          if (hasChanges) {
            setIsUnsavedModalOpen(true);
          } else {
            setHasChanges(false);
            setIsOpen(false);
          }
        }}
        cancelText={'Close'}
        confirmLoading={isLoading}
        okButtonProps={{ hidden: true }}
        width={'90%'}
      >
        <Descriptions
          bordered
          size='small'
          layout='vertical'
          column={{ xxl: 5, xl: 5, lg: 3, md: 2, sm: 1, xs: 1 }}
          className='mb-2'
        >
          <Descriptions.Item label='Crew no.'>
            {selectedCrew.crew_no}
          </Descriptions.Item>
          <Descriptions.Item label='Crew name'>
            {`${selectedCrew.first_name} ${selectedCrew.middle_name} ${selectedCrew.last_name}`}
          </Descriptions.Item>
          <Descriptions.Item label='Vessel'>
            {selectedCrew.vessel_name}
          </Descriptions.Item>
          <Descriptions.Item label='Embarked'>
            {selectedCrew.embark_date}
          </Descriptions.Item>
          <Descriptions.Item label='Position'>
            {selectedCrew.position_name}
          </Descriptions.Item>
        </Descriptions>
        <Tabs
          items={checkAvailableTabs()}
          centered
          defaultActiveKey='1'
          tabPosition='left'
        />
      </Modal>
      <ConfirmationModal
        title='You have unsaved changes! Confirming will lose all your changes. Confirm?'
        cancelText='Continue editing'
        isLoading={isLoading}
        isOpen={isUnsavedModalOpen}
        setIsOpen={setIsUnsavedModalOpen}
        onConfirm={() => {
          setIsUnsavedModalOpen(false);
          setIsOpen(false);
          setHasChanges(false);
        }}
      />
    </React.Fragment>
  );
}
