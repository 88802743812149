import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Segmented,
  Table,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import {
  getVesselSetup,
  getVessels,
  postModifyVesselSetup,
} from '../../utils/api/VesselAPI';
import { GlobalContext } from '../../App';
import {
  ERROR_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../utils/api/_constants';
import { getVesselParametersTable } from '../../utils/tableheader/Maintenance/VesselParametersTable';
import Search from 'antd/es/input/Search';
import { UserContext } from '../../utils/context/UserContext';
import VesselSetupType from '../../utils/types/VesselSetupType';
import VesselType from '../../utils/types/VesselType';
import FormModal from '../../components/CustomModal/FormModal';
import FormInput from '../../components/FormInput/FormInput';
import {
  getVesselSetupAmosup,
  getVesselSetupFifth,
  getVesselSetupFirst,
  getVesselSetupFourth,
  getVesselSetupSecond,
  getVesselSetupThird,
} from '../../utils/inputfields/VesselSetupFields';
import { getAllUnionDues } from '../../utils/api/UnionDuesAPI';
import dayjs from 'dayjs';
import ConfirmationModal from '../../components/CustomModal/ConfirmationModal';
import { IoIosRefresh } from 'react-icons/io';

export default function VesselParametersSetupPage() {
  const TAG = 'Vessel Parameters Setup';
  const { openNotification } = useContext(GlobalContext);
  const { doSearchInArray, profile } = useContext(UserContext);

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [vesselsList, setVesselsList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const [unionDuesOptions, setUnionDuesOptions] = useState([]);

  const [selectedVessel, setSelectedVessel] = useState(VesselType);
  const [selectedVesselSetup, setSelectedVesselSetup] =
    useState(VesselSetupType);

  const [isVesselModalOpen, setIsVesselModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);

  const [vesselSetupTab, setVesselSetupTab] = useState('Allotment Parameters');
  const tabOptions = ['Allotment Parameters', 'AMOSUP Parameters'];

  const tableContents =
    filteredList.length > 0
      ? filteredList.map((data, index) => ({ ...data, key: index }))
      : vesselsList.map((data, index) => ({
          ...data,
          key: index,
        }));

  const handleAction = async (vesselRec) => {
    setIsLoading(true);
    const vessel = vesselsList.find((record) => record.id === vesselRec.id);
    setSelectedVessel(vessel);
    const { data: vesselPayload, error } = await getVesselSetup(vesselRec.id);
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Loading vessel setup.',
        'Error loading setup!'
      );
    } else {
      const { data: unionPayload, error } = await getAllUnionDues();
      if (error) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Loading vessel setup.',
          'Error loading setup!'
        );
      } else {
        setUnionDuesOptions(
          unionPayload.map((record) => ({
            id: record.id.toString(),
            description: record.desc,
          }))
        );
        if (vesselPayload.effectivity_date !== null) {
          vesselPayload.effectivity_date = dayjs(
            vesselPayload.effectivity_date,
            'YYYY-MM-DD'
          );
        }
        form.setFieldsValue(vesselPayload);
        setSelectedVesselSetup(vesselPayload);
        setIsVesselModalOpen(true);
      }
    }
    setIsLoading(false);
  };

  const loadVessels = async () => {
    setIsLoading(true);
    const { data: payload, error } = await getVessels(profile.user_id);
    if (error) {
      openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Error loading vessels!');
    } else {
      setVesselsList(payload);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadVessels();
  }, []);

  return (
    <Content>
      <Typography.Title level={3}>Vessel Maintenance</Typography.Title>
      <Row className='mb-4' justify={'end'}>
        <Col xs={24} sm={8}>
          <Search
            placeholder='Search vessel!'
            onChange={(e) => {
              doSearchInArray(e.target.value, setFilteredList, vesselsList);
            }}
            onSearch={(value) =>
              doSearchInArray(value, setFilteredList, vesselsList)
            }
            allowClear={true}
          />
        </Col>
        <Col xs={24} sm={1} className='text-end'>
          <Button
            type='primary'
            icon={<IoIosRefresh size={18} />}
            disabled={isLoading}
            onClick={() => loadVessels()}
          />
        </Col>
      </Row>

      <Table
        columns={getVesselParametersTable(handleAction)}
        dataSource={tableContents}
        size='small'
        bordered='true'
        scroll={{ x: true }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <FormModal
        title={selectedVessel.vessel_name}
        isOpen={isVesselModalOpen}
        setIsOpen={setIsVesselModalOpen}
        form={form}
        formLayout='horizontal'
        formName='vessel_setup_first_form'
        initialState={selectedVesselSetup}
        isLoading={isLoading}
        props={{ width: '80vw' }}
        onSave={async () => {
          if (hasChanges) {
            try {
              await form.validateFields();
              setIsConfirmationModalOpen(true);
            } catch (e) {}
          } else {
            openNotification(
              INFO_NOTIFICATION_TYPE,
              'Vessel setup',
              'No changes made.'
            );
            setIsVesselModalOpen(false);
          }
        }}
        onCancel={() => {
          if (hasChanges) {
            setIsUnsavedModalOpen(true);
          } else {
            form.resetFields();
            setIsVesselModalOpen(false);
            setHasChanges(false);
          }
        }}
      >
        <Segmented
          options={tabOptions}
          value={vesselSetupTab}
          block
          onChange={setVesselSetupTab}
          className='mb-3'
        />
        {vesselSetupTab === 'Allotment Parameters' ? (
          <React.Fragment>
            <Row>
              {getVesselSetupFirst(
                isLoading,
                selectedVesselSetup,
                setSelectedVesselSetup,
                setHasChanges
              ).map((data, index) => (
                <Col key={index} xs={24} sm={8} className='pe-2'>
                  <FormInput
                    type={data.type}
                    name={data.name}
                    placeholder={data.placeholder}
                    label={data.label}
                    autoFocus={data.autofocus}
                    value={data.value}
                    onChange={data.setValue}
                    required={data.required}
                    disabled={data.disabled}
                    dropdownOptions={data.dropdownOptions}
                  />
                </Col>
              ))}
            </Row>
            <Divider className='my-2' />
            {getVesselSetupSecond(
              isLoading,
              selectedVesselSetup,
              setSelectedVesselSetup,
              setHasChanges
            ).map((data, index) => (
              <FormInput
                key={index}
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            ))}
            <Divider className='my-2' />
            <Row>
              {getVesselSetupThird(
                isLoading,
                selectedVesselSetup,
                setSelectedVesselSetup,
                unionDuesOptions,
                setHasChanges
              ).map((data, index) => (
                <Col key={index} xs={24} sm={12} className='pe-2'>
                  <FormInput
                    type={data.type}
                    name={data.name}
                    placeholder={data.placeholder}
                    label={data.label}
                    autoFocus={data.autofocus}
                    value={data.value}
                    onChange={data.setValue}
                    required={data.required}
                    disabled={data.disabled}
                    dropdownOptions={data.dropdownOptions}
                    props={data.props}
                  />
                </Col>
              ))}
            </Row>
            <Divider className='my-2' />
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                {getVesselSetupFourth(
                  isLoading,
                  selectedVesselSetup,
                  setSelectedVesselSetup,
                  setHasChanges
                ).map((data, index) => (
                  <FormInput
                    key={index}
                    type={data.type}
                    name={data.name}
                    placeholder={data.placeholder}
                    label={data.label}
                    autoFocus={data.autofocus}
                    value={data.value}
                    onChange={data.setValue}
                    required={data.required}
                    disabled={data.disabled}
                    dropdownOptions={data.dropdownOptions}
                    props={data.props}
                  />
                ))}
              </Col>
              <Col xs={24} sm={12}>
                {getVesselSetupFifth(
                  isLoading,
                  selectedVesselSetup,
                  setSelectedVesselSetup,
                  setHasChanges
                ).map((data, index) => (
                  <FormInput
                    key={index}
                    type={data.type}
                    name={data.name}
                    placeholder={data.placeholder}
                    label={data.label}
                    autoFocus={data.autofocus}
                    value={data.value}
                    onChange={data.setValue}
                    required={data.required}
                    disabled={data.disabled}
                    dropdownOptions={data.dropdownOptions}
                    props={data.props}
                  />
                ))}
              </Col>
            </Row>
            <Divider className='my-2' />
          </React.Fragment>
        ) : (
          <Row>
            <Col xs={24} sm={12}>
              {getVesselSetupAmosup(
                isLoading,
                selectedVesselSetup,
                setSelectedVesselSetup,
                setHasChanges
              ).map((data, index) => (
                <FormInput
                  key={index}
                  type={data.type}
                  name={data.name}
                  placeholder={data.placeholder}
                  label={data.label}
                  autoFocus={data.autofocus}
                  value={data.value}
                  onChange={data.setValue}
                  required={data.required}
                  disabled={data.disabled}
                  dropdownOptions={data.dropdownOptions}
                  props={data.props}
                />
              ))}
            </Col>
          </Row>
        )}
      </FormModal>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          const payload = {
            ...selectedVesselSetup,
            regular_allotment: selectedVesselSetup.regular_allotment
              ? parseFloat(selectedVesselSetup.regular_allotment)
              : 0,
            effectivity_date: dayjs(
              selectedVesselSetup.effectivity_date
            ).format('YYYY-MM-DD'),
          };
          const { data: res, error } = await postModifyVesselSetup(
            selectedVesselSetup.id,
            payload
          );
          if (error) {
            openNotification(
              ERROR_NOTIFICATION_TYPE,
              'Vessel setup',
              'Error saving setup!'
            );
          } else {
            openNotification(
              SUCCESS_NOTIFICATION_TYPE,
              'Vessel setup',
              'Set up saved!'
            );
            setHasChanges(false);
            setIsVesselModalOpen(false);
          }
          setIsConfirmationModalOpen(false);
          setIsLoading(false);
        }}
      />
      <ConfirmationModal
        title='You have unsaved changes! Confirming will lose all your changes. Confirm?'
        cancelText='Continue editing'
        isLoading={isLoading}
        isOpen={isUnsavedModalOpen}
        setIsOpen={setIsUnsavedModalOpen}
        onConfirm={() => {
          form.resetFields();
          setSelectedVessel(VesselType);
          setIsUnsavedModalOpen(false);
          setIsVesselModalOpen(false);
          setHasChanges(false);
        }}
      />
    </Content>
  );
}
