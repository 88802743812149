import {
  Avatar,
  Layout,
  Menu,
  Row,
  Space,
  Switch,
  Typography,
  theme,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../utils/context/UserContext';
import { GlobalContext } from '../../App';
import { LOG_OUT_ROUTE } from '../../utils/router/routes';
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { items } from './NavItems';
import avatarImage from '../../assets/icon.png';
import { useIdleTimer } from 'react-idle-timer';
import { WARNING_NOTIFICATION_TYPE } from '../../utils/api/_constants';

const { Sider, Footer, Content } = Layout;

export default function Navigation({ children }) {
  const { profile, validateAccessToFeature } = useContext(UserContext);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const currentLocation = useLocation();

  const { setIsLoggedIn, encryptValue, decryptValue, openNotification } =
    useContext(GlobalContext);

  const [collapsed, setCollapsed] = useState(false);
  const [siderTheme, setSiderTheme] = useState('dark');

  const cookiesSettings = {
    expires: new Date().getTime() + 2 * 60 * 60 * 1000,
    secure: true,
    sameSite: 'strict',
  };

  const logoutUser = () => {
    // log user out
    Cookies.remove('username');
    Cookies.remove('password');
    Cookies.remove('is-logged-in');
    Cookies.remove('location');
    setIsLoggedIn(false);
  };

  const onIdle = () => {
    openNotification(
      WARNING_NOTIFICATION_TYPE,
      'Session timeout.',
      'You are idle for 1 hour.',
      18000
    );
    logoutUser();
  };

  const { getRemaingTime } = useIdleTimer({
    onIdle,
    timeout: 1 * 60 * 60 * 1000,
    throttle: 500,
  });

  useEffect(() => {
    Cookies.set(
      'location',
      encryptValue(currentLocation.pathname),
      cookiesSettings
    );
  }, [currentLocation]);

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        theme={siderTheme}
      >
        {/* TODO */}
        <Row align={'middle'} justify={'space-evenly'} className='my-3'>
          <Avatar
            src={avatarImage}
            style={{ verticalAlign: 'middle' }}
            size='large'
          />
          {!collapsed && (
            <Space>
              <Typography.Text
                style={{ color: siderTheme === 'dark' ? 'white' : 'black' }}
              >
                Hi, {profile.user_name}
              </Typography.Text>
              <Switch
                checked={siderTheme === 'dark'}
                onChange={(checked) =>
                  setSiderTheme(checked ? 'dark' : 'light')
                }
                checkedChildren='Dark'
                unCheckedChildren='Light'
              />
            </Space>
          )}
        </Row>
        <Menu
          style={{ fontSize: '11px' }}
          onClick={(e) => {
            if (e.key === LOG_OUT_ROUTE) {
              // log user out
              logoutUser();
            } else {
              navigate(e.key);
            }
          }}
          theme={siderTheme}
          defaultSelectedKeys={[currentLocation.pathname]}
          mode='inline'
          // items={items}
          items={items.map((rec) => ({
            ...rec,
            disabled: !validateAccessToFeature(rec?.access_key),
            children: rec?.children?.map((child) => ({
              ...child,
              disabled: !validateAccessToFeature(child?.access_key),
            })),
          }))}
        />
      </Sider>
      <Layout>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
        <Footer
          style={{
            paddingTop: 0,
            textAlign: 'center',
          }}
        >
          XGen Innovative Technologies Corp.
        </Footer>
      </Layout>
    </Layout>
  );
}
