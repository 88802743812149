import axios from 'axios';
import { BASE_ENDPOINT, REQUEST_HEADERS } from './_constants';

const GET_ALL_FINAL_PAY_ENDPOINT = BASE_ENDPOINT + '/show_all_final_pay';
const GET_PAYSLIP_DEDUCTION_ENDPOINT = BASE_ENDPOINT + '/show_deduction_entry';
const GET_COMPUTED_GOV_CONTRI_ENDPOINT = BASE_ENDPOINT + '/compute_gov_contri';
const POST_ADD_FINAL_PAY_HDR_ENDPOINT = BASE_ENDPOINT + '/add_final_pay_hdr';
const POST_ADD_FINAL_PAY_DETAILS_ENDPOINT =
  BASE_ENDPOINT + '/add_final_pay_details';
const POST_ADD_FINAL_PAY_WAGES_ENDPOINT =
  BASE_ENDPOINT + '/add_final_pay_wages';
const POST_ADD_FINAL_PAY_ADDWAGES_ENDPOINT =
  BASE_ENDPOINT + '/add_final_pay_addWages';
const POST_ADD_FINAL_PAY_GOV_ENDPOINT =
  BASE_ENDPOINT + '/add_final_pay_govContri';
const POST_SAVE_FINAL_PAY_ENDPOINT = BASE_ENDPOINT + '/save_final_pay';
const GET_SHOW_FINAL_PAY_PER_CREW = BASE_ENDPOINT + '/show_final_pay_per_crew';
const GET_POSTED_FINAL_PAY = BASE_ENDPOINT + '/get_posted_finalpay';
const POST_FINAL_PAY = BASE_ENDPOINT + '/post_finalpay';

export async function getAllFinalPay(month, year) {
  try {
    const { data } = await axios.get(
      `${GET_ALL_FINAL_PAY_ENDPOINT}/${month}/${year}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getFinalPayGovContri(month, year, crew_no) {
  try {
    const { data } = await axios.get(
      `${GET_PAYSLIP_DEDUCTION_ENDPOINT}/${month}/${year}/${crew_no}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postGetComputedGovContri(payload) {
  try {
    const { data } = await axios.post(
      GET_COMPUTED_GOV_CONTRI_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddFinalPayHeader(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_FINAL_PAY_HDR_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
export async function postAddFinalPayDetails(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_FINAL_PAY_DETAILS_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
export async function postAddFinalPayWages(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_FINAL_PAY_WAGES_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddFinalPayAddWages(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_FINAL_PAY_ADDWAGES_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddFinalPayGovContri(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_FINAL_PAY_GOV_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postSaveFinalPay(payload) {
  try {
    const { data } = await axios.post(POST_SAVE_FINAL_PAY_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getFinalPayPerCrew(month, year, crewno) {
  try {
    const { data } = await axios.get(
      `${GET_SHOW_FINAL_PAY_PER_CREW}/${month}/${year}/${crewno}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getPostedFinalPay(month, year) {
  try {
    const { data } = await axios.get(
      `${GET_POSTED_FINAL_PAY}/${month}/${year}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postFinalPay(payload) {
  try {
    const { data } = await axios.post(POST_FINAL_PAY, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
