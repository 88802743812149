export default {
  id: null,
  vessel_api_id: null,
  vessel_code: null,
  vessel_name: null,
  principal_code: null,
  principal_name: null,
  group: null,
  agency_name: null,
  is_deleted: null,
  delete_by: null,
  created_at: null,
  updated_at: null,
};
