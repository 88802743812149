import { Button, DatePicker, Input, Popconfirm, Space, Switch } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { AiFillDelete, AiFillEdit, AiFillPrinter } from 'react-icons/ai';
import { FaEye, FaWrench } from 'react-icons/fa';

export function getAccessLevelTable(handleAction) {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.user_id - b.user_id,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            type='primary'
            onClick={() => handleAction(record, 'edit')}
            icon={<FaWrench size={15} />}
          />
          <Popconfirm
            title='Delete level'
            description='Please confirm your action?'
            onConfirm={() => handleAction(record, 'delete')}
            okText='Yes'
            cancelText='No'
          >
            <Button danger icon={<AiFillDelete size={15} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
}
