import axios from 'axios';
import {
  BASE_ENDPOINT,
  DELETE_HOME_ALLOTMENT_ENDPOINT,
  DELETE_MPO_ENDPOINT,
  DELETE_SIDE_LETTER_ENDPOINT,
  GET_CREW_BY_VESSEL_ENDPOINT,
  GET_HOME_ALLOTMENT_PER_MONTH_ENDPOINT,
  GET_HOME_PROCESSED_PER_VESSEL_ENDPOINT,
  GET_MPO_PROCESSED_PER_VESSEL_ENDPOINT,
  GET_PROCESSED_DEDUCTION_LEGENDS_ENDPOINT,
  GET_PROCESSED_DEDUCTION_LEGENDS_MPO_ENDPOINT,
  GET_PROCESSED_DEDUCTION_LEGENDS_SLB_ENDPOINT,
  GET_PROCESSED_HOME_ALLOTMENT_ENDPOINT,
  GET_PROCESSED_MPO_PER_MONTH_ENDPOINT,
  GET_PROCESSED_SLB_PER_MONTH_ENDPOINT,
  GET_PROCESS_ADJUSTMENT_PER_CREW_ENDOINT,
  GET_SLB_PROCESSED_PER_VESSEL_ENDPOINT,
  POST_PROCESS_ADJUSTMENT_PER_CREW_ENDPOINT,
  POST_PROCESS_HOME_ALLOTMENT_V2_ENDPOINT,
  POST_PROCESS_MPO_ENDPOINT,
  POST_PROCESS_SLB_ENDPOINT,
  POST_UPDATE_SALARY_BREAKDOWN_ENDPOINT,
  REQUEST_HEADERS,
} from './_constants';

const POST_HOME_ALLOTMENT_CLOSE_ALLOTMENT_ENDPOINT =
  BASE_ENDPOINT + '/post_home_allotment';
const POST_SIDE_LETTER_CLOSE_ALLOTMENT_ENDPOINT =
  BASE_ENDPOINT + '/post_slb_allotment';
const POST_MPO_CLOSE_ALLOTMENT_ENDPOINT = BASE_ENDPOINT + '/post_mpo_allotment';
const POST_CREW_WAGES_CLOSE_ALLOTMENT_ENDPOINT =
  BASE_ENDPOINT + '/crew_wages_post';
const GET_ALL_POSTED_VESSEL_ENDPOINT = BASE_ENDPOINT + '/get_all_posted_vessel';

export async function getProcessedHomeAllotmentPerMonth(month, year) {
  try {
    const { data } = await axios.get(
      `${GET_PROCESSED_HOME_ALLOTMENT_ENDPOINT}/${month}/${year}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postProcessHomeAllotment(payload) {
  try {
    const { data } = await axios.post(
      POST_PROCESS_HOME_ALLOTMENT_V2_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getProcessHomeAllotmentPerMonth(month, year, vescode) {
  try {
    const { data } = await axios.get(
      `${GET_HOME_ALLOTMENT_PER_MONTH_ENDPOINT}/${month}/${year}/${vescode}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getProcessedDeductionLegends(month, year, vescode) {
  try {
    const { data } = await axios.get(
      `${GET_PROCESSED_DEDUCTION_LEGENDS_ENDPOINT}/${month}/${year}/${vescode}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getProcessedDeductionLegendsMpo(month, year, vescode) {
  try {
    const { data } = await axios.get(
      `${GET_PROCESSED_DEDUCTION_LEGENDS_MPO_ENDPOINT}/${month}/${year}/${vescode}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getProcessedDeductionLegendsSlb(month, year, vescode) {
  try {
    const { data } = await axios.get(
      `${GET_PROCESSED_DEDUCTION_LEGENDS_SLB_ENDPOINT}/${month}/${year}/${vescode}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getProcessedHomeAllotmentPerVessel(month, year, vescode) {
  try {
    const { data } = await axios.get(
      `${GET_HOME_PROCESSED_PER_VESSEL_ENDPOINT}/${month}/${year}/${vescode}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getCrewByVessel(payload) {
  try {
    const { data } = await axios.post(GET_CREW_BY_VESSEL_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postSalaryBreakdownHeader(payload) {
  try {
    const { data } = await axios.post(
      POST_UPDATE_SALARY_BREAKDOWN_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getProcessAdjustmentEndpoint(crewNo) {
  try {
    const { data } = await axios.get(
      `${GET_PROCESS_ADJUSTMENT_PER_CREW_ENDOINT}/${crewNo}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postProcessAdjustmentPerCrewEndpoint(payload) {
  try {
    const { data } = await axios.post(
      POST_PROCESS_ADJUSTMENT_PER_CREW_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postProcessMpoCompute(payload) {
  try {
    const { data } = await axios.post(POST_PROCESS_MPO_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getProcessedMpoPerMonth(month, year) {
  try {
    const { data } = await axios.get(
      `${GET_PROCESSED_MPO_PER_MONTH_ENDPOINT}/${month}/${year}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getProcessedMpoPerVessel(month, year, vessel) {
  try {
    const { data } = await axios.get(
      `${GET_MPO_PROCESSED_PER_VESSEL_ENDPOINT}/${month}/${year}/${vessel}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postProcessSlbCompute(payload) {
  try {
    const { data } = await axios.post(POST_PROCESS_SLB_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getProcessedSlbperMonth(month, year) {
  try {
    const { data } = await axios.get(
      `${GET_PROCESSED_SLB_PER_MONTH_ENDPOINT}/${month}/${year}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getProcessedSlbPerVessel(month, year, vessel) {
  try {
    const { data } = await axios.get(
      `${GET_SLB_PROCESSED_PER_VESSEL_ENDPOINT}/${month}/${year}/${vessel}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeleteProcessHomeAllotment(payload) {
  try {
    const { data } = await axios.post(DELETE_HOME_ALLOTMENT_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeleteProcessMPO(payload) {
  try {
    const { data } = await axios.post(DELETE_MPO_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeleteProcessSideLetter(payload) {
  try {
    const { data } = await axios.post(DELETE_SIDE_LETTER_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postHomeAllotmentCloseProcessAllotment(payload) {
  try {
    const { data } = await axios.post(
      POST_HOME_ALLOTMENT_CLOSE_ALLOTMENT_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postSLBCloseProcessAllotment(payload) {
  try {
    const { data } = await axios.post(
      POST_SIDE_LETTER_CLOSE_ALLOTMENT_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postMPOCloseProcessAllotment(payload) {
  try {
    const { data } = await axios.post(
      POST_MPO_CLOSE_ALLOTMENT_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postCrewWagesCloseProcessAllotment(payload) {
  try {
    const { data } = await axios.post(
      POST_CREW_WAGES_CLOSE_ALLOTMENT_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getAllPostedVessel(month, year) {
  try {
    const { data } = await axios.get(
      `${GET_ALL_POSTED_VESSEL_ENDPOINT}/${month}/${year}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
