import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  HOME_ROUTE,
  MAINTENANCE_CREW_WAGES_ROUTE,
  MAINTENANCE_DEFINE_VARIOUS_TABLES_ROUTE,
  MAINTENANCE_EXCHANGE_RATE_PER_VESSEL_ROUTE,
  MAINTENANCE_GOVT_CONTRIBUTION_ENTRY_ROUTE,
  MAINTENANCE_VESSEL_PARAMETER_SETUP_ROUTE,
  REPORT_BANK_DOWNLOADING_ROUTE,
  REPORT_PAYSLIP_PER_CREW_ROUTE,
  REPORT_PAYSLIP_PER_VESSEL_ROUTE,
  SYSTEM_SETTINGS_ACCESS_LEVEL_SETUP,
  SYSTEM_SETTINGS_USER_SETUP,
  TRANSACTION_FINAL_PAY_ROUTE,
  TRANSACTION_PAYROLL_PROCESS_ROUTE,
} from './routes';
import Navigation from '../../components/Navigation/Navigation';
import Dashboard from '../../pages/Dashboard/Dashboard';
import VesselParametersSetupPage from '../../pages/Maintenance/VesselParametersSetupPage';
import DefineVariousTablePage from '../../pages/Maintenance/DefineVariousTablePage';
import PayrollProcessPage from '../../pages/Transaction/PayrollProcessPage';
import GovContributionPage from '../../pages/Maintenance/GovContributionPage';
import VesselExchangeRatePage from '../../pages/Maintenance/VesselExchangeRatePage';
import CrewWagesMaintenancePage from '../../pages/Maintenance/CrewWagesMaintenancePage';
import ReportPayslipPerCrewPage from '../../pages/Reports/Payslip/ReportPayslipPerCrewPage';
import ReportPayslipPerVesselPage from '../../pages/Reports/Payslip/ReportPayslipPerVesselPage';
import UserSetupSettingsPage from '../../pages/SystemSettings/UserSetupSettingsPage';
import AccessLevelSetupPage from '../../pages/SystemSettings/AccessLevelSetupPage';
import { UserContext } from '../context/UserContext';
import FinalPayPage from '../../pages/Transaction/FinalPay/FinalPayPage';
import AccessLevelSetupv2Page from '../../pages/SystemSettings/AccessLevelSetupv2Page';
import ReportBankDownloadingPage from '../../pages/Reports/Payslip/ReportBankDownloadingPage';

export default function MainRoute() {
  const { validateAccessToFeature } = useContext(UserContext);

  return (
    <Navigation>
      <Routes>
        <Route path={HOME_ROUTE} element={<Dashboard />}></Route>
        {/* TRANSACTION */}
        {validateAccessToFeature('tran_payroll_process') && (
          <Route
            path={TRANSACTION_PAYROLL_PROCESS_ROUTE}
            element={<PayrollProcessPage />}
          ></Route>
        )}
        {validateAccessToFeature('tran_payroll_process') && (
          <Route
            path={TRANSACTION_FINAL_PAY_ROUTE}
            element={<FinalPayPage />}
          ></Route>
        )}
        {/* MAINTENANCE */}
        {validateAccessToFeature('maintenance_vessel_parameters') && (
          <Route
            path={MAINTENANCE_VESSEL_PARAMETER_SETUP_ROUTE}
            element={<VesselParametersSetupPage />}
          ></Route>
        )}
        {validateAccessToFeature('maintenance_dvt') && (
          <Route
            path={MAINTENANCE_DEFINE_VARIOUS_TABLES_ROUTE}
            element={<DefineVariousTablePage />}
          ></Route>
        )}
        {validateAccessToFeature('maintenance_govt_contri') && (
          <Route
            path={MAINTENANCE_GOVT_CONTRIBUTION_ENTRY_ROUTE}
            element={<GovContributionPage />}
          ></Route>
        )}
        {validateAccessToFeature('maintenance_exchange_rate') && (
          <Route
            path={MAINTENANCE_EXCHANGE_RATE_PER_VESSEL_ROUTE}
            element={<VesselExchangeRatePage />}
          ></Route>
        )}
        {validateAccessToFeature('maintenance_crew_wages') && (
          <Route
            path={MAINTENANCE_CREW_WAGES_ROUTE}
            element={<CrewWagesMaintenancePage />}
          ></Route>
        )}

        {/* REPORTS */}
        {validateAccessToFeature('reports_crew_payslips') && (
          <Route
            path={REPORT_PAYSLIP_PER_CREW_ROUTE}
            element={<ReportPayslipPerCrewPage />}
          ></Route>
        )}
        {validateAccessToFeature('reports_vessel_payslips') && (
          <Route
            path={REPORT_PAYSLIP_PER_VESSEL_ROUTE}
            element={<ReportPayslipPerVesselPage />}
          ></Route>
        )}
        {validateAccessToFeature('reports_bank_downloading') && (
          <Route
            path={REPORT_BANK_DOWNLOADING_ROUTE}
            element={<ReportBankDownloadingPage />}
          ></Route>
        )}

        {/* SYSTEM SETTINGS */}
        {validateAccessToFeature('system_setup_user_setup') && (
          <Route
            path={SYSTEM_SETTINGS_USER_SETUP}
            element={<UserSetupSettingsPage />}
          ></Route>
        )}
        {validateAccessToFeature('system_setup_access_level_setup') && (
          <Route
            path={SYSTEM_SETTINGS_ACCESS_LEVEL_SETUP}
            element={<AccessLevelSetupv2Page />}
          ></Route>
        )}

        <Route path='*' element={<Navigate to='/' />}></Route>
      </Routes>
    </Navigation>
  );
}
