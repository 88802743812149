export const HOME_ROUTE = '/';

export const LOG_OUT_ROUTE = '/log-user-out';

// TRANSACTION MODULE
export const TRANSACTION_CREW_MASTERFILE_ROUTE = 'transaction-crew-masterfile';
export const TRANSACTION_PAYROLL_PROCESS_ROUTE = 'transaction-payroll-process';
export const TRANSACTION_FINAL_PAY_ROUTE = 'transaction-final-pay';

// REPORT MODULE
export const REPORT_PAYSLIP_PER_CREW_ROUTE = 'report-payslip-per-crew';
export const REPORT_PAYSLIP_PER_VESSEL_ROUTE = 'report-payslip-per-vessel';
export const REPORT_BANK_DOWNLOADING_ROUTE = 'report-bank-downloading';
export const REPORT_FINAL_PAY_ROUTE = 'report-final-pay';

// MAINTENANCE MODULE
export const MAINTENANCE_VESSEL_PARAMETER_SETUP_ROUTE =
  'maintenance-vessel-parameter-setup';
export const MAINTENANCE_DEFINE_VARIOUS_TABLES_ROUTE =
  'maintenance-define-various-tables';
export const MAINTENANCE_GOVT_CONTRIBUTION_ENTRY_ROUTE =
  'maintenance-govt-contrib-entry';
export const MAINTENANCE_EXCHANGE_RATE_PER_VESSEL_ROUTE =
  'maintenance-exchange-rate-per-vessel';
export const MAINTENANCE_CHANGE_ACCOUNT_NUMBER_ROUTE =
  'maintenance-change-account-number';
export const MAINTENANCE_CREW_WAGES_ROUTE = 'maintenance-crew-wages';

// SYSTEM SETTINGS
export const SYSTEM_SETTINGS_USER_SETUP = 'system-settings-user-setup';
export const SYSTEM_SETTINGS_ACCESS_LEVEL_SETUP =
  'system-settings-access-level-setup';
