import axios from 'axios';
import {
  DELETE_UNION_DUES_ENDPOINT,
  POST_ADD_UNION_DUES_ENDPOINT,
  POST_MODIFY_UNION_DUES_ENDPOINT,
  REQUEST_HEADERS,
  SHOW_ALL_UNION_DUES_ENDPOINT,
} from './_constants';

export async function getAllUnionDues() {
  try {
    const { data } = await axios.get(SHOW_ALL_UNION_DUES_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function addUnionDues(payload) {
  try {
    const { data } = await axios.post(POST_ADD_UNION_DUES_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postModifyUnionDues(payload) {
  try {
    const { data } = await axios.post(
      `${POST_MODIFY_UNION_DUES_ENDPOINT}/${payload.id}`,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeleteUnionDues(payload) {
  try {
    const { data } = await axios.post(
      `${DELETE_UNION_DUES_ENDPOINT}/${payload.id}`,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
