import dayjs from 'dayjs';
import UserSetupType from '../types/UserSetupType';
import FinalPayType from '../types/FinalPayType';

export const getFinalPayFields = (
  isLoading,
  state = FinalPayType,
  setState,
  setHasChanges,
  inputToLocale,
  crewListOptions,
  exchangeRate
) => {
  const renderCrewInput = () => {
    return state.id == null
      ? {
          type: 'searchable-dropdown',
          name: 'crew_no',
          placeholder: 'Crew name',
          label: 'Crew name',
          autofocus: false,
          required: true,
          hidden: false,
          disabled: isLoading,
          value: state.crew_no,
          dropdownOptions: crewListOptions?.map((item, index) => ({
            key: index,
            id: item.crew_no,
            description: `${item.last_name}, ${item.first_name} ${item.middle_name}`,
          })),
          setValue: (value, option) => {
            if (value) {
              const selectedCrew = crewListOptions?.find(
                (rec) => rec.crew_no == value
              );

              setState({
                ...FinalPayType,
                crew_no: value,
                crew_name: option.children,
                vescode: selectedCrew.vessel_id,
                vesname: selectedCrew.vessel_name,
                position_name: selectedCrew.position_name,
                position_code: selectedCrew.position_id,
                sign_on: selectedCrew.sign_on_date,
                sign_off: selectedCrew.sign_off_date
                  ? dayjs(selectedCrew.sign_off_date, 'YYYY-MM-DD')
                  : null,
              });
            } else {
              setState(FinalPayType);
            }

            setHasChanges(true);
          },
        }
      : {
          type: 'text',
          name: 'crew_name',
          placeholder: '',
          label: 'Crew name',
          autofocus: false,
          required: false,
          disabled: true,
          value: state.crew_name,
          setValue: (e) => {
            const value = e.target.value;
            setState({ ...state, crew_name: value });
            setHasChanges(true);
          },
        };
  };

  return [
    renderCrewInput(),
    {
      type: 'text',
      name: 'crew_no',
      placeholder: '',
      label: 'Crew no',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.crew_no,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, crew_no: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'vesname',
      placeholder: 'Vessel',
      label: 'Vessel name',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.vesname,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, vesname: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'position_name',
      placeholder: 'Position',
      label: 'Position',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.position_name,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, position_name: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'sign_on',
      placeholder: 'YYYY-MM-DD',
      label: 'Sign on',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.sign_on,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, sign_on: value });
        setHasChanges(true);
      },
    },
    {
      type: 'date',
      name: 'sign_off',
      placeholder: 'YYYY-MM-DD',
      label: 'Sign off',
      autofocus: false,
      required: true,
      disabled: isLoading || state.crew_no == null,
      value: state.sign_off,
      setValue: (_, dateString) => {
        if (dateString) {
          const date = dayjs(dateString, 'YYYY-MM-DD');
          setState({ ...state, sign_off: date });
        } else {
          setState({ ...state, sign_off: null });
        }
        setHasChanges(true);
      },
    },
  ];
};
