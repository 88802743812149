import { Button, Input, Popconfirm, Space, Switch } from 'antd';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';
import PayrollOtherDeductionType from '../../types/PayrollOtherDeductionType';
import AllotteeType from '../../types/AllotteeType';

export function getPayrollOtherDeductionsTable(handleAction) {
  return [
    {
      title: 'Crew name',
      dataIndex: 'crew_name',
      key: 'crew_name',
      fixed: 'left',
    },
    // {
    //   title: 'Code',
    //   dataIndex: 'od_code',
    //   key: 'od_code',
    //   fixed: 'left',
    // },
    // {
    //   title: 'Deductions',
    //   dataIndex: 'od_desc',
    //   key: 'od_desc',
    // },

    {
      title: 'Date Start',
      dataIndex: 'startdeduct',
      key: 'startdeduct',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Amount to be Deducted',
      dataIndex: 'amounttoded',
      key: 'amounttoded',
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => handleAction('edit', record)}
            icon={<FaWrench size={15} />}
          />
          <Popconfirm
            title="Delete record"
            description="Are you sure to delete this record?"
            onConfirm={() => handleAction('delete', record)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger icon={<AiFillDelete size={15} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
}
