export default {
  id: null,
  crew_no: null,
  allt_on_board_crew_id: null,
  allottee_id: null,
  allottee_name: null,
  date_requested: null,
  sideletter_amount: null,
  deduct_on_leave_final: null,
  apply: null,
  is_deleted: null,
  delete_by: null,
  created_at: null,
  updated_at: null,
};
