import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import React, { useContext } from 'react';
import logoImage from '../../assets/icon-name.png';
import dayjs from 'dayjs';
import { UserContext } from '../../utils/context/UserContext';

const styles = StyleSheet.create({
  headerImage: {
    height: 80,
    width: 80,
  },
  headerText: {
    fontSize: 9,
  },

  container: { flexDirection: 'row', margin: 0, padding: 0 },
  row: { flexDirection: 'row', margin: 0, padding: 0 },
  column: {
    flex: 1,
    margin: 5,
    marginRight: 0,
  },
});

export default function PayslipPerVesselPDF({ vesselRecords }) {
  const { inputToLocale } = useContext(UserContext);

  const calculateTotalDeductions = (records) => {
    if (!records) {
      return 0;
    }
    const totalDeductions = records.reduce((acc, obj) => {
      if (obj?.amount) {
        let amount = obj?.amount;
        if (typeof obj?.amount === 'string') {
          amount = obj?.amount.replace(',', '');
        }
        return acc + parseFloat(amount);
      }
      return acc + 0;
    }, 0);
    return totalDeductions;
  };

  return (
    <PDFViewer style={{ width: '98%', height: '100vh' }}>
      <Document>
        {vesselRecords.map((crewRecord) => (
          <>
            {crewRecord?.home_allotment?.original?.map((allotteeRecord) => (
              <>
                {allotteeRecord.allottee.map((allottee, index) => (
                  <Page
                    key={index}
                    size={{ width: 297.64, height: 420.94 }}
                    wrap
                  >
                    <View
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        alignItems: 'center',
                        marginBottom: 10,
                      }}
                      fixed
                    >
                      <Image src={logoImage} style={styles.headerImage} />

                      <Text style={styles.headerText}>PAYROLL SLIP</Text>
                      <Text style={styles.headerText}>REGULAR ALLOTMENT</Text>
                      <Text style={styles.headerText}>
                        For the month of{' '}
                        {dayjs(
                          `${allotteeRecord.month}-${allotteeRecord.year}`,
                          'M-YYYY'
                        ).format('MMMM YYYY')}
                      </Text>
                      <Text style={{ ...styles.headerText, marginTop: 10 }}>
                        {allotteeRecord.crew_name}
                      </Text>
                      <Text style={styles.headerText}>
                        {allotteeRecord.vesname}
                      </Text>
                    </View>
                    <View style={{ marginTop: 10, marginLeft: 30 }}>
                      <Text style={styles.headerText}>{allottee.allottee}</Text>
                      <Text style={{ ...styles.headerText, marginTop: 15 }}>
                        {allottee.bank_code} {allottee.bank_branch}
                      </Text>
                      <Text style={{ ...styles.headerText }}>
                        Account no. : {allottee.account_no}
                      </Text>
                    </View>
                    <View
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        alignItems: 'center',
                        marginVertical: 5,
                        height: 1,
                        width: '90%',
                        backgroundColor: 'black',
                      }}
                    />
                    <View style={{ ...styles.row, marginHorizontal: 20 }}>
                      <View style={{ ...styles.column }}>
                        <Text style={styles.headerText}>
                          Allotment USD = {inputToLocale(allottee.allot_usd)}
                        </Text>
                      </View>
                      <View style={{ ...styles.column }}>
                        <Text style={styles.headerText}>
                          Exchange Rate: {inputToLocale(allotteeRecord.exrate)}
                        </Text>
                      </View>
                    </View>
                    <View style={{ ...styles.row, marginHorizontal: 20 }}>
                      <View style={{ ...styles.column }}>
                        <Text style={styles.headerText}>
                          Allotment Peso = {inputToLocale(allottee.total_peso)}
                        </Text>
                      </View>
                    </View>
                    <Text style={{ ...styles.headerText, marginLeft: 25 }}>
                      Less: (Deductions)
                    </Text>
                    {allottee.gov_ud?.map((deductions, dIndex) => (
                      <>
                        {parseFloat(deductions.amount) > 0 && (
                          <View
                            key={dIndex}
                            style={{
                              ...styles.row,
                              marginHorizontal: 20,
                              marginLeft: 30,
                              marginVertical: 0,
                            }}
                          >
                            <View
                              style={{ ...styles.column, marginVertical: 2 }}
                            >
                              <Text style={{ fontSize: 7 }}>
                                {deductions.desc}
                              </Text>
                            </View>
                            <View
                              style={{
                                ...styles.column,
                                marginVertical: 0,
                                alignItems: 'flex-end',
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 7,
                                }}
                              >
                                {inputToLocale(deductions.amount)}
                              </Text>
                            </View>
                          </View>
                        )}
                      </>
                    ))}
                    {allottee.other_deductions?.length > 0 && (
                      <>
                        {allottee.other_deductions?.map(
                          (deductions, dIndex) => (
                            <>
                              {parseFloat(deductions.amount) > 0 && (
                                <View
                                  key={dIndex}
                                  style={{
                                    ...styles.row,
                                    marginHorizontal: 20,
                                    marginLeft: 30,
                                    marginVertical: 0,
                                  }}
                                >
                                  <View
                                    style={{
                                      ...styles.column,
                                      marginVertical: 2,
                                    }}
                                  >
                                    <Text style={{ fontSize: 7 }}>
                                      {deductions.desc}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      ...styles.column,
                                      marginVertical: 0,
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                      }}
                                    >
                                      {inputToLocale(deductions.amount)}
                                    </Text>
                                  </View>
                                </View>
                              )}
                            </>
                          )
                        )}
                      </>
                    )}
                    {parseFloat(
                      calculateTotalDeductions(allottee.gov_ud) +
                        calculateTotalDeductions(allottee.other_deductions)
                    ) > 0 && (
                      <View
                        style={{
                          ...styles.row,
                          marginHorizontal: 20,
                        }}
                      >
                        <View style={{ ...styles.column, marginVertical: 2 }}>
                          <Text style={styles.headerText}>
                            Total deductions
                          </Text>
                        </View>
                        <View
                          style={{
                            ...styles.column,
                            marginVertical: 0,
                            alignItems: 'flex-end',
                          }}
                        >
                          <View
                            style={{
                              borderBottomWidth: 1,
                              borderTopWidth: 1,
                            }}
                          >
                            <Text
                              style={{
                                ...styles.headerText,
                                paddingVertical: 2,
                              }}
                            >
                              P {'       '}
                              {inputToLocale(
                                calculateTotalDeductions(allottee.gov_ud) +
                                  calculateTotalDeductions(
                                    allottee.other_deductions
                                  )
                              )}
                            </Text>
                          </View>
                        </View>
                      </View>
                    )}
                    <View
                      style={{
                        ...styles.row,
                        marginTop: 10,
                        marginHorizontal: 20,
                      }}
                    >
                      <View style={{ ...styles.column, marginVertical: 2 }}>
                        <Text
                          style={styles.headerText}
                        >{`NET AMOUNT  ----------->>>>`}</Text>
                      </View>
                      <View
                        style={{
                          ...styles.column,
                          marginVertical: 0,
                          alignItems: 'flex-end',
                        }}
                      >
                        <View
                          style={{ borderBottomWidth: 1, paddingBottom: 1 }}
                        >
                          <View style={{ borderBottomWidth: 1 }}>
                            <Text
                              style={{
                                ...styles.headerText,
                              }}
                            >
                              P {'       '}
                              {inputToLocale(allottee.total_net)}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        ...styles.row,
                        marginTop: 10,
                        marginHorizontal: 20,
                      }}
                      wrap={false}
                    >
                      <View style={styles.column}>
                        <Text style={{ fontSize: 8 }}>Prepared by:</Text>

                        <Text style={{ fontSize: 8 }}>{'\n\n\n'}Noted by:</Text>
                      </View>
                      <View style={styles.column}>
                        <Text style={{ fontSize: 8 }}>Checked by:</Text>
                      </View>
                    </View>
                  </Page>
                ))}
              </>
            ))}
            {crewRecord?.mpo?.original?.map((allotteeRecord) => (
              <>
                {allotteeRecord.allottee.map((allottee, index) => (
                  <Page
                    key={index}
                    size={{ width: 297.64, height: 420.94 }}
                    wrap
                  >
                    <View
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        alignItems: 'center',
                        marginBottom: 10,
                      }}
                      fixed
                    >
                      <Image src={logoImage} style={styles.headerImage} />

                      <Text style={styles.headerText}>PAYROLL SLIP</Text>
                      <Text style={styles.headerText}>SPECIAL ALLOTMENT</Text>
                      <Text style={styles.headerText}>
                        For the month of{' '}
                        {dayjs(
                          `${allotteeRecord.month}-${allotteeRecord.year}`,
                          'M-YYYY'
                        ).format('MMMM YYYY')}
                      </Text>
                      <Text style={{ ...styles.headerText, marginTop: 10 }}>
                        {allotteeRecord.crew_name}
                      </Text>
                      <Text style={styles.headerText}>
                        {allotteeRecord.vesname}
                      </Text>
                    </View>
                    <View style={{ marginTop: 10, marginLeft: 30 }}>
                      <Text style={styles.headerText}>{allottee.allottee}</Text>
                      <Text style={{ ...styles.headerText, marginTop: 15 }}>
                        {allottee.bank_code} {allottee.bank_branch}
                      </Text>
                      <Text style={{ ...styles.headerText }}>
                        Account no. : {allottee.account_no}
                      </Text>
                    </View>
                    <View
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        alignItems: 'center',
                        marginVertical: 5,
                        height: 1,
                        width: '90%',
                        backgroundColor: 'black',
                      }}
                    />
                    <View style={{ ...styles.row, marginHorizontal: 20 }}>
                      <View style={{ ...styles.column }}>
                        <Text style={styles.headerText}>
                          Allotment USD = {inputToLocale(allottee.allot_usd)}
                        </Text>
                      </View>
                      <View style={{ ...styles.column }}>
                        <Text style={styles.headerText}>
                          Exchange Rate: {inputToLocale(allotteeRecord.exrate)}
                        </Text>
                      </View>
                    </View>
                    <View style={{ ...styles.row, marginHorizontal: 20 }}>
                      <View style={{ ...styles.column }}>
                        <Text style={styles.headerText}>
                          Allotment Peso = {inputToLocale(allottee.total_peso)}
                        </Text>
                      </View>
                    </View>
                    <Text style={{ ...styles.headerText, marginLeft: 25 }}>
                      Less: (Deductions)
                    </Text>
                    {allottee.gov_ud?.map((deductions, dIndex) => (
                      <>
                        {parseFloat(deductions.amount) > 0 && (
                          <View
                            key={dIndex}
                            style={{
                              ...styles.row,
                              marginHorizontal: 20,
                              marginLeft: 30,
                              marginVertical: 0,
                            }}
                          >
                            <View
                              style={{ ...styles.column, marginVertical: 2 }}
                            >
                              <Text style={{ fontSize: 7 }}>
                                {deductions.desc}
                              </Text>
                            </View>
                            <View
                              style={{
                                ...styles.column,
                                marginVertical: 0,
                                alignItems: 'flex-end',
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 7,
                                }}
                              >
                                {inputToLocale(deductions.amount)}
                              </Text>
                            </View>
                          </View>
                        )}
                      </>
                    ))}
                    {allottee.other_deductions?.length > 0 && (
                      <>
                        {allottee.other_deductions?.map(
                          (deductions, dIndex) => (
                            <>
                              {parseFloat(deductions.amount) > 0 && (
                                <View
                                  key={dIndex}
                                  style={{
                                    ...styles.row,
                                    marginHorizontal: 20,
                                    marginLeft: 30,
                                    marginVertical: 0,
                                  }}
                                >
                                  <View
                                    style={{
                                      ...styles.column,
                                      marginVertical: 2,
                                    }}
                                  >
                                    <Text style={{ fontSize: 7 }}>
                                      {deductions.desc}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      ...styles.column,
                                      marginVertical: 0,
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                      }}
                                    >
                                      {inputToLocale(deductions.amount)}
                                    </Text>
                                  </View>
                                </View>
                              )}
                            </>
                          )
                        )}
                      </>
                    )}
                    {parseFloat(
                      calculateTotalDeductions(allottee.gov_ud) +
                        calculateTotalDeductions(allottee.other_deductions)
                    ) > 0 && (
                      <View
                        style={{
                          ...styles.row,
                          marginHorizontal: 20,
                        }}
                      >
                        <View style={{ ...styles.column, marginVertical: 2 }}>
                          <Text style={styles.headerText}>
                            Total deductions
                          </Text>
                        </View>
                        <View
                          style={{
                            ...styles.column,
                            marginVertical: 0,
                            alignItems: 'flex-end',
                          }}
                        >
                          <View
                            style={{
                              borderBottomWidth: 1,
                              borderTopWidth: 1,
                            }}
                          >
                            <Text
                              style={{
                                ...styles.headerText,
                                paddingVertical: 2,
                              }}
                            >
                              P {'       '}
                              {inputToLocale(
                                calculateTotalDeductions(allottee.gov_ud) +
                                  calculateTotalDeductions(
                                    allottee.other_deductions
                                  )
                              )}
                            </Text>
                          </View>
                        </View>
                      </View>
                    )}
                    <View
                      style={{
                        ...styles.row,
                        marginTop: 10,
                        marginHorizontal: 20,
                      }}
                    >
                      <View style={{ ...styles.column, marginVertical: 2 }}>
                        <Text
                          style={styles.headerText}
                        >{`NET AMOUNT  ----------->>>>`}</Text>
                      </View>
                      <View
                        style={{
                          ...styles.column,
                          marginVertical: 0,
                          alignItems: 'flex-end',
                        }}
                      >
                        <View
                          style={{ borderBottomWidth: 1, paddingBottom: 1 }}
                        >
                          <View style={{ borderBottomWidth: 1 }}>
                            <Text
                              style={{
                                ...styles.headerText,
                              }}
                            >
                              P {'       '}
                              {inputToLocale(allottee.total_net)}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        ...styles.row,
                        marginTop: 10,
                        marginHorizontal: 20,
                      }}
                      wrap={false}
                    >
                      <View style={styles.column}>
                        <Text style={{ fontSize: 8 }}>Prepared by:</Text>

                        <Text style={{ fontSize: 8 }}>{'\n\n\n'}Noted by:</Text>
                      </View>
                      <View style={styles.column}>
                        <Text style={{ fontSize: 8 }}>Checked by:</Text>
                      </View>
                    </View>
                  </Page>
                ))}
              </>
            ))}
            {crewRecord?.side_letter?.original?.map((allotteeRecord) => (
              <>
                {allotteeRecord.allottee.map((allottee, index) => (
                  <Page
                    key={index}
                    size={{ width: 297.64, height: 420.94 }}
                    wrap
                  >
                    <View
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        alignItems: 'center',
                        marginBottom: 10,
                      }}
                      fixed
                    >
                      <Image src={logoImage} style={styles.headerImage} />

                      <Text style={styles.headerText}>PAYROLL SLIP</Text>
                      <Text style={styles.headerText}>SIDE LETTER</Text>
                      <Text style={styles.headerText}>
                        For the month of{' '}
                        {dayjs(
                          `${allotteeRecord.month}-${allotteeRecord.year}`,
                          'M-YYYY'
                        ).format('MMMM YYYY')}
                      </Text>
                      <Text style={{ ...styles.headerText, marginTop: 10 }}>
                        {allotteeRecord.crew_name}
                      </Text>
                      <Text style={styles.headerText}>
                        {allotteeRecord.vesname}
                      </Text>
                    </View>
                    <View style={{ marginTop: 10, marginLeft: 30 }}>
                      <Text style={styles.headerText}>{allottee.allottee}</Text>
                      <Text style={{ ...styles.headerText, marginTop: 15 }}>
                        {allottee.bank_code} {allottee.bank_branch}
                      </Text>
                      <Text style={{ ...styles.headerText }}>
                        Account no. : {allottee.account_no}
                      </Text>
                    </View>
                    <View
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        alignItems: 'center',
                        marginVertical: 5,
                        height: 1,
                        width: '90%',
                        backgroundColor: 'black',
                      }}
                    />
                    <View style={{ ...styles.row, marginHorizontal: 20 }}>
                      <View style={{ ...styles.column }}>
                        <Text style={styles.headerText}>
                          Allotment USD = {inputToLocale(allottee.allot_usd)}
                        </Text>
                      </View>
                      <View style={{ ...styles.column }}>
                        <Text style={styles.headerText}>
                          Exchange Rate: {inputToLocale(allotteeRecord.exrate)}
                        </Text>
                      </View>
                    </View>
                    <View style={{ ...styles.row, marginHorizontal: 20 }}>
                      <View style={{ ...styles.column }}>
                        <Text style={styles.headerText}>
                          Allotment Peso = {inputToLocale(allottee.total_peso)}
                        </Text>
                      </View>
                    </View>
                    <Text style={{ ...styles.headerText, marginLeft: 25 }}>
                      Less: (Deductions)
                    </Text>
                    {allottee.gov_ud?.map((deductions, dIndex) => (
                      <>
                        {parseFloat(deductions.amount) > 0 && (
                          <View
                            key={dIndex}
                            style={{
                              ...styles.row,
                              marginHorizontal: 20,
                              marginLeft: 30,
                              marginVertical: 0,
                            }}
                          >
                            <View
                              style={{ ...styles.column, marginVertical: 2 }}
                            >
                              <Text style={{ fontSize: 7 }}>
                                {deductions.desc}
                              </Text>
                            </View>
                            <View
                              style={{
                                ...styles.column,
                                marginVertical: 0,
                                alignItems: 'flex-end',
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 7,
                                }}
                              >
                                {inputToLocale(deductions.amount)}
                              </Text>
                            </View>
                          </View>
                        )}
                      </>
                    ))}
                    {allottee.other_deductions?.length > 0 && (
                      <>
                        {allottee.other_deductions?.map(
                          (deductions, dIndex) => (
                            <>
                              {parseFloat(deductions.amount) > 0 && (
                                <View
                                  key={dIndex}
                                  style={{
                                    ...styles.row,
                                    marginHorizontal: 20,
                                    marginLeft: 30,
                                    marginVertical: 0,
                                  }}
                                >
                                  <View
                                    style={{
                                      ...styles.column,
                                      marginVertical: 2,
                                    }}
                                  >
                                    <Text style={{ fontSize: 7 }}>
                                      {deductions.desc}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      ...styles.column,
                                      marginVertical: 0,
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                      }}
                                    >
                                      {inputToLocale(deductions.amount)}
                                    </Text>
                                  </View>
                                </View>
                              )}
                            </>
                          )
                        )}
                      </>
                    )}
                    {parseFloat(
                      calculateTotalDeductions(allottee.gov_ud) +
                        calculateTotalDeductions(allottee.other_deductions)
                    ) > 0 && (
                      <View
                        style={{
                          ...styles.row,
                          marginHorizontal: 20,
                        }}
                      >
                        <View style={{ ...styles.column, marginVertical: 2 }}>
                          <Text style={styles.headerText}>
                            Total deductions
                          </Text>
                        </View>
                        <View
                          style={{
                            ...styles.column,
                            marginVertical: 0,
                            alignItems: 'flex-end',
                          }}
                        >
                          <View
                            style={{
                              borderBottomWidth: 1,
                              borderTopWidth: 1,
                            }}
                          >
                            <Text
                              style={{
                                ...styles.headerText,
                                paddingVertical: 2,
                              }}
                            >
                              P {'       '}
                              {inputToLocale(
                                calculateTotalDeductions(allottee.gov_ud) +
                                  calculateTotalDeductions(
                                    allottee.other_deductions
                                  )
                              )}
                            </Text>
                          </View>
                        </View>
                      </View>
                    )}
                    <View
                      style={{
                        ...styles.row,
                        marginTop: 10,
                        marginHorizontal: 20,
                      }}
                    >
                      <View style={{ ...styles.column, marginVertical: 2 }}>
                        <Text
                          style={styles.headerText}
                        >{`NET AMOUNT  ----------->>>>`}</Text>
                      </View>
                      <View
                        style={{
                          ...styles.column,
                          marginVertical: 0,
                          alignItems: 'flex-end',
                        }}
                      >
                        <View
                          style={{ borderBottomWidth: 1, paddingBottom: 1 }}
                        >
                          <View style={{ borderBottomWidth: 1 }}>
                            <Text
                              style={{
                                ...styles.headerText,
                              }}
                            >
                              P {'       '}
                              {inputToLocale(allottee.total_net)}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        ...styles.row,
                        marginTop: 10,
                        marginHorizontal: 20,
                      }}
                      wrap={false}
                    >
                      <View style={styles.column}>
                        <Text style={{ fontSize: 8 }}>Prepared by:</Text>

                        <Text style={{ fontSize: 8 }}>{'\n\n\n'}Noted by:</Text>
                      </View>
                      <View style={styles.column}>
                        <Text style={{ fontSize: 8 }}>Checked by:</Text>
                      </View>
                    </View>
                  </Page>
                ))}
              </>
            ))}
            {crewRecord?.rem_wages?.original?.map((allotteeRecord) => (
              <>
                {allotteeRecord.allottee.map((allottee, index) => (
                  <Page
                    key={index}
                    size={{ width: 297.64, height: 420.94 }}
                    wrap
                  >
                    <View
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        alignItems: 'center',
                        marginBottom: 10,
                      }}
                      fixed
                    >
                      <Image src={logoImage} style={styles.headerImage} />

                      <Text style={styles.headerText}>PAYROLL SLIP</Text>
                      <Text style={styles.headerText}>REMAINING WAGES</Text>
                      <Text style={styles.headerText}>
                        For the month of{' '}
                        {dayjs(
                          `${allotteeRecord.month}-${allotteeRecord.year}`,
                          'M-YYYY'
                        ).format('MMMM YYYY')}
                      </Text>
                      <Text style={{ ...styles.headerText, marginTop: 10 }}>
                        {allotteeRecord.crew_name}
                      </Text>
                      <Text style={styles.headerText}>
                        {allotteeRecord.vesname}
                      </Text>
                    </View>
                    <View style={{ marginTop: 10, marginLeft: 30 }}>
                      <Text style={styles.headerText}>{allottee.allottee}</Text>
                      <Text style={{ ...styles.headerText, marginTop: 15 }}>
                        {allottee.bank_code} {allottee.bank_branch}
                      </Text>
                      <Text style={{ ...styles.headerText }}>
                        Account no. : {allottee.account_no}
                      </Text>
                    </View>
                    <View
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        alignItems: 'center',
                        marginVertical: 5,
                        height: 1,
                        width: '90%',
                        backgroundColor: 'black',
                      }}
                    />
                    <View style={{ ...styles.row, marginHorizontal: 20 }}>
                      <View style={{ ...styles.column }}>
                        <Text style={styles.headerText}>
                          Allotment USD = {inputToLocale(allottee.allot_usd)}
                        </Text>
                      </View>
                      <View style={{ ...styles.column }}>
                        <Text style={styles.headerText}>
                          Exchange Rate: {inputToLocale(allotteeRecord.exrate)}
                        </Text>
                      </View>
                    </View>
                    <View style={{ ...styles.row, marginHorizontal: 20 }}>
                      <View style={{ ...styles.column }}>
                        <Text style={styles.headerText}>
                          Allotment Peso = {inputToLocale(allottee.total_peso)}
                        </Text>
                      </View>
                    </View>
                    <Text style={{ ...styles.headerText, marginLeft: 25 }}>
                      Less: (Deductions)
                    </Text>
                    {allottee.gov_ud?.map((deductions, dIndex) => (
                      <>
                        {parseFloat(deductions.amount) > 0 && (
                          <View
                            key={dIndex}
                            style={{
                              ...styles.row,
                              marginHorizontal: 20,
                              marginLeft: 30,
                              marginVertical: 0,
                            }}
                          >
                            <View
                              style={{ ...styles.column, marginVertical: 2 }}
                            >
                              <Text style={{ fontSize: 7 }}>
                                {deductions.desc}
                              </Text>
                            </View>
                            <View
                              style={{
                                ...styles.column,
                                marginVertical: 0,
                                alignItems: 'flex-end',
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 7,
                                }}
                              >
                                {inputToLocale(deductions.amount)}
                              </Text>
                            </View>
                          </View>
                        )}
                      </>
                    ))}
                    {allottee.other_deductions?.length > 0 && (
                      <>
                        {allottee.other_deductions?.map(
                          (deductions, dIndex) => (
                            <>
                              {parseFloat(deductions.amount) > 0 && (
                                <View
                                  key={dIndex}
                                  style={{
                                    ...styles.row,
                                    marginHorizontal: 20,
                                    marginLeft: 30,
                                    marginVertical: 0,
                                  }}
                                >
                                  <View
                                    style={{
                                      ...styles.column,
                                      marginVertical: 2,
                                    }}
                                  >
                                    <Text style={{ fontSize: 7 }}>
                                      {deductions.desc}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      ...styles.column,
                                      marginVertical: 0,
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                      }}
                                    >
                                      {inputToLocale(deductions.amount)}
                                    </Text>
                                  </View>
                                </View>
                              )}
                            </>
                          )
                        )}
                      </>
                    )}
                    {parseFloat(
                      calculateTotalDeductions(allottee.gov_ud) +
                        calculateTotalDeductions(allottee.other_deductions)
                    ) > 0 && (
                      <View
                        style={{
                          ...styles.row,
                          marginHorizontal: 20,
                        }}
                      >
                        <View style={{ ...styles.column, marginVertical: 2 }}>
                          <Text style={styles.headerText}>
                            Total deductions
                          </Text>
                        </View>
                        <View
                          style={{
                            ...styles.column,
                            marginVertical: 0,
                            alignItems: 'flex-end',
                          }}
                        >
                          <View
                            style={{
                              borderBottomWidth: 1,
                              borderTopWidth: 1,
                            }}
                          >
                            <Text
                              style={{
                                ...styles.headerText,
                                paddingVertical: 2,
                              }}
                            >
                              P {'       '}
                              {inputToLocale(
                                calculateTotalDeductions(allottee.gov_ud) +
                                  calculateTotalDeductions(
                                    allottee.other_deductions
                                  )
                              )}
                            </Text>
                          </View>
                        </View>
                      </View>
                    )}
                    <View
                      style={{
                        ...styles.row,
                        marginTop: 10,
                        marginHorizontal: 20,
                      }}
                    >
                      <View style={{ ...styles.column, marginVertical: 2 }}>
                        <Text
                          style={styles.headerText}
                        >{`NET AMOUNT  ----------->>>>`}</Text>
                      </View>
                      <View
                        style={{
                          ...styles.column,
                          marginVertical: 0,
                          alignItems: 'flex-end',
                        }}
                      >
                        <View
                          style={{ borderBottomWidth: 1, paddingBottom: 1 }}
                        >
                          <View style={{ borderBottomWidth: 1 }}>
                            <Text
                              style={{
                                ...styles.headerText,
                              }}
                            >
                              P {'       '}
                              {inputToLocale(allottee.total_net)}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        ...styles.row,
                        marginTop: 10,
                        marginHorizontal: 20,
                      }}
                      wrap={false}
                    >
                      <View style={styles.column}>
                        <Text style={{ fontSize: 8 }}>Prepared by:</Text>

                        <Text style={{ fontSize: 8 }}>{'\n\n\n'}Noted by:</Text>
                      </View>
                      <View style={styles.column}>
                        <Text style={{ fontSize: 8 }}>Checked by:</Text>
                      </View>
                    </View>
                  </Page>
                ))}
              </>
            ))}
          </>
        ))}
      </Document>
    </PDFViewer>
  );
}
