import axios from 'axios';
import {
  API_CALL_ERROR,
  GET_GROUPS_ENDPOINT,
  GET_SHOW_VESSEL_SETUP_ENDPOINT,
  GET_VESSELS_ENDPOINT,
  GET_VESSEL_DETAILS_ENDPOINT,
  POST_MODIFY_VESSET_SETUP_ENDPOINT,
  REQUEST_HEADERS,
} from './_constants';

export async function getVessels(userId) {
  try {
    const { data } = await axios.get(`${GET_VESSELS_ENDPOINT}/${userId}`);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
export async function getVesselGroups(userId) {
  try {
    const { data } = await axios.get(GET_GROUPS_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getVesselSetup(id) {
  try {
    const { data } = await axios.get(`${GET_SHOW_VESSEL_SETUP_ENDPOINT}/${id}`);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postModifyVesselSetup(id, payload) {
  try {
    const { data } = await axios.post(
      `${POST_MODIFY_VESSET_SETUP_ENDPOINT}/${id}`,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getVesselSetupDetails(vessel_code) {
  try {
    const { data } = await axios.get(
      `${GET_VESSEL_DETAILS_ENDPOINT}/${vessel_code}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
