export const getCrewWagesEtntryFields = (
  isLoading,
  state,
  setState,
  setHasChanges,
  deductionOptions
) => {
  return [
    {
      type: 'dropdown',
      name: 'wage_type',
      placeholder: 'Type',
      label: 'Type',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.wage_type,
      dropdownOptions: [
        { id: 'earning', description: 'Earnings' },
        { id: 'deduction', description: 'Deductions' },
        // { id: 'accrued', description: 'Accrued' },
      ],
      setValue: (value, option) => {
        setState({
          ...state,
          wage_type: value,
        });
        setHasChanges(true);
      },
    },
    {
      type: 'searchable-dropdown',
      name: 'deduction_id',
      placeholder: 'Deduction ID',
      label: 'Deduction ID',
      autofocus: false,
      required: state.wage_type === 'deduction',
      hidden: state.wage_type !== 'deduction',
      disabled: false,
      value: state.deduction_id,
      dropdownOptions: deductionOptions,
      setValue: (value, option) => {
        setState({
          ...state,
          deduction_id: value,
        });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'salary_code',
      placeholder: 'Salary code',
      label: 'Salary code',
      autofocus: false,
      required: true,
      disabled: isLoading,
      hidden: state.wage_type === 'deduction',
      value: state.salary_code,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, salary_code: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'wage_other_desc',
      placeholder: 'Other description',
      label: 'Other description',
      autofocus: false,
      required: true,
      disabled: isLoading,
      hidden: state.wage_type === 'deduction',
      value: state.wage_other_desc,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, wage_other_desc: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'amount',
      placeholder: 'Amount',
      label: 'Amount',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.amount,
      setValue: (e) => {
        const value = e.target.value;
        if (state.wage_type === 'deduction') {
          setState({ ...state, deduction_amount: value });
        } else {
          setState({ ...state, amount: value });
        }
        setHasChanges(true);
      },
    },
  ];
};
