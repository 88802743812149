import { Button, DatePicker, Input, Popconfirm, Space, Switch } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';

export function getDisembarkedTable(handleAction, onCrewCutOffChange) {
  return [
    {
      title: 'CREW NO.',
      dataIndex: 'crew_no',
      key: 'crew_no',
      sorter: (a, b) => a.crew_no.localeCompare(b.crew_no),
      fixed: 'left',
    },
    {
      title: 'CREW NAME',
      dataIndex: 'crew_name',
      fixed: 'left',
      render: (text, record) => {
        return `${record.last_name}, ${record.first_name} ${record.middle_name}`;
      },
    },
    {
      title: 'POSITION',
      dataIndex: 'position_name',
      key: 'position_name',
      sorter: (a, b) => a.position_name.localeCompare(b.position_name),
    },
    {
      title: 'VESSEL',
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      sorter: (a, b) => a.vessel_name.localeCompare(b.vessel_name),
    },
    {
      title: 'GROUP',
      dataIndex: 'grp',
      key: 'grp',
      sorter: (a, b) => a.grp.localeCompare(b.grp),
    },
    {
      title: 'EMP. STATUS',
      dataIndex: 'crew_status',
      key: 'crew_status',
      sorter: (a, b) => a.crew_status.localeCompare(b.crew_status),
    },
    {
      title: 'CUT-OFF',
      key: 'crew_cut_off',
      render: (text, record) => (
        <DatePicker
          key={record.crew_cut_off}
          style={{ width: '130px' }}
          defaultValue={
            record.crew_cut_off !== 0 ? dayjs(record.crew_cut_off) : null
          }
          onChange={async (_, dateString) => {
            onCrewCutOffChange(record, dateString);
          }}
          format="YYYY-MM-DD"
        />
      ),
    },
    {
      title: 'BASIC PAY',
      dataIndex: 'basic_pay',
      key: 'basic_pay',
      sorter: (a, b) => a.total_contri - b.total_contri,
    },
    {
      title: 'ALLOTMENT',
      dataIndex: 'allotment_salary',
      key: 'allotment_salary',
    },
    {
      title: 'SIGN-ON',
      dataIndex: 'embark_date',
      key: 'embark_date',
    },
    {
      title: 'VESSEL SIGN-ON',
      dataIndex: 'vessel_sign_on_date',
      key: 'vessel_sign_on_date',
    },
    {
      title: 'VESSEL SIGN-OFF',
      dataIndex: 'vessel_sign_off_date',
      key: 'vessel_sign_off_date',
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => handleAction(record)}
            icon={<FaWrench size={15} />}
          />
        </Space>
      ),
    },
  ];
}
