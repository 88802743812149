import dayjs from 'dayjs';
import CrewType from '../types/CrewType';
import DeductionType from '../types/DeductionType';
import OtherDeductionType from '../types/OtherDeductionType';

export const getPayrollOtherDeductionFields = (
  isLoading,
  state = CrewType.allotment,
  setState,
  setHasChanges,
  crewListOptions = [],
  otherDeductionsOptions,
  allotteesState,
  setAllotteesState,
  onCrewSelectChange
) => {
  return [
    {
      type: 'searchable-dropdown',
      name: 'crew_no',
      placeholder: 'Crew Name',
      label: 'Crew Name',
      autofocus: true,
      required: true,
      disabled:
        isLoading || crewListOptions.length <= 1 || state.crew_no !== null,
      value: state.crew_no,
      dropdownOptions: crewListOptions.map((item) => ({
        id: item.crew_no,
        description: `${item.last_name}, ${item.first_name} - ${item.crew_no}`,
      })),
      setValue: (value, option) => {
        onCrewSelectChange(value);
        setState({
          ...state,
          crew_no: option.value,
          crew_name: option.children,
        });
        setHasChanges(true);
      },
    },
    // {
    //   type: 'searchable-dropdown',
    //   name: 'od_id',
    //   placeholder: 'Type',
    //   label: 'Type',
    //   autofocus: false,
    //   required: true,
    //   disabled: isLoading,
    //   value: state.od_id,
    //   dropdownOptions: otherDeductionsOptions,
    //   setValue: (value, option) => {
    //     setState({
    //       ...state,
    //       od_id: value,
    //     });
    //     setHasChanges(true);
    //   },
    // },
    {
      type: 'date',
      name: 'transdate',
      placeholder: 'Trans Date',
      label: 'Trans date',
      required: true,
      disabled: true,
      value: state.transdate,
    },
    {
      type: 'date',
      name: 'startdeduct',
      placeholder: '',
      label: 'Date start of deduction',
      required: true,
      disabled: isLoading,
      value: state.startdeduct,
      setValue: (_, dateString) => {
        if (dateString) {
          const date = dayjs(dateString, 'YYYY-MM-DD');
          setState({
            ...state,
            startdeduct: date,
          });
        } else {
          setState({
            ...state,
            startdeduct: null,
          });
        }

        setHasChanges(true);
      },
    },
    {
      type: 'dropdown',
      name: 'currency',
      placeholder: 'Currency',
      label: 'Currency',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.currency,
      dropdownOptions: [
        { id: 1, description: 'PHP' },
        { id: 2, description: 'USD' },
      ],
      setValue: (value, option) => {
        setState({ ...state, currency: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'amounttoded',
      placeholder: 'Amount to be deducted',
      label: 'Amount to be deducted',
      autofocus: false,
      required: true,
      disabled: true,
      value: state.amounttoded,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, amounttoded: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'amount',
      placeholder: 'Amount',
      label: 'Amount',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.amount,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, amount: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'remarks',
      placeholder: 'Remarks',
      label: 'Remarks',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.remarks,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, remarks: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'refno',
      placeholder: 'Reference no',
      label: 'Reference no',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.refno,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, refno: value });
        setHasChanges(true);
      },
    },
    {
      type: 'dropdown',
      name: 'stoppayment',
      placeholder: 'Stop payment',
      label: 'Stop payment',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.stoppayment,
      dropdownOptions: [
        { id: 0, description: 'No' },
        { id: 1, description: 'Yes' },
      ],
      setValue: (value, option) => {
        setState({ ...state, stoppayment: value });
        setHasChanges(true);
      },
    },
    {
      type: 'dropdown',
      name: 'assign_to',
      placeholder: 'Deduct to',
      label: 'Deduct to',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.assign_to,
      dropdownOptions: [
        { id: 1, description: 'Reg. Allotment' },
        { id: 2, description: 'Side Letter' },
        { id: 3, description: 'MPO' },
      ],
      setValue: (value, option) => {
        const updatedState = allotteesState.map((item) => {
          const updatedRecord = { ...item };
          updatedRecord['allotee_amount'] = 0;
          return updatedRecord;
        });
        setAllotteesState(updatedState);
        setState({ ...state, assign_to: value });
        setHasChanges(true);
      },
    },
    // {
    //   type: 'dropdown',
    //   name: 'other_deduction_type',
    //   placeholder: 'Deduction Type',
    //   label: 'Deduction Type',
    //   autofocus: false,
    //   required: true,
    //   disabled: isLoading,
    //   value: state.other_deduction_type,
    //   dropdownOptions: [
    //     { id: '1', description: 'Deduct to payroll' },
    //     { id: '2', description: 'Add to payroll' },
    //   ],
    //   setValue: (value, option) => {
    //     setState({ ...state, other_deduction_type: value });
    //     setHasChanges(true);
    //   },
    // },
  ];
};
