import { Button, Form, Input, Popconfirm, Select, Space, Switch } from 'antd';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';
import PayrollOtherDeductionType from '../../types/PayrollOtherDeductionType';
import AllotteeType from '../../types/AllotteeType';

export function getEmbarkationOtherDeductionTable(
  state = PayrollOtherDeductionType,
  setState,
  allotteesState,
  setAllotteesState,
  setHasChanges,
  otherDeductionOptions
) {
  const isEntryDisabled = (record) => {
    const rec = allotteesState.find(
      (item) => item.crew_allottee_id == record.crew_allottee_id
    );

    if (rec) {
      if (state.assign_to == 1) {
        if (
          parseFloat(rec.regular_allotment.percentage) > 0 ||
          parseFloat(rec.regular_allotment.usd_amount) > 0 ||
          parseFloat(rec.regular_allotment.peso_amount) > 0 ||
          rec.regular_allotment.applied_deduction == 1
        ) {
          return false;
        } else {
          return true;
        }
      } else if (state.assign_to == 2) {
        if (
          parseFloat(rec.side_letter.percentage) > 0 ||
          parseFloat(rec.side_letter.usd_amount) > 0 ||
          parseFloat(rec.side_letter.peso_amount) > 0 ||
          rec.side_letter.applied_deduction == 1
        ) {
          return false;
        } else {
          return true;
        }
      } else if (state.assign_to == 3) {
        if (
          parseFloat(rec.mpo.percentage) > 0 ||
          parseFloat(rec.mpo.usd_amount) > 0 ||
          parseFloat(rec.mpo.peso_amount) > 0 ||
          rec.mpo.applied_deduction == 1
        ) {
          return false;
        } else {
          return true;
        }
      }
    }
    return true;
  };

  return [
    {
      title: 'Allottee Name',
      dataIndex: 'allottee_name',
      key: 'allottee_name',
      fixed: 'left',
    },
    {
      title: 'Bank Name',
      dataIndex: 'bankdesc',
      key: 'bankdesc',
    },
    {
      title: 'Account no.',
      dataIndex: 'acct_no',
      key: 'acct_no',
    },
    {
      title: 'Amount',
      key: 'allottee_amount',
      render: (text, record) => (
        <Input
          type='number'
          style={{ width: 80 }}
          placeholder={'Amount'}
          value={record.allotee_amount}
          disabled={isEntryDisabled(record)}
          onChange={(e) => {
            let value = 0;
            if (e.target.value) {
              value = parseFloat(e.target.value);
            }
            const newAllotteeState = allotteesState.map((item) => {
              if (item.crew_allottee_id === record.crew_allottee_id) {
                return { ...item, allotee_amount: value, amount: value };
              }
              return item;
            });
            setAllotteesState(newAllotteeState);
            const total = newAllotteeState
              .map((rec) => (rec.allotee_amount ? rec.allotee_amount : 0))
              .reduce((acc, value) => acc + value, 0);
            setState({
              ...state,
              amounttoded: total,
            });
            setHasChanges(true);
          }}
          allowClear={true}
        />
      ),
    },
    {
      title: 'Type',
      key: 'other_deduction_allottee_od_id',
      render: (text, record) => (
        <Select
          value={record.other_deduction_allottee_od_id}
          allowClear={false}
          style={{ width: 150 }}
          disabled={isEntryDisabled(record)}
          defaultActiveFirstOption={true}
          showSearch
          filterOption={(inputValue, option) => {
            const optionDescription = option.children.toLowerCase();
            return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
          }}
          onChange={(value, option) => {
            const newAllotteeState = allotteesState.map((item) => {
              if (item.crew_allottee_id === record.crew_allottee_id) {
                return {
                  ...item,
                  other_deduction_allottee_od_id: value,
                };
              }
              return item;
            });
            setAllotteesState(newAllotteeState);
            setHasChanges(true);
          }}
        >
          {otherDeductionOptions.map((option) => (
            <Select.Option key={option.id} value={option.id}>
              {option.description}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Deduction Type',
      key: 'other_deduction_type',
      render: (text, record) => (
        <Select
          value={record.other_deduction_type}
          allowClear={false}
          style={{ width: 200 }}
          disabled={isEntryDisabled(record)}
          defaultActiveFirstOption={true}
          onChange={(value, option) => {
            const newAllotteeState = allotteesState.map((item) => {
              if (item.crew_allottee_id === record.crew_allottee_id) {
                return {
                  ...item,
                  other_deduction_type: value,
                };
              }
              return item;
            });
            setAllotteesState(newAllotteeState);
            setHasChanges(true);
          }}
        >
          {[
            { id: 1, description: 'Deduct to payroll' },
            { id: 2, description: 'Add to payroll' },
          ].map((option) => (
            <Select.Option key={option.id} value={option.id}>
              {option.description}
            </Select.Option>
          ))}
        </Select>
      ),
    },
  ];
}
