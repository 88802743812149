export default {
  user_id: null,
  user_code: null,
  user_name: null,
  email: null,
  first_name: null,
  last_name: null,
  access_level: null,
  leveldesc: null,
  last_login: null,
  password: null,
  is_first_login: null,
  is_active: null,
  created_at: null,
  updated_at: null,
};
