import { Tabs } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext } from 'react';
import ComputeTabPage from './PayrollProcess/ComputeTabs/ComputeTabPage';
import TransactionsTabPage from './PayrollProcess/TransactionsTabs/TransactionsTabPage';
import DeductionsPage from './PayrollProcess/Deductions/DeductionsPage';
import MPORequestsPage from './PayrollProcess/TransactionsTabs/MPORequestsPage';
import SLBRequestPage from './PayrollProcess/TransactionsTabs/SLBRequestPage';
import { UserContext } from '../../utils/context/UserContext';

export default function PayrollProcessPage() {
  const { validateAccessToFeature } = useContext(UserContext);

  const checkAvailableTabs = () => {
    const tabs = [];
    if (validateAccessToFeature('feature_tran_pp_transactions')) {
      tabs.push({
        key: '1',
        label: 'Transactions',
        children: <TransactionsTabPage />,
        disabled: !validateAccessToFeature('feature_tran_pp_transactions'),
      });
    }
    if (validateAccessToFeature('feature_tran_pp_deductions')) {
      tabs.push({
        key: '2',
        label: 'Deductions',
        children: <DeductionsPage />,
        disabled: !validateAccessToFeature('feature_tran_pp_deductions'),
      });
    }
    if (validateAccessToFeature('feature_tran_pp_compute')) {
      tabs.push({
        key: '4',
        label: 'Compute',
        children: <ComputeTabPage />,
        disabled: !validateAccessToFeature('feature_tran_pp_compute'),
      });
    }
    if (validateAccessToFeature('feature_tran_pp_mpo_requests')) {
      tabs.push({
        key: '5',
        label: 'MPO Requests',
        children: <MPORequestsPage />,
        disabled: !validateAccessToFeature('feature_tran_pp_mpo_requests'),
      });
    }
    if (validateAccessToFeature('feature_tran_pp_slb_requests')) {
      tabs.push({
        key: '6',
        label: 'SLB Requests',
        children: <SLBRequestPage />,
        disabled: !validateAccessToFeature('feature_tran_pp_slb_requests'),
      });
    }
    return tabs;
  };

  return (
    <Content>
      <Tabs
        items={checkAvailableTabs()}
        centered
        tabPosition='left'
        size='large'
      />
    </Content>
  );
}
