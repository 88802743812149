import dayjs from 'dayjs';
import DeductionType from '../types/DeductionType';
import MpoRequestType from '../types/MpoRequestType';
import SlbRequestType from '../types/SlbRequestType';

export const getSlbRequestFields = (
  isLoading,
  state = SlbRequestType,
  setState,
  setHasChanges
) => {
  return [
    {
      type: 'text',
      name: 'crew_no',
      placeholder: 'Crew no',
      label: 'Crew no',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.crew_no,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, crew_no: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'allottee_id',
      placeholder: 'Allottee id',
      label: 'Allottee id',
      autofocus: true,
      required: true,
      disabled: true,
      value: state.allottee_id,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, allottee_id: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'allottee_name',
      placeholder: 'Allottee name',
      label: 'Allottee name',
      autofocus: false,
      required: true,
      disabled: true,
      value: state.allottee_name,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, allottee_name: value });
        setHasChanges(true);
      },
    },
    {
      type: 'date',
      name: 'date_requested',
      placeholder: 'SLB request date',
      label: 'SLB request date',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.date_requested,
      setValue: (_, dateString) => {
        if (dateString) {
          const date = dayjs(dateString, 'YYYY-MM-DD');
          setState({ ...state, date_requested: date });
        } else {
          setState({ ...state, date_requested: null });
        }
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'sideletter_amount',
      placeholder: 'SLB Amount',
      label: 'SLB Amount',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.sideletter_amount,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, sideletter_amount: value });
        setHasChanges(true);
      },
    },
    {
      type: 'toggle',
      name: 'deduct_on_leave_final',
      placeholder: '',
      label: 'Deduct on final pay',
      required: false,
      disabled: isLoading,
      value: state.deduct_on_leave_final == 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          deduct_on_leave_final: checked ? 1 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        className: 'text-start',
      },
    },
    {
      type: 'toggle',
      name: 'apply',
      placeholder: '',
      label: 'Apply',
      required: false,
      disabled: isLoading,
      value: state.apply == 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          apply: checked ? 1 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        className: 'text-start',
      },
    },
  ];
};
