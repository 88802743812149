import { Button, DatePicker, Input, Popconfirm, Space, Switch } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { AiFillDelete, AiFillEdit, AiFillPrinter } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';

export function getCrewWagesForInitialTable(
  forInitialCrewList,
  setForInitialCrewList,
  setHasChanges,
  selectedTemplate
) {
  const renderInputs = () => {
    switch (selectedTemplate?.cw_format) {
      case 1:
        return [
          {
            title: 'Beginning Balance',
            key: 'beg_balance',
            render: (text, record) => (
              <Input
                key={record.beg_balance}
                type='number'
                placeholder={'Beginning Balance'}
                autoFocus={false}
                disabled={false}
                defaultValue={record.beg_balance}
                onBlur={(e) => {
                  const value = e.target.value;
                  const newList = structuredClone(forInitialCrewList);
                  const updatedState = newList.map((crew) => {
                    if (crew.crew_no == record.crew_no) {
                      setHasChanges(true);
                      return { ...crew, beg_balance: value };
                    } else {
                      return crew;
                    }
                  });
                  setForInitialCrewList(updatedState);
                }}
                allowClear={false}
              />
            ),
          },
          {
            title: 'Initial Prov.',
            key: 'initial_prov',
            render: (text, record) => (
              <Input
                key={record.initial_prov}
                type='number'
                placeholder={'Initial Prov.'}
                autoFocus={false}
                disabled={false}
                defaultValue={record.initial_prov}
                onBlur={(e) => {
                  const value = e.target.value;
                  const newList = structuredClone(forInitialCrewList);
                  const updatedState = newList.map((crew) => {
                    if (crew.crew_no == record.crew_no) {
                      setHasChanges(true);
                      return { ...crew, initial_prov: value };
                    } else {
                      return crew;
                    }
                  });
                  setForInitialCrewList(updatedState);
                }}
                allowClear={false}
              />
            ),
          },
          {
            title: 'Initial Leave',
            key: 'initial_leave',
            render: (text, record) => (
              <Input
                key={record.initial_leave}
                type='number'
                placeholder={'Initial Leave'}
                autoFocus={false}
                disabled={false}
                defaultValue={record.initial_leave}
                onBlur={(e) => {
                  const value = e.target.value;
                  const newList = structuredClone(forInitialCrewList);
                  const updatedState = newList.map((crew) => {
                    if (crew.crew_no == record.crew_no) {
                      setHasChanges(true);
                      return { ...crew, initial_leave: value };
                    } else {
                      return crew;
                    }
                  });
                  setForInitialCrewList(updatedState);
                }}
                allowClear={false}
              />
            ),
          },
        ];
      case 2:
      case 3:
        return [
          {
            title: 'Excess OT',
            key: 'excess_ot',
            render: (text, record) => (
              <Input
                key={record.excess_ot}
                type='number'
                placeholder={'Excess OT'}
                autoFocus={false}
                disabled={false}
                defaultValue={record.excess_ot}
                onBlur={(e) => {
                  const value = e.target.value;
                  const newList = structuredClone(forInitialCrewList);
                  const updatedState = newList.map((crew) => {
                    if (crew.crew_no == record.crew_no) {
                      setHasChanges(true);
                      return { ...crew, excess_ot: value };
                    } else {
                      return crew;
                    }
                  });
                  setForInitialCrewList(updatedState);
                }}
                allowClear={false}
              />
            ),
          },
          {
            title: 'Return bonus',
            key: 'return_bonus',
            render: (text, record) => (
              <Input
                key={record.return_bonus}
                type='number'
                placeholder={'Return Bonus'}
                autoFocus={false}
                disabled={false}
                defaultValue={record.return_bonus}
                onBlur={(e) => {
                  const value = e.target.value;
                  const newList = structuredClone(forInitialCrewList);
                  const updatedState = newList.map((crew) => {
                    if (crew.crew_no == record.crew_no) {
                      setHasChanges(true);
                      return { ...crew, return_bonus: value };
                    } else {
                      return crew;
                    }
                  });
                  setForInitialCrewList(updatedState);
                }}
                allowClear={false}
              />
            ),
          },
          {
            title: 'Balance Transfer',
            key: 'balance_transfer',
            render: (text, record) => (
              <Input
                key={record.balance_transfer}
                type='number'
                placeholder={'Balance Transfer'}
                autoFocus={false}
                disabled={false}
                defaultValue={record.balance_transfer}
                onBlur={(e) => {
                  const value = e.target.value;
                  const newList = structuredClone(forInitialCrewList);
                  const updatedState = newList.map((crew) => {
                    if (crew.crew_no == record.crew_no) {
                      setHasChanges(true);
                      return { ...crew, balance_transfer: value };
                    } else {
                      return crew;
                    }
                  });
                  setForInitialCrewList(updatedState);
                }}
                allowClear={false}
              />
            ),
          },
        ];
      default:
        return [];
    }
  };
  return [
    {
      title: 'Crew no.',
      dataIndex: 'crew_no',
      key: 'crew_no',
      sorter: (a, b) => a.crew_no.localeCompare(b.crew_no),
      fixed: 'left',
    },
    {
      title: 'Crew name',
      dataIndex: 'crew_name',
      key: 'crew_name',
      fixed: 'left',
    },
    ...renderInputs(),
  ];
}
