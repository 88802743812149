export function getBankDownloadingVesselTable() {
  return [
    {
      title: 'Vessel Code',
      dataIndex: 'vessel_code',
      key: 'vessel_code',
      sorter: (a, b) => a.vessel_code - b.vessel_code,
    },
    {
      title: 'Vessel name',
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      sorter: (a, b) => a.vessel_name.localeCompare(b.vessel_name),
    },
  ];
}
