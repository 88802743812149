import dayjs from 'dayjs';
import OtherDeductionType from '../types/OtherDeductionType';
import VesselExchangeRateType from '../types/VesselExchangeRateType';

export const getVesselExchangeRateFields = (
  isLoading,
  state = VesselExchangeRateType,
  setState,
  setHasChanges,
  vesselListOptions
) => {
  return [
    {
      type: 'searchable-dropdown',
      name: 'vessel_code',
      placeholder: 'Vessel',
      label: 'Search Vessel!',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.bankcode,
      dropdownOptions: vesselListOptions.map((item) => ({
        id: item.vessel_code,
        description: item.vessel_name,
      })),
      setValue: (value, option) => {
        if (value) {
          const vessel = vesselListOptions.find(
            (item) => item.vessel_code === value
          );
          setState({
            ...state,
            vessel_id: vessel.id,
            vessel_code: vessel.vessel_code,
            vessel_name: vessel.vessel_name,
          });
        } else {
          setState({
            ...state,
            vessel_id: null,
            vessel_code: null,
            vessel_name: null,
          });
        }

        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'exchange_rate',
      placeholder: 'Exchange Rate',
      label: 'Exchange Rate',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.exchange_rate,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, exchange_rate: value });
        setHasChanges(true);
      },
    },
    {
      type: 'searchable-dropdown',
      name: 'month',
      placeholder: 'Month',
      label: 'Month',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.month,
      dropdownOptions: [
        { id: 1, description: 'January' },
        { id: 2, description: 'February' },
        { id: 3, description: 'March' },
        { id: 4, description: 'April' },
        { id: 5, description: 'May' },
        { id: 6, description: 'June' },
        { id: 7, description: 'July' },
        { id: 8, description: 'August' },
        { id: 9, description: 'September' },
        { id: 10, description: 'October' },
        { id: 11, description: 'November' },
        { id: 12, description: 'December' },
      ],
      setValue: (value, option) => {
        setState({
          ...state,
          month: value,
        });
        setHasChanges(true);
      },
    },
    {
      type: 'date-year',
      name: 'year',
      placeholder: '',
      label: 'Year',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.year,
      setValue: (_, dateString) => {
        if (dateString) {
          const date = dayjs(dateString, 'YYYY');
          setState({
            ...state,
            year: date,
          });
        } else {
          setState({
            ...state,
            year: null,
          });
        }
        setHasChanges(true);
      },
    },
  ];
};
