export default {
  crew_allottee_id: null,
  last_name: null,
  first_name: null,
  middle_name: null,
  acct_no: null,
  acct_type: null,
  is_dollar_account: null,
  bankcode: null,
  bankdesc: null,
  bankbranch: null,
  regular_allotment: {
    is_checked: null,
    percentage: null,
    usd_amount: null,
    peso_amount: null,
    applied_deduction: null,
    adjustment_amount: null,
  },
  side_letter: {
    is_checked: null,
    percentage: null,
    usd_amount: null,
    peso_amount: null,
    applied_deduction: null,
    adjustment_amount: null,
  },
  mpo: {
    is_checked: 1,
    percentage: null,
    usd_amount: null,
    peso_amount: null,
    applied_deduction: null,
    adjustment_amount: null,
  },
  regular_allotment_percentage: null,
  regular_allotment_usd_amount: null,
  regular_allotment_peso_amount: null,
  regular_allotment_applied_deduction: null,
  regular_allotment_adjustment_amount: null,
  regular_allotment_is_checked: null,
  side_letter_percentage: null,
  side_letter_usd_amount: null,
  side_letter_peso_amount: null,
  side_letter_applied_deduction: null,
  side_letter_adjustment_amount: null,
  side_letter_is_checked: null,
  mpo_percentage: null,
  mpo_usd_amount: null,
  mpo_peso_amount: null,
  mpo_applied_deduction: null,
  mpo_adjustment_amount: null,
  mpo_is_checked: null,
};
