export default {
  vessel_code: null,
  crew_no: null,
  crew_name: null,
  embark_date: null,
  sign_on: null,
  sign_off: null,
  disembark: null,
  wage_code: null,
  position_name: null,
  vessel_name: null,
  uk_hrs: null,
  uk_days: null,
  uk_gbp_hrs: null,
  uk_gbp_days: null,
  uk_usd_hrs: null,
  uk_usd_days: null,
  uk_divisor: null,
  uk_excess_ot: null,
  uk_excess_ot_rate: null,
  uk_adjustment: null,
  excess_ot2: null,
  initial_leave: null,
  home_allotment: null,
  prorate_exclu_fot: null,
  prorate_fot: null,
  earnings: [],
  deductions: [],
  accrued: [],
};
