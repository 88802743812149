import UnionDuesType from '../types/UnionDuesType';

export const getUnionDuesFields = (
  isLoading,
  state = UnionDuesType,
  setState,
  setHasChanges
) => {
  return [
    {
      type: 'text',
      name: 'code',
      placeholder: 'Code',
      label: 'Code',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: state.code,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, code: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'desc',
      placeholder: 'Description',
      label: 'Description',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.desc,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, desc: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'peso_ratings',
      placeholder: '',
      label: 'Peso (Ratings)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.peso_ratings,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, peso_ratings: value });
      },
    },
    {
      type: 'number',
      name: 'peso_officer',
      placeholder: '',
      label: 'Peso (Officer)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.peso_officer,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, peso_officer: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'fmdcp_ratings',
      placeholder: '',
      label: 'FMDCP (RATINGS)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.fmdcp_ratings,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, fmdcp_ratings: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'fmdcp_officer',
      placeholder: '',
      label: 'FMDCP (OFFICER)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.fmdcp_officer,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, fmdcp_officer: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'ptgwo_ratings',
      placeholder: '',
      label: 'PTGWO (RATINGS)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.ptgwo_ratings,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, ptgwo_ratings: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'ptgwo_officer',
      placeholder: '',
      label: 'PTGWO (OFFICER)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.ptgwo_officer,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, ptgwo_officer: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'tdaf_ratings',
      placeholder: '',
      label: 'TDAF (RATINGS)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.tdaf_ratings,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, tdaf_ratings: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'tdaf_officer',
      placeholder: '',
      label: 'TDAF (OFFICER)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.tdaf_officer,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, tdaf_officer: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'ud_ratings',
      placeholder: '',
      label: 'UD (RATINGS)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.ud_ratings,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, ud_ratings: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'ud_officer',
      placeholder: '',
      label: 'UD (OFFICER)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.ud_officer,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, ud_officer: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'fmdp_ratings',
      placeholder: '',
      label: 'FMDP (RATINGS)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.fmdp_ratings,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, fmdp_ratings: value });
      },
    },
    {
      type: 'number',
      name: 'fmdp_officer',
      placeholder: '',
      label: 'FMDP (OFFICER)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.fmdp_officer,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, fmdp_officer: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'wmbp_ratings',
      placeholder: '',
      label: 'WMBP (RATINGS)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.wmbp_ratings,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, wmbp_ratings: value });
      },
    },
    {
      type: 'number',
      name: 'wmbp_officer',
      placeholder: '',
      label: 'WMBP (OFFICER)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.wmbp_officer,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, wmbp_officer: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'maap_ratings',
      placeholder: '',
      label: 'MAAP (RATINGS)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.maap_ratings,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, maap_ratings: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'maap_officer',
      placeholder: '',
      label: 'MAAP (OFFICER)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.maap_officer,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, maap_officer: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'training_levy_ratings',
      placeholder: '',
      label: 'TRAINING LEVY (RATINGS)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.training_levy_ratings,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, training_levy_ratings: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'training_levy_officer',
      placeholder: '',
      label: 'TRAINING LEVY (OFFICER)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.training_levy_officer,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, training_levy_officer: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'survivorship_ratings',
      placeholder: '',
      label: 'SURVIVORSHIP (RATINGS)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.survivorship_ratings,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, survivorship_ratings: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'survivorship_officer',
      placeholder: '',
      label: 'SURVIVORSHIP (OFFICER)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.survivorship_officer,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, survivorship_officer: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'rating1',
      placeholder: '',
      label: 'Rating 1 - 15',
      autofocus: false,
      required: state.fixed != 0,
      disabled: isLoading,
      value: state.rating1,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, rating1: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'rating2',
      placeholder: '',
      label: 'Rating 16 - 30',
      autofocus: false,
      required: state.fixed != 0,
      disabled: isLoading,
      value: state.rating2,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, rating2: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'percentage',
      placeholder: '',
      label: 'Percentage',
      autofocus: false,
      required: state.fixed != 0,
      disabled: isLoading,
      value: state.percentage,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, percentage: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'officer1',
      placeholder: '',
      label: 'Officer 1 - 15',
      autofocus: false,
      required: state.fixed != 0,
      disabled: isLoading,
      value: state.officer1,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, officer1: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'officer2',
      placeholder: '',
      label: 'Officer 16 - 30',
      autofocus: false,
      required: state.fixed != 0,
      disabled: isLoading,
      value: state.officer2,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, officer2: value });
        setHasChanges(true);
      },
    },
    {
      type: 'dropdown',
      name: 'fixed_dropdown',
      placeholder: 'Fixed',
      label: 'Fixed',
      autofocus: true,
      required: false,
      disabled: isLoading,
      value: state.fixed_dropdown,
      dropdownOptions: [
        { id: 0, description: 'N/A' },
        { id: 1, description: 'ALL' },
        { id: 2, description: 'OFFICER' },
        { id: 3, description: 'RATINGS' },
      ],
      setValue: (value, option) => {
        if (value === 0) {
          setState({
            ...state,
            fixed_dropdown: 0,
            fixed: 0,
            fixed_ratings: 0,
            fixed_officer: 0,
          });
        } else if (value === 1) {
          setState({
            ...state,
            fixed_dropdown: 1,
            fixed: 1,
            fixed_ratings: 1,
            fixed_officer: 1,
          });
        } else if (value === 2) {
          setState({
            ...state,
            fixed_dropdown: 2,
            fixed: 1,
            fixed_ratings: 0,
            fixed_officer: 1,
          });
        } else if (value === 3) {
          setState({
            ...state,
            fixed_dropdown: 3,
            fixed: 1,
            fixed_ratings: 1,
            fixed_officer: 0,
          });
        }

        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'fixed_usd',
      placeholder: '',
      label: 'Fixed USD',
      autofocus: false,
      required: state.fixed == 1,
      disabled: isLoading || state.fixed == 0,
      value: state.fixed_usd,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, fixed_usd: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'account_code',
      placeholder: 'Account Code',
      label: 'Account Code',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.account_code,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, account_code: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'sl_code',
      placeholder: 'SL Code',
      label: 'SL Code',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.sl_code,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, sl_code: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'expense_acct',
      placeholder: 'Expense Account',
      label: 'Expense Account',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.expense_acct,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, expense_acct: value });
        setHasChanges(true);
      },
    },
  ];
};
