import { Button, Input, Popconfirm, Space } from 'antd';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaCopy, FaRegCopy, FaWrench } from 'react-icons/fa';

export function getCrewWagesMaintenanceTable(
  handleAction,
  handleCopyTo,
  vesselCrewWagesList
) {
  return [
    {
      title: 'Vessel Code',
      dataIndex: 'vessel_code',
      key: 'vessel_code',
      sorter: (a, b) => a.vessel_code - b.vessel_code,
      fixed: 'left',
    },
    {
      title: 'Vessel Name',
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      sorter: (a, b) => a.vessel_name.localeCompare(b.vessel_name),
    },
    {
      title: 'Principal Name',
      dataIndex: 'principal_name',
      key: 'principal_name',
      sorter: (a, b) => a.principal_name.localeCompare(b.principal_name),
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      sorter: (a, b) => a.group.localeCompare(b.group),
    },
    {
      title: 'Agency Name',
      dataIndex: 'agency_name',
      key: 'agency_name',
      sorter: (a, b) => a.agency_name.localeCompare(b.agency_name),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => handleAction(record)}
            icon={<FaWrench size={15} />}
          />
          <Button
            type="dashed"
            disabled={
              !vesselCrewWagesList.some(
                (item) => item.vessel_code == record.vessel_code
              )
            }
            icon={<FaRegCopy size={15} onClick={() => handleCopyTo(record)} />}
          />
        </Space>
      ),
    },
  ];
}
