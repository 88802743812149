import dayjs from 'dayjs';
import UserSetupType from '../types/UserSetupType';
import FinalPayWUAType from '../types/FinalPayWUAType';

export const getFinalPayWUAFields = (
  isLoading,
  state = FinalPayWUAType,
  setState,
  setHasChanges,
  inputToLocale,
  crewWagesOptions,
  exchangeRate,
  selectedFinalPay,
  calculateAccumulatedDays,
  computeWUAUSDAmount,
  originalWages
) => {
  return [
    {
      type: 'searchable-dropdown',
      name: 'wages_id',
      placeholder: 'Wage desc',
      label: 'Wage desc',
      autofocus: false,
      required: true,
      hidden: false,
      disabled: isLoading,
      value: state.wages_id,
      dropdownOptions: crewWagesOptions.map((item, index) => ({
        key: index,
        id: item.code,
        description: item.description,
      })),
      setValue: (value, option) => {
        if (value) {
          const selectedWage = originalWages?.find((rec) => rec.code == value);
          setState({
            ...state,
            wages_id: value,
            desc: option.children,
            amount: selectedWage?.usd_amt ? selectedWage.usd_amt : null,
            details:
              selectedWage?.usd_amt > 0
                ? `(${inputToLocale(
                    selectedWage?.usd_amt
                  )} * ${calculateAccumulatedDays()}) / 30`
                : '',
            usd_amt: computeWUAUSDAmount(selectedWage?.usd_amt),
            php_amt: computeWUAUSDAmount(selectedWage?.usd_amt) * exchangeRate,
          });
        }
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'amount',
      placeholder: '0.00',
      label: 'Amount',
      autofocus: false,
      required: true,
      disabled: isLoading || state.based_on_days == 'T',
      value: state.based_on_days == 'T' ? '' : state.amount,
      setValue: (e) => {
        const value = e.target.value;

        setState({
          ...state,
          amount: value,
          details:
            value > 0 ? `(${value} * ${calculateAccumulatedDays()}) / 30` : '',
          usd_amt: computeWUAUSDAmount(parseFloat(value)),
          php_amt: computeWUAUSDAmount(parseFloat(value)) * exchangeRate,
        });
        setHasChanges(true);
      },
    },
    {
      type: 'date',
      name: 'period_from',
      placeholder: 'YYYY-MM-DD',
      label: 'Sign on',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.period_from,
      setValue: (_, dateString) => {
        if (dateString) {
          const date = dayjs(dateString, 'YYYY-MM-DD');
          setState({ ...state, period_from: date });
        } else {
          setState({ ...state, period_from: null });
        }
        setHasChanges(true);
      },
    },
    {
      type: 'date',
      name: 'period_to',
      placeholder: 'YYYY-MM-DD',
      label: 'Sign off',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.period_to,
      setValue: (_, dateString) => {
        if (dateString) {
          const date = dayjs(dateString, 'YYYY-MM-DD');
          setState({ ...state, period_to: date });
        } else {
          setState({ ...state, period_to: null });
        }
        setHasChanges(true);
      },
    },
    {
      type: 'checkbox',
      name: 'based_on_days',
      label: 'Based on days',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.based_on_days == 'T' ? true : false,
      setValue: (e) => {
        const checked = e.target.checked;
        setState({ ...state, based_on_days: checked ? 'T' : 'F' });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'based_on_days_amount',
      label: 'Days',
      placeholder: '0.00',
      autofocus: false,
      required: false,
      disabled: isLoading || state.based_on_days != 'T',
      value: state.based_on_days_amount,
      setValue: (e) => {
        const value = e.target.value;
        const basicPay = originalWages.find((rec) => rec.code == 'BASIC-PAY');

        setState({
          ...state,
          based_on_days_amount: value,
          details:
            value > 0
              ? `((${
                  basicPay.usd_amt
                } / 30) * (${value} * ${calculateAccumulatedDays()})) / 30`
              : '',
          usd_amt: computeWUAUSDAmount(
            parseFloat(value),
            parseFloat(basicPay.usd_amt)
          ),
          php_amt:
            computeWUAUSDAmount(
              parseFloat(value),
              parseFloat(basicPay.usd_amt)
            ) * exchangeRate,
        });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'details',
      placeholder: '',
      label: 'Details',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.details,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, details: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'usd_amt',
      label: 'USD Amount',
      placeholder: '0.00',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.usd_amt,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, usd_amt: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'php_amt',
      placeholder: '0.00',
      label: 'PHP Amount',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.php_amt,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, php_amt: value });
        setHasChanges(true);
      },
    },
  ];
};
