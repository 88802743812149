import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../App';
import { UserContext } from '../../../utils/context/UserContext';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import SSSContributionType from '../../../utils/types/SSSContributionType';
import {
  ERROR_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../../utils/api/_constants';
import {
  getAllSSSContribution,
  getSSSPerYearContribution,
  postAddSSSContribution,
  postCopySSSContribution,
  postDeleteSSSContribution,
  postModifySSSContribution,
} from '../../../utils/api/GovtContributionAPI';
import { Content } from 'antd/es/layout/layout';
import Search from 'antd/es/input/Search';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { IoIosRefresh } from 'react-icons/io';
import { getSSSContributionTable } from '../../../utils/tableheader/Maintenance/SSSContributionTable';
import FormModal from '../../../components/CustomModal/FormModal';
import { getSSSContributionFields } from '../../../utils/inputfields/SSSContributionFields';
import FormInput from '../../../components/FormInput/FormInput';
import ConfirmationModal from '../../../components/CustomModal/ConfirmationModal';
import {
  addUnionDues,
  postDeleteUnionDues,
  postModifyUnionDues,
} from '../../../utils/api/UnionDuesAPI';
import dayjs from 'dayjs';
import { FaCopy } from 'react-icons/fa';
import CustomModal from '../../../components/CustomModal/CustomModal';

export default function SSSContributionPage() {
  const { openNotification } = useContext(GlobalContext);
  const { doSearchInArray } = useContext(UserContext);

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [sssContribList, setSssContribList] = useState([]);

  const [selectedSSSContrib, setSelectedSSSContrib] =
    useState(SSSContributionType);

  const [year, setYear] = useState(dayjs().year());
  const [copyToYear, setCopyToYear] = useState(dayjs().year() + 1);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);
  const [isCopyToModalOpen, setIsCopyToModalOpen] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);

  const tableContents = filteredList.map((item, index) => ({
    ...item,
    key: index,
  }));

  const handleAction = async (record, action = 'edit') => {
    const parsedRecord = {
      ...record,
      year: dayjs().year(record.year),
    };
    if (action === 'delete') {
      setIsLoading(true);
      const { data: res, error } = await postDeleteSSSContribution(record);
      if (error) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'SSS Contributions',
          'Error deleting changes!'
        );
      } else {
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          'SSS Contributions',
          'Record deleted!'
        );
        fetchData();
      }
      setIsLoading(false);
    } else {
      setSelectedSSSContrib(parsedRecord);
      form.setFieldsValue(parsedRecord);
      setIsFormModalOpen(true);
    }
  };

  const fetchData = async (selectedYear = year) => {
    setIsLoading(true);
    const { data: res, error } = await getSSSPerYearContribution(selectedYear);
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'SSS Contributions',
        'Loading sss contributions failed.'
      );
    } else {
      setFilteredList(res);
      setSssContribList(res);
      doSearchInArray(searchQuery, setFilteredList, res);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    form.setFieldsValue(selectedSSSContrib);
  }, [selectedSSSContrib]);

  return (
    <Content>
      <Row className='mb-4' justify={'space-between'}>
        <Col xs={24} sm={6} className='me-2'>
          <Typography.Text strong className='me-2'>
            Year:
          </Typography.Text>
          <DatePicker
            allowClear={false}
            style={{ width: '30%' }}
            picker='year'
            defaultValue={dayjs(String(year), 'YYYY')}
            onChange={(_, dateString) => {
              setYear(dateString);
              fetchData(dateString);
            }}
            format={'YYYY'}
            disabled={isLoading}
          />
          <Button
            className='mx-2'
            type='primary'
            icon={<FaCopy size={18} />}
            disabled={isLoading}
            onClick={() => {
              setIsCopyToModalOpen(true);
            }}
          >
            Copy to
          </Button>
        </Col>
        <Col xs={24} sm={8} className='text-end'>
          <Space.Compact>
            <Search
              disabled={isLoading}
              placeholder='Search SSS contribution!'
              onChange={(e) => {
                setSearchQuery(e.target.value);
                doSearchInArray(
                  e.target.value,
                  setFilteredList,
                  sssContribList
                );
              }}
              onSearch={(value) => {
                setSearchQuery(value);
                doSearchInArray(value, setFilteredList, sssContribList);
              }}
              allowClear={true}
            />
            <Button
              className='mx-2'
              type='primary'
              icon={<AiOutlinePlusCircle size={18} />}
              disabled={isLoading}
              onClick={() => {
                form.setFieldsValue(SSSContributionType);
                setSelectedSSSContrib(SSSContributionType);
                setIsFormModalOpen(true);
              }}
            >
              Add
            </Button>
            <Button
              type='primary'
              icon={<IoIosRefresh size={18} />}
              disabled={isLoading}
              onClick={() => fetchData()}
            />
          </Space.Compact>
        </Col>
      </Row>
      <Table
        columns={getSSSContributionTable(handleAction)}
        dataSource={tableContents}
        size='small'
        bordered='true'
        scroll={{ x: true }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <FormModal
        title={'SSS Contribution entry'}
        isOpen={isFormModalOpen}
        setIsOpen={setIsFormModalOpen}
        isLoading={isLoading}
        props={{ width: '50vw' }}
        form={form}
        formName={'deduction_form'}
        initialState={selectedSSSContrib}
        onSave={async () => {
          if (hasChanges) {
            try {
              await form.validateFields();
              if (
                parseFloat(selectedSSSContrib.sal_range_to) >
                parseFloat(selectedSSSContrib.sal_range_from)
              ) {
                setIsConfirmationModalOpen(true);
              } else {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'SSS Contribution entry',
                  'Salary range from cannot be higher than Salary range to.'
                );
              }
            } catch {}
          } else {
            openNotification(
              INFO_NOTIFICATION_TYPE,
              'SSS Contribution entry',
              'No changes made.'
            );
            setIsFormModalOpen(false);
          }
        }}
        onCancel={() => {
          if (hasChanges) {
            setIsUnsavedModalOpen(true);
          } else {
            form.resetFields();
            setIsFormModalOpen(false);
            setHasChanges(false);
          }
        }}
      >
        <Row gutter={16}>
          {getSSSContributionFields(
            isLoading,
            selectedSSSContrib,
            setSelectedSSSContrib,
            setHasChanges
          ).map((data, index) => (
            <Col key={index} xs={24} sm={12}>
              <FormInput
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            </Col>
          ))}
        </Row>
      </FormModal>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          const parsedRecord = {
            ...selectedSSSContrib,
            year: dayjs(selectedSSSContrib.year).year(),
          };
          if (selectedSSSContrib.id) {
            const { data: res, error } = await postModifySSSContribution(
              parsedRecord
            );
            if (error) {
              if (error?.request?.status == 422) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'SSS Contribution',
                  'The provided range overlaps with an existing range.'
                );
              } else {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'SSS Contribution',
                  'Error saving changes!'
                );
              }
            } else {
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                'SSS Contribution entry',
                'Set up saved!'
              );
              fetchData();
              setHasChanges(false);
              setIsFormModalOpen(false);
            }
          } else {
            const { data: res, error } = await postAddSSSContribution(
              parsedRecord
            );
            if (error) {
              if (error?.request?.status == 422) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'SSS Contribution',
                  'The provided range overlaps with an existing range.'
                );
              } else {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'SSS Contribution',
                  'Error saving changes!'
                );
              }
            } else {
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                'SSS Contribution',
                'Set up saved!'
              );
              fetchData();
              setHasChanges(false);
              setIsFormModalOpen(false);
            }
          }

          setIsConfirmationModalOpen(false);
          setIsLoading(false);
        }}
      />
      <ConfirmationModal
        title='You have unsaved changes! Confirming will lose all your changes. Confirm?'
        cancelText='Continue editing'
        isLoading={isLoading}
        isOpen={isUnsavedModalOpen}
        setIsOpen={setIsUnsavedModalOpen}
        onConfirm={() => {
          form.resetFields();
          setIsUnsavedModalOpen(false);
          setIsFormModalOpen(false);
          setHasChanges(false);
        }}
      />
      <CustomModal
        title={'Copy to year'}
        isLoading={isLoading}
        isOpen={isCopyToModalOpen}
        setIsOpen={setIsCopyToModalOpen}
        props={{ width: '20vw' }}
        okText='Copy'
        onOkDisabled={isLoading}
        onOk={async () => {
          // setIsConfirmationModalOpen(true);
          setIsLoading(true);

          const { data: res, error } = await postCopySSSContribution({
            year: year,
            year_to: copyToYear,
          });
          if (error) {
            openNotification(
              ERROR_NOTIFICATION_TYPE,
              'Copy SSS',
              'Something went wrong!'
            );
          } else {
            openNotification(
              SUCCESS_NOTIFICATION_TYPE,
              'Copy SSS',
              'Saving completed.'
            );
            setIsCopyToModalOpen(false);
            fetchData();
          }
          setIsLoading(false);
        }}
      >
        <Row gutter={8} className='my-3'>
          <Col xs={24} sm={8}>
            <Typography.Text strong className='me-2'>
              From :
            </Typography.Text>
          </Col>
          <Col xs={24} sm={16}>
            <DatePicker
              allowClear={false}
              style={{ width: '100%' }}
              picker='year'
              defaultValue={dayjs(String(year), 'YYYY')}
              onChange={(_, dateString) => {
                setYear(dateString);
              }}
              format={'YYYY'}
              disabled={isLoading}
            />
          </Col>
        </Row>

        <Row gutter={8}>
          <Col xs={24} sm={8}>
            <Typography.Text strong className='me-2'>
              To :
            </Typography.Text>
          </Col>
          <Col xs={24} sm={16}>
            <DatePicker
              allowClear={false}
              style={{ width: '100%' }}
              picker='year'
              defaultValue={dayjs(String(copyToYear), 'YYYY')}
              onChange={(_, dateString) => {
                setCopyToYear(dateString);
              }}
              format={'YYYY'}
              disabled={isLoading}
            />
          </Col>
        </Row>
      </CustomModal>
    </Content>
  );
}
