import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../utils/context/UserContext';
import { GlobalContext } from '../../../../App';
import dayjs from 'dayjs';
import {
  getAllPrincipals,
  getProcessedGovContri,
  postProcessGovContri,
  postSaveGovContri,
} from '../../../../utils/api/ProcessGovContriAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  WARNING_NOTIFICATION_TYPE,
} from '../../../../utils/api/_constants';
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd';
import PrincipalType from '../../../../utils/types/PrincipalType';
import Search from 'antd/es/input/Search';
import { getGovContriTable } from '../../../../utils/tableheader/Transactions/GovContriTable';
import ConfirmationModal from '../../../../components/CustomModal/ConfirmationModal';
import ProcessedGovContriPDF from '../../../../components/PDFReports/ProcessedGovContriPDF';
import CustomModal from '../../../../components/CustomModal/CustomModal';

export default function ProcessGovContriPage() {
  const TAG = 'Process Government Contributions';

  const {
    getCurrentDate,
    doSearchInArray,
    parseToFloat,
    inputToLocale,
    validateAccessToFeature,
  } = useContext(UserContext);

  const { openNotification } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const [date, setDate] = useState({
    month: dayjs().month() + 1,
    year: dayjs().year(),
  });

  const [govContriList, setGovContriList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const [govContriListForPrint, setGovContriListForPrint] = useState([]);

  const [principalList, setPrincipalList] = useState([]);
  const [selectedPrincipal, setSelectedPrincipal] = useState(PrincipalType);

  const [exchangeRate, setExchangeRate] = useState(null);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const fetchDatas = async () => {
    setIsLoading(true);
    const { data: plist, error } = await getAllPrincipals();
    if (error) {
      openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Something went wrong.');
    } else {
      if (plist.length > 0) {
        setPrincipalList(plist);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <Content>
      <Spin spinning={isLoading} fullscreen />
      <Row className='mb-2'>
        <Col xs={24} sm={5} className='me-2'>
          <Typography.Text strong className='me-2'>
            Month/Year:
          </Typography.Text>
          <DatePicker
            allowClear={false}
            // style={{ width: '60%' }}
            picker='month'
            defaultValue={dayjs(`${date.month}/${date.year}`, 'M/YYYY')}
            onChange={(_, dateString) => {
              const [dateMonth, dateYear] = dateString.split('/');
              setDate({ month: dateMonth, year: dateYear });
              setGovContriList([]);
              setFilteredList([]);
            }}
            format='M/YYYY'
            disabled={isLoading}
          />
        </Col>
        <Col xs={24} sm={8} className='text-start'>
          <Typography.Text strong className='me-2'>
            Exchange Rate:
          </Typography.Text>
          <Input
            type='number'
            allowClear
            style={{ width: '20%' }}
            placeholder={'0.00'}
            disabled={isLoading}
            value={exchangeRate}
            onChange={(e) => {
              const value = e.target.value;
              setExchangeRate(value);
              setGovContriList([]);
              setFilteredList([]);
            }}
          />
          <Button
            type='primary'
            className='ms-2'
            disabled={
              isLoading ||
              date.month == null ||
              date.year == null ||
              selectedPrincipal.p_code == null
            }
            onClick={async () => {
              setIsLoading(true);
              const { data: printRes, error } = await getProcessedGovContri(
                selectedPrincipal.p_code,
                date.month,
                date.year
              );
              if (error) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  TAG,
                  'Something went wrong.'
                );
              } else {
                if (printRes?.list?.length > 0) {
                  setGovContriListForPrint(printRes.list);
                  setIsPrintModalOpen(true);
                } else {
                  openNotification(
                    WARNING_NOTIFICATION_TYPE,
                    TAG,
                    'No records found.'
                  );
                }
              }
              setIsLoading(false);
            }}
          >
            Print
          </Button>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col xs={24} sm={8} className='text-start'>
          <Typography.Text strong className='me-2'>
            Principal:
          </Typography.Text>
          <Select
            style={{ width: '80%' }}
            placeholder={'Search principal!'}
            allowClear
            value={selectedPrincipal.p_code}
            onChange={(value, option) => {
              const principal = principalList.find(
                (item) => item.p_code == value
              );
              setSelectedPrincipal(principal ? principal : PrincipalType);
              setGovContriList([]);
              setFilteredList([]);
            }}
            disabled={isLoading}
            showSearch
            filterOption={(inputValue, option) => {
              const optionDescription = option.children.toLowerCase();
              return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
            }}
          >
            {principalList.map((option) => (
              <Select.Option key={option.p_code} value={option.p_code}>
                {option.p_name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={8} className='text-start'>
          <Button
            type='primary'
            className='ms-2'
            disabled={
              isLoading ||
              date.month == null ||
              date.year == null ||
              selectedPrincipal.p_code == null ||
              exchangeRate <= 0
            }
            onClick={async () => {
              setIsLoading(true);
              const payload = {
                principal_code: selectedPrincipal.p_code,
                month: date.month,
                year: date.year,
                exrate: exchangeRate,
              };

              const { data: processRes, error } = await postProcessGovContri(
                payload
              );
              if (error) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  TAG,
                  'Something went wrong.'
                );
              } else {
                const list = processRes.map((rec, ind) => ({
                  ...rec,
                  key: ind,
                }));
                setGovContriList(list);
                setFilteredList(list);
                openNotification(
                  SUCCESS_NOTIFICATION_TYPE,
                  TAG,
                  'Processing complete.'
                );
              }
              setIsLoading(false);
            }}
          >
            Process
          </Button>
          <Button
            type='primary'
            className='ms-2'
            disabled={
              isLoading ||
              date.month == null ||
              date.year == null ||
              selectedPrincipal.p_code == null ||
              exchangeRate <= 0 ||
              govContriList.length <= 0
            }
            onClick={() => {
              setIsConfirmationModalOpen(true);
            }}
          >
            Save
          </Button>
        </Col>
        <Col xs={24} sm={8} className='text-end'>
          <Space.Compact block>
            <Search
              disabled={isLoading || govContriList.length === 0}
              placeholder='Search!'
              onChange={(e) => {
                setSearchQuery(e.target.value);
                doSearchInArray(e.target.value, setFilteredList, govContriList);
              }}
              onSearch={(value) => {
                setSearchQuery(value);
                doSearchInArray(value, setFilteredList, govContriList);
              }}
              allowClear={true}
            />
          </Space.Compact>
        </Col>
      </Row>
      <Table
        columns={getGovContriTable()}
        dataSource={filteredList}
        size={'small'}
        bordered={false}
        scroll={{ x: true }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          const payload = {
            principal_code: selectedPrincipal.p_code,
            month: date.month,
            year: date.year,
            exrate: exchangeRate,
          };

          const { data: res, error } = await postSaveGovContri(payload);
          if (error) {
            openNotification(
              ERROR_NOTIFICATION_TYPE,
              TAG,
              'Something went wrong.'
            );
          } else {
            if (res.isSuccessful) {
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                TAG,
                'Saving completed.'
              );
              setIsConfirmationModalOpen(false);
            } else {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                TAG,
                'Something went wrong.'
              );
            }
          }
          setIsLoading(false);
        }}
      />
      <CustomModal
        isLoading={isLoading}
        isOpen={isPrintModalOpen}
        setIsOpen={setIsPrintModalOpen}
        cancelText='Close'
        props={{ okButtonProps: { hidden: true }, width: '100vw' }}
        isClosable={true}
      >
        <ProcessedGovContriPDF
          contriList={govContriListForPrint}
          selectedPrincipal={selectedPrincipal}
          date={date}
          exchangeRate={exchangeRate}
        />
      </CustomModal>
    </Content>
  );
}
