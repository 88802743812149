import { Tabs } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext } from 'react';
import HomeAllotmentPage from './HomeAllotmentPage';
import MPOAllotmentPage from './MPOAllotmentPage';
import SideLetterAllotmentPage from './SideLetterAllotmentPage';
import CrewWagesPage from './CrewWagesPage';
import RemainingWagesPage from './RemainingWagesPage';
import { UserContext } from '../../../../utils/context/UserContext';

export default function ComputeTabPage() {
  const { validateAccessToFeature } = useContext(UserContext);

  const checkAvailableTabs = () => {
    const tabs = [];
    if (validateAccessToFeature('sub_feature_tran_pp_compute_home_allotment')) {
      tabs.push({
        key: '1',
        label: 'Home Allotment',
        children: <HomeAllotmentPage />,
        disabled: !validateAccessToFeature(
          'sub_feature_tran_pp_compute_home_allotment'
        ),
      });
    }
    if (validateAccessToFeature('sub_feature_tran_pp_compute_mpo')) {
      tabs.push({
        key: '2',
        label: 'MPO',
        children: <MPOAllotmentPage />,
        disabled: !validateAccessToFeature('sub_feature_tran_pp_compute_mpo'),
      });
    }
    if (validateAccessToFeature('sub_feature_tran_pp_compute_slb')) {
      tabs.push({
        key: '3',
        label: 'Side Letter Bonus',
        children: <SideLetterAllotmentPage />,
        disabled: !validateAccessToFeature('sub_feature_tran_pp_compute_slb'),
      });
    }
    if (validateAccessToFeature('sub_feature_tran_pp_compute_crew_wages')) {
      tabs.push({
        key: '4',
        label: 'Crew Wages',
        children: <CrewWagesPage />,
        disabled: !validateAccessToFeature(
          'sub_feature_tran_pp_compute_crew_wages'
        ),
      });
    }
    if (
      validateAccessToFeature('sub_feature_tran_pp_compute_remaining_wages')
    ) {
      tabs.push({
        key: '5',
        label: 'Remaining Wages',
        children: <RemainingWagesPage />,
        disabled: !validateAccessToFeature(
          'sub_feature_tran_pp_compute_remaining_wages'
        ),
      });
    }
    return tabs;
  };

  return (
    <Content>
      <Tabs items={checkAvailableTabs()} centered />
    </Content>
  );
}
