import DeductionType from '../types/DeductionType';
import FixedPagibigType from '../types/FixedPagibigType';

export const getFixedPagibigFields = (
  isLoading,
  state = FixedPagibigType,
  setState,
  setHasChanges
) => {
  return [
    {
      type: 'number',
      name: 'employee_share',
      placeholder: 'Employee share',
      label: 'Employee share',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: state.employee_share,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, employee_share: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'employer_share',
      placeholder: 'Employer share',
      label: 'Employer share',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.employer_share,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, employer_share: value });
        setHasChanges(true);
      },
    },
  ];
};
