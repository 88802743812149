import dayjs from 'dayjs';
import VesselSetupType from '../types/VesselSetupType';

export const getVesselSetupFirst = (
  isLoading,
  state = VesselSetupType,
  setState,
  setHasChanges
) => {
  return [
    {
      type: 'dropdown',
      name: 'divisor',
      placeholder: 'Divisor',
      autofocus: true,
      label: 'Divisor',
      required: false,
      disabled: isLoading,
      value: state.divisor,
      dropdownOptions: [
        { id: 1, description: 'No. of working days' },
        { id: 2, description: 'Applicable months' },
      ],
      setValue: (value, option) => {
        setState({
          ...state,
          divisor: value,
        });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'no_of_days',
      placeholder: '',
      label: 'No. of Days',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.no_of_days,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, no_of_days: value });
      },
    },
    {
      type: 'number',
      name: 'allotment_cut_off',
      placeholder: '',
      label: 'Home allotment Cut-off day',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.allotment_cut_off,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, allotment_cut_off: value });
        setHasChanges(true);
      },
    },
  ];
};

export const getVesselSetupSecond = (
  isLoading,
  state = VesselSetupType,
  setState,
  setHasChanges
) => {
  return [
    {
      type: 'toggle',
      name: 'cut_off_pro_rate1',
      placeholder: '',
      label:
        'Include after Cut-off pro-rate computation to the following month with pro-rate government contribution',
      required: false,
      disabled: isLoading,
      value: state.cut_off_pro_rate === 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          cut_off_pro_rate: checked ? 1 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        style: { marginBottom: '4px' },
      },
    },
    {
      type: 'toggle',
      name: 'cut_off_pro_rate2',
      placeholder: '',
      label:
        'Exclude after Cut-off pro-rate computation to the following month but include government contribution based on Basic Salary',
      required: false,
      disabled: isLoading,
      value: state.cut_off_pro_rate === 2 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          cut_off_pro_rate: checked ? 2 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        style: { marginBottom: '4px' },
      },
    },
    {
      type: 'toggle',
      name: 'prorate_basic_salary',
      placeholder: '',
      label: 'Prorate Basic Salary Computation rounded off to the nearest one',
      required: false,
      disabled: isLoading,
      value: state.prorate_basic_salary === 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          prorate_basic_salary: checked ? 1 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        className: 'text-start',
      },
    },
  ];
};

export const getVesselSetupThird = (
  isLoading,
  state = VesselSetupType,
  setState,
  unionOptions,
  setHasChanges
) => {
  return [
    {
      type: 'dropdown',
      name: 'union_dues_type_id',
      placeholder: 'Union Dues Type',
      label: 'Union Dues Type',
      required: false,
      disabled: isLoading,
      value: state.union_dues_type_id,
      dropdownOptions: unionOptions,
      setValue: (value, option) => {
        setState({
          ...state,
          union_dues_type_id: value,
        });
        setHasChanges(true);
      },
    },
    {
      type: 'date',
      name: 'effectivity_date',
      placeholder: 'Effectivity',
      label: 'Effectivity',
      required: false,
      disabled: isLoading,
      value: state.effectivity_date,
      setValue: (_, dateString) => {
        if (dateString) {
          const date = dayjs(dateString, 'YYYY-MM-DD');
          setState({ ...state, effectivity_date: date });
        } else {
          setState({ ...state, effectivity_date: null });
        }
        setHasChanges(true);
      },
    },
  ];
};

export const getVesselSetupFourth = (
  isLoading,
  state = VesselSetupType,
  setState,
  setHasChanges
) => {
  return [
    {
      type: 'number',
      name: 'regular_allotment',
      placeholder: 'Regular allotment',
      label: 'Regular Allotment Ceiling Amount in USD',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.regular_allotment,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, regular_allotment: value });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 18 },
        wrapperCol: { span: 6 },
        style: { marginBottom: '4px' },
      },
    },
    {
      type: 'number',
      name: 'batch_no',
      placeholder: 'Batch no.',
      label: 'Batch No. (Applicable for BPI download only)',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.batch_no,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, batch_no: value });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 18 },
        wrapperCol: { span: 6 },
        style: { marginBottom: '4px' },
      },
    },
    {
      type: 'toggle',
      name: 'is_pagibig_member',
      placeholder: '',
      label: 'If the vessel a Pag-Ibig Member',
      required: false,
      disabled: isLoading,
      value: state.is_pagibig_member == 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          is_pagibig_member: checked ? 1 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        style: { marginBottom: '4px' },
      },
    },
    {
      type: 'toggle',
      name: 'is_hdmf_shoulder_by_company',
      placeholder: '',
      label: 'If Pag-Ibig contribution shouldered by Employer',
      required: false,
      disabled: isLoading,
      value: state.is_hdmf_shoulder_by_company == 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          is_hdmf_shoulder_by_company: checked ? 1 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        style: { marginBottom: '4px' },
      },
    },
    {
      type: 'toggle',
      name: 'pro_rate_computations_gov_deduc',
      placeholder: '',
      label: 'Allow pro-rate computation of government deductions',
      required: false,
      disabled: isLoading,
      value: state.pro_rate_computations_gov_deduc == 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          pro_rate_computations_gov_deduc: checked ? 1 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        style: { marginBottom: '4px' },
      },
    },
    {
      type: 'toggle',
      name: 'is_amosup_member',
      placeholder: '',
      label: 'Is the vessel Amosup Member',
      required: false,
      disabled: isLoading,
      value: state.is_amosup_member == 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          is_amosup_member: checked ? 1 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        style: { marginBottom: '4px' },
      },
    },
  ];
};

export const getVesselSetupFifth = (
  isLoading,
  state = VesselSetupType,
  setState,
  setHasChanges
) => {
  return [
    {
      type: 'toggle',
      name: 'round_pro_rate_allotment1',
      placeholder: '',
      label: 'Rounded Up allotment',
      required: false,
      disabled: isLoading,
      value: state.round_pro_rate_allotment == 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          round_pro_rate_allotment: checked ? 1 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        style: { marginBottom: '4px' },
      },
    },
    {
      type: 'toggle',
      name: 'round_pro_rate_allotment2',
      placeholder: '',
      label: 'Rounded Down allotment',
      required: false,
      disabled: isLoading,
      value: state.round_pro_rate_allotment == 2 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          round_pro_rate_allotment: checked ? 2 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        style: { marginBottom: '4px' },
      },
    },
    {
      type: 'toggle',
      name: 'round_percentage_allotment1',
      placeholder: '',
      label: 'Rounded Up percentage allotment',
      required: false,
      disabled: isLoading,
      value: state.round_percentage_allotment == 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          round_percentage_allotment: checked ? 1 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        style: { marginBottom: '4px' },
      },
    },
    {
      type: 'toggle',
      name: 'round_percentage_allotment2',
      placeholder: '',
      label: 'Rounded Down percentage allotment',
      required: false,
      disabled: isLoading,
      value: state.round_percentage_allotment == 2 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          round_percentage_allotment: checked ? 2 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        style: { marginBottom: '4px' },
      },
    },
  ];
};

export const getVesselSetupAmosup = (
  isLoading,
  state = VesselSetupType,
  setState,
  setHasChanges
) => {
  return [
    {
      type: 'toggle',
      name: 'amosup_provident',
      placeholder: '',
      label: 'Rounded off the computation in Provident Report',
      required: false,
      disabled: isLoading,
      value: state.amosup_provident == 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          amosup_provident: checked ? 1 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        style: { marginBottom: '4px' },
      },
    },
    {
      type: 'toggle',
      name: 'amosup_maap',
      placeholder: '',
      label: 'Rounded off computation in MAAP Report',
      required: false,
      disabled: isLoading,
      value: state.amosup_maap == 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          amosup_maap: checked ? 1 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        style: { marginBottom: '4px' },
      },
    },
    {
      type: 'toggle',
      name: 'amosup_training',
      placeholder: '',
      label: 'Rounded off computation in Training Report',
      required: false,
      disabled: isLoading,
      value: state.amosup_training == 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          amosup_training: checked ? 1 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        style: { marginBottom: '4px' },
      },
    },
    {
      type: 'toggle',
      name: 'amosup_survivorship_pension',
      placeholder: '',
      label: 'Rounded off computation in Survivorship Pension',
      required: false,
      disabled: isLoading,
      value: state.amosup_survivorship_pension == 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          amosup_survivorship_pension: checked ? 1 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        style: { marginBottom: '4px' },
      },
    },
    {
      type: 'number',
      name: 'guaranteed_ot',
      placeholder: '',
      label: 'Guaranteed OT Hours',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.guaranteed_ot,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, guaranteed_ot: value });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 18 },
        wrapperCol: { span: 6 },
        style: { marginBottom: '4px' },
      },
    },
  ];
};
