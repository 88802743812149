export const BASE_ENDPOINT =
  'https://xgitcorp-uat.com/scanmarcrewpayrollsystem/public/api';

export const REQUEST_HEADERS = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

export const USER_LOGIN_ENDPOINT = BASE_ENDPOINT + '/login';

// TRANSACTIONS
export const GET_PROCESSED_HOME_ALLOTMENT_ENDPOINT =
  BASE_ENDPOINT + '/list_processed_home_permonth';
export const POST_PROCESS_HOME_ALLOTMENT_V2_ENDPOINT =
  BASE_ENDPOINT + '/process_home_allotment';
export const GET_HOME_ALLOTMENT_PER_MONTH_ENDPOINT =
  BASE_ENDPOINT + '/show_home_allot_per_month';
export const GET_HOME_PROCESSED_PER_VESSEL_ENDPOINT =
  BASE_ENDPOINT + '/list_processed_home_perves';
export const GET_CREW_BY_VESSEL_ENDPOINT =
  BASE_ENDPOINT + '/get_crew_by_vessel';
export const POST_UPDATE_SALARY_BREAKDOWN_ENDPOINT =
  BASE_ENDPOINT + '/update_api_allotment_header';
export const GET_PROCESSED_DEDUCTION_LEGENDS_ENDPOINT =
  BASE_ENDPOINT + '/processed_deduction';
export const GET_PROCESSED_DEDUCTION_LEGENDS_MPO_ENDPOINT =
  BASE_ENDPOINT + '/processed_deduction_mpo';
export const GET_PROCESSED_DEDUCTION_LEGENDS_SLB_ENDPOINT =
  BASE_ENDPOINT + '/processed_deduction_slb';

export const POST_PROCESS_MPO_ENDPOINT =
  BASE_ENDPOINT + '/process_mpo_allotment';
export const GET_MPO_PROCESSED_PER_VESSEL_ENDPOINT =
  BASE_ENDPOINT + '/show_mpo_allot_per_month';
export const GET_PROCESSED_MPO_PER_MONTH_ENDPOINT =
  BASE_ENDPOINT + '/list_processed_mpo_permonth';

export const POST_PROCESS_SLB_ENDPOINT =
  BASE_ENDPOINT + '/process_slb_allotment';
export const GET_SLB_PROCESSED_PER_VESSEL_ENDPOINT =
  BASE_ENDPOINT + '/show_slb_allot_per_month';
export const GET_PROCESSED_SLB_PER_MONTH_ENDPOINT =
  BASE_ENDPOINT + '/list_processed_slb_permonth';

// DELETE PROCESSED COMPUTATION
export const DELETE_HOME_ALLOTMENT_ENDPOINT =
  BASE_ENDPOINT + '/delete_home_allotment';
export const DELETE_MPO_ENDPOINT = BASE_ENDPOINT + '/delete_mpo_allotment';
export const DELETE_SIDE_LETTER_ENDPOINT =
  BASE_ENDPOINT + '/delete_slb_allotment';

// VESSELS
export const GET_VESSELS_ENDPOINT = BASE_ENDPOINT + '/show_all_vessel';
export const GET_SHOW_VESSEL_SETUP_ENDPOINT = BASE_ENDPOINT + '/show_set_up';
export const POST_MODIFY_VESSET_SETUP_ENDPOINT =
  BASE_ENDPOINT + '/modify_set_up';
export const GET_VESSEL_DETAILS_ENDPOINT =
  BASE_ENDPOINT + '/show_vessel_w_setup';
export const GET_GROUPS_ENDPOINT = BASE_ENDPOINT + '/show_all_group';

// UNION DUES
export const SHOW_ALL_UNION_DUES_ENDPOINT =
  BASE_ENDPOINT + '/show_all_union_dues';
export const POST_ADD_UNION_DUES_ENDPOINT = BASE_ENDPOINT + '/add_union_dues';
export const POST_MODIFY_UNION_DUES_ENDPOINT =
  BASE_ENDPOINT + '/modify_union_dues';
export const DELETE_UNION_DUES_ENDPOINT = BASE_ENDPOINT + '/delete_union_dues';

// DEDUCTIONS
export const GET_ALL_DEDUCTIONS_ENDPOINT =
  BASE_ENDPOINT + '/show_all_deduction';
export const POST_ADD_DEDUCTIONS_ENDPOINT = BASE_ENDPOINT + '/add_deduction';
export const POST_MODIFY_DEDUCTIONS_ENDPOINT =
  BASE_ENDPOINT + '/modify_deduction';
export const POST_DELETE_DEDUCTIONS_ENDPOINT =
  BASE_ENDPOINT + '/delete_deduction';
export const GET_SHOW_ALL_TRANSACTION_DEDUCTIONS_ENDPOINT =
  BASE_ENDPOINT + '/show_all_deduc_config';
export const GET_SHOW_ALL_TRANSACTION_DEDUCTIONS_PER_CREW_ENDPOINT =
  BASE_ENDPOINT + '/show_deduc_config';
export const GET_SHOW_SCHEDULE_OF_DEDUCTIONS_PER_CREW_ENDPOINT =
  BASE_ENDPOINT + '/show_schedule_deduction';
export const POST_ADD_SCHEDULE_OF_DEDUCTION_ENDPOINT =
  BASE_ENDPOINT + '/add_schedule_deduction';

// OTHER DEDUCTIONS
export const GET_ALL_OTHER_DEDUCTIONS_ENDPOINT =
  BASE_ENDPOINT + '/show_all_other_deduction';
export const GET_SHOW_ALL_OTHER_DEDUCTIONS_PER_CREW_ENDPOINT =
  BASE_ENDPOINT + '/show_all_deduc_config_per_crew';
export const POST_ADD_OTHER_DEDUCTIONS_ENDPOINT =
  BASE_ENDPOINT + '/add_other_deduction';
export const POST_MODIFY_OTHER_DEDUCTIONS_ENDPOINT =
  BASE_ENDPOINT + '/modify_other_deduction';
export const POST_DELETE_OTHER_DEDUCTIONS_ENDPOINT =
  BASE_ENDPOINT + '/delete_other_deduction';

export const POST_ADD_ALLOTTEE_HEADER_OTHER_DEDUCTIONS_ENDPOINT =
  BASE_ENDPOINT + '/add_deduc_config';
export const POST_MODIFY_ALLOTTEE_HEADER_OTHER_DEDUCTIONS_ENDPOINT =
  BASE_ENDPOINT + '/modify_deduc_config';
export const POST_DELETE_ALLOTTEE_HEADER_OTHER_DEDUCTIONS_ENDPOINT =
  BASE_ENDPOINT + '/delete_deduc_config';
export const POST_ADD_ALLOTTEE_PER_ALLOTTEE_OTHER_DEDUCTIONS_ENDPOINT =
  BASE_ENDPOINT + '/add_deducAllt_config';
export const POST_MODIFY_ALLOTTEE_PER_ALLOTTEE_OTHER_DEDUCTIONS_ENDPOINT =
  BASE_ENDPOINT + '/modify_deducAllt_config';

export const GET_FIXED_PAGIBIG_PER_CREW_ENDPOINT =
  BASE_ENDPOINT + '/show_per_crew_fixed_pagibig';
export const POST_ADD_FIXED_PAGIBIG_PER_CREW_ENDPOINT =
  BASE_ENDPOINT + '/add_fixed_pagibig';
export const POST_UPDATE_FIXED_PAGIBIG_PER_CREW_ENDPOINT =
  BASE_ENDPOINT + '/modify_fixed_pagibig';

export const GET_PROCESS_ADJUSTMENT_PER_CREW_ENDOINT =
  BASE_ENDPOINT + '/show_allotment_adjustment';
export const POST_PROCESS_ADJUSTMENT_PER_CREW_ENDPOINT =
  BASE_ENDPOINT + '/add_allotment_adjustment';

// GOVT CONTRIBUTION
export const GET_ALL_SSS_CONTRIBUTION_ENDPOINT =
  BASE_ENDPOINT + '/show_all_sss';
export const GET_SSS_PER_YEAR_CONTRIBUTION_ENDPOINT =
  BASE_ENDPOINT + '/get_year_sss';
export const POST_ADD_SSS_CONTRIBUTION_ENDPOINT = BASE_ENDPOINT + '/add_sss';
export const POST_MODIFY_SSS_CONTRIBUTION_ENDPOINT =
  BASE_ENDPOINT + '/modify_sss';
export const POST_COPY_SSS_CONTRIBUTION_ENDPOINT = BASE_ENDPOINT + '/copy_sss';
export const POST_DELETE_SSS_CONTRIBUTION_ENDPOINT =
  BASE_ENDPOINT + '/delete_sss';

export const GET_ALL_PHILHEALTH_CONTRIBUTION_ENDPOINT =
  BASE_ENDPOINT + '/show_all_philhealth';
export const GET_PHILHEALTH_PER_YEAR_CONTRIBUTION_ENDPOINT =
  BASE_ENDPOINT + '/get_year_philhealth';
export const POST_ADD_PHILHEALTH_CONTRIBUTION_ENDPOINT =
  BASE_ENDPOINT + '/add_philhealth';
export const POST_MODIFY_PHILHEALTH_CONTRIBUTION_ENDPOINT =
  BASE_ENDPOINT + '/modify_philhealth';
export const POST_COPY_PHILHEALTH_CONTRIBUTION_ENDPOINT =
  BASE_ENDPOINT + '/copy_philhealth';
export const POST_DELETE_PHILHEALTH_CONTRIBUTION_ENDPOINT =
  BASE_ENDPOINT + '/delete_philhealth';

export const GET_ALL_PAGIBIG_CONTRIBUTION_ENDPOINT =
  BASE_ENDPOINT + '/show_all_pagibig';
export const POST_ADD_PAGIBIG_CONTRIBUTION_ENDPOINT =
  BASE_ENDPOINT + '/add_pagibig';
export const POST_MODIFY_PAGIBIG_CONTRIBUTION_ENDPOINT =
  BASE_ENDPOINT + '/modify_pagibig';
export const POST_DELETE_PAGIBIG_CONTRIBUTION_ENDPOINT =
  BASE_ENDPOINT + '/delete_pagibig';

// CUT OFF
export const GET_ALL_CREW_CUT_OFF_ENDPOINT =
  BASE_ENDPOINT + '/show_all_cut_off';
export const POST_MODIFY_CREW_CUT_OFF_ENDPINT = BASE_ENDPOINT + '/add_cut_off';
export const POST_DELETE_CREW_CUT_OFF_ENDPINT =
  BASE_ENDPOINT + '/delete_cut_off';
export const GET_ALL_FULL_MONTH_ENDPOINT = BASE_ENDPOINT + '/show_full_month';
export const POST_MODIFY_CREW_FULL_MONTH_ENDPOINT =
  BASE_ENDPOINT + '/add_full_month';

// ALLOTTEE
export const POST_UPDATE_ALLOTMENT_HEADER_ENDPOINT =
  BASE_ENDPOINT + '/update_api_allotment_header';
export const POST_ADD_ALLOTMENT_ENDPOINT = BASE_ENDPOINT + '/add_api_allottee';
export const POST_UPDATE_ALLOTTEE_ENDPOINT =
  BASE_ENDPOINT + '/update_api_allottee';
export const POST_UPDATE_SIDE_LETTER_ALLOTTEE_ENDPOINT =
  BASE_ENDPOINT + '/update_api_allottee_side_letter';
export const POST_ADD_SIDE_LETTER_CREW_AMOUNT_ENDPOINT =
  BASE_ENDPOINT + '/add_sideletter';
export const POST_UPDATE_MPO_ALLOTTEE_ENDPOINT =
  BASE_ENDPOINT + '/update_api_allottee_mpo';
export const POST_DELETE_ALLOTTEE_ENDPOINT =
  BASE_ENDPOINT + '/delete_api_allottee';

// BANK
export const GET_ALL_BANK_ENDPOINT = BASE_ENDPOINT + '/get_bank_codes';

// EXCHANGE RATE
export const GET_ALL_EXCHANGE_RATE_ENDPOINT =
  BASE_ENDPOINT + '/show_all_exchange_rate';
export const GET_EXCHANGE_RATE_PER_ID_ENDPOINT =
  BASE_ENDPOINT + '/show_exchange_rate';
export const POST_ADD_EXCHANGE_RATE_ENDPOINT =
  BASE_ENDPOINT + '/add_exchange_rate';
export const POST_MODIFY_EXCHANGE_RATE_ENDPOINT =
  BASE_ENDPOINT + '/modify_exchange_rate';
export const POST_DELETE_EXCHANGE_RATE_ENDPOINT =
  BASE_ENDPOINT + '/delete_exchange_rate';

// CREW WAGES
export const GET_ALL_WAGES_ENDPOINT = BASE_ENDPOINT + '/wage_scale';
export const GET_ALL_CREW_WAGES_ENDPOINT =
  BASE_ENDPOINT + '/show_all_crew_wages_maintenance';

// MPO REQUEST
export const GET_SHOW_MPO_REQUEST_ALLOTMENT = BASE_ENDPOINT + '/show_mpo_allt';
export const GET_SHOW_ALL_MPO_REQUEST_ALLOTMENT =
  BASE_ENDPOINT + '/show_all_mpo_allt';
export const POST_ADD_MPO_REQUEST_ALLOTMENT = BASE_ENDPOINT + '/add_mpo_allt';
export const POST_DELETE_MPO_REQUEST_ALLOTMENT =
  BASE_ENDPOINT + '/delete_mpo_allt';

// SIDE LETTER BONUS
export const GET_SHOW_SLB_REQUEST_ALLOTMENT =
  BASE_ENDPOINT + '/show_sideletter';
export const GET_SHOW_ALL_SLB_ALLT_ENDPOINT =
  BASE_ENDPOINT + '/show_all_slb_allt';
export const POST_DELETE_SLB_REQUEST_ALLOTMENT =
  BASE_ENDPOINT + '/delete_sideletter';
export const POST_ADD_SLB_REQUEST_ALLOTMENT = BASE_ENDPOINT + '/add_sideletter';

// API RESPONSE
export const API_CALL_ERROR = 'api_call_error';

// NOTIFICATION TYPE DEFINITIONS
export const SUCCESS_NOTIFICATION_TYPE = 'success';
export const INFO_NOTIFICATION_TYPE = 'info';
export const WARNING_NOTIFICATION_TYPE = 'warning';
export const ERROR_NOTIFICATION_TYPE = 'error';
