export default {
  id: null,
  code: null,
  desc: null,
  sss: null,
  philhealth: null,
  pag_ibig: null,
  union: null,
  acct_code: null,
  sl_acct_code: null,
  expense_acct_code: null,
  gov_contri_id: null,
  is_deleted: null,
  delete_by: null,
  created_at: null,
  updated_at: null,
};
