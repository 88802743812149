export default {
  crew_no: null,
  crew_name: null,
  transdate: null,
  startdeduct: null,
  currency: null,
  amount: null,
  amounttoded: null,
  stoppayment: null,
  assign_to: null,
  od_id: null,
  other_deduction_type: null,
  remarks: null,
  refno: null,
};
