import {
  Button,
  DatePicker,
  Input,
  Popconfirm,
  Space,
  Switch,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { AiFillDelete, AiFillEdit, AiFillPrinter } from 'react-icons/ai';
import { FaEye, FaWrench } from 'react-icons/fa';
import { MdOutlineCancel } from 'react-icons/md';

export function getFinalPayTable(handleAction) {
  return [
    {
      title: 'No',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Crew no',
      dataIndex: 'crew_no',
      key: 'crew_no',
      sorter: (a, b) => a.crew_no - b.crew_no,
    },
    {
      title: 'Crew name',
      dataIndex: 'crew_name',
      key: 'crew_name',
      sorter: (a, b) => a.crew_name.localeCompare(b.crew_name),
    },
    {
      title: 'USD Net Amount',
      dataIndex: 'net_amount',
      key: 'net_amount',
      sorter: (a, b) => a.net_amount - b.net_amount,
    },
    {
      title: 'Position',
      dataIndex: 'position_name',
      key: 'position_name',
      sorter: (a, b) => a.position_name.localeCompare(b.position_name),
    },
    {
      title: 'Vessel',
      dataIndex: 'vesname',
      key: 'vesname',

      sorter: (a, b) => a.vesname.localeCompare(b.vesname),
    },
    {
      title: 'Sign on',
      dataIndex: 'sign_on',
      key: 'sign_on',
    },
    {
      title: 'Sign off',
      dataIndex: 'sign_off',
      key: 'sign_off',
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            onClick={() => handleAction(record, 'edit')}
            icon={<FaWrench size={15} />}
            disabled={record.is_disabled}
          />
          <Button
            onClick={() => handleAction(record, 'cancel')}
            icon={<MdOutlineCancel size={20} color='red' />}
            disabled={record.is_disabled}
          />
          <Button
            type='primary'
            onClick={() => handleAction(record, 'print')}
            icon={<AiFillPrinter size={15} />}
          ></Button>
        </Space>
      ),
    },
  ];
}
