import { Button, Input, Popconfirm, Space, Switch } from 'antd';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';

export function getSideLetterBonusTable(handleAction) {
  return [
    {
      title: 'Name',
      dataIndex: 'allottee_name',
      fixed: 'left',
      render: (text, record) => {
        return `${record.last_name}, ${record.first_name} ${record.middle_name}`;
      },
    },
    {
      title: 'Account Number',
      dataIndex: 'acct_no',
      key: 'acct_no',
      sorter: (a, b) => a.acct_no.localeCompare(b.acct_no),
    },
    {
      title: 'Dollar Acct.',
      key: 'is_dollar_account',
      render: (text, record) => (
        <Switch
          size="small"
          disabled={true}
          defaultChecked={record.is_dollar_account === 0 ? false : true}
        />
      ),
    },
    {
      title: 'Percentage',
      dataIndex: 'side_letter_percentage',
      key: 'side_letter_percentage',
    },
    {
      title: 'USD Amount',
      dataIndex: 'side_letter_usd_amount',
      key: 'side_letter_usd_amount',
    },
    {
      title: 'Fixed Peso',
      dataIndex: 'side_letter_peso_amount',
      key: 'side_letter_peso_amount',
    },
    {
      title: 'Rem. salary',
      key: 'side_letter_applied_deduction',
      render: (text, record) => (
        <Switch
          size="small"
          disabled
          checked={record.side_letter.applied_deduction === '1' ? true : false}
        />
      ),
    },
    {
      title: 'SLB Date',
      dataIndex: 'side_letter_request_date',
      key: 'side_letter_request_date',
    },
    {
      title: 'Side Letter Bonus',
      key: 'side_letter_is_checked',
      render: (text, record) => (
        <Switch
          size="small"
          disabled
          checked={record.side_letter.is_checked === 1 ? true : false}
        />
      ),
    },
    {
      title: 'Bank Code',
      dataIndex: 'bankcode',
      key: 'bankcode',
    },
    {
      title: 'Bank Name',
      dataIndex: 'bankdesc',
      key: 'bankdesc',
    },
    {
      title: 'Branch',
      dataIndex: 'bankbranch',
      key: 'bankbranch',
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => handleAction(record, 'side_letter')}
            icon={<FaWrench size={15} />}
          />
          <Popconfirm
            title="Delete record"
            description="Are you sure to delete this record?"
            onConfirm={() => handleAction(record, 'delete')}
            okText="Yes"
            cancelText="No"
          >
            <Button danger icon={<AiFillDelete size={15} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
}
