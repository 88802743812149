export default {
  id: null,
  vescode: null,
  crew_no: null,
  employee_share: null,
  employer_share: null,
  is_deleted: null,
  delete_by: null,
  created_at: null,
  updated_at: null,
};
