import dayjs from 'dayjs';

export const getCrewWagesDetailsFields = (
  isLoading,
  state,
  setState,
  setHasChanges,
  selectedTemplate
) => {
  return [
    {
      type: 'date',
      name: 'sign_on',
      placeholder: 'Sign on',
      label: 'Sign on',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.sign_on,
      setValue: (_, dateString) => {
        if (dateString) {
          const date = dayjs(dateString, 'YYYY-MM-DD');
          setState({ ...state, sign_on: date });
        } else {
          setState({ ...state, sign_on: null });
        }
        setHasChanges(true);
      },
    },
    {
      type: 'date',
      name: 'embark_date',
      placeholder: 'Embark date',
      label: 'Embark date',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.sign_on,
      setValue: (_, dateString) => {
        if (dateString) {
          const date = dayjs(dateString, 'YYYY-MM-DD');
          setState({ ...state, embark_date: date });
        } else {
          setState({ ...state, embark_date: null });
        }
        setHasChanges(true);
      },
    },
    {
      type: 'date',
      name: 'sign_off',
      placeholder: 'Sign off',
      label: 'Sign off',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.sign_off,
      setValue: (_, dateString) => {
        if (dateString) {
          const date = dayjs(dateString, 'YYYY-MM-DD');
          setState({ ...state, sign_off: date });
        } else {
          setState({ ...state, sign_off: null });
        }
        setHasChanges(true);
      },
    },
    {
      type: 'date',
      name: 'disembark',
      placeholder: 'Arrive Manila',
      label: 'Arrive Manila',
      autofocus: false,
      required: false,
      disabled: isLoading || state.disembark == '',
      value: state.disembark,
      setValue: (_, dateString) => {
        if (dateString) {
          const date = dayjs(dateString, 'YYYY-MM-DD');
          setState({ ...state, disembark: date, crew_status: 'disembarked' });
        } else {
          setState({ ...state, disembark: null });
        }
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'home_allotment',
      placeholder: 'Home Allotment',
      label: 'Home Allotment',
      autofocus: false,
      required: selectedTemplate?.cw_format == '1',
      hidden: selectedTemplate?.cw_format != '1',
      disabled: isLoading,
      value: state.home_allotment,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, home_allotment: value });
        setHasChanges(true);
      },
    },
  ];
};
