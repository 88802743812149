export default {
  wages_id: null,
  finalpay_id: null,
  desc: null,
  details: null,
  based_on_days: null,
  usd_amt: null,
  php_amt: null,

  amount: null,
  period_from: null,
  period_to: null,
  based_on_days_amount: null,
};
