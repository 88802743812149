import React, { useContext } from 'react';
import {
  PDFViewer,
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from '@react-pdf/renderer';
import HomeAllotmentHeaderType from '../../utils/types/HomeAllotmentHeaderType';
import dayjs from 'dayjs';
import logoImage from '../../assets/icon-name.png';
import { UserContext } from '../../utils/context/UserContext';

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 10,
    paddingBottom: 20,
  },
  container: {
    flex: 1,
    alignItems: 'center',
  },
  headerImage: {
    height: 60,
    width: 60,
  },
  headerText: {
    fontSize: 6,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
  },
  tableRow: {
    flexDirection: 'row',
    borderTopWidth: 0,
    paddingBottom: 0,
  },
  tableCell: {
    width: '6%',
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    paddingTop: 5,
    paddingBottom: 0,
    paddingHorizontal: 1,
    fontSize: '7px',
    marginBottom: 0,
  },

  pageNumber: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 10,
    fontSize: 6,
    textAlign: 'left',
    paddingHorizontal: 10,
  },
  legends: {
    //   position: 'absolute',
    //   bottom: 30,
    //   left: 80,
    //   right: 0,
    fontSize: 8,
    textAlign: 'left',
  },

  bottomContainer: { flexDirection: 'row', margin: 0, padding: 0 },
  column: {
    flex: 1,
    margin: 10,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    // borderTopWidth: 1,
    // textAlign: 'center',
    // backgroundColor: 'lightgray',
    paddingTop: 10,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
});

export default function CrewWagesHomeAllotmentPDF({
  crewList,
  selectedVessel,
  month,
  year,
  grandTotals,
}) {
  const { inputToLocale, generateFiveNumber } = useContext(UserContext);

  const parseValue = (value) => {
    if (parseFloat(value) != 0) {
      return inputToLocale(value);
    } else {
      return '-';
    }
  };

  return (
    <PDFViewer style={{ width: '98%', height: '100vh' }}>
      <Document>
        <Page size='A4' style={styles.page} wrap>
          <View style={{ ...styles.bottomContainer, marginBottom: -15 }} fixed>
            <View
              style={{
                ...styles.column,
                alignItems: 'flex-end',
                paddingVertical: 0,
                marginRight: 0,
                marginTop: 4,
              }}
            >
              <Image src={logoImage} style={styles.headerImage} />
            </View>
            <View style={{ ...styles.column, paddingTop: 35, marginLeft: 1 }}>
              <Text style={styles.headerText}>
                {`(${selectedVessel.vessel_code}) ${selectedVessel.vessel_name}`}
              </Text>
              <Text style={styles.headerText}>HOME ALLOTMENT</Text>
            </View>
          </View>
          <View style={styles.table}>
            <View style={{ ...styles.tableRow }}>
              <View style={{ ...styles.tableCell, width: '45%' }}>
                <Text></Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '20%',
                  border: 1,
                  borderBottom: 0,
                  borderRight: 0,
                }}
              >
                <Text>{`${dayjs(`${month}-${year}`, 'M-YYYY')
                  .subtract(1, 'month')
                  .format('MMM YYYY')
                  .toUpperCase()} home allotments`}</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '10%',
                  border: 1,
                  borderBottom: 0,
                }}
              >
                <Text>Previous Month's</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '20%' }}>
                <Text></Text>
              </View>
            </View>
            <View style={{ ...styles.tableRow }} fixed>
              <View
                style={{
                  ...styles.tableCell,
                  border: 1,
                  borderRight: 0,
                  width: '20%',
                }}
              >
                <Text>Crew</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  border: 1,
                  borderRight: 0,
                  width: '25%',
                }}
              >
                <Text>Position</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '10%',
                  border: 1,
                  borderRight: 0,
                  textAlign: 'right',
                }}
              >
                <Text>As Billed</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '10%',
                  border: 1,
                  borderRight: 0,
                  textAlign: 'right',
                }}
              >
                <Text>Should Be</Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '10%',
                  border: 1,
                  borderRight: 0,
                }}
              >
                <Text>Adjustments</Text>
              </View>

              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '10%',
                  border: 1,
                  borderRight: 0,
                }}
              >
                <Text>
                  {dayjs(`${month}-${year}`, 'M-YYYY')
                    .format('MMM YYYY')
                    .toUpperCase()}
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '10%',
                  border: 1,
                }}
              >
                <Text>Total</Text>
              </View>
            </View>
            {crewList.map((crew, index) => (
              <View
                key={index}
                style={{
                  ...styles.tableRow,
                  borderBottom: index + 1 == crewList.length ? 1 : 0,
                }}
              >
                <View style={{ ...styles.tableCell, width: '20%' }}>
                  <Text>{crew.crew_name}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: '25%' }}>
                  <Text>{crew.position_name}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '10%',
                    textAlign: 'right',
                  }}
                >
                  <Text>{parseValue(crew.as_billed)}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '10%',
                    textAlign: 'right',
                  }}
                >
                  <Text>{parseValue(crew.should_be)}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '10%',
                    textAlign: 'right',
                  }}
                >
                  <Text>{parseValue(crew.prev_month_adjustment)}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '10%',
                    textAlign: 'right',
                  }}
                >
                  <Text>{parseValue(crew.current_month)}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '10%',
                    textAlign: 'right',
                  }}
                >
                  <Text>{parseValue(crew.total)}</Text>
                </View>
              </View>
            ))}
            <View style={{ ...styles.tableRow }} fixed>
              <View
                style={{
                  ...styles.tableCell,
                  border: 0,
                  width: '45%',
                }}
              ></View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '10%',
                  textAlign: 'right',
                  paddingHorizontal: 0,
                  borderBottom: 1,
                }}
              >
                <Text
                  style={{
                    padding: 0,
                    marginBottom: 1,
                    borderBottom: 1,
                  }}
                >
                  {parseValue(grandTotals?.total_as_billed)}
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '10%',
                  textAlign: 'right',
                  paddingHorizontal: 0,
                  borderBottom: 1,
                }}
              >
                <Text
                  style={{
                    padding: 0,
                    marginBottom: 1,
                    borderBottom: 1,
                  }}
                >
                  {parseValue(grandTotals?.total_should_be)}
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '10%',
                  paddingHorizontal: 0,
                  borderBottom: 1,
                }}
              >
                <Text
                  style={{
                    padding: 0,
                    marginBottom: 1,
                    borderBottom: 1,
                  }}
                >
                  {parseValue(grandTotals?.total_prev_adj)}
                </Text>
              </View>

              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '10%',
                  paddingHorizontal: 0,
                  borderBottom: 1,
                }}
              >
                <Text
                  style={{
                    padding: 0,
                    marginBottom: 1,
                    borderBottom: 1,
                  }}
                >
                  {parseValue(grandTotals?.total_curr)}
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  textAlign: 'right',
                  width: '10%',
                  paddingHorizontal: 0,
                  borderBottom: 1,
                }}
              >
                <Text
                  style={{
                    padding: 0,
                    marginBottom: 1,
                    borderBottom: 1,
                  }}
                >
                  {parseValue(grandTotals?.grand_total)}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.footer} wrap={false}>
            <View style={{ ...styles.bottomContainer, paddingLeft: 100 }}>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Prepared By:{`\n\n_____________________\n`} {`        `}
                  Administrator
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Checked By:{`\n\n_____________________\n`} {`           `}
                  Accountant
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Approved By:{`\n\n_____________________\n`} {`             `}
                  President
                </Text>
              </View>
            </View>
          </View>
          <Text
            style={{ ...styles.pageNumber, textAlign: 'right' }}
            render={({ pageNumber, totalPages }) =>
              `Page: ${pageNumber} / ${totalPages}`
            }
            fixed
          />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `Date printed: [${dayjs().format('YYYY-MM-DD HH:mm:ss')}]`
            }
            fixed
          />
        </Page>
      </Document>
    </PDFViewer>
  );
}
