import axios from 'axios';
import { BASE_ENDPOINT, REQUEST_HEADERS } from './_constants';

const GET_ALL_ACCESS_LEVELS_ENDPOINT =
  BASE_ENDPOINT + '/show_all_access_levels';
const MODIFY_ACCESS_LEVEL_ENDPOINT = BASE_ENDPOINT + '/modify_access_level';
const DELETE_ACCESS_LEVEL_ENDPOINT = BASE_ENDPOINT + '/delete_access';

export async function getAllAccessLevels() {
  try {
    const { data } = await axios.get(GET_ALL_ACCESS_LEVELS_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postModifyAccessLevel(payload) {
  try {
    const { data } = await axios.post(MODIFY_ACCESS_LEVEL_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeleteAccessLevel(id) {
  try {
    const { data } = await axios.post(
      `${DELETE_ACCESS_LEVEL_ENDPOINT}/${id}`,
      {},
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
