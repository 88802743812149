import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Table,
  Tabs,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../utils/context/UserContext';
import { GlobalContext } from '../../../App';
import dayjs from 'dayjs';
import { FaPlusCircle } from 'react-icons/fa';
import Search from 'antd/es/input/Search';
import FinalPayType from '../../../utils/types/FinalPayType';
import { getFinalPayTable } from '../../../utils/tableheader/Transactions/FinalPayTable';
import {
  getAllFinalPay,
  getFinalPayGovContri,
  getFinalPayPerCrew,
  getPostedFinalPay,
  postFinalPay,
  postGetComputedGovContri,
  postSaveFinalPay,
} from '../../../utils/api/FinalPayAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  WARNING_NOTIFICATION_TYPE,
} from '../../../utils/api/_constants';
import CustomModal from '../../../components/CustomModal/CustomModal';
import ConfirmationModal from '../../../components/CustomModal/ConfirmationModal';
import { getCrewByVessel } from '../../../utils/api/TransactionsAPI';
import { getFinalPayFields } from '../../../utils/inputfields/FinalPayFields';
import FormInput from '../../../components/FormInput/FormInput';
import { getFinalPayBreakdownTable } from '../../../utils/tableheader/Transactions/FinalPayBreakdownTable';
import { getAllWages } from '../../../utils/api/CrewWagesAPI';
import { getFinalPayWUAFields } from '../../../utils/inputfields/FinalPayWUAFields';
import { getFinalPayWUATable } from '../../../utils/tableheader/Transactions/FinalPayWUATable';
import FinalPayWUAType from '../../../utils/types/FinalPayWUAType';
import FinalPayAddtlType from '../../../utils/types/FinalPayAddtlType ';
import FinalPayGovConType from '../../../utils/types/FinalPayGovConType';
import { getFinalPayAddtlTable } from '../../../utils/tableheader/Transactions/FinalPayAddtlTable';
import { getFinalPayGovConTable } from '../../../utils/tableheader/Transactions/FinalPayGovConTable';
import { getFinalPayAddtlFields } from '../../../utils/inputfields/FinalPayAddtlFields.js';
import { getFinalPayGovContriFields } from '../../../utils/inputfields/FinalPayGovContriFields.js';
import FinalPayReportPDF from '../../../components/PDFReports/FinalPayReportPDF.js';
import axios from 'axios';

export default function FinalPayPage() {
  const TAG = 'Final Pay';

  const {
    getCurrentDate,
    doSearchInArray,
    parseToFloat,
    inputToLocale,
    validateAccessToFeature,
  } = useContext(UserContext);

  const { openNotification } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const [date, setDate] = useState({
    month: dayjs().month() + 1,
    year: dayjs().year(),
  });

  const [finalPayList, setFinalPayList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const [crewList, setCrewList] = useState([]);
  const [wagesList, setWagesList] = useState([]);
  const [originalWages, setOriginalWages] = useState([]);

  const [govContriDate, setGovContriDate] = useState({
    month: null,
    year: null,
  });
  const [exchangeRate, setExchangeRate] = useState(0);
  const [selectedFinalPay, setSelectedFinalPay] = useState(FinalPayType);
  const [selectedWUA, setSelectedWUA] = useState(FinalPayWUAType);
  const [selectedAddtl, setSelectedAddtl] = useState(FinalPayAddtlType);
  const [selectedGovtCon, setSelectedGovtCon] = useState(FinalPayGovConType);
  const [postedCrews, setPostedCrews] = useState([]);

  const [isFinalPayModalOpen, setIsFinalPayModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isPostConfirmationModalOpen, setIsPostConfirmationModalOpen] =
    useState(false);
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] =
    useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const [finalPayForm] = Form.useForm();
  const [finalPayWUAForm] = Form.useForm();
  const [finalPayAddtlForm] = Form.useForm();
  const [finalPayGovConForm] = Form.useForm();

  const [forPayslipPrinting, setForPayslipPrinting] = useState(null);

  const [includeCrewForPost, setIncludeCrewForPost] = useState([]);
  const [includeCrewForPostKeys, setIncludeCrewForPostKeys] = useState([]);

  const getRowClassName = (record, index) => {
    const exists = postedCrews.some((item) => item.crew_no == record.crew_no);
    return exists ? 'bg-warning' : '';
  };

  const computeWUAUSDAmount = (value, basicPayUSD = 0) => {
    let amount = 0;
    if (
      selectedWUA.based_on_days == 'T' &&
      selectedWUA?.based_on_days_amount > 0
    ) {
      amount = ((basicPayUSD / 30) * (value * calculateAccumulatedDays())) / 30;
    } else {
      amount = (parseFloat(value) * calculateAccumulatedDays()) / 30;
    }

    return amount;
  };

  const handleWUAAction = (record, action) => {
    switch (action) {
      case 'edit':
        const parseRecord = {
          ...record,
          usd_amt: parseFloat(record.usd_amt.replace(/,/g, '')),
          php_amt: parseFloat(record.php_amt.replace(/,/g, '')),
        };
        finalPayWUAForm.setFieldsValue(parseRecord);
        setSelectedWUA(parseRecord);
        break;
      case 'delete':
        const updatedWages = selectedFinalPay?.wages?.filter(
          (rec, ind) => record.key != ind
        );
        setSelectedFinalPay({ ...selectedFinalPay, wages: updatedWages });
        break;

      default:
        break;
    }
  };

  const handleAddtlAction = (record, action) => {
    switch (action) {
      case 'edit':
        const parseRecord = {
          ...record,
          usd_amt: parseFloat(record.usd_amt.replace(/,/g, '')),
          php_amt: parseFloat(record.php_amt.replace(/,/g, '')),
        };
        finalPayAddtlForm.setFieldsValue(parseRecord);
        setSelectedAddtl(parseRecord);
        break;
      case 'delete':
        const updatedWages = selectedFinalPay?.addWages?.filter(
          (rec, ind) => record.key != ind
        );
        setSelectedFinalPay({ ...selectedFinalPay, addWages: updatedWages });
        break;

      default:
        break;
    }
  };

  const handleGovConAction = (record, action) => {
    switch (action) {
      case 'edit':
        setSelectedGovtCon(record);
        finalPayGovConForm.setFieldsValue(record);
        break;

      case 'delete':
        const updatedWages = selectedFinalPay?.govContri?.filter(
          (rec, ind) => record.key != ind
        );
        setSelectedFinalPay({ ...selectedFinalPay, govContri: updatedWages });
        break;

      default:
        break;
    }
  };

  const handleBreakdownAction = (record) => {
    const updatedRecord = selectedFinalPay?.details?.filter(
      (rec) => rec.code != record.code
    );
    setSelectedFinalPay({ ...selectedFinalPay, details: updatedRecord });
  };

  const handleAction = async (record, action) => {
    switch (action) {
      case 'edit':
        const parseRecord = {
          ...record,
          sign_off: record.sign_off
            ? dayjs(record.sign_off, 'YYYY-MM-DD')
            : null,
        };
        finalPayForm.setFieldsValue(parseRecord);
        setSelectedFinalPay(parseRecord);
        setIsFinalPayModalOpen(true);
        break;
      case 'print':
        setIsLoading(true);
        const { data: res, error } = await getFinalPayPerCrew(
          date.month,
          date.year,
          record?.crew_no
        );
        if (error) {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            TAG,
            'Something went wrong!'
          );
        } else {
          console.log(res);
          setForPayslipPrinting(res);
          setIsPrintModalOpen(true);
        }
        setIsLoading(false);
        break;
      case 'cancel':
        setSelectedFinalPay(record);
        setIsCancelConfirmationModalOpen(true);
        break;
      default:
        break;
    }
  };

  const calculateAccumulatedDays = () => {
    const signOnDate = selectedWUA?.period_from;
    const signOffDate = selectedWUA?.period_to;
    if (selectedWUA?.based_on_days == 'T') {
      return signOffDate?.diff(signOnDate, 'day') + 1;
    }
    let monthsDifference = signOffDate?.diff(signOnDate, 'month');
    if (signOffDate?.date() >= signOnDate?.date()) {
      monthsDifference -= 1;
    }

    monthsDifference = monthsDifference * 30;

    const daysInMonth = signOnDate?.daysInMonth();
    const remainingDays = daysInMonth - signOnDate?.date() + 1;
    return (
      monthsDifference +
      remainingDays +
      calculateDaysWithinTheMonth(signOffDate)
    );
  };

  const calculateDaysWithinTheMonth = (baseOnWUAMonth) => {
    if (baseOnWUAMonth) {
      const beginningOfMonth = baseOnWUAMonth?.startOf('month');
      const daysDifference = baseOnWUAMonth?.diff(beginningOfMonth, 'day') + 1;
      return daysDifference;
    } else {
      const beginningOfMonth = selectedFinalPay?.sign_off?.startOf('month');
      const daysDifference =
        selectedFinalPay?.sign_off?.diff(beginningOfMonth, 'day') + 1;
      return daysDifference;
    }
  };

  const fetchCrews = async () => {
    setCrewList([]);
    openNotification(
      WARNING_NOTIFICATION_TYPE,
      TAG,
      'Fetching crew list in background.'
    );
    const { data: res, error } = await getCrewByVessel({
      month: date.month,
      year: date.year,
    });

    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        TAG,
        'Fetching crew list failed.'
      );
    } else {
      setCrewList(res);
      openNotification(
        SUCCESS_NOTIFICATION_TYPE,
        TAG,
        'Crew list is now available.'
      );
    }
  };

  const fetchDatas = async () => {
    setIsLoading(true);
    await Promise.all([
      getAllFinalPay(date.month, date.year),
      getAllWages(),
      getPostedFinalPay(date.month, date.year),
    ]).then((res) => {
      if (res[0].error || res[1].error || res[2].error) {
        openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Something went wrong.');
      }
      const fPays = res[0].data;
      if (fPays.length > 0) {
        fPays.forEach((record, ind) => {
          let totalUSD = 0;

          const categories = ['details', 'wages', 'addWages', 'govContri'];
          categories.forEach((category) => {
            totalUSD += record[category].reduce(
              (acc, curr) => acc + parseFloat(curr.usd_amt),
              0
            );
          });

          record.net_amount = inputToLocale(totalUSD);
          record.date = dayjs(record.date).format('YYYY-MM-DD');
          record.is_disabled = res[2].data.some(
            (item) => item.crew_no == record.crew_no
          );
          record.key = ind;
        });
        setFinalPayList(fPays);
        setFilteredList(fPays);
      } else {
        openNotification(WARNING_NOTIFICATION_TYPE, TAG, 'No record found.');
      }
      setWagesList(res[1]?.data?.result);
      setPostedCrews(res[2].data);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    finalPayForm.setFieldsValue(selectedFinalPay);

    if (selectedWUA.wages_id != null) {
      const parsedWUAFields = {
        ...selectedWUA,
        period_from: dayjs(selectedFinalPay?.sign_on),
        period_to: selectedFinalPay?.sign_off,
        details:
          selectedWUA?.amount > 0
            ? `(${inputToLocale(
                selectedWUA?.amount
              )} * ${calculateAccumulatedDays()}) / 30`
            : '',
        usd_amt: parseToFloat(computeWUAUSDAmount(selectedWUA?.amount)),
        php_amt: parseToFloat(
          computeWUAUSDAmount(selectedWUA?.amount) * exchangeRate
        ),
      };
      finalPayWUAForm.setFieldsValue(parsedWUAFields);
      setSelectedWUA(parsedWUAFields);
    }
  }, [
    selectedFinalPay,
    exchangeRate,
    selectedWUA?.based_on_days,
    selectedWUA?.wages_id,
  ]);

  useEffect(() => {
    if (selectedWUA.wages_id != null) {
      const parsedWUAFields = {
        ...selectedWUA,
        details:
          selectedWUA?.amount > 0
            ? `(${inputToLocale(
                selectedWUA?.amount
              )} * ${calculateAccumulatedDays()}) / 30`
            : '',
        usd_amt: parseToFloat(computeWUAUSDAmount(selectedWUA?.amount)),
        php_amt: parseToFloat(
          computeWUAUSDAmount(selectedWUA?.amount) * exchangeRate
        ),
      };
      finalPayWUAForm.setFieldsValue(parsedWUAFields);
      setSelectedWUA(parsedWUAFields);
    }
  }, [exchangeRate, selectedWUA?.period_from, selectedWUA?.period_to]);

  useEffect(() => {
    finalPayWUAForm.setFieldsValue(selectedWUA);
  }, [selectedWUA]);

  useEffect(() => {
    if (selectedFinalPay?.details?.length > 0) {
      const salary_details = selectedFinalPay?.details?.map((rec) => {
        const proRatedValue =
          (parseFloat(rec.usd_amt) * parseFloat(rec.days)) / 30;
        return {
          ...rec,
          // code: rec.salary_code,
          // desc: rec.salary_code,
          // usd_amt: rec.amount,
          days: parseFloat(rec.days),
          computed: proRatedValue,
          php_amt: parseFloat(proRatedValue) * parseFloat(exchangeRate),
        };
      });
      setSelectedFinalPay({
        ...selectedFinalPay,
        details: salary_details,
      });
    }

    if (selectedAddtl.desc != null) {
      const phpAmount = selectedAddtl.usd_amt * exchangeRate;
      setSelectedAddtl({ ...selectedAddtl, php_amt: phpAmount });
    }
  }, [selectedFinalPay?.sign_off, exchangeRate]);

  useEffect(() => {
    finalPayAddtlForm.setFieldsValue(selectedAddtl);
  }, [selectedAddtl]);

  useEffect(() => {
    finalPayGovConForm.setFieldsValue(selectedGovtCon);
  }, [selectedGovtCon]);

  useEffect(() => {
    fetchDatas();
  }, []);

  useEffect(() => {
    fetchCrews();
  }, [date]);

  const renderTabs = () => {
    const tabs = [];
    // if (validateAccessToFeature('feature_tran_pp_transactions')) {
    tabs.push({
      key: '1',
      label: 'Wages upon arrival',
      children: (
        <>
          <Form
            labelAlign='left'
            form={finalPayWUAForm}
            name='final_pay_wua_form'
            layout='vertical'
            initialValues={selectedWUA}
          >
            <Row gutter={16} align={'middle'}>
              {getFinalPayWUAFields(
                isLoading,
                selectedWUA,
                setSelectedWUA,
                setHasChanges,
                inputToLocale,
                wagesList,
                exchangeRate,
                selectedFinalPay,
                calculateAccumulatedDays,
                computeWUAUSDAmount,
                originalWages
              ).map((data, index) => (
                <Col key={index} xs={24} sm={6}>
                  <FormInput
                    type={data.type}
                    name={data.name}
                    placeholder={data.placeholder}
                    label={data.label}
                    autoFocus={data.autofocus}
                    value={data.value}
                    onChange={data.setValue}
                    required={data.required}
                    disabled={data.disabled}
                    hidden={data.hidden}
                    dropdownOptions={data.dropdownOptions}
                    props={data.props}
                  />
                </Col>
              ))}
              <Col xs={24} sm={6}>
                <Button
                  type='primary'
                  className='me-3'
                  size='large'
                  disabled={isLoading}
                  onClick={async () => {
                    try {
                      await finalPayWUAForm.validateFields();
                      const newFinalPay = { ...selectedFinalPay };
                      if (newFinalPay.wages == null) {
                        newFinalPay.wages = [];
                      }
                      if (selectedWUA?.key) {
                        const existingIndex = newFinalPay.wages.findIndex(
                          (rec) => rec.wages_id == selectedWUA.wages_id
                        );
                        if (existingIndex !== -1) {
                          newFinalPay.wages[existingIndex] = {
                            ...newFinalPay.wages[existingIndex],
                            ...selectedWUA,
                          };
                        } else {
                          newFinalPay.wages.push({
                            ...selectedWUA,
                            desc: `ACCUM(${dayjs(
                              selectedWUA?.period_from
                            ).format('YYYY-MM-DD')} to ${dayjs(
                              selectedWUA?.period_to
                            ).format('YYYY-MM-DD')}) ${selectedWUA?.desc}`,
                          });
                        }
                      } else {
                        newFinalPay.wages.push({
                          ...selectedWUA,
                          desc: `ACCUM(${dayjs(selectedWUA?.period_from).format(
                            'YYYY-MM-DD'
                          )} to ${dayjs(selectedWUA?.period_to).format(
                            'YYYY-MM-DD'
                          )}) ${selectedWUA?.desc}`,
                        });
                      }
                      setSelectedFinalPay(newFinalPay);
                      setSelectedWUA(FinalPayWUAType);
                    } catch (e) {}
                  }}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
          <Table
            columns={getFinalPayWUATable(handleWUAAction)}
            dataSource={selectedFinalPay?.wages?.map((rec, ind) => ({
              ...rec,
              key: ind,
              usd_amt: inputToLocale(rec.usd_amt),
              php_amt: inputToLocale(rec.php_amt),
            }))}
            size={'small'}
            bordered={false}
            scroll={{ x: true }}
            loading={isLoading}
            pagination={{
              defaultPageSize: 50,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} records`,
            }}
          />
        </>
      ),
      // disabled: !validateAccessToFeature(
      //   'feature_tran_pp_transactions'
      // ),
    });
    // }
    // if (validateAccessToFeature('feature_tran_pp_transactions')) {
    tabs.push({
      key: '2',
      label: 'Additional wages',
      children: (
        <>
          <Form
            labelAlign='left'
            form={finalPayAddtlForm}
            name='final_pay_addtl_form'
            layout='vertical'
            initialValues={selectedAddtl}
          >
            <Row gutter={16} align={'middle'}>
              {getFinalPayAddtlFields(
                isLoading,
                selectedAddtl,
                setSelectedAddtl,
                setHasChanges,
                inputToLocale,
                exchangeRate
              ).map((data, index) => (
                <Col key={index} xs={24} sm={6}>
                  <FormInput
                    type={data.type}
                    name={data.name}
                    placeholder={data.placeholder}
                    label={data.label}
                    autoFocus={data.autofocus}
                    value={data.value}
                    onChange={data.setValue}
                    required={data.required}
                    disabled={data.disabled}
                    hidden={data.hidden}
                    dropdownOptions={data.dropdownOptions}
                    props={data.props}
                  />
                </Col>
              ))}

              <Col xs={24} sm={6}>
                <Button
                  type='primary'
                  className='me-3'
                  size='large'
                  disabled={isLoading}
                  onClick={async () => {
                    try {
                      await finalPayAddtlForm.validateFields();
                      const newFinalPay = { ...selectedFinalPay };
                      if (newFinalPay.addWages == null) {
                        newFinalPay.addWages = [];
                      }
                      newFinalPay.addWages.push(selectedAddtl);
                      setSelectedFinalPay(newFinalPay);
                      setHasChanges(true);
                      setSelectedAddtl(FinalPayAddtlType);
                    } catch (e) {}
                  }}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
          <Table
            columns={getFinalPayAddtlTable(handleAddtlAction)}
            dataSource={selectedFinalPay?.addWages?.map((rec, ind) => ({
              ...rec,
              key: ind,
              usd_amt: inputToLocale(rec.usd_amt),
              php_amt: inputToLocale(rec.php_amt),
            }))}
            size={'small'}
            bordered={false}
            scroll={{ x: true }}
            loading={isLoading}
            pagination={{
              defaultPageSize: 50,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} records`,
            }}
          />
        </>
      ),
      // disabled: !validateAccessToFeature(
      //   'feature_tran_pp_transactions'
      // ),
    });
    // }
    // if (validateAccessToFeature('feature_tran_pp_transactions')) {
    tabs.push({
      key: '3',
      label: `Gov't Contribution and Other deductions`,
      children: (
        <>
          <Row className='mb-2'>
            <Col xs={24} sm={10} className='me-2'>
              <Typography.Text strong className='me-2'>
                Last allotment:
              </Typography.Text>
              <DatePicker
                allowClear={false}
                className='me-2'
                style={{ width: '30%' }}
                picker='month'
                defaultValue={dayjs(
                  selectedFinalPay?.sign_off,
                  'YYYY-MM-DD'
                ).subtract(1, 'month')}
                onChange={(_, dateString) => {
                  const [dateMonth, dateYear] = dateString.split('/');
                  setGovContriDate({ month: dateMonth, year: dateYear });
                }}
                format='M/YYYY'
                disabled={isLoading || exchangeRate == 0}
              />
              <Button
                type='primary'
                className='me-3'
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  let totalUsdAmt = 0;
                  selectedFinalPay?.details?.forEach((item) => {
                    totalUsdAmt += parseFloat(item?.usd_amt);
                  });
                  const basicPay = selectedFinalPay?.details?.find(
                    (rec) => rec.code == 'BASIC-PAY'
                  );

                  let sDate = dayjs(
                    selectedFinalPay?.sign_off,
                    'YYYY-MM-DD'
                  ).subtract(1, 'month');
                  if (govContriDate.year != null) {
                    sDate = dayjs(
                      `${govContriDate.year}-${govContriDate.month}-01`,
                      'YYYY-M-DD'
                    );
                  }
                  let currentDate = sDate.add(1, 'month');
                  const govContris = [];
                  while (
                    currentDate.isBefore(selectedFinalPay.sign_off) ||
                    currentDate.isSame(selectedFinalPay.sign_off, 'month')
                  ) {
                    const payload = {
                      vescode: selectedFinalPay.vescode,
                      month: currentDate.month() + 1,
                      year: currentDate.year(),
                      total_wages: totalUsdAmt,
                      basic_pay: basicPay?.usd_amt,
                      exrate: exchangeRate,
                    };
                    const { data: res, error } = await postGetComputedGovContri(
                      payload
                    );

                    if (error) {
                      openNotification(
                        ERROR_NOTIFICATION_TYPE,
                        TAG,
                        `Processing ${currentDate.format('MMMM YYYY')} failed.`
                      );
                    } else {
                      govContris.push({
                        desc: `SSS ${
                          currentDate.month() + 1
                        }/${currentDate.year()}`,
                        usd_amt:
                          parseFloat(res.sss_employee) /
                          parseFloat(res?.exrate),
                        php_amt: res.sss_employee,
                      });
                      govContris.push({
                        desc: `SSS PROVIDENT FUND ${
                          currentDate.month() + 1
                        }/${currentDate.year()}`,
                        usd_amt:
                          parseFloat(res.prov_sss_employee) /
                          parseFloat(res?.exrate),
                        php_amt: res.prov_sss_employee,
                      });
                      govContris.push({
                        desc: `PHILHEALTH ${
                          currentDate.month() + 1
                        }/${currentDate.year()}`,
                        usd_amt:
                          parseFloat(res.ph_employee) / parseFloat(res?.exrate),
                        php_amt: res.ph_employee,
                      });
                      govContris.push({
                        desc: `PAGIBIG ${
                          currentDate.month() + 1
                        }/${currentDate.year()}`,
                        usd_amt:
                          parseFloat(res.pag_ibig_employee) /
                          parseFloat(res?.exrate),
                        php_amt: res.pag_ibig_employee,
                      });
                    }

                    currentDate = currentDate.add(1, 'month');
                  }
                  setSelectedFinalPay({
                    ...selectedFinalPay,
                    govContri: govContris,
                  });
                  setIsLoading(false);
                }}
              >
                Process
              </Button>
            </Col>
          </Row>
          <Form
            labelAlign='left'
            form={finalPayGovConForm}
            name='final_pay_govcon_form'
            layout='vertical'
            initialValues={selectedGovtCon}
          >
            <Row gutter={16} align={'middle'}>
              {getFinalPayGovContriFields(
                isLoading,
                selectedGovtCon,
                setSelectedGovtCon,
                setHasChanges,
                inputToLocale,
                exchangeRate
              ).map((data, index) => (
                <Col key={index} xs={24} sm={6}>
                  <FormInput
                    type={data.type}
                    name={data.name}
                    placeholder={data.placeholder}
                    label={data.label}
                    autoFocus={data.autofocus}
                    value={data.value}
                    onChange={data.setValue}
                    required={data.required}
                    disabled={data.disabled}
                    hidden={data.hidden}
                    dropdownOptions={data.dropdownOptions}
                    props={data.props}
                  />
                </Col>
              ))}

              <Col xs={24} sm={6}>
                <Button
                  type='primary'
                  className='me-3'
                  size='large'
                  disabled={isLoading}
                  onClick={async () => {
                    try {
                      await finalPayGovConForm.validateFields();
                      const newFinalPay = { ...selectedFinalPay };
                      if (newFinalPay.govContri == null) {
                        newFinalPay.govContri = [];
                      }
                      if (selectedGovtCon?.key !== undefined) {
                        const newGovContri = newFinalPay.govContri?.map(
                          (rec, index) => {
                            if (index == selectedGovtCon.key) {
                              return { ...rec, ...selectedGovtCon };
                            } else {
                              return rec;
                            }
                          }
                        );
                        newFinalPay.govContri = newGovContri;
                      } else {
                        newFinalPay.govContri.push(selectedGovtCon);
                      }
                      setSelectedFinalPay(newFinalPay);
                      setSelectedGovtCon({ ...FinalPayGovConType });
                    } catch (e) {}
                  }}
                >
                  Add
                </Button>
              </Col>
              <Col xs={24} sm={6}></Col>
            </Row>
          </Form>
          <Table
            columns={getFinalPayGovConTable(handleGovConAction)}
            dataSource={selectedFinalPay?.govContri?.map((rec, ind) => ({
              ...rec,
              key: ind,
              usd_amt: inputToLocale(rec.usd_amt),
              php_amt: inputToLocale(rec.php_amt),
            }))}
            size={'small'}
            bordered={false}
            scroll={{ x: true }}
            loading={isLoading}
            pagination={{
              defaultPageSize: 20,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} records`,
            }}
          />
        </>
      ),
      // disabled: !validateAccessToFeature(
      //   'feature_tran_pp_transactions'
      // ),
    });
    // }
    return tabs;
  };

  return (
    <Content>
      <Row>
        <Col xs={24} sm={8} className='me-2'>
          <Typography.Text strong className='me-2'>
            Month/Year:
          </Typography.Text>
          <DatePicker
            allowClear={false}
            className='me-2'
            style={{ width: '30%' }}
            picker='month'
            defaultValue={dayjs(`${date.month}/${date.year}`, 'M/YYYY')}
            onChange={(_, dateString) => {
              const [dateMonth, dateYear] = dateString.split('/');
              setDate({ month: dateMonth, year: dateYear });
            }}
            format='M/YYYY'
            disabled={isLoading}
          />
          <Button
            type='primary'
            className='me-2'
            disabled={isLoading}
            onClick={() => {
              fetchDatas();
            }}
          >
            Filter
          </Button>
          <Button
            type='primary'
            className='me-3'
            disabled={isLoading || postedCrews.length > 0}
            icon={<FaPlusCircle />}
            onClick={() => {
              setSelectedFinalPay(FinalPayType);
              finalPayForm.setFieldsValue(FinalPayType);
              setIsFinalPayModalOpen(true);
            }}
          >
            Add
          </Button>
          <Button
            danger
            className='me-3'
            disabled={
              isLoading ||
              // postedCrews.length > 0 ||
              includeCrewForPost.length <= 0
            }
            onClick={() => {
              setIsPostConfirmationModalOpen(true);
            }}
          >
            Post
          </Button>
        </Col>
      </Row>
      <Row className='mb-4 mt-2' justify={'end'}>
        <Col xs={24} sm={8} className='text-end'>
          <Space.Compact block>
            <Search
              disabled={isLoading}
              placeholder='Search!'
              onChange={(e) => {
                setSearchQuery(e.target.value);
                doSearchInArray(e.target.value, setFilteredList, finalPayList);
              }}
              onSearch={(value) => {
                setSearchQuery(value);
                doSearchInArray(value, setFilteredList, finalPayList);
              }}
              allowClear={true}
            />
          </Space.Compact>
        </Col>
      </Row>
      <Table
        columns={getFinalPayTable(handleAction)}
        dataSource={filteredList}
        size={'small'}
        bordered={false}
        scroll={{ x: true }}
        loading={isLoading}
        rowClassName={getRowClassName}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
        rowSelection={{
          selectedRowKeys: includeCrewForPostKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setIncludeCrewForPost(newSelectedRows);
            setIncludeCrewForPostKeys(newSelectedRowKeys);
          },
        }}
      />
      <CustomModal
        title={selectedFinalPay.crew_name}
        isLoading={isLoading}
        isOpen={isFinalPayModalOpen}
        setIsOpen={setIsFinalPayModalOpen}
        props={{ width: '50vw' }}
        okText='Save'
        onOkDisabled={isLoading || !hasChanges}
        onOk={() => {
          setIsConfirmationModalOpen(true);
        }}
        onCancel={() => {
          if (hasChanges) {
            setIsUnsavedModalOpen(true);
          } else {
            setIsFinalPayModalOpen(false);
          }
        }}
      >
        <Form
          labelAlign='left'
          form={finalPayForm}
          name='final_pay_form'
          layout='vertical'
          initialValues={selectedFinalPay}
        >
          <Row gutter={16}>
            {getFinalPayFields(
              isLoading,
              selectedFinalPay,
              setSelectedFinalPay,
              setHasChanges,
              inputToLocale,
              crewList,
              exchangeRate
            ).map((data, index) => (
              <Col key={index} xs={24} sm={8}>
                <FormInput
                  type={data.type}
                  name={data.name}
                  placeholder={data.placeholder}
                  label={data.label}
                  autoFocus={data.autofocus}
                  value={data.value}
                  onChange={data.setValue}
                  required={data.required}
                  disabled={data.disabled}
                  hidden={data.hidden}
                  dropdownOptions={data.dropdownOptions}
                  props={data.props}
                />
              </Col>
            ))}
          </Row>
          <Row gutter={16} align={'middle'}>
            <Col xs={24} sm={8}>
              <FormInput
                type='number'
                name='exchangeRate'
                placeholder='0'
                label='Exchange rate'
                autoFocus={true}
                value={exchangeRate}
                onChange={(e) => {
                  setExchangeRate(e.target.value);
                }}
                required={false}
                disabled={isLoading}
                hidden={false}
                // props={{ style: { width: '40%' } }}
              />
            </Col>
            <Col xs={24} sm={16}>
              <Button
                type='primary'
                className='me-3'
                size='large'
                disabled={isLoading}
                onClick={async () => {
                  try {
                    await finalPayForm.validateFields();
                    if (exchangeRate > 0) {
                      if (selectedFinalPay.id == null) {
                        const selectedCrew = crewList.find(
                          (rec) => rec.crew_no == selectedFinalPay.crew_no
                        );
                        const salary_details =
                          selectedCrew?.salary_details[0]?.salary
                            ?.filter(
                              (rec) =>
                                rec?.salary_code != 'SMB' &&
                                rec?.salary_code != 'HOURLY-OT1'
                            )
                            .map((rec) => {
                              const proRatedValue =
                                (parseFloat(rec.amount) *
                                  calculateDaysWithinTheMonth()) /
                                30;
                              return {
                                code: rec.salary_code,
                                desc: rec.salary_code,
                                usd_amt: rec.amount,
                                days: calculateDaysWithinTheMonth(),
                                computed: proRatedValue,
                                php_amt:
                                  parseFloat(proRatedValue) *
                                  parseFloat(exchangeRate),
                              };
                            });
                        setSelectedFinalPay({
                          ...selectedFinalPay,
                          details: salary_details,
                        });
                        setOriginalWages(salary_details);
                        if (salary_details.length == 0) {
                          openNotification(
                            WARNING_NOTIFICATION_TYPE,
                            TAG,
                            'No salary breakdown found.'
                          );
                        }
                      } else {
                        const salary_details = selectedFinalPay?.details?.map(
                          (rec) => {
                            const proRatedValue =
                              (parseFloat(rec.usd_amt) *
                                calculateDaysWithinTheMonth()) /
                              30;
                            return {
                              ...rec,
                              // code: rec.salary_code,
                              // desc: rec.salary_code,
                              // usd_amt: rec.amount,
                              days: calculateDaysWithinTheMonth(),
                              computed: inputToLocale(proRatedValue),
                              php_amt:
                                parseFloat(proRatedValue) *
                                parseFloat(exchangeRate),
                            };
                          }
                        );
                        setSelectedFinalPay({
                          ...selectedFinalPay,
                          details: salary_details,
                        });
                        setOriginalWages(salary_details);
                      }
                    } else {
                      openNotification(
                        WARNING_NOTIFICATION_TYPE,
                        TAG,
                        'Exchange rate should have a value.'
                      );
                    }
                  } catch (e) {}
                }}
              >
                Load
              </Button>
            </Col>
          </Row>
        </Form>

        <Divider orientation='left'>Salary breakdown</Divider>
        <Table
          columns={getFinalPayBreakdownTable(
            handleBreakdownAction,
            selectedFinalPay,
            setSelectedFinalPay,
            setHasChanges,
            exchangeRate
          )}
          dataSource={selectedFinalPay?.details?.map((rec, ind) => {
            return {
              ...rec,
              key: ind,
              usd_amt: inputToLocale(rec.usd_amt),
              computed: inputToLocale(rec.computed),
              php_amt: inputToLocale(rec.php_amt),
            };
          })}
          size={'small'}
          bordered={false}
          scroll={{ x: true }}
          loading={isLoading}
          pagination={{
            defaultPageSize: 50,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} records`,
          }}
        />
        {selectedFinalPay.crew_no != null && (
          <>
            <Divider orientation='left'>Others</Divider>
            <Tabs
              items={renderTabs()}
              centered
              tabPosition='top'
              // size='large'
            />
          </>
        )}
      </CustomModal>

      <ConfirmationModal
        title='You have unsaved changes! Confirming will lose all your changes. Confirm?'
        cancelText='Continue editing'
        isLoading={isLoading}
        isOpen={isUnsavedModalOpen}
        setIsOpen={setIsUnsavedModalOpen}
        onConfirm={() => {
          finalPayForm.resetFields();
          finalPayWUAForm.resetFields();
          finalPayAddtlForm.resetFields();
          finalPayGovConForm.resetFields();
          setHasChanges(false);
          setIsUnsavedModalOpen(false);
          setIsFinalPayModalOpen(false);
        }}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          const parsedFinalPay = {
            ...selectedFinalPay,
            cancelled: 0,
            exrate: exchangeRate,
            month: date.month,
            year: date.year,
            sign_off: dayjs(selectedFinalPay.sign_off).format('YYYY-MM-DD'),
          };

          const { data: res, error } = await postSaveFinalPay(parsedFinalPay);
          if (error) {
            openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Saving failed.');
            setIsLoading(false);
          } else {
            openNotification(
              SUCCESS_NOTIFICATION_TYPE,
              TAG,
              'Final pay saved.'
            );
            setHasChanges(false);
            setIsConfirmationModalOpen(false);
            setIsFinalPayModalOpen(false);
            fetchDatas();
          }
        }}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isPostConfirmationModalOpen}
        setIsOpen={setIsPostConfirmationModalOpen}
        title='Please confirm posting this date!'
        onConfirm={async () => {
          setIsLoading(true);
          const { data: res, error } = await postFinalPay(date);
          if (error) {
            openNotification(
              ERROR_NOTIFICATION_TYPE,
              TAG,
              'Something went wrong.'
            );
          } else {
            fetchDatas();
          }
          setIsLoading(false);
        }}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isCancelConfirmationModalOpen}
        setIsOpen={setIsCancelConfirmationModalOpen}
        title='Confirm to cancel final pay?'
        onConfirm={async () => {
          setIsLoading(true);
          // const { data: res, error } = await postFinalPay(date);
          // if (error) {
          //   openNotification(
          //     ERROR_NOTIFICATION_TYPE,
          //     TAG,
          //     'Something went wrong.'
          //   );
          // } else {
          //   fetchDatas();
          //   setSelectedFinalPay(FinalPayType)
          // }
          setIsLoading(false);
        }}
      />
      <CustomModal
        isLoading={isLoading}
        isOpen={isPrintModalOpen}
        setIsOpen={setIsPrintModalOpen}
        cancelText='Close'
        props={{ okButtonProps: { hidden: true }, width: '100vw' }}
        isClosable={true}
      >
        <FinalPayReportPDF date={date} payslipDetails={forPayslipPrinting} />
      </CustomModal>
    </Content>
  );
}
