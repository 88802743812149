import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../utils/context/UserContext';
import { GlobalContext } from '../../../../App';
import Search from 'antd/es/input/Search';
import VesselType from '../../../../utils/types/VesselType';
import {
  ERROR_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../../../utils/api/_constants';
import { getVessels } from '../../../../utils/api/VesselAPI';
import FormModal from '../../../../components/CustomModal/FormModal';
import FormInput from '../../../../components/FormInput/FormInput';
import CustomModal from '../../../../components/CustomModal/CustomModal';
import ConfirmationModal from '../../../../components/CustomModal/ConfirmationModal';
import { getAllOtherDeductions } from '../../../../utils/api/DeductionsAPI';
import RemainingWagesType from '../../../../utils/types/RemainingWagesType';
import { getRemainingWagesComputeTable } from '../../../../utils/tableheader/Transactions/RemainingWagesComputeTable';
import {
  getAllRemainingWages,
  getRemainingWagesReportPerMonth,
  postAddCrewRemainingWages,
  postProcessCrewRemainingWages,
} from '../../../../utils/api/RemainingWagesAPI';
import { getRemainingWagesEntryFields } from '../../../../utils/inputfields/RemainingWagesEntryFields';
import { getRemainingWagesEntryTable } from '../../../../utils/tableheader/Transactions/RemainingWagesEntryTable';
import { getRemainingWagesDeductionFields } from '../../../../utils/inputfields/RemainingWagesDeductionFields';
import RemainingWagesDeductionType from '../../../../utils/types/RemainingWagesDeductionType';

export default function RemainingWagesPage() {
  const TAG = 'Remaining Wages';

  const {
    getCurrentDate,
    doSearchInArray,
    parseToFloat,
    inputToLocale,
    localeToFloat,
    profile,
  } = useContext(UserContext);

  const [form] = Form.useForm();
  const [deductionForm] = Form.useForm();

  const { openNotification } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const [month, setMonth] = useState(dayjs().month() + 1);
  const [year, setYear] = useState(dayjs().year());

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const [vesselList, setVesselList] = useState([]);
  const [selectedVessel, setSelectedVessel] = useState(VesselType);

  const [selectedCrew, setSelectedCrew] = useState(RemainingWagesType);
  const [selectedCrewDeductionsList, setSelectedCrewDeductionsList] = useState(
    []
  );
  const [selectedDeduction, setSelectedDeduction] = useState(
    RemainingWagesDeductionType
  );

  const [crewList, setCrewList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const [deductionOptions, setDeductionOptions] = useState([]);

  // MODAL STATES
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isFormDeductionModalOpen, setIsFormDeductionModalOpen] =
    useState(false);
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);

  const [excludedCrewForPrint, setExcludedCrewForPrint] = useState([]);
  const [excludedCrewForPrintKeys, setExcludedCrewForPrintKeys] = useState([]);

  const [exchangeRate, setExchangeRate] = useState(0);

  const calculateTotalDeductions = (records) => {
    if (!records) {
      return 0;
    }
    const totalDeductions = records.reduce((acc, obj) => {
      if (obj?.deduction_amount) {
        let amount = obj?.deduction_amount;
        if (typeof obj?.deduction_amount === 'string') {
          amount = obj?.deduction_amount.replace(',', '');
        }
        return acc + parseFloat(amount);
      }
      return acc + 0;
    }, 0);
    return totalDeductions;
  };

  const handleAction = async (record = RemainingWagesType) => {
    const parseRecord = {
      ...record,
      amount_php: localeToFloat(record.amount_php),
      amount_usd: localeToFloat(record.amount_usd),
    };

    console.log(parseRecord);

    setSelectedCrewDeductionsList(
      record.deduction_list.map((item, i) => ({
        ...item,
        deduction_amount: item.deduction_amount,
        desc: item.wage_other_desc,
      }))
    );
    setSelectedDeduction(RemainingWagesDeductionType);

    form.setFieldsValue(parseRecord);
    setSelectedCrew(parseRecord);
    setIsFormModalOpen(true);
  };

  const handleEntryAction = async (action, record) => {
    if (action === 'delete') {
      const newList = selectedCrewDeductionsList.filter(
        (item, i) => i !== record
      );
      setSelectedCrewDeductionsList(newList);
    } else {
      setSelectedDeduction(record);
      deductionForm.setFieldsValue(record);
      setIsFormDeductionModalOpen(true);
    }
  };

  const loadCrewsByVessel = async () => {
    setIsLoading(true);
    const { data: res, error } = await getAllRemainingWages({
      vescode: selectedVessel.vessel_code,
      month: month,
      year: year,
      exrate: exchangeRate,
    });
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        TAG,
        'Error fetching crew list.'
      );
    } else {
      const list = res.map((item, index) => ({
        ...item,
        key: index,
        vessel: `${selectedVessel.vessel_name} {${selectedVessel.vessel_code}}`,
        amount_php: inputToLocale(item.amount_php),
        amount_usd: inputToLocale(item.amount_usd),
        deductions: inputToLocale(item.deductions),
        net_pay: inputToLocale(item.net_pay),
      }));

      setFilteredList(list);
      setCrewList(list);
      doSearchInArray(searchQuery, setFilteredList, list);
    }
    setIsLoading(false);
  };

  const fetchDeductionTypes = async () => {
    const { data: res, error } = await getAllOtherDeductions();
    if (!error) {
      const list = res.map((item) => ({
        ...item,
        id: item.id,
        description: item.desc,
      }));
      setDeductionOptions(list);
    }
  };

  const fetchDatas = async () => {
    setIsLoading(true);
    await Promise.all([getVessels(profile.user_id)]);
    const { data: res, error } = await getVessels(profile.user_id);
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Embarked',
        'Error fetching vessels.'
      );
    } else {
      setVesselList(res);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDatas();
    fetchDeductionTypes();
  }, []);

  useEffect(() => {
    form.setFieldsValue(selectedCrew);
  }, [selectedCrew]);

  useEffect(() => {
    deductionForm.setFieldsValue(selectedDeduction);
  }, [selectedDeduction]);

  return (
    <Content>
      <Row>
        <Col xs={24} sm={6} className='me-2'>
          <Typography.Text strong className='me-2'>
            Month/Year:
          </Typography.Text>
          <DatePicker
            allowClear={false}
            style={{ width: '70%' }}
            picker='month'
            defaultValue={dayjs(`${month}/${year}`, 'M/YYYY')}
            onChange={(_, dateString) => {
              const [dateMonth, dateYear] = dateString.split('/');
              setMonth(dateMonth);
              setYear(dateYear);
            }}
            format='M/YYYY'
            disabled={isLoading}
          />
        </Col>
        <Col xs={24} sm={10} className='text-start'>
          <Typography.Text strong className='me-2'>
            Vessel:
          </Typography.Text>
          <Select
            style={{ width: '50%' }}
            placeholder={'Search vessel!'}
            value={selectedVessel.vessel_code}
            onChange={(value, option) => {
              const vessel = vesselList.find(
                (item) => item.vessel_code === value
              );
              setSelectedVessel(vessel);
            }}
            disabled={isLoading}
            showSearch
            filterOption={(inputValue, option) => {
              const optionDescription = option.children.toLowerCase();
              return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
            }}
          >
            {vesselList.map((option) => (
              <Select.Option
                key={option.vessel_code}
                value={option.vessel_code}
              >
                {option.vessel_name}
              </Select.Option>
            ))}
          </Select>
          <Button
            type='primary'
            className='mx-2'
            disabled={
              isLoading || selectedVessel.id === null || exchangeRate <= 0
            }
            onClick={() => loadCrewsByVessel()}
          >
            Filter
          </Button>
          <Button
            type='primary'
            disabled={
              isLoading ||
              month == null ||
              year == null ||
              selectedVessel.vessel_code == null ||
              exchangeRate <= 0
            }
            onClick={async () => {
              setIsLoading(true);
              const { data: res, error } =
                await getRemainingWagesReportPerMonth(
                  month,
                  year,
                  selectedVessel.vessel_code
                );
              console.log(res);
              setIsLoading(false);
              setIsPrintModalOpen(true);
            }}
          >
            Print
          </Button>
        </Col>
        <Space></Space>
      </Row>
      <Row className='mb-4 mt-2' justify={'space-between'}>
        <Col xs={24} sm={14}>
          <Typography.Text strong className='me-2'>
            Exchange Rate:
          </Typography.Text>
          <Input
            type='number'
            style={{ width: '15%' }}
            placeholder={'Exchange Rate'}
            disabled={isLoading}
            value={exchangeRate}
            onChange={(e) => {
              const value = e.target.value;
              setExchangeRate(value);
            }}
          />
          <Button
            type='primary'
            className='ms-2'
            disabled={
              isLoading ||
              month == null ||
              year == null ||
              selectedVessel.vessel_code == null ||
              exchangeRate <= 0
            }
            onClick={async () => {
              setIsLoading(true);

              const payload = {
                vescode: selectedVessel.vessel_code,
                month: month,
                year: year,
                exrate: exchangeRate,
              };

              const { data: res, error } = await postProcessCrewRemainingWages(
                payload
              );

              if (error) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  TAG,
                  'Computing failed.'
                );
              } else {
                console.log(res);
              }

              setIsLoading(false);
            }}
          >
            Compute / Re-Compute
          </Button>
        </Col>
        <Col xs={24} sm={8} className='text-end'>
          <Space.Compact block>
            <Search
              disabled={isLoading || crewList.length === 0}
              placeholder='Search crew!'
              onChange={(e) => {
                setSearchQuery(e.target.value);
                doSearchInArray(e.target.value, setFilteredList, crewList);
              }}
              onSearch={(value) => {
                setSearchQuery(value);
                doSearchInArray(value, setFilteredList, crewList);
              }}
              allowClear={true}
            />
          </Space.Compact>
        </Col>
      </Row>
      <Table
        columns={getRemainingWagesComputeTable(handleAction)}
        dataSource={filteredList}
        size='small'
        bordered='true'
        scroll={{ x: true }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
        rowSelection={{
          selectedRowKeys: excludedCrewForPrintKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setExcludedCrewForPrint(newSelectedRows);
            setExcludedCrewForPrintKeys(newSelectedRowKeys);
          },
        }}
      />
      <FormModal
        title={'Remaining Wages entry'}
        isOpen={isFormModalOpen}
        setIsOpen={setIsFormModalOpen}
        isLoading={isLoading}
        props={{ width: '50vw' }}
        form={form}
        formName={'remaining_wages_form'}
        initialState={selectedCrew}
        onSave={async () => {
          if (hasChanges) {
            try {
              await form.validateFields();
              setIsConfirmationModalOpen(true);
            } catch (e) {}
          } else {
            openNotification(INFO_NOTIFICATION_TYPE, TAG, 'No changes made.');
          }
        }}
        onCancel={() => {
          if (hasChanges) {
            setIsUnsavedModalOpen(true);
          } else {
            setIsFormModalOpen(false);
          }
        }}
      >
        <Row gutter={16}>
          {getRemainingWagesEntryFields(
            isLoading,
            selectedCrew,
            setSelectedCrew,
            setHasChanges,
            exchangeRate
          ).map((data, index) => (
            <Col key={index} xs={24} sm={8}>
              <FormInput
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                hidden={data.hidden}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            </Col>
          ))}
        </Row>
        <Table
          columns={getRemainingWagesEntryTable(handleEntryAction)}
          dataSource={selectedCrewDeductionsList}
          size='small'
          bordered='true'
          scroll={{ x: true }}
          loading={isLoading}
          pagination={false}
          // pagination={{
          //   defaultPageSize: 50,
          //   showSizeChanger: true,
          //   showTotal: (total, range) =>
          //     `${range[0]}-${range[1]} of ${total} records`,
          // }}
        />
        <Row gutter={16} justify={'end'}>
          <Col xs={24} sm={12}>
            <Button
              type='primary'
              className='mt-2'
              onClick={() => {
                deductionForm.setFieldsValue(RemainingWagesDeductionType);
                setIsFormDeductionModalOpen(true);
              }}
            >
              Add deduction
            </Button>
          </Col>
          <Col xs={24} sm={12} className='text-end'>
            <Typography.Text code>
              Total deductions:{' '}
              {inputToLocale(
                calculateTotalDeductions(selectedCrewDeductionsList)
              )}
            </Typography.Text>
          </Col>
        </Row>
      </FormModal>
      <FormModal
        title={'Remaining Wages Deduction entry'}
        isOpen={isFormDeductionModalOpen}
        setIsOpen={setIsFormDeductionModalOpen}
        isLoading={isLoading}
        props={{ width: '50vw' }}
        form={deductionForm}
        formName={'remaining_wages_deduction_form'}
        initialState={selectedDeduction}
        onSave={async () => {
          try {
            await deductionForm.validateFields();
            console.log(selectedDeduction);
            if ('key' in selectedDeduction) {
              const record = selectedCrewDeductionsList.find(
                (item) => item.key == selectedDeduction.key
              );
              const newList = selectedCrewDeductionsList.filter(
                (item) => item.key != selectedDeduction.key
              );

              newList.push({
                ...selectedDeduction,
                deduction_id: selectedDeduction.deduction_id,
                desc: selectedDeduction.desc,
              });

              // const arrangedList =
              setSelectedCrewDeductionsList(newList);

              console.log('list', newList);
              console.log('record', record);
            } else {
              const newList = [...selectedCrewDeductionsList];
              newList.push(selectedDeduction);

              setSelectedCrewDeductionsList(
                newList.map((item, index) => ({
                  ...item,
                  key: index,
                }))
              );
            }

            deductionForm.setFieldsValue(RemainingWagesDeductionType);
            setSelectedDeduction(RemainingWagesDeductionType);
            setIsFormDeductionModalOpen(false);
          } catch (e) {}
        }}
        onCancel={() => {
          deductionForm.setFieldsValue(RemainingWagesDeductionType);
          setSelectedDeduction(RemainingWagesDeductionType);
          setIsFormDeductionModalOpen(false);
        }}
      >
        <Row gutter={16}>
          {getRemainingWagesDeductionFields(
            isLoading,
            selectedDeduction,
            setSelectedDeduction,
            setHasChanges,
            deductionOptions
          ).map((data, index) => (
            <Col key={index} xs={24} sm={8}>
              <FormInput
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                hidden={data.hidden}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            </Col>
          ))}
        </Row>
      </FormModal>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          try {
            const payload = {
              ...selectedCrew,
              deduction_list: selectedCrewDeductionsList,
              vescode: selectedVessel.vessel_code,
              vesname: selectedVessel.vessel_name,
              bank_desc: '',
              month: month,
              year: year,
            };

            console.log(payload);

            const { data: res, error } = await postAddCrewRemainingWages(
              payload
            );
            if (error) {
              openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Saving failed.');
              setIsLoading(false);
            } else {
              setIsLoading(false);
              setHasChanges(false);
              setIsConfirmationModalOpen(false);
              setIsFormModalOpen(false);
              loadCrewsByVessel();
            }
          } catch (error) {
            openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Saving failed');
            setIsLoading(false);
          }
        }}
      />
      <ConfirmationModal
        title='You have unsaved changes! Confirming will lose all your changes. Confirm?'
        cancelText='Continue editing'
        isLoading={isLoading}
        isOpen={isUnsavedModalOpen}
        setIsOpen={setIsUnsavedModalOpen}
        onConfirm={() => {
          form.resetFields();
          deductionForm.resetFields();
          setHasChanges(false);
          setIsUnsavedModalOpen(false);
          setIsFormModalOpen(false);
        }}
      />
      <CustomModal
        isLoading={isLoading}
        isOpen={isPrintModalOpen}
        setIsOpen={setIsPrintModalOpen}
        cancelText='Close'
        props={{ okButtonProps: { hidden: true }, width: '100vw' }}
        isClosable={true}
      >
        {/* <CrewWagesPDF /> */}
      </CustomModal>
    </Content>
  );
}
