import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../App';
import { UserContext } from '../../../../utils/context/UserContext';
import { Button, Col, Form, Row, Table } from 'antd';
import CrewDeductionType from '../../../../utils/types/CrewDeductionType';
import {
  getAllOtherDeductions,
  getPayrollDeductions,
  postAddOtherDeductionsAllottee,
  postAddOtherDeductionsAllotteePerAllottee,
  postDeleteOtherDeductionsAllottee,
  postModifyOtherDeductionsAllottee,
  postModifyOtherDeductionsAllotteePerAllottee,
} from '../../../../utils/api/DeductionsAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../../../utils/api/_constants';
import Search from 'antd/es/input/Search';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { IoIosRefresh } from 'react-icons/io';
import { getTransactionDeductionsTable } from '../../../../utils/tableheader/Transactions/TransactionDeductionsTable';
import dayjs from 'dayjs';
import { getCrewByVessel } from '../../../../utils/api/TransactionsAPI';
import FormModal from '../../../../components/CustomModal/FormModal';
import { getPayrollOtherDeductionFields } from '../../../../utils/inputfields/PayrollOtherDeductionFields';
import FormInput from '../../../../components/FormInput/FormInput';
import { getEmbarkationOtherDeductionTable } from '../../../../utils/tableheader/Transactions/EmbarkationOtherDeductionsTable';
import ConfirmationModal from '../../../../components/CustomModal/ConfirmationModal';

export default function DeductionsPage() {
  const { openNotification } = useContext(GlobalContext);
  const { doSearchInArray, generateFiveNumber } = useContext(UserContext);

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isCrewFetching, setIsCrewFetching] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [deductionsList, setDeductionsList] = useState([]);

  const [selectedCrewDeduction, setSelectedCrewDeduction] =
    useState(CrewDeductionType);
  const [crewListOptions, setCrewListOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [allotteesState, setAllotteesState] = useState([]);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);

  const tableContents = filteredList.map((item, index) => ({
    ...item,
    key: index,
  }));

  const onCrewSelectChange = async (crew_no) => {
    const crewAllottees = crewListOptions.find(
      (item) => item.crew_no === crew_no
    );
    setAllotteesState(
      crewAllottees.allotment?.allottee?.map((item, index) => ({
        ...item,
        key: index,
        allottee_name: `${item.last_name}, ${item.first_name} ${item.middle_name}`,
        allottee_id: item.crew_allottee_id,
        accountno: item.acct_type,
        assign: 1,
        allotee_amount: 0,
        api_allottee_amount: 0,
        bankname: item.bankdesc,
      }))
    );

    const parseRecord = {
      ...CrewDeductionType,
      assign_to: 1,
      transdate: dayjs(),
    };
    form.setFieldsValue(parseRecord);
    setSelectedCrewDeduction(parseRecord);
  };

  const handleAction = async (record, action = 'edit') => {
    if (action === 'edit') {
      const crewAllottees = crewListOptions.find(
        (item) => item.crew_no == record.crew_no
      );

      const allottees = crewAllottees?.allotment?.allottee?.map((item1) => {
        const corresspondingItem = record?.allottee?.find(
          (item2) => item2.allottee_id == item1.crew_allottee_id
        );

        return {
          ...item1,
          ...corresspondingItem,
          allotte_othd_id: corresspondingItem?.allotte_othd_id,
          allottee_name: `${item1.last_name}, ${item1.first_name} ${item1.middle_name}`,
          allotee_amount: corresspondingItem?.amount
            ? parseFloat(corresspondingItem.amount)
            : 0,
        };
      });
      setAllotteesState(allottees);

      const parseRecord = {
        ...record,
        assign_to: parseInt(record.assign_to),
        transdate: dayjs(record.transdate, 'YYYY-MM-DD'),
        startdeduct: dayjs(record.startdeduct, 'YYYY-MM-DD'),
      };
      form.setFieldsValue(parseRecord);
      setSelectedCrewDeduction(parseRecord);
      setIsFormModalOpen(true);
    } else {
      const { data: res, error } = await postDeleteOtherDeductionsAllottee(
        record
      );
      if (error) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Deleting record.',
          'Deleting record failed.'
        );
      } else {
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          'Deleting record.',
          'Success deleting record.'
        );
        fetchData();
      }
    }
  };

  const fetchCrews = async () => {
    setIsCrewFetching(true);
    const { data: res, error } = await getCrewByVessel({
      month: dayjs().month() + 1,
      year: dayjs().year(),
    });

    if (res.length > 0) {
      setCrewListOptions(
        res.map((item, index) => ({
          key: `${index}-${generateFiveNumber()}`,
          ...item,
        }))
      );
    }
    setIsCrewFetching(false);
  };

  const fetchData = async () => {
    setIsLoading(true);
    await Promise.all([getPayrollDeductions(), getAllOtherDeductions()])
      .then((response) => {
        if (response[0].data.length > 0) {
          const resWithKey = response[0].data.map((item, index) => ({
            key: index,
            ...item,
          }));
          setFilteredList(resWithKey);
          setDeductionsList(resWithKey);
          doSearchInArray(searchQuery, setFilteredList, resWithKey);
        }

        if (response[1].data.length > 0) {
          const list = response[1].data.map((item) => ({
            key: item.id,
            id: item.id,
            description: item.desc,
          }));
          setTypeOptions(list);
        }
      })
      .catch((e) => {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Deductions Entry.',
          'Fetching data failed.'
        );
      });
    setIsLoading(false);
  };

  useEffect(() => {
    form.setFieldsValue(selectedCrewDeduction);
  }, [selectedCrewDeduction]);

  useEffect(() => {
    fetchCrews();
    fetchData();
  }, []);

  return (
    <Content>
      <Row className='mb-4' justify={'end'}>
        <Col xs={24} sm={8} className='text-end'>
          <Search
            disabled={isLoading}
            placeholder='Search record!'
            onChange={(e) => {
              setSearchQuery(e.target.value);
              doSearchInArray(e.target.value, setFilteredList, deductionsList);
            }}
            onSearch={(value) => {
              setSearchQuery(value);
              doSearchInArray(value, setFilteredList, deductionsList);
            }}
            allowClear={true}
          />
        </Col>
        <Col xs={24} sm={3} className='text-end'>
          <Button
            className='mx-2'
            type='primary'
            icon={<AiOutlinePlusCircle size={18} />}
            disabled={isLoading}
            onClick={() => {
              const record = {
                ...CrewDeductionType,
                transdate: dayjs(),
              };
              setAllotteesState([]);
              setSelectedCrewDeduction(record);
              form.setFieldsValue(record);
              setIsFormModalOpen(true);
            }}
          >
            Add
          </Button>
          <Button
            type='primary'
            icon={<IoIosRefresh size={18} />}
            disabled={isLoading}
            onClick={() => fetchData()}
          />
        </Col>
      </Row>
      <Table
        columns={getTransactionDeductionsTable(handleAction)}
        dataSource={tableContents}
        size='small'
        bordered='true'
        scroll={{ x: true }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <FormModal
        title={'Deductions entry'}
        isOpen={isFormModalOpen}
        setIsOpen={setIsFormModalOpen}
        isLoading={isLoading}
        props={{ width: '80vw' }}
        form={form}
        formName={'crew_deduction_form'}
        initialState={selectedCrewDeduction}
        onSave={async () => {
          if (hasChanges) {
            try {
              await form.validateFields();
              setIsConfirmationModalOpen(true);
            } catch {}
          } else {
            openNotification(
              INFO_NOTIFICATION_TYPE,
              'Deduction entry',
              'No changes made.'
            );
            setIsFormModalOpen(false);
          }
        }}
        onCancel={() => {
          if (hasChanges) {
            setIsUnsavedModalOpen(true);
          } else {
            form.resetFields();
            setIsFormModalOpen(false);
            setHasChanges(false);
          }
        }}
      >
        <Row gutter={16}>
          {getPayrollOtherDeductionFields(
            isLoading,
            selectedCrewDeduction,
            setSelectedCrewDeduction,
            setHasChanges,
            crewListOptions,
            typeOptions,
            allotteesState,
            setAllotteesState,
            onCrewSelectChange
          ).map((data, index) => (
            <Col key={`${index}-a-s-v`} xs={24} sm={12}>
              <FormInput
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            </Col>
          ))}
        </Row>
        <Table
          columns={getEmbarkationOtherDeductionTable(
            selectedCrewDeduction,
            setSelectedCrewDeduction,
            allotteesState,
            setAllotteesState,
            setHasChanges,
            typeOptions
          )}
          dataSource={allotteesState}
          size='small'
          bordered='true'
          scroll={{ x: true }}
          loading={isLoading}
          pagination={{
            defaultPageSize: 50,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} records`,
          }}
        />
      </FormModal>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          await form.validateFields();
          if (
            parseFloat(selectedCrewDeduction.amounttoded) >
            parseFloat(selectedCrewDeduction.amount)
          ) {
            openNotification(
              ERROR_NOTIFICATION_TYPE,
              'Adding Other Deduction',
              'Amount to be deducted cannot be more than Amount allotted.'
            );
            setIsLoading(false);
          } else {
            const crew = crewListOptions.find(
              (item) => item.crew_no === selectedCrewDeduction.crew_no
            );
            const payload = {
              ...selectedCrewDeduction,
              crew_name: `${crew.last_name}, ${crew.first_name} ${crew.middle_name}`,
              transdate: dayjs(selectedCrewDeduction.transdate).format(
                'YYYY-MM-DD'
              ),
              startdeduct: dayjs(selectedCrewDeduction.startdeduct).format(
                'YYYY-MM-DD'
              ),
            };
            let hasValidationError = false;
            allotteesState.map((record) => {
              if (parseFloat(record?.allotee_amount) > 0) {
                if (
                  !record?.other_deduction_allottee_od_id ||
                  !record?.other_deduction_type
                ) {
                  hasValidationError = true;
                }
              }
            });
            if (hasValidationError) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                'Deduction Entry',
                'Please enter Type and/or Deduction Type.'
              );
              setIsConfirmationModalOpen(false);
              setIsLoading(false);
            } else {
              if (payload.id) {
                const { data: res, error } =
                  await postModifyOtherDeductionsAllottee(payload);
                if (error) {
                  openNotification(
                    ERROR_NOTIFICATION_TYPE,
                    'Adding Other Deduction',
                    'Failed.'
                  );

                  setIsLoading(false);
                } else {
                  let hasError = false;
                  await Promise.all(
                    allotteesState.map(async (record) => {
                      console.log('allottee', record);
                      const { data: res, error } =
                        await postModifyOtherDeductionsAllotteePerAllottee({
                          ...record,
                          other_deduction_config_id: payload.id,
                          other_deduction_allottee_od_id:
                            record.other_deduction_allottee_od_id
                              ? record.other_deduction_allottee_od_id
                              : '',
                          other_deduction_type: record.other_deduction_type
                            ? record.other_deduction_type
                            : '',
                        });
                      if (error) {
                        hasError = true;
                        openNotification(
                          ERROR_NOTIFICATION_TYPE,
                          'Adding Other Deduction',
                          'Failed'
                        );
                        setIsLoading(false);
                      }
                    })
                  );
                  if (!hasError) {
                    openNotification(
                      SUCCESS_NOTIFICATION_TYPE,
                      'Adding Other Deduction',
                      'Success'
                    );
                    setHasChanges(false);
                    setIsConfirmationModalOpen(false);
                    setIsFormModalOpen(false);
                    fetchData();
                  }
                }
              } else {
                const { data: res, error } =
                  await postAddOtherDeductionsAllottee(payload);
                if (error) {
                  openNotification(
                    ERROR_NOTIFICATION_TYPE,
                    'Adding Other Deduction',
                    'Failed.'
                  );

                  setIsLoading(false);
                } else {
                  let hasError = false;
                  await Promise.all(
                    allotteesState.map(async (record) => {
                      const { data: res, error } =
                        await postAddOtherDeductionsAllotteePerAllottee({
                          ...record,
                          other_deduction_allottee_od_id:
                            record.other_deduction_allottee_od_id
                              ? record.other_deduction_allottee_od_id
                              : '',
                          other_deduction_type: record.other_deduction_type
                            ? record.other_deduction_type
                            : '',
                        });
                      if (error) {
                        hasError = true;
                        openNotification(
                          ERROR_NOTIFICATION_TYPE,
                          'Adding Other Deduction',
                          'Failed'
                        );
                        setIsLoading(false);
                      }
                    })
                  );
                  if (!hasError) {
                    openNotification(
                      SUCCESS_NOTIFICATION_TYPE,
                      'Adding Other Deduction',
                      'Success'
                    );
                    setHasChanges(false);
                    setIsConfirmationModalOpen(false);
                    setIsFormModalOpen(false);
                    fetchData();
                  }
                }
              }
            }
          }
        }}
      />
      <ConfirmationModal
        title='You have unsaved changes! Confirming will lose all your changes. Confirm?'
        cancelText='Continue editing'
        isLoading={isLoading}
        isOpen={isUnsavedModalOpen}
        setIsOpen={setIsUnsavedModalOpen}
        onConfirm={() => {
          form.resetFields();
          setIsUnsavedModalOpen(false);
          setIsFormModalOpen(false);
          setHasChanges(false);
        }}
      />
    </Content>
  );
}
