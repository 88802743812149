export function getSalaryBreakdownTable() {
  return [
    {
      title: 'Code',
      dataIndex: 'salary_code',
      key: 'salary_code',
    },
    {
      title: 'Scale',
      dataIndex: 'salary_code',
      key: 'salary_code',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Hourly rate',
      dataIndex: 'hourly_rate',
      key: 'hourly_rate',
    },
  ];
}
