export const transactionTree = {
  title: 'Transaction Module',
  key: 'main_transaction',
  children: [
    {
      title: 'Payroll Process',
      key: 'tran_payroll_process',
      children: [
        {
          title: 'Transactions',
          key: 'feature_tran_pp_transactions',
          children: [
            {
              title: 'Embarked',
              key: 'sub_feature_tran_pp_embarked',
              children: [
                {
                  title: 'Main',
                  key: 'sub_feature_tran_pp_emb_main',
                  children: [
                    {
                      title: 'Filter',
                      key: 'function_tran_pp_emb_filter',
                    },
                    {
                      title: 'Print',
                      key: 'function_tran_pp_emb_print',
                    },
                    {
                      title: 'Action',
                      key: 'function_tran_pp_emb_action',
                    },
                  ],
                },
                {
                  title: 'Salary Breakdown',
                  key: 'sub_feature_tran_pp_emb_salary_breakdown_main',
                  children: [
                    {
                      title: 'View',
                      key: 'function_tran_pp_emb_salary_breakdown_view',
                    },
                    {
                      title: 'Modify',
                      key: 'function_tran_pp_emb_salary_breakdown_modify',
                    },
                  ],
                },
                {
                  title: 'Home Allotment',
                  key: 'sub_feature_tran_pp_emb_home_allotment_main',
                  children: [
                    {
                      title: 'View',
                      key: 'function_tran_pp_emb_allotment_distrib_home_allotment_view',
                    },
                    {
                      title: 'Modify',
                      key: 'function_tran_pp_emb_allotment_distrib_home_allotment_modify',
                    },
                  ],
                },
                {
                  title: 'Side Letter Bonus',
                  key: 'sub_feature_tran_pp_emb_slb_main',
                  children: [
                    {
                      title: 'View',
                      key: 'function_tran_pp_emb_allotment_distrib_slb_view',
                    },
                    {
                      title: 'Modify',
                      key: 'function_tran_pp_emb_allotment_distrib_slb_modify',
                    },
                  ],
                },
                {
                  title: 'MPO',
                  key: 'sub_feature_tran_pp_emb_mpo_main',
                  children: [
                    {
                      title: 'View',
                      key: 'function_tran_pp_emb_allotment_distrib_mpo_view',
                    },
                    {
                      title: 'Modify',
                      key: 'function_tran_pp_emb_allotment_distrib_mpo_modify',
                    },
                  ],
                },
                {
                  title: 'Schedule of Deduction',
                  key: 'function_tran_pp_emb_sched_of_deduc_view',
                },
                {
                  title: 'Deduction Entry',
                  key: 'sub_feature_tran_pp_emb_deduc_entry_main',
                  children: [
                    {
                      title: 'View',
                      key: 'function_tran_pp_emb_deduc_entry_view',
                    },
                    {
                      title: 'Modify',
                      key: 'function_tran_pp_emb_deduc_entry_modify',
                    },
                  ],
                },
                {
                  title: 'Others',
                  key: 'sub_feature_tran_pp_emb_others_main',
                  children: [
                    {
                      title: 'View',
                      key: 'function_tran_pp_emb_others_view',
                    },
                    {
                      title: 'Modify',
                      key: 'function_tran_pp_emb_others_modify',
                    },
                  ],
                },
              ],
            },
            {
              title: 'Disembarked',
              key: 'sub_feature_tran_pp_disembarked',
              children: [
                {
                  title: 'Main',
                  key: 'sub_feature_tran_pp_disemb_main',
                  children: [
                    {
                      title: 'Filter',
                      key: 'function_tran_pp_disemb_filter',
                    },
                    {
                      title: 'Print',
                      key: 'function_tran_pp_disemb_print',
                    },
                    {
                      title: 'Action',
                      key: 'function_tran_pp_disemb_action',
                    },
                  ],
                },
                {
                  title: 'Salary Breakdown',
                  key: 'sub_feature_tran_pp_disembb_salary_breakdown_main',
                  children: [
                    {
                      title: 'View',
                      key: 'function_tran_pp_disemb_salary_breakdown_view',
                    },
                    {
                      title: 'Modify',
                      key: 'function_tran_pp_disemb_salary_breakdown_modify',
                    },
                  ],
                },
                {
                  title: 'Home Allotment',
                  key: 'sub_feature_tran_pp_disemb_home_allotment_main',
                  children: [
                    {
                      title: 'View',
                      key: 'function_tran_pp_disemb_allotment_distrib_home_allotment_view',
                    },
                    {
                      title: 'Modify',
                      key: 'function_tran_pp_disemb_allotment_distrib_home_allotment_modify',
                    },
                  ],
                },
                {
                  title: 'Side Letter Bonus',
                  key: 'sub_feature_tran_pp_disemb_slb_main',
                  children: [
                    {
                      title: 'View',
                      key: 'function_tran_pp_disemb_allotment_distrib_slb_view',
                    },
                    {
                      title: 'Modify',
                      key: 'function_tran_pp_disemb_allotment_distrib_slb_modify',
                    },
                  ],
                },
                {
                  title: 'MPO',
                  key: 'sub_feature_tran_pp_disemb_mpo_main',
                  children: [
                    {
                      title: 'View',
                      key: 'function_tran_pp_disemb_allotment_distrib_mpo_view',
                    },
                    {
                      title: 'Modify',
                      key: 'function_tran_pp_disemb_allotment_distrib_mpo_modify',
                    },
                  ],
                },
                {
                  title: 'Schedule of Deduction',
                  key: 'function_tran_pp_disemb_sched_of_deduc_view',
                },
                {
                  title: 'Deduction Entry',
                  key: 'sub_feature_tran_pp_disemb_deduc_entry_main',
                  children: [
                    {
                      title: 'View',
                      key: 'function_tran_pp_disemb_deduc_entry_view',
                    },
                    {
                      title: 'Modify',
                      key: 'function_tran_pp_disemb_deduc_entry_modify',
                    },
                  ],
                },
                {
                  title: 'Others',
                  key: 'sub_feature_tran_pp_disemb_others_main',
                  children: [
                    {
                      title: 'View',
                      key: 'function_tran_pp_disemb_others_view',
                    },
                    {
                      title: 'Modify',
                      key: 'function_tran_pp_disemb_others_modify',
                    },
                  ],
                },
              ],
            },
            {
              title: 'Post records',
              key: 'sub_feature_tran_pp_post_records',
              children: [
                { title: 'Post', key: 'function_tran_pp_post_record_post' },
                {
                  title: 'Home Allotment',
                  key: 'function_tran_pp_post_record_home_allotment',
                },
                {
                  title: 'Side Letter',
                  key: 'function_tran_pp_post_record_side_letter',
                },
                { title: 'MPO', key: 'function_tran_pp_post_record_mpo' },
                {
                  title: 'Crew wages',
                  key: 'function_tran_pp_post_record_crew_wages',
                },
              ],
            },
          ],
        },
        {
          title: 'Deductions',
          key: 'feature_tran_pp_deductions',
          children: [
            { title: 'View', key: 'function_tran_pp_deductions_view' },
            { title: 'Modify', key: 'function_tran_pp_deductions_modify' },
            { title: 'Delete', key: 'function_tran_pp_deductions_delete' },
          ],
        },
        {
          title: 'Compute',
          key: 'feature_tran_pp_compute',
          children: [
            {
              title: 'Home Allotment',
              key: 'sub_feature_tran_pp_compute_home_allotment',
              children: [
                {
                  title: 'Process',
                  key: 'function_tran_pp_compute_home_allotment_process',
                },
                {
                  title: 'Delete',
                  key: 'function_tran_pp_compute_home_allotment_delete',
                },
                {
                  title: 'Print',
                  key: 'function_tran_pp_compute_home_allotment_print',
                },
              ],
            },
            {
              title: 'SLB',
              key: 'sub_feature_tran_pp_compute_slb',
              children: [
                {
                  title: 'Process',
                  key: 'function_tran_pp_compute_slb_process',
                },
                {
                  title: 'Delete',
                  key: 'function_tran_pp_compute_slb_delete',
                },
                {
                  title: 'Print',
                  key: 'function_tran_pp_compute_slb_print',
                },
              ],
            },
            {
              title: 'MPO',
              key: 'sub_feature_tran_pp_compute_mpo',
              children: [
                {
                  title: 'Process',
                  key: 'function_tran_pp_compute_mpo_process',
                },
                {
                  title: 'Delete',
                  key: 'function_tran_pp_compute_mpo_delete',
                },
                {
                  title: 'Print',
                  key: 'function_tran_pp_compute_mpo_print',
                },
              ],
            },
            {
              title: 'Crew Wages',
              key: 'sub_feature_tran_pp_compute_crew_wages',
              children: [
                {
                  title: 'Filter',
                  key: 'function_tran_pp_compute_crew_wages_filter',
                },
                {
                  title: 'Print',
                  key: 'function_tran_pp_compute_crew_wages_print',
                },
                {
                  title: 'Compute',
                  key: 'function_tran_pp_compute_crew_wages_compute',
                },
                {
                  title: 'Edit Initial',
                  key: 'function_tran_pp_compute_crew_wages_edit_initial_modify',
                },
                {
                  title: 'Action',
                  key: 'function_tran_pp_compute_crew_wages_action_modify',
                },
                {
                  title: 'Templates',
                  key: 'function_tran_pp_compute_crew_wages_templates',
                  children: [
                    {
                      title: 'Fugro',
                      key: 'function_tran_pp_compute_crew_wages_template_fugro',
                    },
                    {
                      title: 'SMT',
                      key: 'function_tran_pp_compute_crew_wages_template_smt',
                    },
                    {
                      title: 'Forestwave',
                      key: 'function_tran_pp_compute_crew_wages_template_forestwave',
                    },
                  ],
                },
              ],
            },
            {
              title: 'Remaining wages',
              key: 'sub_feature_tran_pp_compute_remaining_wages',
              children: [
                {
                  title: 'Filter',
                  key: 'function_tran_pp_compute_remaining_wages_filter',
                },
                {
                  title: 'Print',
                  key: 'function_tran_pp_compute_remaining_wages_print',
                },
                {
                  title: 'Compute',
                  key: 'function_tran_pp_compute_remaining_wages_compute',
                },
                {
                  title: 'Action',
                  key: 'function_tran_pp_compute_remaining_wages_action_modify',
                },
              ],
            },
          ],
        },
        {
          title: 'MPO Requests',
          key: 'feature_tran_pp_mpo_requests',
          children: [
            { title: 'Filter', key: 'function_tran_pp_mpo_requests_filter' },
            { title: 'Modify', key: 'function_tran_pp_mpo_requests_modify' },
            { title: 'Delete', key: 'function_tran_pp_mpo_requests_delete' },
          ],
        },
        {
          title: 'SLB Requests',
          key: 'feature_tran_pp_slb_requests',
          children: [
            { title: 'Filter', key: 'function_tran_pp_slb_requests_filter' },
            { title: 'Modify', key: 'function_tran_pp_slb_requests_modify' },
            { title: 'Delete', key: 'function_tran_pp_slb_requests_delete' },
          ],
        },
      ],
    },
  ],
};
