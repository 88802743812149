import axios from 'axios';
import {
  GET_ALL_BANK_ENDPOINT,
  GET_SHOW_ALL_MPO_REQUEST_ALLOTMENT,
  GET_SHOW_ALL_SLB_ALLT_ENDPOINT,
  GET_SHOW_MPO_REQUEST_ALLOTMENT,
  GET_SHOW_SLB_REQUEST_ALLOTMENT,
  POST_ADD_ALLOTMENT_ENDPOINT,
  POST_ADD_MPO_REQUEST_ALLOTMENT,
  POST_ADD_SIDE_LETTER_CREW_AMOUNT_ENDPOINT,
  POST_ADD_SLB_REQUEST_ALLOTMENT,
  POST_DELETE_ALLOTTEE_ENDPOINT,
  POST_DELETE_MPO_REQUEST_ALLOTMENT,
  POST_DELETE_SLB_REQUEST_ALLOTMENT,
  POST_UPDATE_ALLOTTEE_ENDPOINT,
  POST_UPDATE_MPO_ALLOTTEE_ENDPOINT,
  POST_UPDATE_SIDE_LETTER_ALLOTTEE_ENDPOINT,
  REQUEST_HEADERS,
} from './_constants';

export async function postAddAllottee(payload) {
  try {
    const { data } = await axios.post(POST_ADD_ALLOTMENT_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postUpdateHomeAllotment(payload) {
  try {
    const { data } = await axios.post(POST_UPDATE_ALLOTTEE_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postUpdateSideLetter(payload) {
  try {
    const { data } = await axios.post(
      POST_UPDATE_SIDE_LETTER_ALLOTTEE_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postUpdateSideLetteTotalAmount(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_SIDE_LETTER_CREW_AMOUNT_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postUpdateMPO(payload) {
  try {
    const { data } = await axios.post(
      POST_UPDATE_MPO_ALLOTTEE_ENDPOINT,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeleteAllottee(payload) {
  try {
    const { data } = await axios.post(POST_DELETE_ALLOTTEE_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getAllBanks() {
  try {
    const { data } = await axios.post(
      GET_ALL_BANK_ENDPOINT,
      {},
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getAllMpoRequestAllottee(crew_no) {
  try {
    const { data } = await axios.get(
      `${GET_SHOW_MPO_REQUEST_ALLOTMENT}/${crew_no}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getAllSlbRequestAllottee(crew_no) {
  try {
    const { data } = await axios.get(
      `${GET_SHOW_SLB_REQUEST_ALLOTMENT}/${crew_no}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getShowAllMpoRequestAllottee(vescode, year, month) {
  try {
    const { data } = await axios.get(
      `${GET_SHOW_ALL_MPO_REQUEST_ALLOTMENT}/${vescode}/${year}/${month}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getShowAllSlbRequestAllottee(vescode, year, month) {
  try {
    const { data } = await axios.get(
      `${GET_SHOW_ALL_SLB_ALLT_ENDPOINT}/${vescode}/${year}/${month}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddMpoRequestAllottee(payload) {
  try {
    const { data } = await axios.post(POST_ADD_MPO_REQUEST_ALLOTMENT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddSlbRequestAllottee(payload) {
  try {
    const { data } = await axios.post(POST_ADD_SLB_REQUEST_ALLOTMENT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeleteMpoRequestAllottee(payload) {
  try {
    const { data } = await axios.post(
      `${POST_DELETE_MPO_REQUEST_ALLOTMENT}/${payload.allottee_id}/${payload.id}/${payload.crew_no}`,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeleteSlbRequestAllottee(payload) {
  try {
    const { data } = await axios.post(
      `${POST_DELETE_SLB_REQUEST_ALLOTMENT}/${payload.allottee_id}/${payload.id}/${payload.crew_no}`,
      payload,
      {
        headers: REQUEST_HEADERS,
      }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
