export default {
  id: null,
  p_code: null,
  p_name: null,
  based_on_days: null,
  days: null,
  is_deleted: null,
  delete_by: null,
  created_at: null,
  updated_at: null,
};
