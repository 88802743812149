import dayjs from 'dayjs';
import UserSetupType from '../types/UserSetupType';
import FinalPayWUAType from '../types/FinalPayWUAType';
import FinalPayAddtlType from '../types/FinalPayAddtlType ';
import FinalPayGovConType from '../types/FinalPayGovConType';

export const getFinalPayGovContriFields = (
  isLoading,
  state = FinalPayGovConType,
  setState,
  setHasChanges,
  inputToLocale,
  exchangeRate
) => {
  return [
    {
      type: 'text',
      name: 'desc',
      placeholder: 'Description',
      label: 'Description',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.desc,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, desc: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number-negative',
      name: 'usd_amt',
      placeholder: '0.00',
      label: 'USD Amount',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.usd_amt,
      setValue: (e) => {
        const value = e.target.value;
        const phpAmount = parseFloat(value) * parseFloat(exchangeRate);
        setState({
          ...state,
          usd_amt: value,
          php_amt: phpAmount,
          is_usd_manual: true,
        });
        setHasChanges(true);
      },
    },
    {
      type: 'number-negative',
      name: 'php_amt',
      placeholder: '0.00',
      label: 'PHP Amount',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.php_amt,
      setValue: (e) => {
        const value = e.target.value;
        const usdAmount = parseFloat(value) / parseFloat(exchangeRate);
        if (state?.is_usd_manual) {
          setState({
            ...state,
            php_amt: value,
          });
        } else {
          setState({
            ...state,
            usd_amt: parseFloat(usdAmount).toFixed(2),
            php_amt: value,
          });
        }

        setHasChanges(true);
      },
    },
  ];
};
