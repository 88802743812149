import { Button, Input, Popconfirm, Space, Switch } from 'antd';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';

export function getDeductionsTable(handleAction) {
  return [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      fixed: 'left',
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      sorter: (a, b) => a.desc.localeCompare(b.desc),
    },
    {
      title: 'SSS',
      key: 'sss',
      render: (text, record) => (
        <Switch
          disabled
          size='small'
          checked={record.sss == 1 ? true : false}
        />
      ),
    },
    {
      title: 'PHILHEALTH',
      key: 'philhealth',
      render: (text, record) => (
        <Switch
          size='small'
          disabled
          checked={record.philhealth == 1 ? true : false}
        />
      ),
    },
    {
      title: 'PAGIBIG',
      key: 'pag_ibig',
      render: (text, record) => (
        <Switch
          size='small'
          disabled
          checked={record.pag_ibig == 1 ? true : false}
        />
      ),
    },
    {
      title: 'UNION',
      key: 'union',
      render: (text, record) => (
        <Switch
          size='small'
          disabled
          checked={record.union == 1 ? true : false}
        />
      ),
    },
    {
      title: 'Account Code',
      dataIndex: 'acct_code',
      key: 'acct_code',
      sorter: (a, b) => a.acct_code.localeCompare(b.acct_code),
    },
    {
      title: 'SL Account Code',
      dataIndex: 'sl_acct_code',
      key: 'sl_acct_code',
      sorter: (a, b) => a.sl_acct_code.localeCompare(b.sl_acct_code),
    },
    {
      title: 'Expense Account Code',
      dataIndex: 'expense_acct_code',
      key: 'expense_acct_code',
      sorter: (a, b) => a.expense_acct_code.localeCompare(b.expense_acct_code),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            type='primary'
            onClick={() => handleAction(record, 'edit')}
            icon={<FaWrench size={15} />}
          />
          <Popconfirm
            title='Delete record'
            description='Are you sure to delete this record?'
            onConfirm={() => handleAction(record, 'delete')}
            okText='Yes'
            cancelText='No'
          >
            <Button danger icon={<AiFillDelete size={15} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
}
