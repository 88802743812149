import React, { useContext } from 'react';
import {
  PDFViewer,
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from '@react-pdf/renderer';
import HomeAllotmentHeaderType from '../../utils/types/HomeAllotmentHeaderType';
import dayjs from 'dayjs';
import logoImage from '../../assets/icon-name.png';
import { UserContext } from '../../utils/context/UserContext';

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 10,
    paddingBottom: 40,
  },
  container: {
    flex: 1,
    alignItems: 'center',
  },
  headerImage: {
    height: 60,
    width: 60,
  },
  headerText: {
    fontSize: 6,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
  },
  tableRow: {
    flexDirection: 'row',
    borderTopWidth: 0.5,
    paddingBottom: 0,
  },
  tableCell: {
    width: '6%',
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    paddingTop: 5,
    paddingBottom: 0,
    paddingHorizontal: 1,
    fontSize: '6px',
    marginBottom: 0,
  },

  pageNumber: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 10,
    fontSize: 6,
    textAlign: 'left',
    paddingHorizontal: 10,
  },
  legends: {
    //   position: 'absolute',
    //   bottom: 30,
    //   left: 80,
    //   right: 0,
    fontSize: 8,
    textAlign: 'left',
  },

  bottomContainer: { flexDirection: 'row', margin: 0, padding: 0 },
  column: {
    flex: 1,
    margin: 10,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    // borderTopWidth: 1,
    // textAlign: 'center',
    // backgroundColor: 'lightgray',
    paddingTop: 10,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
});

export default function ProcessedGovContriPDF({
  contriList,
  selectedPrincipal,
  date,
  exchangeRate,
}) {
  const { inputToLocale, generateFiveNumber } = useContext(UserContext);

  // const getEarningValue = (header, earnings) => {
  //   let amount = 0;
  //   const record = earnings.find(
  //     (earning) => earning.salary_code == header.salary_code
  //   );
  //   if (record) {
  //     amount = record.amount;
  //   }
  //   return parseFloat(amount) != 0 ? inputToLocale(amount) : '';
  // };

  // const getDeductionValue = (header, deductions) => {
  //   let amount = 0;
  //   const record = deductions.find(
  //     (ded) => ded.salary_code == header.salary_code
  //   );
  //   if (record) {
  //     amount = record.amount;
  //   }
  //   return parseFloat(amount) != 0 ? inputToLocale(amount) : '';
  // };

  // const getAccruedValue = (header, accrued) => {
  //   let amount = 0;
  //   const record = accrued.find((acc) => acc.salary_code == header.salary_code);
  //   if (record) {
  //     amount = record.current_month;
  //   }
  //   return parseFloat(amount) != 0 ? inputToLocale(amount) : '';
  // };

  // const getEarningGrandTotal = (header) => {
  //   const record = grandTotals.grand_total_earnings[header.salary_code];
  //   return parseFloat(record) != 0 ? inputToLocale(record) : '';
  // };

  // const getDeductionGrandTotal = (header) => {
  //   const record = grandTotals.grand_total_deductions[header.salary_code];
  //   return parseFloat(record) != 0 ? inputToLocale(record) : '';
  // };

  // const getAccruedGrandTotal = (header) => {
  //   const record =
  //     grandTotals.grand_total_accrued[header.salary_code][0].curr_grand_total;
  //   return parseFloat(record) != 0 ? inputToLocale(record) : '';
  // };

  // const parseValue = (value) => {
  //   if (parseFloat(value) != 0) {
  //     return inputToLocale(value);
  //   } else {
  //     return '';
  //   }
  // };

  return (
    <PDFViewer style={{ width: '98%', height: '100vh' }}>
      <Document>
        <Page size='LEGAL' style={styles.page} orientation='landscape' wrap>
          <View
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              alignItems: 'center',
              marginBottom: 10,
            }}
            fixed
          >
            <Image src={logoImage} style={styles.headerImage} />

            <Text style={styles.headerText}>
              LIST OF GOVERNMENT CONTRIBUTIONS PER PRINCIPAL & VESSEL
            </Text>
            <Text style={styles.headerText}>
              {dayjs(`${date.month}-${date.year}`, 'M-YYYY')
                .format('MMMM YYYY')
                .toUpperCase()}
            </Text>
          </View>

          <View style={styles.footer} wrap={false}>
            <View style={{ ...styles.bottomContainer, paddingLeft: 100 }}>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Prepared By:{`\n\n_____________________\n`} {`        `}
                  Administrator
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Checked By:{`\n\n_____________________\n`} {`           `}
                  Accountant
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Approved By:{`\n\n_____________________\n`} {`             `}
                  President
                </Text>
              </View>
            </View>
          </View>
          <Text
            style={{ ...styles.pageNumber, textAlign: 'right' }}
            render={({ pageNumber, totalPages }) =>
              `Page: ${pageNumber} / ${totalPages}`
            }
            fixed
          />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `Date printed: [${dayjs().format('YYYY-MM-DD HH:mm:ss')}]`
            }
            fixed
          />
        </Page>
      </Document>
    </PDFViewer>
  );
}
