export default {
  id: null,
  vessel_id: null,
  vessel_api_id: null,
  vessel_code: null,
  vessel_name: null,
  principal_name: null,
  group: null,
  agency_name: null,
  month: null,
  year: null,
  exchange_rate: null,
};
