import { Button, DatePicker, Input, Popconfirm, Space, Switch } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';

export function getSlbRequestTable(handleAction) {
  return [
    {
      title: 'Crew no',
      dataIndex: 'crew_no',
      key: 'crew_no',
      sorter: (a, b) => a.crew_no.localeCompare(b.crew_no),
    },
    {
      title: 'Allottee id',
      dataIndex: 'allottee_id',
      key: 'allottee_id',
    },
    {
      title: 'Allottee Name',
      dataIndex: 'allottee_name',
      key: 'allottee_name',
      sorter: (a, b) => a.allottee_name.localeCompare(b.allottee_name),
    },
    {
      title: 'Date requested',
      dataIndex: 'date_requested',
      key: 'date_requested',
    },
    {
      title: 'Side Letter Amount',
      dataIndex: 'sideletter_amount',
      key: 'sideletter_amount',
      sorter: (a, b) => a.sideletter_amount - b.sideletter_amount,
    },
    {
      title: 'Deduct on final pay',
      key: 'deduct_on_leave_final',
      render: (text, record) => (
        <Switch
          size="small"
          disabled={true}
          checked={record.deduct_on_leave_final == 0 ? false : true}
        />
      ),
    },
    {
      title: 'Apply',
      key: 'apply',
      render: (text, record) => (
        <Switch
          size="small"
          disabled={true}
          checked={record.apply == 0 ? false : true}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => handleAction(record)}
            icon={<FaWrench size={15} />}
          />
          <Popconfirm
            title="Delete record"
            description="Are you sure to delete this record?"
            onConfirm={() => handleAction(record, 'delete')}
            okText="Yes"
            cancelText="No"
          >
            <Button danger icon={<AiFillDelete size={15} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
}
