import React, { useContext, useEffect } from 'react';
import CrewType from '../../../../../utils/types/CrewType';
import { Content } from 'antd/es/layout/layout';
import { Button, Col, Form, Row, Typography } from 'antd';
import {
  getFixedPagibigPerCrew,
  postAddFixedPagibig,
  postUpdateFixedPagibig,
} from '../../../../../utils/api/DeductionsAPI';
import { GlobalContext } from '../../../../../App';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../../../../utils/api/_constants';
import { useState } from 'react';
import FixedPagibigType from '../../../../../utils/types/FixedPagibigType';
import { getFixedPagibigFields } from '../../../../../utils/inputfields/FixedPagIbigFields';
import FormInput from '../../../../../components/FormInput/FormInput';
import ConfirmationModal from '../../../../../components/CustomModal/ConfirmationModal';

export default function EmbarkationOthersPage({
  selectedCrew = CrewType,
  isLoading,
  setIsLoading,
  hasChanges,
  setHasChanges,
  fetchDatas,
}) {
  const { openNotification } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [selectedPagibig, setSelectedPagibig] = useState(FixedPagibigType);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const fetchFixedPagibig = async () => {
    const { data: res, error } = await getFixedPagibigPerCrew(
      selectedCrew.crew_no
    );
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Fixed Pagibig',
        'Fetching data failed.'
      );
    } else {
      if (res.length > 0) {
        form.setFieldsValue(res[0]);
        setSelectedPagibig(res[0]);
      } else {
        form.setFieldsValue(FixedPagibigType);
        setSelectedPagibig(FixedPagibigType);
      }
    }
  };
  useEffect(() => {
    fetchFixedPagibig();
  }, [selectedCrew]);

  return (
    <Content>
      <Typography.Title level={3}>Fixed Pag-Ibig Contribution</Typography.Title>
      <Form
        className="m-3"
        labelAlign="left"
        form={form}
        name="pagibig_form"
        initialValues={selectedPagibig}
        layout="vertical"
      >
        <Row gutter={16}>
          {getFixedPagibigFields(
            isLoading,
            selectedPagibig,
            setSelectedPagibig,
            setHasChanges
          ).map((data, index) => (
            <Col xs={24} sm={12} key={index}>
              <FormInput
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            </Col>
          ))}
        </Row>
        {hasChanges && (
          <Row>
            <Button
              danger
              className="me-2"
              onClick={() => {
                form.resetFields();
                form.setFieldsValue(selectedCrew.allotment);
                setHasChanges(false);
              }}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              onClick={async () => {
                try {
                  await form.validateFields();
                  setIsConfirmationModalOpen(true);
                } catch {}
              }}
            >
              Save
            </Button>
          </Row>
        )}
      </Form>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onCancel={() => setIsConfirmationModalOpen(false)}
        onConfirm={async () => {
          setIsLoading(true);
          if (selectedPagibig.id === null) {
            const { data: res, error } = await postAddFixedPagibig({
              ...selectedPagibig,
              crew_no: selectedCrew.crew_no,
              vescode: selectedCrew.vessel_id,
            });
            if (error) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                'Fixed Pag Ibig',
                'Error saving value.'
              );
            } else {
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                'Fixed Pag Ibig',
                'Success saving value.'
              );
              setHasChanges(false);
              setIsConfirmationModalOpen(false);
              fetchDatas();
            }
          } else {
            const { data: res, error } = await postUpdateFixedPagibig(
              selectedPagibig
            );
            if (error) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                'Fixed Pag Ibig',
                'Error saving value.'
              );
            } else {
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                'Fixed Pag Ibig',
                'Success saving value.'
              );
              setHasChanges(false);
              setIsConfirmationModalOpen(false);
              fetchDatas();
            }
          }

          setIsLoading(false);
        }}
      />
    </Content>
  );
}
