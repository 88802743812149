export default {
  id: null,
  code: null,
  desc: null,
  short_name: null,
  pagibig_loan: null,
  sss_loan: null,
  account_code: null,
  sl_acct_code: null,
  expense_acct_code: null,
  show_legends: null,
  is_deleted: null,
  delete_by: null,
  created_at: null,
  updated_at: null,
};
