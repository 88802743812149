import { Content } from 'antd/es/layout/layout';
import React, { useContext } from 'react';
import { UserContext } from '../../../utils/context/UserContext';
import BankDownloadingBPIPage from './BankDownloading/BankDownloadingBPIPage';
import { Tabs } from 'antd';
import BankDownloadingBDOPage from './BankDownloading/BankDownloadingBDOPage';

export default function ReportBankDownloadingPage() {
  const { validateAccessToFeature } = useContext(UserContext);
  const checkAvailableTabs = () => {
    const tabs = [];
    // if (validateAccessToFeature('sub_feature_tran_pp_embarked')) {
    tabs.push({
      key: '1',
      label: 'BPI',
      children: <BankDownloadingBPIPage />,
      disabled: !validateAccessToFeature('sub_feature_tran_pp_embarked'),
    });
    tabs.push({
      key: '2',
      label: 'BDO',
      children: <BankDownloadingBDOPage />,
      disabled: !validateAccessToFeature('sub_feature_tran_pp_embarked'),
    });
    // }
    // if (validateAccessToFeature('sub_feature_tran_pp_disembarked')) {
    // tabs.push({
    //   key: '2',
    //   label: 'Disembarked',
    //   children: <DisembarkedPage />,
    //   disabled: !validateAccessToFeature('sub_feature_tran_pp_disembarked'),
    // });
    // }
    // if (validateAccessToFeature('')) {
    // tabs.push({
    //   key: '3',
    //   label: 'Hold Allotment',
    //   children: '',
    //   disabled: !validateAccessToFeature(''),
    // });
    // }
    // if (validateAccessToFeature('sub_feature_tran_pp_post_records')) {
    //   tabs.push({
    //     key: '4',
    //     label: 'Close Home Allotment/MPO/Side Letter Bonus',
    //     children: <CloseAllotmentPage />,
    //     disabled: !validateAccessToFeature('sub_feature_tran_pp_post_records'),
    //   });
    // }
    // if (validateAccessToFeature('')) {
    // tabs.push({
    //   key: '5',
    //   label: 'Process Government Contributions',
    //   children: <ProcessGovContriPage />,
    //   // disabled: !validateAccessToFeature(''),
    // });
    // }
    return tabs;
  };

  return (
    <Content>
      <Tabs items={checkAvailableTabs()} centered />
    </Content>
  );
}
