import { Button, DatePicker, Input, Popconfirm, Space, Switch } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { AiFillDelete, AiFillEdit, AiFillPrinter } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';

export function getCrewWagesComputeTable(
  handleAction,
  selectedTemplate,
  isVesselPosted,
  validateAccessToFeature
) {
  return [
    {
      title: 'Crew no.',
      dataIndex: 'crew_no',
      key: 'crew_no',
      sorter: (a, b) => a.crew_no.localeCompare(b.crew_no),
      fixed: 'left',
    },
    {
      title: 'Crew name',
      dataIndex: 'crew_name',
      key: 'crew_name',
      fixed: 'left',
      sorter: (a, b) => a.crew_name.localeCompare(b.crew_name),
    },
    {
      title: 'Position',
      dataIndex: 'position_name',
      key: 'position_name',
      sorter: (a, b) => a.position_name.localeCompare(b.position_name),
    },
    // {
    //   title: 'Initial Prov.',
    //   key: 'initial_prov',
    //   render: (text, record) => (
    //     <Input
    //       key={record.initial_prov}
    //       type="number"
    //       placeholder={'Initial Prov.'}
    //       autoFocus={false}
    //       disabled={false}
    //       defaultValue={record.initial_prov}
    //       onBlur={(e) => {
    //         const value = e.target.value;
    //         const payload = {
    //           ...record,
    //           initial_provf: value,
    //           disembark: record.original_disembark,
    //           embark: record.embark_date,
    //         };
    //         onTableChange(payload);
    //       }}
    //       allowClear={true}
    //     />
    //   ),
    // },
    // {
    //   title: 'Initial Leave',
    //   key: 'initial_leave',
    //   render: (text, record) => (
    //     <Input
    //       key={record.initial_leave}
    //       type="number"
    //       placeholder={'Initial Leave'}
    //       autoFocus={false}
    //       disabled={false}
    //       defaultValue={record.initial_leave}
    //       onBlur={(e) => {
    //         const value = e.target.value;
    //         const payload = {
    //           ...record,
    //           initial_provf: record.initial_prov,
    //           disembark: record.original_disembark,
    //           embark: record.embark_date,
    //           initial_leave: value ? value : '0.00',
    //         };
    //         onTableChange(payload);
    //       }}
    //       allowClear={true}
    //     />
    //   ),
    // },
    {
      title: 'Sign On',
      dataIndex: 'sign_on',
      key: 'sign_on',
    },
    {
      title: 'Arrive Vessel',
      dataIndex: 'embark_date',
      key: 'embark_date',
    },
    {
      title: 'Sign Off',
      dataIndex: 'sign_off',
      key: 'sign_off',
    },
    {
      title: 'Arrive Manila',
      dataIndex: 'disembark',
      key: 'disembark',
    },
    {
      title: 'Travel days',
      dataIndex: 'travel_days',
      key: 'travel_days',
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            disabled={
              selectedTemplate?.cw_format == null ||
              isVesselPosted ||
              !validateAccessToFeature(
                'function_tran_pp_compute_crew_wages_action_modify'
              )
            }
            onClick={() => handleAction(record)}
            icon={<FaWrench size={15} />}
          />
          <Button
            type='primary'
            disabled={
              selectedTemplate?.cw_format == null ||
              // selectedTemplate == '3' ||
              !validateAccessToFeature(
                'function_tran_pp_compute_crew_wages_print'
              )
            }
            onClick={() => handleAction(record, 'print')}
            icon={<AiFillPrinter size={15} />}
          >
            Print
          </Button>
        </Space>
      ),
    },
  ];
}
