import { Button, DatePicker, Input, Popconfirm, Space, Switch } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { AiFillDelete, AiFillEdit, AiFillPrinter } from 'react-icons/ai';
import { FaEye, FaWrench } from 'react-icons/fa';

export function getFinalPayWUATable(handleAction) {
  return [
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
    },
    {
      title: 'Based on days',
      dataIndex: 'based_on_days',
      key: 'based_on_days',
    },
    {
      title: 'USD Amount',
      dataIndex: 'usd_amt',
      key: 'usd_amt',
      sorter: (a, b) => a.usd_amt - b.usd_amt,
    },
    {
      title: 'PHP Amount',
      dataIndex: 'php_amt',
      key: 'php_amt',
      sorter: (a, b) => a.php_amt - b.php_amt,
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            onClick={() => handleAction(record, 'edit')}
            icon={<FaWrench size={15} />}
          />
          <Popconfirm
            title='Delete record'
            description='Are you sure to delete this record?'
            onConfirm={() => handleAction(record, 'delete')}
            okText='Yes'
            cancelText='No'
          >
            <Button danger icon={<AiFillDelete size={15} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
}
