import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'antd';
import Search from 'antd/es/input/Search';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { IoIosRefresh } from 'react-icons/io';
import { GlobalContext } from '../../../App';
import { UserContext } from '../../../utils/context/UserContext';
import {
  ERROR_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../../utils/api/_constants';
import FormModal from '../../../components/CustomModal/FormModal';
import FormInput from '../../../components/FormInput/FormInput';
import ConfirmationModal from '../../../components/CustomModal/ConfirmationModal';
import UnionDuesType from '../../../utils/types/UnionDuesType';
import {
  addUnionDues,
  getAllUnionDues,
  postDeleteUnionDues,
  postModifyUnionDues,
} from '../../../utils/api/UnionDuesAPI';
import { getUnionDuesTable } from '../../../utils/tableheader/Maintenance/UnionDuesTable';
import { getUnionDuesFields } from '../../../utils/inputfields/UnionDuesSetupFields';

export default function UnionDuesPage() {
  const { openNotification } = useContext(GlobalContext);
  const { doSearchInArray } = useContext(UserContext);

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [unionDuesList, setUnionDuesList] = useState([]);

  const [selectedDues, setSelectedDues] = useState(UnionDuesType);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);

  const tableContents = filteredList.map((item, index) => ({
    ...item,
    key: index,
  }));

  const handleAction = async (record, action = 'edit') => {
    if (action === 'delete') {
      setIsLoading(true);
      const { data: res, error } = await postDeleteUnionDues(record);
      if (error) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Deleting Union dues',
          'Error deleting changes!'
        );
      } else {
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          'Deleting Union dues',
          'Record deleted!'
        );
        fetchData();
      }
      setIsLoading(false);
    } else {
      let fixed_value = 0;
      if (record.fixed == 1) {
        if (record.fixed_officer == 1 && record.fixed_ratings == 1) {
          fixed_value = 1;
        } else if (record.fixed_officer == 1 && record.fixed_ratings == 0) {
          fixed_value = 2;
        } else if (record.fixed_officer == 0 && record.fixed_ratings == 1) {
          fixed_value = 3;
        }
      }

      const parseRecord = {
        ...record,
        fixed_dropdown: fixed_value,
      };

      form.setFieldsValue(parseRecord);
      setSelectedDues(parseRecord);
      setIsFormModalOpen(true);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    const { data: res, error } = await getAllUnionDues();
    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Union dues',
        'Loading union dues failed.'
      );
    } else {
      setFilteredList(res);
      setUnionDuesList(res);
      doSearchInArray(searchQuery, setFilteredList, res);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Content>
      <Row className="mb-4" justify={'end'}>
        <Col xs={24} sm={8} className="text-end">
          <Search
            disabled={isLoading}
            placeholder="Search union dues!"
            onChange={(e) => {
              setSearchQuery(e.target.value);
              doSearchInArray(e.target.value, setFilteredList, unionDuesList);
            }}
            onSearch={(value) => {
              setSearchQuery(value);
              doSearchInArray(value, setFilteredList, unionDuesList);
            }}
            allowClear={true}
          />
        </Col>
        <Col xs={24} sm={3} className="text-end">
          <Button
            className="mx-2"
            type="primary"
            icon={<AiOutlinePlusCircle size={18} />}
            disabled={isLoading}
            onClick={() => {
              const parseDefault = {
                ...UnionDuesType,
                fixed_dropdown: null,
              };
              setSelectedDues(parseDefault);
              form.setFieldsValue(parseDefault);
              setIsFormModalOpen(true);
            }}
          >
            Add
          </Button>
          <Button
            type="primary"
            icon={<IoIosRefresh size={18} />}
            disabled={isLoading}
            onClick={() => fetchData()}
          />
        </Col>
      </Row>
      <Table
        columns={getUnionDuesTable(handleAction)}
        dataSource={tableContents}
        size="small"
        bordered="true"
        scroll={{ x: true }}
        loading={isLoading}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <FormModal
        title={'Union Dues Entry'}
        isOpen={isFormModalOpen}
        setIsOpen={setIsFormModalOpen}
        isLoading={isLoading}
        props={{ width: '50vw' }}
        form={form}
        formName={'deduction_form'}
        initialState={selectedDues}
        onSave={async () => {
          if (hasChanges) {
            try {
              await form.validateFields();
              setIsConfirmationModalOpen(true);
            } catch {}
          } else {
            openNotification(
              INFO_NOTIFICATION_TYPE,
              'Union dues entry',
              'No changes made.'
            );
            setIsFormModalOpen(false);
          }
        }}
        onCancel={() => {
          if (hasChanges) {
            setIsUnsavedModalOpen(true);
          } else {
            form.resetFields();
            setIsFormModalOpen(false);
            setHasChanges(false);
          }
        }}
      >
        <Row gutter={16}>
          {getUnionDuesFields(
            isLoading,
            selectedDues,
            setSelectedDues,
            setHasChanges
          ).map((data, index) => (
            <Col key={index} xs={24} sm={8}>
              <FormInput
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            </Col>
          ))}
        </Row>
      </FormModal>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          if (selectedDues.id) {
            const { data: res, error } = await postModifyUnionDues(
              selectedDues
            );
            if (error) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                'Union dues entry',
                'Error saving changes!'
              );
            } else {
              if (res.isSuccessful === 'false') {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'Union dues entry',
                  res.message
                );
              } else {
                openNotification(
                  SUCCESS_NOTIFICATION_TYPE,
                  'Union dues entry',
                  'Set up saved!'
                );
                fetchData();
                setHasChanges(false);
                setIsFormModalOpen(false);
              }
            }
          } else {
            const { data: res, error } = await addUnionDues(selectedDues);
            if (error) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                'Adding Union dues',
                'Error saving changes!'
              );
            } else {
              if (res.isSuccessful === 'false') {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  'Adding Other deduction',
                  res.message
                );
              } else {
                openNotification(
                  SUCCESS_NOTIFICATION_TYPE,
                  'Adding Union dues',
                  'Set up saved!'
                );
                fetchData();
                setHasChanges(false);
                setIsFormModalOpen(false);
              }
            }
          }

          setIsConfirmationModalOpen(false);
          setIsLoading(false);
        }}
      />
      <ConfirmationModal
        title="You have unsaved changes! Confirming will lose all your changes. Confirm?"
        cancelText="Continue editing"
        isLoading={isLoading}
        isOpen={isUnsavedModalOpen}
        setIsOpen={setIsUnsavedModalOpen}
        onConfirm={() => {
          form.resetFields();
          setIsUnsavedModalOpen(false);
          setIsFormModalOpen(false);
          setHasChanges(false);
        }}
      />
    </Content>
  );
}
