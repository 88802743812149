import UserSetupType from '../types/UserSetupType';

export const getUserSetupFields = (
  isLoading,
  state = UserSetupType,
  setState,
  setHasChanges,
  accessLevelOptions,
  emailValidationAPI,
  usernameValidationAPI,
  isViewingOnly,
  originalUser
) => {
  return [
    {
      type: 'text',
      name: 'user_id',
      placeholder: '0',
      label: 'ID',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.user_id,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, user_id: value });
        setHasChanges(true);
      },
    },
    {
      type: 'username',
      name: 'user_name',
      placeholder: 'Username',
      label: 'Username',
      autofocus: true,
      required: true,
      disabled: isLoading || isViewingOnly,
      value: state.user_name,
      usernameValidatorAPI:
        state.user_name != originalUser.user_name
          ? usernameValidationAPI
          : null,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, user_code: value, user_name: value });
        setHasChanges(true);
      },
    },
    {
      type: 'email',
      name: 'email',
      placeholder: 'john.doe@domain.com',
      label: 'Email',
      autofocus: false,
      required: true,
      disabled: isLoading || isViewingOnly,
      value: state.email,
      emailValidatorAPI:
        state.email != originalUser.email ? emailValidationAPI : null,
      setValue: async (e) => {
        const value = e.target.value;
        setState({ ...state, email: value });
        setHasChanges(true);
      },
    },
    {
      type: 'dropdown',
      name: 'access_level',
      placeholder: '',
      label: 'Access Level',
      autofocus: false,
      required: true,
      hidden: false,
      disabled: isLoading || isViewingOnly,
      value: state.access_level,
      dropdownOptions: accessLevelOptions.map((item, index) => ({
        key: index,
        id: item.id,
        description: item.title,
      })),
      setValue: (value, option) => {
        setState({
          ...state,
          access_level: value,
          leveldesc: option.children,
        });
        setHasChanges(true);
      },
    },
    {
      type: 'name',
      name: 'first_name',
      placeholder: 'John',
      label: 'First name',
      autofocus: false,
      required: true,
      disabled: isLoading || isViewingOnly,
      value: state.first_name,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, first_name: value });
        setHasChanges(true);
      },
    },
    {
      type: 'name',
      name: 'last_name',
      placeholder: 'Doe',
      label: 'Last name',
      autofocus: false,
      required: true,
      disabled: isLoading || isViewingOnly,
      value: state.last_name,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, last_name: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'last_login',
      placeholder: 'Last login',
      label: 'Last login',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.last_login,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, last_login: value });
        setHasChanges(true);
      },
    },
    {
      type: 'password',
      name: 'password',
      placeholder: 'Your secured password here',
      label: 'Password',
      autofocus: false,
      required: state.user_id == null,
      disabled: isLoading || isViewingOnly,
      withGeneratePassword: true,
      value: state.password,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, password: value });
        setHasChanges(true);
      },
    },
  ];
};
