import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Search from 'antd/es/transfer/search';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { UserContext } from '../../utils/context/UserContext';

export default function FormInput({
  type = 'text',
  name,
  placeholder,
  label,
  autoFocus = false,
  value,
  onChange,
  required = true,
  disabled = false,
  hidden = false,
  allowClear = true,
  dropdownOptions = [],
  withGeneratePassword = false,
  emailValidatorAPI = null,
  usernameValidatorAPI = null,
  props,
}) {
  const { generateRandomPassword } = useContext(UserContext);

  const validateInput = (rule, value) => {
    const scriptPattern = /<\s*script[^>]*>.*?<\s*\/\s*script\s*>/gi;
    if (scriptPattern.test(value)) {
      return Promise.reject('Input cannot contain scripts!');
    }
    return Promise.resolve();
  };

  const handleEmailAvailabilityChecking = async (_, email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(email)) {
      const { data: res, error } = await emailValidatorAPI({ email });
      if (error) {
        throw new Error('Email validation failed.');
      } else {
        if (res.exists) {
          throw new Error(
            `${email} is already used, please use another email.`
          );
        }
      }
    }
  };

  const handleUsernameAvailabilityChecking = async (_, user_name) => {
    const { data: res, error } = await usernameValidatorAPI({ user_name });
    if (error) {
      throw new Error('Username validation failed.');
    } else {
      if (res.exists) {
        throw new Error(
          `${user_name} is already used, please use another username.`
        );
      }
    }
  };

  return (
    <>
      {type === 'name' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              validator: validateInput,
            },
          ]}
        >
          <Input
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            allowClear={true}
            onInput={(e) => {
              const value = e.target.value;
              const inputValue = value.charAt(0).toUpperCase() + value.slice(1);
              e.target.value = inputValue;
            }}
          />
        </Form.Item>
      )}
      {type === 'email' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              validator: validateInput,
            },
            {
              validator: emailValidatorAPI
                ? handleEmailAvailabilityChecking
                : null,
            },
            { type: 'email', message: `${value} is not a valid email.` },
          ]}
        >
          <Input
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            allowClear={true}
          />
        </Form.Item>
      )}
      {type === 'password' && (
        <Form.Item
          {...props}
          label={label}
          name={name}
          hidden={hidden}
          rules={[
            { required: required, message: 'Please input your password!' },
            {
              validator: validateInput,
            },
          ]}
        >
          {withGeneratePassword ? (
            <Space.Compact block>
              <Input.Password
                placeholder={placeholder}
                onChange={onChange}
                autoFocus={autoFocus}
                disabled={disabled}
                value={value}
                allowClear={true}
              />
              <Button
                type='primary'
                disabled={disabled}
                onClick={() => {
                  const password = {
                    target: {
                      value: generateRandomPassword(),
                    },
                  };
                  onChange(password);
                }}
              >
                Generate password
              </Button>
            </Space.Compact>
          ) : (
            <Input.Password
              placeholder={placeholder}
              onChange={onChange}
              autoFocus={autoFocus}
              disabled={disabled}
              value={value}
              allowClear={true}
            />
          )}
        </Form.Item>
      )}
      {type === 'text' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              validator: validateInput,
            },
          ]}
        >
          <Input
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            allowClear={true}
          />
        </Form.Item>
      )}
      {type === 'username' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              validator: validateInput,
            },
            {
              validator: usernameValidatorAPI
                ? handleUsernameAvailabilityChecking
                : null,
            },
          ]}
        >
          <Input
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            allowClear={true}
          />
        </Form.Item>
      )}
      {type === 'number' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              pattern: /^[0-9]+(\.[0-9]+)?$/,
              message: `${label}  must contain only digits.`,
            },
            {
              validator: validateInput,
            },
          ]}
        >
          <Input
            type='number'
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            allowClear={true}
          />
        </Form.Item>
      )}
      {type === 'number-negative' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              pattern: /^-?\d*\.?\d*$/,
              message: `${label}  must contain only digits.`,
            },
            {
              validator: validateInput,
            },
          ]}
        >
          <Input
            type='number'
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            allowClear={true}
          />
        </Form.Item>
      )}
      {type === 'quantity' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              validator: validateInput,
            },
          ]}
        >
          <InputNumber
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            min={0}
            type='number'
            controls={false}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
      )}
      {type === 'price' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              validator: validateInput,
            },
          ]}
        >
          <Input
            type='number'
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            allowClear={true}
            prefix='₱'
          />
        </Form.Item>
      )}
      {type === 'textarea' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              validator: validateInput,
            },
          ]}
        >
          <TextArea
            placeholder={placeholder}
            autoSize={{
              minRows: 2,
            }}
            disabled={disabled}
            allowClear={true}
            value={value}
            onChange={onChange}
          />
        </Form.Item>
      )}
      {type === 'phone' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            { len: 9, message: `${label} must have exactly 9 digits/` },
            {
              pattern: /^[0-9]+$/,
              message: `${label}  must contain only digits.`,
            },
            {
              validator: validateInput,
            },
          ]}
        >
          <Input
            type='number'
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            allowClear={true}
            addonBefore={'+639'}
          />
        </Form.Item>
      )}
      {type === 'dropdown' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              validator: validateInput,
            },
          ]}
        >
          <Select
            placeholder={placeholder}
            allowClear={true}
            value={value}
            onChange={onChange}
            disabled={disabled}
            filterOption={false}
          >
            {dropdownOptions.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.description}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {type === 'searchable-dropdown' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              validator: validateInput,
            },
          ]}
        >
          <Select
            placeholder={placeholder}
            allowClear={true}
            value={value}
            onChange={onChange}
            disabled={disabled}
            showSearch
            filterOption={(inputValue, option) => {
              const optionDescription = option.children.toLowerCase();
              return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
            }}
          >
            {dropdownOptions.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.description}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {type === 'search' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              validator: validateInput,
            },
          ]}
        >
          <Search
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onSearch={onChange}
            allowClear={true}
          />
        </Form.Item>
      )}
      {type === 'no-form-search' && (
        <Search
          loading={disabled}
          enterButton
          size='large'
          placeholder={placeholder}
          onSearch={(value) => onChange(value)}
          onChange={(e) => onChange(e.target.value)}
          allowClear={true}
        />
      )}
      {type === 'toggle' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              validator: validateInput,
            },
          ]}
        >
          <Switch
            autoFocus={autoFocus}
            disabled={disabled}
            onChange={onChange}
            checked={value}
          />
        </Form.Item>
      )}
      {type === 'date' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              validator: validateInput,
            },
          ]}
        >
          <DatePicker
            style={{ width: '100%' }}
            value={dayjs(value, 'YYYY-MM-DD')}
            onChange={onChange}
            format='YYYY-MM-DD'
            disabled={disabled}
            allowClear={allowClear}
          />
        </Form.Item>
      )}
      {type === 'date-year' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              validator: validateInput,
            },
          ]}
        >
          <DatePicker
            style={{ width: '100%' }}
            picker='year'
            value={dayjs(value, 'YYYY')}
            onChange={onChange}
            disabled={disabled}
          />
        </Form.Item>
      )}
      {type === 'date-month' && (
        <Form.Item
          {...props}
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            {
              required: required,
              message: 'This field is required.',
            },
            {
              validator: validateInput,
            },
          ]}
        >
          <DatePicker
            style={{ width: '100%' }}
            picker='month'
            value={dayjs(value, 'M/YYYY')}
            onChange={onChange}
            disabled={disabled}
            format='M/YYYY'
          />
        </Form.Item>
      )}
      {type === 'checkbox' && (
        <Form.Item
          {...props}
          className='m-0 p-0 me-2'
          name={name}
          label={label}
          hidden={hidden}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              validator: validateInput,
            },
          ]}
        >
          <Checkbox
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            checked={value}
            onChange={onChange}
          />
        </Form.Item>
      )}
    </>
  );
}
