import { Button, DatePicker, Input, Popconfirm, Space, Switch } from 'antd';
import dayjs from 'dayjs';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';
import { UserContext } from '../../context/UserContext';

export function getGovContriTable() {
  return [
    {
      title: 'Vessel code',
      dataIndex: 'vessel_code',
      key: 'vessel_code',
      sorter: (a, b) => a.vessel_code.localeCompare(b.vessel_code),
    },
    {
      title: 'Vessel name',
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      sorter: (a, b) => a.vessel_name.localeCompare(b.vessel_name),
    },
    {
      title: 'Crew no',
      dataIndex: 'crew_no',
      key: 'crew_no',
      sorter: (a, b) => a.crew_no.localeCompare(b.crew_no),
    },
    {
      title: 'Crew name',
      dataIndex: 'crew_name',
      key: 'crew_name',
      sorter: (a, b) => a.crew_name.localeCompare(b.crew_name),
    },
    {
      title: 'Status',
      dataIndex: 'crew_status',
      key: 'crew_status',
      sorter: (a, b) => a.crew_status.localeCompare(b.crew_status),
    },
    {
      title: 'Basic pay',
      dataIndex: 'basic_pay',
      key: 'basic_pay',
    },
    {
      title: 'Basic pay (pro-rated)',
      dataIndex: 'prorate_basic',
      key: 'prorate_basic',
    },
    {
      title: 'SSS Employee',
      dataIndex: 'sss_employee',
      key: 'sss_employee',
    },
    {
      title: 'SSS Employer',
      dataIndex: 'sss_employer',
      key: 'sss_employer',
    },
    {
      title: 'EC',
      dataIndex: 'ecola',
      key: 'ecola',
    },
    {
      title: 'Prov. SSS Employee',
      dataIndex: 'prov_sss_employee',
      key: 'prov_sss_employee',
    },
    {
      title: 'Prov. SSS Employer',
      dataIndex: 'prov_sss_employer',
      key: 'prov_sss_employer',
    },
    {
      title: 'Total SSS & Prov.',
      dataIndex: 'total_sss_and_prov',
      key: 'total_sss_and_prov',
    },
    {
      title: 'PhilHealth Employee',
      dataIndex: 'ph_employee',
      key: 'ph_employee',
    },
    {
      title: 'PhilHealth Employer',
      dataIndex: 'ph_employer',
      key: 'ph_employer',
    },
    {
      title: 'Total PhilHealth',
      dataIndex: 'total_phil_health',
      key: 'total_phil_health',
    },
    {
      title: 'PagIbig Employee',
      dataIndex: 'pag_ibig_employee',
      key: 'pag_ibig_employee',
    },
    {
      title: 'PagIbig Employer',
      dataIndex: 'pag_ibig_employer',
      key: 'pag_ibig_employer',
    },
    {
      title: 'Total PagIbig',
      dataIndex: 'total_pagibig',
      key: 'total_pagibig',
    },

    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   fixed: 'right',
    //   render: (text, record) => (
    //     <Space>
    //       <Button
    //         type='primary'
    //         onClick={() => handleAction(record)}
    //         icon={<FaWrench size={15} />}
    //       />
    //     </Space>
    //   ),
    // },
  ];
}
