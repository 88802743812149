import { createContext, useEffect, useState } from 'react';
import './App.css';
import { notification } from 'antd';
import CryptoJS from 'crypto-js';
import { UserProvider } from './utils/context/UserContext';
import { HashRouter, useNavigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage/LoginPage';
import AuthRoute from './utils/router/AuthRoute';
import MainRoute from './utils/router/MainRoute';
import Cookies from 'js-cookie';

function App() {
  const [api, contextHolder] = notification.useNotification();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [isPageLoading, setIsPageLoading] = useState(false);

  const openNotification = (
    type,
    messageHeader,
    messageDescription,
    duration = 10
  ) => {
    api[type]({
      message: messageHeader,
      description: messageDescription,
      duration: duration,
    });
  };

  const encryptValue = (value) => {
    return CryptoJS.AES.encrypt(value, 'scanmarcpscryptorkey').toString();
  };

  const decryptValue = (value) => {
    return CryptoJS.AES.decrypt(value, 'scanmarcpscryptorkey').toString(
      CryptoJS.enc.Utf8
    );
  };

  const globalContext = {
    isLoggedIn,
    setIsLoggedIn,
    isPageLoading,
    setIsPageLoading,
    openNotification,
    encryptValue,
    decryptValue,
  };

  return (
    <GlobalContext.Provider value={globalContext}>
      <UserProvider>
        <HashRouter>
          {contextHolder}
          <div className='App'>
            {isLoggedIn ? <MainRoute /> : <AuthRoute />}
          </div>
        </HashRouter>
      </UserProvider>
    </GlobalContext.Provider>
  );
}

export default App;

export const GlobalContext = createContext();
