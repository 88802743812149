import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Space,
  Switch,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { FaWrench } from "react-icons/fa";

export function getCrewWagesEntryTable(
  handleAction,
  deductionOptions,
  setHasChanges
) {
  return [
    {
      title: "Salary Code",
      key: "salary_code",
      render: (text, record, index) => (
        <>
          {record.wage_type === "deduction" && (
            <Select
              style={{ width: "100%" }}
              placeholder={"Deduction"}
              value={record.deduction_id}
              onChange={(value, option) => {
                handleAction("edit", index, "deduction_id", value);
                setHasChanges(true);
              }}
              disabled={record.editable}
              filterOption={false}
            >
              {deductionOptions.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.description}
                </Select.Option>
              ))}
            </Select>
          )}
          {record.wage_type === "earning" && (
            <Input
              placeholder={"Salary code"}
              disabled={record.editable}
              value={record.salary_code}
              onChange={(e) => {
                handleAction("edit", index, "salary_code", e.target.value);
                setHasChanges(true);
              }}
            />
          )}
          {record.wage_type === "accrued" && (
            <Input
              placeholder={"Salary code"}
              disabled={record.editable}
              value={record.salary_code}
              onChange={(e) => {
                handleAction("edit", index, "salary_code", e.target.value);
                setHasChanges(true);
              }}
            />
          )}
        </>
      ),
    },
    {
      title: "Other description",
      key: "salary_code",
      render: (text, record, index) => (
        <>
          <Input
            placeholder={""}
            disabled={record.editable}
            value={record.wage_other_desc}
            onChange={(e) => {
              handleAction("edit", index, "wage_other_desc", e.target.value);
              setHasChanges(true);
            }}
          />
        </>
      ),
    },
    {
      title: "Amount",
      key: "amount",
      render: (text, record, index) => (
        <>
          <Input
            placeholder={"Amount"}
            disabled={record.editable}
            value={record.amount}
            onChange={(e) => {
              handleAction(
                "edit",
                index,
                "amount",
                e.target.value.replace(/[^0-9]/g, "")
              );
              setHasChanges(true);
            }}
          />
        </>
      ),
    },
    {
      title: "Type",
      key: "wage_type",
      render: (text, record, index) => (
        <>
          <Select
            placeholder={"Type"}
            value={record.wage_type}
            onChange={(value, option) => {
              handleAction("edit", index, "wage_type", value);
              setHasChanges(true);
            }}
            disabled={record.editable}
            filterOption={false}
          >
            <Select.Option value={"earning"}>Earnings</Select.Option>
            <Select.Option value={"deduction"}>Deductions</Select.Option>
            <Select.Option value={"accrued"}>Accrued</Select.Option>
          </Select>
        </>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      render: (text, record, index) => (
        <Popconfirm
          title="Delete record"
          description="Are you sure to remove this record?"
          onConfirm={() => {
            handleAction("delete", index);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            danger
            disabled={record.editable}
            icon={<AiFillDelete size={15} />}
          />
        </Popconfirm>
      ),
    },
  ];
}
