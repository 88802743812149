import { Button, DatePicker, Input, Popconfirm, Space, Switch } from 'antd';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { AiFillDelete, AiFillEdit, AiFillPrinter } from 'react-icons/ai';
import { FaEye, FaWrench } from 'react-icons/fa';
import { UserContext } from '../../context/UserContext';

export function getFinalPayBreakdownTable(
  handleAction,
  selectedFinalPay,
  setSelectedFinalPay,
  setHasChanges,
  exchangeRate
) {
  return [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      sorter: (a, b) => a.desc.localeCompare(b.desc),
    },
    {
      title: 'USD Amount',
      dataIndex: 'usd_amt',
      key: 'usd_amt',
      sorter: (a, b) => a.usd_amt - b.usd_amt,
    },
    {
      title: 'Days',
      key: 'days',
      render: (text, record) => (
        <Input
          key={record.details_id}
          type='number'
          placeholder={'Days'}
          autoFocus={true}
          disabled={false}
          allowClear={false}
          value={record.days}
          onChange={(e) => {
            const value = e.target.value;
            const proratedValue =
              (parseFloat(record.usd_amt.replace(/,/g, '')) *
                parseFloat(value)) /
              30;
            const newList = structuredClone(selectedFinalPay?.details);
            const updatedState = newList.map((sal) => {
              if (sal.code == record.code) {
                setHasChanges(true);
                return {
                  ...sal,
                  days: value,
                  computed: proratedValue,
                  php_amt: proratedValue * exchangeRate,
                };
              } else {
                return sal;
              }
            });
            setSelectedFinalPay({ ...selectedFinalPay, details: updatedState });
          }}
        />
      ),
    },
    {
      title: 'Computed',
      dataIndex: 'computed',
      key: 'computed',
      sorter: (a, b) => a.computed - b.computed,
    },
    {
      title: 'PHP Amount',
      dataIndex: 'php_amt',
      key: 'php_amt',
      sorter: (a, b) => a.php_amt - b.position_name,
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Popconfirm
            title='Delete record'
            description='Are you sure to delete this record?'
            onConfirm={() => handleAction(record)}
            okText='Yes'
            cancelText='No'
          >
            <Button danger icon={<AiFillDelete size={15} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
}
