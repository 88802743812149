import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import {
  getAllUserSystem,
  getAllowedPrincipals,
  postAddUser,
  postCheckEmailAvailability,
  postCheckUsernameAvailability,
  postDeactivateUser,
  postDeleteUser,
  postReactivateUser,
  postUnbanUser,
  postUpdateAllowedPrincipal,
} from '../../utils/api/UsersAPI';
import { GlobalContext } from '../../App';
import { UserContext } from '../../utils/context/UserContext';
import {
  ERROR_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../utils/api/_constants';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import Search from 'antd/es/input/Search';
import { getUserSetupTable } from '../../utils/tableheader/Transactions/UserSetupSystemTable';
import { IoIosAddCircle, IoIosRefresh } from 'react-icons/io';
import UserSetupType from '../../utils/types/UserSetupType';
import FormInput from '../../components/FormInput/FormInput';
import { getUserSetupFields } from '../../utils/inputfields/UserSetupFields';
import FormModal from '../../components/CustomModal/FormModal';
import ConfirmationModal from '../../components/CustomModal/ConfirmationModal';
import { getAllAccessLevels } from '../../utils/api/AccessLevelAPI';
import { getAllPrincipals } from '../../utils/api/ProcessGovContriAPI';
import { getVesselGroups } from '../../utils/api/VesselAPI';

export default function UserSetupSettingsPage() {
  const TAG = 'User Setup';

  const { getCurrentDate, doSearchInArray, parseToFloat, inputToLocale } =
    useContext(UserContext);
  const { openNotification } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [searchQuery, setSearchQuery] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const [isViewingOnly, setIsViewingOnly] = useState(false);

  const [usersList, setUsersList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const [accessLevelOptions, setAccessLevelOptions] = useState([]);

  const [selectedUser, setSelectedUser] = useState(UserSetupType);
  const [originalUser, setOriginalUser] = useState(UserSetupType);

  const [vesselGroupList, setVesselGroupList] = useState([]);
  const [principalToUpdate, setPrincipalToUpdate] = useState([]);
  const [checkedPrincipals, setCheckedPrincipals] = useState([]);

  //MODALS - FORMS
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);

  //MODALS - DEACTIVATE
  const [isDeactivateConfirmationOpen, setIsDeactivateConfirmationOpen] =
    useState(false);

  const getRowClassName = (record, index) => {
    return record.is_active == 0 ? 'bg-warning' : '';
  };

  const handleAction = async (record = UserSetupType, action) => {
    setPrincipalToUpdate([]);
    setSelectedUser(record);
    setOriginalUser(record);
    if (action == 'view' || action == 'edit') {
      setIsLoading(true);
      const { data: pListRes, pListError } = await getAllowedPrincipals(
        record.user_id
      );
      if (pListError) {
        openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Something went wrong!');
      } else {
        setCheckedPrincipals(
          pListRes
            .filter((item) => item.is_allowed == 1)
            .map((item) => item.principal_code)
        );
      }
      setIsLoading(false);
    }
    switch (action) {
      case 'view':
        setIsViewingOnly(true);
        form.setFieldsValue(record);
        setIsFormModalOpen(true);
        break;
      case 'edit':
        setIsViewingOnly(false);
        form.setFieldsValue(record);
        setIsFormModalOpen(true);
        break;
      case 'action':
        setIsDeactivateConfirmationOpen(true);
        break;
      case 'unban':
        setIsLoading(true);
        const { data: res, error } = await postUnbanUser({
          user_name: record.user_name,
        });
        if (error) {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            TAG,
            'Something went wrong!'
          );
        } else {
          openNotification(SUCCESS_NOTIFICATION_TYPE, TAG, 'User unbanned.');
          fetchDatas();
        }
        setIsLoading(false);
        break;
      case 'delete':
        setIsLoading(true);
        const { data: res2, error2 } = await postDeleteUser(record.user_id);
        if (error2) {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            TAG,
            'Something went wrong!'
          );
        } else {
          openNotification(SUCCESS_NOTIFICATION_TYPE, TAG, 'User deleted.');
          fetchDatas();
        }
        break;
      default:
        break;
    }
  };

  const fetchDatas = async () => {
    setIsLoading(true);
    await Promise.all([
      getAllUserSystem(),
      getAllAccessLevels(),
      getVesselGroups(),
    ])
      .then((response) => {
        if (response[0].error) {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            TAG,
            'Something went wrong.'
          );
        } else {
          const resWithKey = response[0].data.map((rec, ind) => ({
            ...rec,
            access_level: parseInt(rec.access_level),
            key: ind,
          }));
          setUsersList(resWithKey);
          setFilteredList(resWithKey);
        }

        if (response[1].error) {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            TAG,
            'Something went wrong.'
          );
        } else {
          setAccessLevelOptions(response[1].data);
        }

        if (response[2].error) {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            TAG,
            'Something went wrong.'
          );
        } else {
          setVesselGroupList(
            response[2].data.sort((a, b) => {
              if (a.group_code < b.group_code) {
                return -1;
              }
              if (a.group_code > b.group_code) {
                return 1;
              }
              return 0;
            })
          );
        }
      })
      .catch((error) => {
        openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Error loading data.');
      });

    setIsLoading(false);
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <Content>
      <Row className='mb-4 mt-2' justify={'space-between'}>
        <Col xs={24} sm={16}>
          <Button
            type='primary'
            icon={<IoIosAddCircle size={15} />}
            onClick={() => {
              setPrincipalToUpdate([]);
              setSelectedUser(UserSetupType);
              form.setFieldsValue(UserSetupType);
              setIsFormModalOpen(true);
            }}
          >
            Add User
          </Button>
        </Col>
        <Col xs={24} sm={8} className='text-end'>
          <Space.Compact block>
            <Search
              disabled={isLoading || usersList.length === 0}
              placeholder='Search users!'
              onChange={(e) => {
                setSearchQuery(e.target.value);
                doSearchInArray(e.target.value, setFilteredList, usersList);
              }}
              onSearch={(value) => {
                setSearchQuery(value);
                doSearchInArray(value, setFilteredList, usersList);
              }}
              allowClear={true}
            />
            <Button
              type='primary'
              icon={<IoIosRefresh />}
              loading={isLoading}
              onClick={() => fetchDatas()}
            />
          </Space.Compact>
        </Col>
      </Row>

      <Table
        columns={getUserSetupTable(handleAction)}
        dataSource={filteredList}
        size={'small'}
        bordered={false}
        scroll={{ x: true }}
        loading={isLoading}
        rowClassName={getRowClassName}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <FormModal
        title={'Users Entry'}
        isOpen={isFormModalOpen}
        setIsOpen={setIsFormModalOpen}
        isLoading={isLoading}
        props={{ width: '70vw' }}
        form={form}
        formName={'users_form'}
        initialState={selectedUser}
        onSave={async () => {
          if (hasChanges) {
            try {
              await form.validateFields();
              setIsConfirmationModalOpen(true);
            } catch {}
          } else {
            openNotification(INFO_NOTIFICATION_TYPE, TAG, 'No changes made.');
            setIsFormModalOpen(false);
          }
        }}
        onCancel={() => {
          if (hasChanges) {
            setIsUnsavedModalOpen(true);
          } else {
            form.resetFields();
            setIsFormModalOpen(false);
            setHasChanges(false);
          }
        }}
      >
        <Row gutter={16}>
          {getUserSetupFields(
            isLoading,
            selectedUser,
            setSelectedUser,
            setHasChanges,
            accessLevelOptions,
            postCheckEmailAvailability,
            postCheckUsernameAvailability,
            isViewingOnly,
            originalUser
          ).map((data, index) => (
            <Col key={index} xs={24} sm={12}>
              <FormInput
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                hidden={data.hidden}
                withGeneratePassword={data.withGeneratePassword}
                emailValidatorAPI={data.emailValidatorAPI}
                usernameValidatorAPI={data.usernameValidatorAPI}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            </Col>
          ))}
        </Row>
        <Row className='mt-3'>
          <Typography.Text strong className='me-2'>
            Principals:
            <Checkbox
              className='ms-2'
              onChange={(e) => {
                setHasChanges(true);
                const allGroupCode = vesselGroupList.map(
                  (item) => item.group_code
                );
                let allGroupPayload = [];
                if (e.target.checked) {
                  allGroupPayload = vesselGroupList.map((g) => ({
                    user_id: selectedUser.user_id,
                    principal_code: g.group_code,
                    principal_name: g.group_code,
                    p_group_code: g.group_code,
                    is_allowed: 1,
                  }));
                  setCheckedPrincipals(allGroupCode);
                } else {
                  allGroupPayload = vesselGroupList.map((g) => ({
                    user_id: selectedUser.user_id,
                    principal_code: g.group_code,
                    principal_name: g.group_code,
                    p_group_code: g.group_code,
                    is_allowed: 0,
                  }));
                  setCheckedPrincipals([]);
                }
                setPrincipalToUpdate(allGroupPayload);
              }}
            >
              Select all
            </Checkbox>
          </Typography.Text>
          <Checkbox.Group
            disabled={isLoading || isViewingOnly}
            value={checkedPrincipals}
          >
            <Row gutter={24}>
              {vesselGroupList.map((g, ind) => (
                <Col xs={24} sm={6} key={ind} className='mb-1'>
                  <Checkbox
                    value={g.group_code}
                    onChange={(e) => {
                      setHasChanges(true);
                      if (e.target.checked) {
                        setCheckedPrincipals([
                          ...checkedPrincipals,
                          g.group_code,
                        ]);
                      } else {
                        const newList = checkedPrincipals.filter(
                          (code) => code != g.group_code
                        );
                        setCheckedPrincipals(newList);
                      }

                      const payload = {
                        user_id: selectedUser.user_id,
                        principal_code: g.group_code,
                        principal_name: g.group_code,
                        p_group_code: g.group_code,
                        is_allowed: e.target.checked ? 1 : 0,
                      };
                      setPrincipalToUpdate([...principalToUpdate, payload]);
                    }}
                  >
                    {g.desc}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Row>
      </FormModal>
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          const { data: res, error } = await postAddUser(selectedUser);
          if (error) {
            setIsLoading(false);
            setIsConfirmationModalOpen(false);
            openNotification(
              ERROR_NOTIFICATION_TYPE,
              TAG,
              'Something went wrong!'
            );
          } else {
            if (res.isSuccessful) {
              const { data: res, error } = await postUpdateAllowedPrincipal({
                payload: principalToUpdate,
              });
              if (error) {
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  TAG,
                  'Saving principal failed.'
                );
              }
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                TAG,
                'User added successfully.'
              );
              setIsLoading(false);
              setHasChanges(false);
              setIsConfirmationModalOpen(false);
              setIsFormModalOpen(false);
              fetchDatas();
            } else {
              setIsLoading(false);
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                TAG,
                'Something went wrong!'
              );
            }
          }
        }}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isDeactivateConfirmationOpen}
        setIsOpen={setIsDeactivateConfirmationOpen}
        onConfirm={async () => {
          setIsLoading(true);
          if (selectedUser.is_active == 1) {
            const { data: res, error } = await postDeactivateUser(selectedUser);
            if (error) {
              setIsLoading(false);
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                TAG,
                'Something went wrong!'
              );
            } else {
              if (res.isSuccessful) {
                openNotification(
                  SUCCESS_NOTIFICATION_TYPE,
                  TAG,
                  'User deactivated successfully.'
                );
                setIsLoading(false);
                setIsDeactivateConfirmationOpen(false);
                fetchDatas();
              } else {
                setIsLoading(false);
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  TAG,
                  'Something went wrong!'
                );
              }
            }
          } else {
            const { data: res, error } = await postReactivateUser(selectedUser);
            if (error) {
              setIsLoading(false);
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                TAG,
                'Something went wrong!'
              );
            } else {
              if (res.isSuccessful) {
                openNotification(
                  SUCCESS_NOTIFICATION_TYPE,
                  TAG,
                  'User reactivated successfully.'
                );
                setIsLoading(false);
                setIsDeactivateConfirmationOpen(false);
                fetchDatas();
              } else {
                setIsLoading(false);
                openNotification(
                  ERROR_NOTIFICATION_TYPE,
                  TAG,
                  'Something went wrong!'
                );
              }
            }
          }
        }}
      />
      <ConfirmationModal
        title='You have unsaved changes! Confirming will lose all your changes. Confirm?'
        cancelText='Continue editing'
        isLoading={isLoading}
        isOpen={isUnsavedModalOpen}
        setIsOpen={setIsUnsavedModalOpen}
        onConfirm={() => {
          form.resetFields();
          setIsUnsavedModalOpen(false);
          setIsFormModalOpen(false);
          setHasChanges(false);
        }}
      />
    </Content>
  );
}
