import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../App';
import { UserContext } from '../../utils/context/UserContext';
import { getVessels } from '../../utils/api/VesselAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  INFO_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  WARNING_NOTIFICATION_TYPE,
} from '../../utils/api/_constants';
import { Content } from 'antd/es/layout/layout';
import {
  Button,
  Col,
  Form,
  Row,
  Space,
  Table,
  Transfer,
  Typography,
} from 'antd';
import Search from 'antd/es/input/Search';
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
  IoIosRefresh,
} from 'react-icons/io';
import {
  getAllCrewWages,
  getAllWages,
  getCrewWagesPerVessel,
  postAddCrewWagePerVessel,
} from '../../utils/api/CrewWagesAPI';
import CustomModal from '../../components/CustomModal/CustomModal';
import VesselType from '../../utils/types/VesselType';
import dayjs from 'dayjs';
import ConfirmationModal from '../../components/CustomModal/ConfirmationModal';
import FormModal from '../../components/CustomModal/FormModal';
import CrewWagesType from '../../utils/types/CrewWagesType';
import { getCrewWagesMaintenanceFields } from '../../utils/inputfields/WagesMaintenanceFields';
import FormInput from '../../components/FormInput/FormInput';
import { getCrewWagesMaintenanceTable } from '../../utils/tableheader/Maintenance/CrewWagesMaintenanceTable';

export default function CrewWagesMaintenancePage() {
  const TAG = 'Crew Wages Maintenance';

  const [form] = Form.useForm();

  const { openNotification } = useContext(GlobalContext);
  const { doSearchInArray, profile } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [vesselsList, setVesselsList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const [vesselCrewWagesList, setVesselCrewWagesList] = useState([]);

  const [selectedVessel, setSelectedVessel] = useState(VesselType);
  const [selectedCrewWage, setSelectedCrewWage] = useState(CrewWagesType);

  const [wagesList, setWagesList] = useState([]);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const [perVesselCrewWagesList, setPerVesselCrewWagesList] = useState([]);

  const [selectedToMove, setSelectedToMove] = useState(null);

  const [hasChanges, setHasChanges] = useState(false);

  const [isCopyToModalOpen, setIsCopyToModalOpen] = useState(false);
  const [copyToTargetKeys, setCopyToTargetKeys] = useState([]);
  const [copyToSelectedKeys, setCopyToSelectedKeys] = useState([]);

  const [isEditingAdded, setIsEditingAdded] = useState(false);

  const getRowClassName = (record, index) => {
    const exists = vesselCrewWagesList.some(
      (item) => item.vessel_code === record.vessel_code
    );
    return exists ? '' : 'bg-warning';
  };

  const tableContents =
    filteredList.length > 0
      ? filteredList.map((data, index) => ({ ...data, key: index }))
      : vesselsList.map((data, index) => ({
          ...data,
          key: index,
        }));

  const handleAction = async (vessel) => {
    setIsLoading(true);
    const ves = vesselsList.find(
      (item) => item.vessel_code === vessel.vessel_code
    );

    setSelectedVessel(ves);

    const crewWages = vesselCrewWagesList.find(
      (item) => item.vessel_code === ves.vessel_code
    );

    console.log(crewWages);
    if (crewWages?.wages) {
      setPerVesselCrewWagesList(crewWages?.wages);
      setTargetKeys(crewWages?.wages?.map((item) => item.salary_code));
    } else {
      setPerVesselCrewWagesList([]);
      setTargetKeys([]);
    }

    setIsModalOpen(true);
    setIsLoading(false);
  };

  const handleCopyTo = async (record) => {
    const ves = vesselsList.find(
      (item) => item.vessel_code === record.vessel_code
    );

    setSelectedVessel(ves);
    setIsCopyToModalOpen(true);
  };

  const fetchDatas = async () => {
    setIsLoading(true);
    await Promise.all([
      getVessels(profile.user_id),
      getAllWages(),
      getCrewWagesPerVessel(),
    ])
      .then((response) => {
        if (response[0].error) {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            TAG,
            'Error loading vessels.'
          );
        } else {
          setVesselsList(response[0].data);
        }
        if (response[1].error) {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            TAG,
            'Error loading wages.'
          );
        } else {
          const wages = response[1].data.result.map((item, index) => ({
            key: item.code,
            title: item.code,
            description: item.description,
          }));
          setWagesList(wages);
        }
        if (response[2].error) {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            TAG,
            'Fetching Crew Wages Failed.'
          );
        } else {
          setVesselCrewWagesList(response[2].data);
        }
      })
      .catch((error) => {
        openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Error loading data.');
      });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <Content>
      <Typography.Title level={3}>Crew Wages Maintenance</Typography.Title>
      <Row className='mb-4' justify={'end'}>
        <Col xs={24} sm={8}>
          <Search
            placeholder='Search vessel!'
            loading={isLoading}
            disabled={isLoading}
            onChange={(e) => {
              doSearchInArray(e.target.value, setFilteredList, vesselsList);
            }}
            onSearch={(value) =>
              doSearchInArray(value, setFilteredList, vesselsList)
            }
            allowClear={true}
          />
        </Col>
        <Col xs={24} sm={1} className='text-end'>
          <Button
            type='primary'
            icon={<IoIosRefresh size={18} />}
            disabled={isLoading}
            onClick={() => fetchDatas()}
          />
        </Col>
      </Row>
      <Table
        columns={getCrewWagesMaintenanceTable(
          handleAction,
          handleCopyTo,
          vesselCrewWagesList
        )}
        dataSource={tableContents}
        size='small'
        bordered='true'
        scroll={{ x: true }}
        loading={isLoading}
        rowClassName={getRowClassName}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <CustomModal
        title={`${selectedVessel.vessel_name} - ${selectedVessel.vessel_code}`}
        isLoading={isLoading}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        props={{ width: 'auto' }}
        onOkDisabled={isLoading || !hasChanges}
        onOk={() => {
          setIsConfirmationModalOpen(true);
        }}
        onCancel={() => {
          if (hasChanges) {
            setIsUnsavedModalOpen(true);
          } else {
            setTargetKeys([]);
            setSelectedKeys([]);
            setIsModalOpen(false);
          }
        }}
      >
        <Row align={'middle'}>
          <Col xs={22}>
            <Transfer
              showSearch
              titles={['Salary', 'Selected Salary']}
              dataSource={wagesList}
              targetKeys={targetKeys}
              selectedKeys={selectedKeys}
              pagination={{
                pageSize: 30,
              }}
              listStyle={{
                width: 350,
              }}
              filterOption={(inputValue, option) => {
                const optionDescription = option.key.toLowerCase();
                return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
              }}
              render={(item) => {
                const isMatched = perVesselCrewWagesList.find(
                  (wage) => wage.salary_code == item.title
                );
                if (isMatched) {
                  let typeDesc = '';
                  if (isMatched.wage_type == 'earnings') {
                    typeDesc = 'Earnings';
                  }
                  if (isMatched.wage_type == 'leave') {
                    typeDesc = 'Accrued';
                  }
                  return `${item.title} - ${isMatched.wage_other_desc} ${
                    typeDesc == '' ? '' : ` - ${typeDesc}`
                  }`;
                }
                return `${item.title} - ${item.description}`;
              }}
              onChange={(nextTargetKeys, direction, moveKeys) => {
                if (direction === 'left') {
                  const newArray = perVesselCrewWagesList.filter(
                    (item) => !moveKeys.includes(item.salary_code)
                  );
                  setPerVesselCrewWagesList(newArray);
                }
                setHasChanges(true);
                setTargetKeys(nextTargetKeys);
              }}
              onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
                if (sourceSelectedKeys.length > 0) {
                  const wageSelected = wagesList.find(
                    (item) => item.key === sourceSelectedKeys[0]
                  );
                  const wage = {
                    salary_code: wageSelected.key,
                    wage_desc: wageSelected.description,
                    wage_type: 'earnings',
                    wage_other_desc: wageSelected.description,
                    is_travel_days_paid: 0,
                  };
                  setIsEditingAdded(false);
                  setSelectedCrewWage(wage);
                  form.setFieldsValue(wage);
                  setIsFormModalOpen(true);
                }
                if (
                  targetSelectedKeys.length == 1 &&
                  selectedKeys.length == 0
                ) {
                  const wageSelected = perVesselCrewWagesList.find(
                    (item) => item.salary_code === targetSelectedKeys[0]
                  );
                  setIsEditingAdded(true);
                  setSelectedCrewWage(wageSelected);
                  form.setFieldsValue(wageSelected);
                  setIsFormModalOpen(true);
                }
                if (targetSelectedKeys.length === 1) {
                  setSelectedToMove(targetSelectedKeys[0]);
                } else {
                  setSelectedToMove(null);
                }

                setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
              }}
            />
          </Col>
          <Col xs={2} className='text-end'>
            <Space>
              <Space.Compact direction='vertical'>
                <Button
                  type='primary'
                  icon={<IoIosArrowDropupCircle size={23} />}
                  disabled={isLoading || selectedToMove == null}
                  onClick={() => {
                    const currentIndex = perVesselCrewWagesList.findIndex(
                      (item) => item.salary_code === selectedToMove
                    );
                    if (currentIndex === 0) {
                      openNotification(
                        WARNING_NOTIFICATION_TYPE,
                        TAG,
                        'Position is already on top'
                      );
                    } else {
                      setHasChanges(true);
                      const itemsCopy = [...perVesselCrewWagesList];
                      const [movedItem] = itemsCopy.splice(currentIndex, 1);
                      itemsCopy.splice(currentIndex - 1, 0, movedItem);
                      setPerVesselCrewWagesList(itemsCopy);

                      const selectedSalaries = itemsCopy.map(
                        (item) => item.salary_code
                      );
                      setTargetKeys(selectedSalaries);
                    }
                  }}
                ></Button>
                <Button
                  type='primary'
                  disabled={isLoading || selectedToMove == null}
                  icon={<IoIosArrowDropdownCircle size={23} />}
                  style={{ marginTop: 16 }}
                  onClick={() => {
                    const currentIndex = perVesselCrewWagesList.findIndex(
                      (item) => item.salary_code === selectedToMove
                    );
                    if (currentIndex === targetKeys.length - 1) {
                      openNotification(
                        WARNING_NOTIFICATION_TYPE,
                        TAG,
                        'Position is already on bottom'
                      );
                    } else {
                      setHasChanges(true);
                      const itemsCopy = [...perVesselCrewWagesList];
                      const [movedItem] = itemsCopy.splice(currentIndex, 1);
                      itemsCopy.splice(currentIndex + 1, 0, movedItem);
                      setPerVesselCrewWagesList(itemsCopy);
                      const selectedSalaries = itemsCopy.map(
                        (item) => item.salary_code
                      );
                      setTargetKeys(selectedSalaries);
                    }
                  }}
                ></Button>
              </Space.Compact>
            </Space>
          </Col>
        </Row>
      </CustomModal>
      <CustomModal
        title={`Copy config of ${selectedVessel.vessel_name} - ${selectedVessel.vessel_code}`}
        isLoading={isLoading}
        isOpen={isCopyToModalOpen}
        setIsOpen={setIsCopyToModalOpen}
        props={{ width: 'auto' }}
        onOkDisabled={isLoading}
        onOk={async () => {
          setIsLoading(true);
          const crewWages = vesselCrewWagesList.find(
            (item) => item.vessel_code === selectedVessel.vessel_code
          );
          if (crewWages?.wages) {
            try {
              await Promise.all(
                copyToTargetKeys.map(async (record) => {
                  const payload = crewWages?.wages.map((wage) => ({
                    ...wage,
                    vessel_code: record,
                  }));
                  const { data: res, error } = await postAddCrewWagePerVessel(
                    payload
                  );

                  if (error) {
                    openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'ERROR');
                  } else {
                    openNotification(SUCCESS_NOTIFICATION_TYPE, TAG, 'SUCCESS');
                    setCopyToTargetKeys([]);
                    setCopyToSelectedKeys([]);
                    setIsCopyToModalOpen(false);
                    fetchDatas();
                  }
                })
              );
            } catch (error) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                TAG,
                'Copying config failed.'
              );
            }
          }
          setIsLoading(false);
        }}
        onCancel={() => {
          setCopyToTargetKeys([]);
          setCopyToSelectedKeys([]);
          setIsCopyToModalOpen(false);
        }}
      >
        <Transfer
          showSearch
          titles={['Vessel', 'Selected Vessel']}
          dataSource={vesselsList.map((vessel, index) => ({
            key: vessel.vessel_code,
            title: vessel.vessel_name,
            description: vessel.vessel_name,
          }))}
          targetKeys={copyToTargetKeys}
          filterOption={(inputValue, option) => {
            const optionDescription = option.title.toLowerCase();
            return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
          }}
          selectedKeys={copyToSelectedKeys}
          render={(item) => item.title}
          pagination={{
            pageSize: 30,
          }}
          listStyle={{
            width: 350,
          }}
          onChange={(nextTargetKeys, direction, moveKeys) => {
            setCopyToTargetKeys(nextTargetKeys);
          }}
          onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
            setCopyToSelectedKeys([
              ...sourceSelectedKeys,
              ...targetSelectedKeys,
            ]);
          }}
        />
      </CustomModal>
      <FormModal
        title={'Crew Wages Entry'}
        isOpen={isFormModalOpen}
        saveText='Add'
        setIsOpen={setIsFormModalOpen}
        isLoading={isLoading}
        props={{ width: '50vw' }}
        form={form}
        formName={'crew_wage_form'}
        initialState={selectedCrewWage}
        onSave={async () => {
          try {
            await form.validateFields();
            const targetIndex = targetKeys.findIndex(
              (item) => item == selectedCrewWage.salary_code
            );

            if (targetIndex !== -1) {
              const updatedTarget = [...targetKeys];
              updatedTarget[targetIndex] = selectedCrewWage.salary_code;
              setTargetKeys(updatedTarget);
            } else {
              setTargetKeys([...targetKeys, selectedCrewWage.salary_code]);
            }

            const index = perVesselCrewWagesList.findIndex(
              (item) => item.salary_code == selectedCrewWage.salary_code
            );
            if (index !== -1) {
              const updatedList = [...perVesselCrewWagesList];
              updatedList[index] = selectedCrewWage;
              setPerVesselCrewWagesList(updatedList);
            } else {
              setPerVesselCrewWagesList([
                ...perVesselCrewWagesList,
                selectedCrewWage,
              ]);
            }
            setIsFormModalOpen(false);
            setHasChanges(true);
            form.resetFields();
          } catch {}
        }}
        onCancel={() => {
          if (!isEditingAdded) {
            setSelectedKeys([]);
          }
          setIsFormModalOpen(false);
          form.resetFields();
        }}
      >
        <Row gutter={16}>
          {getCrewWagesMaintenanceFields(
            isLoading,
            selectedCrewWage,
            setSelectedCrewWage,
            setHasChanges
          ).map((data, index) => (
            <Col key={index} xs={24} sm={12}>
              <FormInput
                type={data.type}
                name={data.name}
                placeholder={data.placeholder}
                label={data.label}
                autoFocus={data.autofocus}
                value={data.value}
                onChange={data.setValue}
                required={data.required}
                disabled={data.disabled}
                dropdownOptions={data.dropdownOptions}
                props={data.props}
              />
            </Col>
          ))}
        </Row>
      </FormModal>
      <ConfirmationModal
        title='You have unsaved changes! Confirming will lose all your changes. Confirm?'
        cancelText='Continue editing'
        isLoading={isLoading}
        isOpen={isUnsavedModalOpen}
        setIsOpen={setIsUnsavedModalOpen}
        onConfirm={() => {
          form.resetFields();
          setTargetKeys([]);
          setSelectedKeys([]);
          setHasChanges(false);
          setIsUnsavedModalOpen(false);
          setIsModalOpen(false);
        }}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          const payload = perVesselCrewWagesList.map((item) => ({
            ...item,
            vessel_code: selectedVessel.vessel_code,
          }));
          console.log(payload);
          const { data: res, error } = await postAddCrewWagePerVessel(payload);

          if (error) {
            openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'ERROR');
          } else {
            openNotification(SUCCESS_NOTIFICATION_TYPE, TAG, 'SUCCESS');
            setTargetKeys([]);
            setSelectedKeys([]);
            setHasChanges(false);
            setIsConfirmationModalOpen(false);
            setIsModalOpen(false);
            fetchDatas();
          }
          setIsLoading(false);
        }}
      />
    </Content>
  );
}
