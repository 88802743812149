import { Button, Flex, Input, Popconfirm, Switch, Table, Tag } from 'antd';
import React from 'react';
import { AiFillDelete, AiFillEdit, AiFillPrinter } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';
import {
  ERROR_NOTIFICATION_TYPE,
  WARNING_NOTIFICATION_TYPE,
} from '../../api/_constants';
import dayjs from 'dayjs';

export function getPostAllotmentTable(
  selectedToPost,
  setSelectedToPost,
  vesselList
) {
  return [
    {
      title: 'Vessel Code',
      dataIndex: 'vessel_code',
      key: 'vessel_code',
    },
    {
      title: 'Vessel Name',
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      sorter: (a, b) => a.vessel_name.localeCompare(b.vessel_name),
    },
    {
      title: 'Principal Name',
      dataIndex: 'principal_name',
      key: 'principal_name',
      sorter: (a, b) => a.principal_name.localeCompare(b.principal_name),
    },
    // {
    //   title: 'Last Update',
    //   dataIndex: 'updated_at',
    //   key: 'updated_at',
    //   defaultSortOrder: 'descend',
    //   sorter: (a, b) => a.process_id - b.process_id,
    // },
    {
      title: 'Posted transactions',
      render: (text, record) => (
        <Flex wrap>
          {record?.is_crew_wages_posted == 1 && (
            <Tag color='#f50'>Crew wages</Tag>
          )}

          {record?.is_home_allotment_posted == 1 && (
            <Tag color='#2db7f5'>Home Allotment</Tag>
          )}
          {record?.is_slb_posted == 1 && <Tag color='#87d068'>Side Letter</Tag>}
          {record?.is_mpo_posted == 1 && <Tag color='#108ee9'>MPO</Tag>}
        </Flex>
      ),
    },
    {
      title: 'Select to post',
      key: 'select_to_post',
      fixed: 'right',
      sorter: (a, b) => {
        const indexA = selectedToPost.indexOf(a);
        const indexB = selectedToPost.indexOf(b);

        // Handle cases where a or b are not found in array2
        if (indexA === undefined) return 1;
        if (indexB === undefined) return -1;

        return indexA - indexB;
      },
      render: (text, record) => (
        <Switch
          checked={selectedToPost.find(
            (item) => item.vessel_code === record.vessel_code
          )}
          onChange={(checked) => {
            if (checked) {
              const recordToAdd = vesselList.find(
                (rec) => rec.vessel_code === record.vessel_code
              );
              const isExisting = selectedToPost.find(
                (rec) => rec.vessel_code === record.vessel_code
              );
              if (recordToAdd && !isExisting) {
                setSelectedToPost([...selectedToPost, recordToAdd]);
              }
            } else {
              const recordToRemove = selectedToPost.find(
                (rec) => rec.vessel_code === record.vessel_code
              );
              if (recordToRemove) {
                const updatedList = selectedToPost.filter(
                  (item) => item.vessel_code !== record.vessel_code
                );
                setSelectedToPost(updatedList);
              }
            }
          }}
        />
      ),
    },
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   fixed: 'right',
    //   render: (text, record) => (
    //     <Button
    //       type="primary"
    //       disabled={record.updated_at === '' || !record.updated_at}
    //       onClick={() => handleAction(record)}
    //       icon={<AiFillPrinter size={15} />}
    //     >
    //       Print
    //     </Button>
    //   ),
    // },
  ];
}
