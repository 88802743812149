import { Button, Flex, Input, Popconfirm, Space, Switch, Tag } from 'antd';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';

export function getTransactionDeductionsTable(handleAction) {
  return [
    {
      title: 'Crew no',
      dataIndex: 'crew_no',
      key: 'crew_no',
      sorter: (a, b) => a.crew_no.localeCompare(b.crew_no),
      fixed: 'left',
    },
    {
      title: 'Crew name',
      dataIndex: 'crew_name',
      key: 'crew_name',
      sorter: (a, b) => a.crew_name.localeCompare(b.crew_name),
      fixed: 'left',
    },
    {
      title: 'Ded. date',
      dataIndex: 'startdeduct',
      key: 'startdeduct',
    },
    {
      title: 'Trans. date',
      dataIndex: 'startdeduct',
      key: 'startdeduct',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Type',
      render: (text, record) => (
        <Flex wrap>
          {record?.od_desc?.map((ded, index) => (
            <Tag color='#87d068' key={index}>
              {ded}
            </Tag>
          ))}
        </Flex>
      ),
    },
    {
      title: 'Amount to deduct',
      dataIndex: 'amounttoded',
      key: 'amounttoded',
    },
    {
      title: 'Running balance',
      dataIndex: 'running_balance',
      key: 'running_balance',
    },
    {
      title: 'Stop payment',
      key: 'stoppayment',
      render: (text, record) => (
        <Switch
          size='small'
          disabled
          checked={record.stoppayment == 1 ? true : false}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            type='primary'
            onClick={() => handleAction(record, 'edit')}
            icon={<FaWrench size={15} />}
          />
          <Popconfirm
            title='Delete record'
            description='Are you sure to delete this record?'
            onConfirm={() => handleAction(record, 'delete')}
            okText='Yes'
            cancelText='No'
          >
            <Button danger icon={<AiFillDelete size={15} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
}
