import { Button, Input, Popconfirm, Switch, Table } from 'antd';
import React from 'react';
import { AiFillDelete, AiFillEdit, AiFillPrinter } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';
import {
  ERROR_NOTIFICATION_TYPE,
  WARNING_NOTIFICATION_TYPE,
} from '../../api/_constants';
import dayjs from 'dayjs';

export function getHomeAllotmentTable(
  handleAction,
  selectedToCompute,
  setSelectedToCompute,
  selectedToDelete,
  setSelectedToDelete,
  vesselList,
  setVesselList,
  setFilteredList,
  openNotification,
  postedVessels,
  fieldToCheckForPosted
) {
  const isVesselPosted = (rec) => {
    return (
      postedVessels.find((pVes) => pVes.vescode == rec.vessel_code)[
        fieldToCheckForPosted
      ] == 1
    );
  };

  return [
    {
      title: 'Vessel Name',
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      sorter: (a, b) => a.vessel_name.localeCompare(b.vessel_name),
      fixed: 'left',
    },
    {
      title: 'Principal Name',
      dataIndex: 'principal_name',
      key: 'principal_name',
      sorter: (a, b) => a.principal_name.localeCompare(b.principal_name),
    },
    {
      title: 'Vessel Ex-Rate',
      key: 'exrate',
      sorter: (a, b) => a.exrate - b.exrate,
      render: (text, record) => (
        <Input
          type='number'
          placeholder={'Exchange Rate'}
          disabled={isVesselPosted(record)}
          value={record.exrate}
          onChange={(e) => {
            const value = parseFloat(e.target.value);
            setSelectedToCompute((prevItems) =>
              prevItems.map((item) =>
                item.vessel_code === record.vessel_code
                  ? { ...item, exrate: value }
                  : item
              )
            );

            setFilteredList((prevItems) =>
              prevItems.map((item) =>
                item.vessel_code === record.vessel_code
                  ? { ...item, exrate: value }
                  : item
              )
            );
            setVesselList((prevItems) =>
              prevItems.map((item) =>
                item.vessel_code === record.vessel_code
                  ? { ...item, exrate: value }
                  : item
              )
            );
          }}
          allowClear={true}
        />
      ),
    },
    {
      title: 'Vessel Type of Union Dues',
      dataIndex: 'union_dues',
      key: 'union_dues',
      sorter: (a, b) => a.union_dues.localeCompare(b.union_dues),
    },
    {
      title: 'Last Update',
      dataIndex: 'updated_at',
      key: 'updated_at',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.process_id - b.process_id,
    },
    {
      title: 'Select to compute',
      key: 'select_to_compute',
      fixed: 'right',
      sorter: (a, b) => {
        const indexA = selectedToCompute.indexOf(a);
        const indexB = selectedToCompute.indexOf(b);

        // Handle cases where a or b are not found in array2
        if (indexA === undefined) return 1;
        if (indexB === undefined) return -1;

        return indexA - indexB;
      },
      render: (text, record) => (
        <Switch
          checked={selectedToCompute.find(
            (item) => item.vessel_code === record.vessel_code
          )}
          disabled={isVesselPosted(record)}
          onChange={(checked) => {
            if (checked) {
              const recordToAdd = vesselList.find(
                (rec) => rec.vessel_code === record.vessel_code
              );
              const isExisting = selectedToCompute.find(
                (rec) => rec.vessel_code === record.vessel_code
              );
              if (recordToAdd && !isExisting) {
                setSelectedToCompute([...selectedToCompute, recordToAdd]);
              }
            } else {
              const recordToRemove = selectedToCompute.find(
                (rec) => rec.vessel_code === record.vessel_code
              );
              if (recordToRemove) {
                const updatedList = selectedToCompute.filter(
                  (item) => item.vessel_code !== record.vessel_code
                );
                setSelectedToCompute(updatedList);
              }
            }
          }}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Button
          type='primary'
          disabled={record.updated_at === '' || !record.updated_at}
          onClick={() => handleAction(record)}
          icon={<AiFillPrinter size={15} />}
        >
          Print
        </Button>
      ),
    },
    {
      title: 'Select to delete',
      key: 'select_to_delete',
      sorter: (a, b) => {
        const indexA = selectedToDelete.indexOf(a);
        const indexB = selectedToDelete.indexOf(b);

        // Handle cases where a or b are not found in array2
        if (indexA === undefined) return 1;
        if (indexB === undefined) return -1;

        return indexA - indexB;
      },
      render: (text, record) => (
        <Switch
          checked={selectedToDelete.find(
            (item) => item.vessel_code === record.vessel_code
          )}
          disabled={isVesselPosted(record)}
          onChange={(checked) => {
            if (checked) {
              const recordToAdd = vesselList.find(
                (rec) => rec.vessel_code === record.vessel_code
              );
              const isExisting = selectedToDelete.find(
                (rec) => rec.vessel_code === record.vessel_code
              );
              if (recordToAdd && !isExisting) {
                setSelectedToDelete([...selectedToDelete, recordToAdd]);
              }
            } else {
              const recordToRemove = selectedToDelete.find(
                (rec) => rec.vessel_code === record.vessel_code
              );
              if (recordToRemove) {
                const updatedList = selectedToDelete.filter(
                  (item) => item.vessel_code !== record.vessel_code
                );
                setSelectedToDelete(updatedList);
              }
            }
          }}
        />
      ),
    },
  ];
}
