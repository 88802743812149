export default {
  id: null,
  crew_no: null,
  embark_date: null,
  vessel_sign_on_date: null,
  vessel_sign_off_date: null,
  is_disembark: null,
  planning_id: null,
  last_name: null,
  first_name: null,
  middle_name: null,
  position_id: null,
  vessel_id: null,
  crew_cut_off: null,
  grp: null,
  position_name: null,
  vessel_name: null,
  crew_status: null,
  salary_details: [
    {
      id: null,
      date: null,
      transaction_type: null,
      wage_code: null,
      position_name: null,
      vessel_name: null,
      salary: [
        {
          salary_code: null,
          amount: null,
        },
        {
          salary_code: null,
          amount: null,
        },
        {
          salary_code: null,
          amount: null,
        },
        {
          salary_code: null,
          amount: null,
        },
        {
          salary_code: null,
          amount: null,
        },
        {
          salary_code: null,
          amount: null,
        },
      ],
    },
  ],
  allotment: {
    id: null,
    crew_timeline_details_id: null,
    salary_for_allotment: null,
    allotment_type: null,
    allotment_amount: null,
    allotment_percentage: null,
    total_adjustment: null,
    modified_by: null,
    modified_date: null,
    adjustment_date_from: null,
    adjustment_date_to: null,
    crew_no: null,
    allottee: [
      {
        crew_allottee_id: null,
        last_name: null,
        first_name: null,
        middle_name: null,
        acct_no: null,
        acct_type: null,
        is_dollar_account: null,
        bankcode: null,
        bankdesc: null,
        bankbranch: null,
        regular_allotment: {
          is_checked: null,
          percentage: null,
          usd_amount: null,
          peso_amount: null,
          applied_deduction: null,
          adjustment_amount: null,
        },
        side_letter: {
          is_checked: null,
          percentage: null,
          usd_amount: null,
          peso_amount: null,
          applied_deduction: null,
          adjustment_amount: null,
        },
        mpo: {
          is_checked: null,
          percentage: null,
          usd_amount: null,
          peso_amount: null,
          applied_deduction: null,
          adjustment_amount: null,
        },
      },
    ],
  },
};
