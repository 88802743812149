import { Button, Input, Popconfirm, Space, Switch } from 'antd';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';

export function getScheduleOfDeductionTable(onRecordChange) {
  return [
    {
      title: 'Code',
      dataIndex: 'deduction_code',
      key: 'deduction_code',
      fixed: 'left',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Account Id',
      dataIndex: 'allt_on_board_crew_id',
      key: 'allt_on_board_crew_id',
    },
    {
      title: 'Account no.',
      dataIndex: 'account_no',
      key: 'account_no',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Middle Name',
      dataIndex: 'middle_name',
      key: 'middle_name',
    },
    {
      title: 'Regular Allotment',
      key: 'is_regular_allotment',
      render: (text, record) => (
        <Switch
          size="small"
          disabled={true}
          checked={record.is_regular_allotment == 0 ? false : true}
          onChange={(checked) => {
            const modifiedRecord = {
              ...record,
              is_regular_allotment: checked ? 1 : 0,
            };
            onRecordChange(modifiedRecord);
          }}
        />
      ),
    },
    {
      title: 'Side Letter Bonus',
      key: 'is_side_letter_bonus',
      render: (text, record) => (
        <Switch
          size="small"
          disabled={true}
          checked={record.is_side_letter_bonus == 0 ? false : true}
          onChange={(checked) => {
            const modifiedRecord = {
              ...record,
              is_side_letter_bonus: checked ? 1 : 0,
            };
            onRecordChange(modifiedRecord);
          }}
        />
      ),
    },
    {
      title: 'MPO',
      key: 'is_mpo',
      render: (text, record) => (
        <Switch
          size="small"
          disabled={true}
          checked={record.is_mpo == 0 ? false : true}
          onChange={(checked) => {
            const modifiedRecord = {
              ...record,
              is_mpo: checked ? 1 : 0,
            };
            onRecordChange(modifiedRecord);
          }}
        />
      ),
    },
    {
      title: 'Final Pay',
      key: 'final_pay',
      render: (text, record) => <Switch size="small" disabled={true} />,
    },
    {
      title: 'Exclude Prev Month',
      key: 'exclude_prev_month',
      render: (text, record) => <Switch size="small" disabled={true} />,
    },
  ];
}
