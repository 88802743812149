import { Button, Input, Popconfirm, Space, Switch } from 'antd';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';

export function getHomeAllotmentTable(handleAction) {
  return [
    {
      title: 'Name',
      dataIndex: 'allottee_name',
      fixed: 'left',
      render: (text, record) => {
        return `${record.last_name}, ${record.first_name} ${record.middle_name}`;
      },
    },
    {
      title: 'Dollar Acct.',
      key: 'is_dollar_account',
      render: (text, record) => (
        <Switch
          size="small"
          disabled={true}
          defaultChecked={record.is_dollar_account === 0 ? false : true}
        />
      ),
    },
    {
      title: 'Account Number',
      dataIndex: 'acct_no',
      key: 'acct_no',
      sorter: (a, b) => a.acct_no.localeCompare(b.acct_no),
    },
    {
      title: 'Percentage',
      dataIndex: 'regular_allotment_percentage',
      key: 'regular_allotment_percentage',
    },
    {
      title: 'USD Amount',
      dataIndex: 'regular_allotment_usd_amount',
      key: 'regular_allotment_usd_amount',
    },
    {
      title: 'Fixed Peso',
      dataIndex: 'regular_allotment_peso_amount',
      key: 'regular_allotment_peso_amount',
    },
    {
      title: 'Rem. salary',
      key: 'regular_allotment_applied_deduction',
      render: (text, record) => (
        <Switch
          size="small"
          disabled
          checked={
            record.regular_allotment.applied_deduction == 1 ? true : false
          }
        />
      ),
    },
    {
      title: 'For the month adj.',
      dataIndex: 'regular_allotment_adjustment_amount',
      key: 'regular_allotment_adjustment_amount',
    },
    {
      title: 'Reg. Allotment',
      key: 'regular_allotment_is_checked',
      render: (text, record) => (
        <Switch
          size="small"
          disabled
          checked={record.regular_allotment.is_checked === 1 ? true : false}
        />
      ),
    },
    {
      title: 'Bank Code',
      dataIndex: 'bankcode',
      key: 'bankcode',
    },
    {
      title: 'Bank Name',
      dataIndex: 'bankdesc',
      key: 'bankdesc',
    },
    {
      title: 'Branch',
      dataIndex: 'bankbranch',
      key: 'bankbranch',
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => handleAction(record, 'home_allotment')}
            icon={<FaWrench size={15} />}
          />
          <Popconfirm
            placement="left"
            title="Delete record"
            description="Are you sure to delete this record?"
            onConfirm={() => handleAction(record, 'delete')}
            okText="Yes"
            cancelText="No"
          >
            <Button danger icon={<AiFillDelete size={15} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
}
