import axios from 'axios';
import {
  GET_ALL_EXCHANGE_RATE_ENDPOINT,
  GET_EXCHANGE_RATE_PER_ID_ENDPOINT,
  POST_ADD_EXCHANGE_RATE_ENDPOINT,
  POST_DELETE_EXCHANGE_RATE_ENDPOINT,
  POST_MODIFY_EXCHANGE_RATE_ENDPOINT,
  REQUEST_HEADERS,
} from './_constants';

export async function getAllExchangeRates() {
  try {
    const { data } = await axios.get(GET_ALL_EXCHANGE_RATE_ENDPOINT);
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function getExchangeRatePerId(id) {
  try {
    const { data } = await axios.get(
      `${GET_EXCHANGE_RATE_PER_ID_ENDPOINT}/${id}`
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postAddExchangeRate(payload) {
  try {
    const { data } = await axios.post(
      POST_ADD_EXCHANGE_RATE_ENDPOINT,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postModifyExchangeRate(payload) {
  try {
    const { data } = await axios.post(
      `${POST_MODIFY_EXCHANGE_RATE_ENDPOINT}/${payload.id}`,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function postDeleteExchangeRate(payload) {
  try {
    const { data } = await axios.post(
      `${POST_DELETE_EXCHANGE_RATE_ENDPOINT}/${payload.id}`,
      payload,
      { headers: REQUEST_HEADERS }
    );
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
