import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../utils/context/UserContext';
import { GlobalContext } from '../../../App';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { getCrewByVessel } from '../../../utils/api/TransactionsAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  WARNING_NOTIFICATION_TYPE,
} from '../../../utils/api/_constants';
import CrewType from '../../../utils/types/CrewType';
import {
  getPayslipMpoPerCrew,
  getPayslipPerCrew,
  getPayslipRemainingPerCrew,
  getPayslipSideLetterPerCrew,
} from '../../../utils/api/PayslipAPI';
import CustomModal from '../../../components/CustomModal/CustomModal';
import PayslipPerCrewPDF from '../../../components/PDFReports/PayslipPerCrewPDF';

export default function ReportPayslipPerCrewPage() {
  const TAG = 'Payslip per Crew';
  const { getCurrentDate, doSearchInArray, parseToFloat, inputToLocale } =
    useContext(UserContext);

  const { openNotification } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);

  const [month, setMonth] = useState(new Date(getCurrentDate()).getMonth() + 1);
  const [year, setYear] = useState(new Date(getCurrentDate()).getFullYear());
  const [toMonth, setToMonth] = useState(
    new Date(getCurrentDate()).getMonth() + 1
  );
  const [toYear, setToYear] = useState(
    new Date(getCurrentDate()).getFullYear()
  );

  const [crewList, setCrewList] = useState([]);

  const [selectedCrew, setSelectedCrew] = useState(CrewType);

  const [homeAllotmentPayslip, sethomeAllotmentPayslip] = useState([]);
  const [mpoPayslips, setMpoPayslips] = useState([]);
  const [slbPayslip, setSlbPayslip] = useState([]);
  const [remWagesPayslip, setRemWagesPayslip] = useState([]);

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const toggleOptions = [
    {
      label: 'Home Allotment',
      value: 'home_allotment',
    },
    {
      label: 'Side Letter',
      value: 'side_letter',
    },
    {
      label: 'Special Allotment',
      value: 'special_allotment',
    },
    {
      label: 'Remaining Wages',
      value: 'remaining_wages',
    },
  ];

  const fetchDatas = async () => {
    setIsLoading(true);
    const { data: res, error } = await getCrewByVessel({
      month: month,
      year: year,
    });

    if (error) {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        TAG,
        'Fetching crew list failed.'
      );
    } else {
      setCrewList(res);
    }
    setIsLoading(false);
  };

  const fetchCrewPayslip = async () => {
    setIsLoading(true);
    let shouldShow = false;
    if (selectedOptions.includes('home_allotment')) {
      const { data: homeAllotmentRes, error } = await getPayslipPerCrew(
        selectedCrew.crew_no,
        month,
        year,
        toMonth,
        toYear,
        selectedCrew.vessel_id
      );
      if (error) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          TAG,
          'Fetching regular allotment payslip failed'
        );
      } else {
        if (homeAllotmentRes.length > 0) {
          sethomeAllotmentPayslip(homeAllotmentRes);
          shouldShow = true;
        } else {
          openNotification(
            WARNING_NOTIFICATION_TYPE,
            TAG,
            'No records for regular allotment.'
          );
        }
      }
    }
    if (selectedOptions.includes('side_letter')) {
      const { data: sideLetterRes, error } = await getPayslipSideLetterPerCrew(
        selectedCrew.crew_no,
        month,
        year,
        toMonth,
        toYear,
        selectedCrew.vessel_id
      );
      if (error) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          TAG,
          'Fetching side letter payslip failed'
        );
      } else {
        if (sideLetterRes.length > 0) {
          setSlbPayslip(sideLetterRes);
          shouldShow = true;
        } else {
          openNotification(
            WARNING_NOTIFICATION_TYPE,
            TAG,
            'No records for side letter.'
          );
        }
      }
    }
    if (selectedOptions.includes('special_allotment')) {
      const { data: mpoRes, error } = await getPayslipMpoPerCrew(
        selectedCrew.crew_no,
        month,
        year,
        toMonth,
        toYear,
        selectedCrew.vessel_id
      );
      if (error) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          TAG,
          'Fetching special allotment payslip failed'
        );
      } else {
        if (mpoRes.length > 0) {
          setMpoPayslips(mpoRes);
          shouldShow = true;
        } else {
          openNotification(
            WARNING_NOTIFICATION_TYPE,
            TAG,
            'No records for special allotment.'
          );
        }
      }
    }
    if (selectedOptions.includes('remaining_wages')) {
      const { data: remWagesRes, error } = await getPayslipRemainingPerCrew(
        selectedCrew.crew_no,
        month,
        year,
        toMonth,
        toYear,
        selectedCrew.vessel_id
      );
      if (error) {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          TAG,
          'Fetching remaining wages payslip failed'
        );
      } else {
        if (remWagesRes.length > 0) {
          setRemWagesPayslip(remWagesRes);
          shouldShow = true;
        } else {
          openNotification(
            WARNING_NOTIFICATION_TYPE,
            TAG,
            'No records for remaining wages.'
          );
        }
      }
    }
    if (shouldShow) {
      setIsPrintModalOpen(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <Content>
      <Row>
        <Col xs={24} sm={6} className='me-2'>
          <Typography.Text strong className='me-2'>
            Month/Year:
          </Typography.Text>
          <DatePicker.RangePicker
            allowClear={false}
            style={{ width: '70%' }}
            picker='month'
            defaultValue={[
              dayjs(`${month}/${year}`, 'M/YYYY'),
              dayjs(`${toMonth}/${toYear}`, 'M/YYYY'),
            ]}
            onChange={(dates, dateString) => {
              const [fromMonth, fromYear] = dateString[0].split('/');
              const [dateToMonth, dateToYear] = dateString[1].split('/');
              setMonth(fromMonth);
              setYear(fromYear);
              setToMonth(dateToMonth);
              setToYear(dateToYear);
            }}
            format='M/YYYY'
            disabled={isLoading}
          />
        </Col>
        <Col xs={24} sm={8} className='text-start'>
          <Typography.Text strong className='me-2'>
            Crew:
          </Typography.Text>
          <Select
            style={{ width: '80%' }}
            placeholder={'Search crew!'}
            value={selectedCrew.crew_no}
            onChange={(value, option) => {
              const crew = crewList.find((item) => item.crew_no === value);
              setSelectedCrew(crew);
            }}
            disabled={isLoading}
            showSearch
            filterOption={(inputValue, option) => {
              const optionDescription = option.children.toLowerCase();
              return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
            }}
          >
            {crewList.map((option) => (
              <Select.Option key={option.crew_no} value={option.crew_no}>
                {`${option.last_name}, ${option.first_name} - ${option.crew_no}`}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={8} className='text-start'>
          <Button
            type='primary'
            disabled={isLoading || selectedCrew.crew_no === null}
            onClick={() => fetchCrewPayslip()}
          >
            Process
          </Button>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Typography.Text strong className='me-2'>
          Options:
        </Typography.Text>
        <Checkbox.Group
          disabled={isLoading}
          options={toggleOptions}
          onChange={(checkedValues) => {
            setSelectedOptions(checkedValues);
          }}
        />
      </Row>
      <CustomModal
        isLoading={isLoading}
        isOpen={isPrintModalOpen}
        setIsOpen={setIsPrintModalOpen}
        cancelText='Close'
        props={{ okButtonProps: { hidden: true }, width: '100vw' }}
        isClosable={true}
      >
        <PayslipPerCrewPDF
          homeAllotmentRecords={homeAllotmentPayslip}
          mpoRecords={mpoPayslips}
          slbRecords={slbPayslip}
          remWagesRecords={remWagesPayslip}
        />
      </CustomModal>
    </Content>
  );
}
