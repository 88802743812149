import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import Search from 'antd/es/input/Search';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { UserContext } from '../../../../utils/context/UserContext';
import { getPostAllotmentTable } from '../../../../utils/tableheader/Transactions/PostAllotmentTable.js';
import { getVessels } from '../../../../utils/api/VesselAPI.js';
import { GlobalContext } from '../../../../App.js';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../../../utils/api/_constants.js';
import ConfirmationModal from '../../../../components/CustomModal/ConfirmationModal.js';
import {
  getAllPostedVessel,
  postCrewWagesCloseProcessAllotment,
  postHomeAllotmentCloseProcessAllotment,
  postMPOCloseProcessAllotment,
  postSLBCloseProcessAllotment,
} from '../../../../utils/api/TransactionsAPI.js';

export default function CloseAllotmentPage() {
  const TAG = 'Post Allotment';
  const { openNotification } = useContext(GlobalContext);
  const { doSearchInArray, profile } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [vesselsList, setVesselsList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const [postedVessels, setPostedVessels] = useState([]);

  const [month, setMonth] = useState(dayjs().month() + 1);
  const [year, setYear] = useState(dayjs().year());

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [selectedToPost, setSelectedToPost] = useState([]);

  // MODALS
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const getRowClassName = (record, index) => {
    const exists = postedVessels.some(
      (item) => item.vescode == record.vessel_code
    );
    return exists ? 'bg-warning' : '';
  };

  const tableContents =
    filteredList.length > 0
      ? filteredList.map((data, index) => ({ ...data, key: index }))
      : vesselsList.map((data, index) => ({
          ...data,
          key: index,
        }));

  const fetchDatas = async (m, y) => {
    setIsLoading(true);
    await Promise.all([getVessels(profile.user_id), getAllPostedVessel(m, y)])
      .then((response) => {
        const parsedVesselList = response[0].data.map((record) => {
          const postVessel = response[1].data.find(
            (rec) => rec.vescode == record.vessel_code
          );
          if (postVessel) {
            return {
              ...record,
              is_crew_wages_posted: postVessel.crew_wages,
              is_home_allotment_posted: postVessel.home_allotment,
              is_slb_posted: postVessel.slb_allotment,
              is_mpo_posted: postVessel.mpo_allotment,
            };
          } else {
            return record;
          }
        });
        setVesselsList(parsedVesselList);
        setFilteredList(parsedVesselList);
        setPostedVessels(response[1].data);
      })
      .catch((error) => {
        openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Error loading data.');
      });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDatas(month, year);
  }, []);

  return (
    <Content>
      <Space className='me-2'>
        <Typography.Text strong>For month of:</Typography.Text>
        <DatePicker
          picker='month'
          defaultValue={dayjs(`${month}/${year}`, 'M/YYYY')}
          onChange={async (_, dateString) => {
            const [dateMonth, dateYear] = dateString.split('/');
            setMonth(dateMonth);
            setYear(dateYear);
            fetchDatas(dateMonth, dateYear);
          }}
          format='M/YYYY'
          disabled={isLoading}
          allowClear={false}
        />
        <Button
          type='primary'
          disabled={
            isLoading ||
            selectedToPost.length <= 0 ||
            selectedOptions.length <= 0
          }
          onClick={() => setIsConfirmationModalOpen(true)}
        >
          Post
        </Button>
      </Space>

      <Row className='my-2' align={'middle'}>
        <Col xs={24} sm={16}>
          <Typography.Text strong className='me-2'>
            Options:
          </Typography.Text>
          <Checkbox.Group
            disabled={isLoading}
            options={[
              {
                label: 'Home Allotment',
                value: 'home_allotment',
              },
              {
                label: 'Side Letter',
                value: 'side_letter',
              },
              {
                label: 'MPO',
                value: 'mpo',
              },
              {
                label: 'Crew Wages',
                value: 'crew_wages',
              },
            ]}
            onChange={(checkedValues) => {
              setSelectedOptions(checkedValues);
            }}
          />
        </Col>
        <Col xs={24} sm={8}>
          <Search
            placeholder='Search vessel!'
            loading={isLoading}
            disabled={isLoading}
            onChange={(e) => {
              doSearchInArray(e.target.value, setFilteredList, vesselsList);
            }}
            onSearch={(value) =>
              doSearchInArray(value, setFilteredList, vesselsList)
            }
            allowClear={true}
          />
        </Col>
      </Row>
      <Table
        columns={getPostAllotmentTable(
          selectedToPost,
          setSelectedToPost,
          vesselsList
        )}
        dataSource={tableContents}
        size='small'
        bordered='true'
        scroll={{ x: true }}
        loading={isLoading}
        // rowClassName={getRowClassName}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={async () => {
          setIsLoading(true);
          const failedArray = [];
          const successfulProcess = [];
          try {
            await Promise.all(
              selectedToPost.map(async (record) => {
                const payload = {
                  vessel_code: record.vessel_code,
                  month: month,
                  year: year,
                };

                if (selectedOptions.includes('home_allotment')) {
                  const { data: res, error } =
                    await postHomeAllotmentCloseProcessAllotment(payload);
                  if (error) {
                    openNotification(
                      ERROR_NOTIFICATION_TYPE,
                      TAG,
                      `Posting ${record.vessel_name} home allotment failed.`
                    );
                    const existingRecord = failedArray.find(
                      (item) => item.vessel_code === record.vessel_code
                    );
                    if (!existingRecord) {
                      failedArray.push(record);
                    }
                  } else {
                    openNotification(
                      SUCCESS_NOTIFICATION_TYPE,
                      TAG,
                      `Posting ${record.vessel_name} home allotment complete.`
                    );
                    successfulProcess.push(record);
                  }
                }

                if (selectedOptions.includes('side_letter')) {
                  const { data: res, error } =
                    await postSLBCloseProcessAllotment(payload);
                  if (error) {
                    openNotification(
                      ERROR_NOTIFICATION_TYPE,
                      TAG,
                      `Posting ${record.vessel_name} side letter failed.`
                    );
                    const existingRecord = failedArray.find(
                      (item) => item.vessel_code === record.vessel_code
                    );
                    if (!existingRecord) {
                      failedArray.push(record);
                    }
                  } else {
                    openNotification(
                      SUCCESS_NOTIFICATION_TYPE,
                      TAG,
                      `Posting ${record.vessel_name} side letter complete.`
                    );
                    successfulProcess.push(record);
                  }
                }

                if (selectedOptions.includes('mpo')) {
                  const { data: res, error } =
                    await postMPOCloseProcessAllotment(payload);
                  if (error) {
                    openNotification(
                      ERROR_NOTIFICATION_TYPE,
                      TAG,
                      `Posting ${record.vessel_name} mpo failed.`
                    );
                    const existingRecord = failedArray.find(
                      (item) => item.vessel_code === record.vessel_code
                    );
                    if (!existingRecord) {
                      failedArray.push(record);
                    }
                  } else {
                    openNotification(
                      SUCCESS_NOTIFICATION_TYPE,
                      TAG,
                      `Posting ${record.vessel_name} mpo complete.`
                    );
                    successfulProcess.push(record);
                  }
                }

                if (selectedOptions.includes('crew_wages')) {
                  const { data: res, error } =
                    await postCrewWagesCloseProcessAllotment(payload);
                  if (error) {
                    openNotification(
                      ERROR_NOTIFICATION_TYPE,
                      TAG,
                      `Posting ${record.vessel_name} crew wages failed.`
                    );
                    const existingRecord = failedArray.find(
                      (item) => item.vessel_code === record.vessel_code
                    );
                    if (!existingRecord) {
                      failedArray.push(record);
                    }
                  } else {
                    openNotification(
                      SUCCESS_NOTIFICATION_TYPE,
                      TAG,
                      `Posting ${record.vessel_name} crew wages complete.`
                    );
                    successfulProcess.push(record);
                  }
                }

                if (failedArray.length == 0) {
                  fetchDatas(month, year);
                  setIsConfirmationModalOpen(false);
                }
              })
            );
          } catch (error) {
            openNotification(ERROR_NOTIFICATION_TYPE, TAG, 'Posting failed');
          }
          setSelectedToPost([]);
          setIsLoading(false);
        }}
      />
    </Content>
  );
}
