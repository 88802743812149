export default {
  id: null,
  vessel_id: null,
  divisor: null,
  no_of_days: null,
  allotment_cut_off: null,
  cut_off_pro_rate: null,
  prorate_basic_salary: null,
  regular_allotment: null,
  round_pro_rate_allotment: null,
  round_percentage_allotment: null,
  batch_no: null,
  is_pagibig_member: null,
  is_hdmf_shoulder_by_company: null,
  pro_rate_computations_gov_deduc: null,
  is_amosup_member: null,
  union_dues_type_id: null,
  amosup_type: null,
  effectivity_date: null,
  amosup_provident: null,
  amosup_maap: null,
  amosup_training: null,
  amosup_survivorship_pension: null,
  guaranteed_ot: null,
  remittance_bank_id: null,
  is_deleted: null,
  delete_by: null,
  created_at: null,
  updated_at: null,
};
