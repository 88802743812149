export default {
  id: null,
  code: null,
  desc: null,
  peso_ratings: null,
  peso_officer: null,
  fmdcp_ratings: null,
  fmdcp_officer: null,
  ptgwo_ratings: null,
  ptgwo_officer: null,
  tdaf_ratings: null,
  tdaf_officer: null,
  ud_ratings: null,
  ud_officer: null,
  fmdp_ratings: null,
  fmdp_officer: null,
  wmbp_ratings: null,
  wmbp_officer: null,
  maap_ratings: null,
  maap_officer: null,
  training_levy_ratings: null,
  training_levy_officer: null,
  survivorship_ratings: null,
  survivorship_officer: null,
  account_code: null,
  sl_code: null,
  expense_acct: null,
  rating1: null,
  rating2: null,
  officer1: null,
  officer2: null,
  onboard_officer: null,
  onboard_ratings: null,
  percentage: null,
  entrance_fee: null,
  fixed: null,
  fixed_ratings: null,
  fixed_officer: null,
  fixed_usd: null,
  is_deleted: null,
  delete_by: null,
  created_at: null,
  updated_at: null,
};
