import { Button, DatePicker, Input, Popconfirm, Space, Switch } from 'antd';
import dayjs from 'dayjs';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';
import { UserContext } from '../../context/UserContext';

export function getRemainingWagesComputeTable(handleAction) {
  return [
    {
      title: 'Crew no.',
      dataIndex: 'crew_no',
      key: 'crew_no',
      sorter: (a, b) => a.crew_no.localeCompare(b.crew_no),
      fixed: 'left',
    },
    {
      title: 'Crew name',
      dataIndex: 'crew_name',
      key: 'crew_name',
      fixed: 'left',
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      sorter: (a, b) => a.position.localeCompare(b.position),
    },
    {
      title: 'Amount (US$)',
      dataIndex: 'amount_usd',
      key: 'amount_usd',
    },
    {
      title: 'Amount (PHP)',
      dataIndex: 'amount_php',
      key: 'amount_php',
    },
    {
      title: 'Deductions',
      dataIndex: 'deductions',
      key: 'deductions',
    },
    {
      title: 'Net pay',
      dataIndex: 'net_pay',
      key: 'net_pay',
    },
    {
      title: 'Allottee',
      dataIndex: 'allottee',
      key: 'allottee',
    },
    {
      title: 'Account no.',
      dataIndex: 'account_no',
      key: 'account_no',
    },
    // {
    //   title: 'Bank',
    //   dataIndex: 'bank_code',
    //   key: 'bank_code',
    // },
    // {
    //   title: 'Branch',
    //   dataIndex: 'bank_branch',
    //   key: 'bank_branch',
    // },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => handleAction(record)}
            icon={<FaWrench size={15} />}
          />
        </Space>
      ),
    },
  ];
}
