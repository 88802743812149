import dayjs from 'dayjs';
import PhilhealthContributionType from '../types/PhilhealthContributionType';

export const getPhihealthContributionFields = (
  isLoading,
  state = PhilhealthContributionType,
  setState,
  setHasChanges
) => {
  return [
    {
      type: 'number',
      name: 'sal_range_from',
      placeholder: '',
      label: 'Salary range from',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: state.sal_range_from,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, sal_range_from: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'sal_range_to',
      placeholder: '',
      label: 'Salary range to',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.sal_range_to,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, sal_range_to: value });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'employee_share',
      placeholder: '',
      label: 'Employee share',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.employee_share,
      setValue: (e) => {
        const value = e.target.value;
        const total =
          parseFloat(value ? value : 0) +
          parseFloat(state.employer_share ? state.employer_share : 0);
        setState({ ...state, employee_share: value, total_contri: total });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'employer_share',
      placeholder: '',
      label: 'Employer share',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.employer_share,
      setValue: (e) => {
        const value = e.target.value;
        const total =
          parseFloat(value ? value : 0) +
          parseFloat(state.employee_share ? state.employee_share : 0);
        setState({ ...state, employer_share: value, total_contri: total });
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'sal_bracket',
      placeholder: '',
      label: 'Percentage',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.sal_bracket,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, sal_bracket: value });
        setHasChanges(true);
      },
    },
    {
      type: 'date-year',
      name: 'year',
      placeholder: '',
      label: 'Year',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.year,
      setValue: (_, dateString) => {
        if (dateString) {
          const date = dayjs(dateString, 'YYYY');
          setState({
            ...state,
            year: date,
          });
        } else {
          setState({
            ...state,
            year: null,
          });
        }
        setHasChanges(true);
      },
    },
    {
      type: 'number',
      name: 'total_contri',
      placeholder: '',
      label: 'Total contribution',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.total_contri,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, total_contri: value });
        setHasChanges(true);
      },
    },
    {
      type: 'toggle',
      name: 'is_percentage',
      placeholder: '',
      label: 'By percentage',
      required: false,
      disabled: isLoading,
      value: state.is_percentage === 1 ? true : false,
      setValue: (checked) => {
        setState({
          ...state,
          is_percentage: checked ? 1 : 0,
        });
        setHasChanges(true);
      },
      props: {
        labelCol: { span: 22 },
        wrapperCol: { span: 2 },
        className: 'text-start',
      },
    },
  ];
};
