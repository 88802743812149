import axios from "axios";
import {
  API_CALL_ERROR,
  REQUEST_HEADERS,
  USER_LOGIN_ENDPOINT,
} from "./_constants";

export async function login(username, password) {
  try {
    const payload = {
      user_name: username,
      password: password,
    };

    const { data } = await axios.post(USER_LOGIN_ENDPOINT, payload, {
      headers: REQUEST_HEADERS,
    });
    return data;
  } catch (error) {
    console.error(error.response.data.error);
    return {
      isSuccessful: false,
      data: API_CALL_ERROR,
      statusCode: error.response.status,
      error: error.response.data.error,
    };
  }
}
