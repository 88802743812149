import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import React, { useContext } from 'react';
import logoImage from '../../assets/icon-name.png';
import skanFilImage from '../../assets/skanfil-icon.jpg';
import dayjs from 'dayjs';
import { UserContext } from '../../utils/context/UserContext';

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 10,
    paddingBottom: 40,
  },
  container: {
    flex: 1,
    alignItems: 'center',
  },
  headerImage: {
    height: 60,
    width: 60,
  },
  headerText: {
    fontSize: 12,
  },
  detailsText: {
    fontSize: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
  },
  tableRow: {
    flexDirection: 'row',
    borderTopWidth: 0,
    paddingBottom: 0,
  },
  tableCell: {
    width: '6%',
    borderWidth: 0,
    paddingTop: 5,
    paddingBottom: 0,
    paddingHorizontal: 1,
    fontSize: '6px',
    marginBottom: 0,
  },

  pageNumber: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 10,
    fontSize: 6,
    textAlign: 'left',
    paddingHorizontal: 10,
  },
  legends: {
    //   position: 'absolute',
    //   bottom: 30,
    //   left: 80,
    //   right: 0,
    fontSize: 8,
    textAlign: 'left',
  },

  bottomContainer: { flexDirection: 'row', margin: 0, padding: 0 },
  column: {
    flex: 1,
    margin: 10,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    // borderTopWidth: 1,
    // textAlign: 'center',
    // backgroundColor: 'lightgray',
    paddingTop: 10,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
});

export default function FinalPayReportPDF({ date, payslipDetails }) {
  const { inputToLocale } = useContext(UserContext);

  const parseValue = (value) => {
    if (parseFloat(value) != 0) {
      return inputToLocale(value);
    } else {
      return '';
    }
  };

  const getCalculatedFromFields = (firstField, secondField) => {
    return payslipDetails[firstField]?.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue[secondField]),
      0
    );
  };

  return (
    <PDFViewer style={{ width: '98%', height: '100vh' }}>
      <Document>
        <Page size='LETTER' wrap style={{ margin: 5 }}>
          <View style={{ ...styles.bottomContainer, marginBottom: -15 }} fixed>
            <View
              style={{
                ...styles.column,
                paddingTop: 28,
              }}
            >
              <Text style={{ ...styles.headerText, fontWeight: 'bold' }}>
                FP-{dayjs().year()}-{payslipDetails?.id}
              </Text>
            </View>
            <View
              style={{
                ...styles.column,
                alignItems: 'center',
                paddingVertical: 0,
                marginRight: 0,
                marginTop: 4,
              }}
            >
              <Image
                src={
                  payslipDetails?.logo == 'scanmar' ? logoImage : skanFilImage
                }
                style={styles.headerImage}
              />
            </View>
            <View
              style={{
                ...styles.column,
                paddingTop: 28,
                alignItems: 'flex-end',
                marginRight: 40,
              }}
            >
              <Text style={{ ...styles.headerText, fontWeight: 'bold' }}>
                {`Rundate: ${dayjs().format('DDMMMYYYY').toUpperCase()}`}
              </Text>
              <Text
                style={{
                  ...styles.headerText,
                  marginTop: '2',
                  fontWeight: 'bold',
                }}
              >
                {`US $ Rate: ${parseValue(payslipDetails.exrate)}`}
              </Text>
            </View>
          </View>
          {/* <View style={{ marginLeft: 3, marginTop: 10 }}>
            <Text
              style={{ fontSize: 12 }}
            >{`${payslipDetails.crew_name} <${payslipDetails.crew_no}>`}</Text>
            <Text
              style={{ fontSize: 8 }}
            >{`US $ Rate: ${payslipDetails.exrate}`}</Text>
            <Text style={{ fontSize: 8 }}>{`FP-2-2023`}</Text>
          </View> */}

          <View
            style={{
              marginTop: 20,
              marginHorizontal: 5,
            }}
          >
            <Text
              style={{ ...styles.detailsText, maxWidth: '94vw' }}
            >{`Final Payment of Renumerations of ${payslipDetails?.position_name}, ${payslipDetails?.crew_name} (${payslipDetails.crew_no}) of ${payslipDetails.vesname} under his employment contract for the period ${payslipDetails?.sign_on} to ${payslipDetails.sign_off}.`}</Text>
          </View>
          <View
            style={{
              marginVertical: 5,
              height: 1,
              width: '95%',
              backgroundColor: 'black',
            }}
          />
          <View style={{ ...styles.table, marginBottom: 10 }}>
            <View style={{ ...styles.tableRow }}>
              <View
                style={{
                  ...styles.tableCell,
                  width: '30%',
                  border: 0,
                }}
              >
                <Text
                  style={{
                    ...styles.headerText,
                    textDecoration: 'underline',
                    marginLeft: 10,
                  }}
                >
                  UNPAID SHIPBOARD PAY:
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '30%',
                }}
              />
              <View
                style={{
                  ...styles.tableCell,
                  width: '15%',
                  textAlign: 'center',
                }}
              >
                <Text
                  style={{
                    ...styles.headerText,
                  }}
                >
                  US $
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '25%',
                  textAlign: 'center',
                }}
              >
                <Text
                  style={{
                    ...styles.headerText,
                  }}
                >
                  PHP
                </Text>
              </View>
            </View>
            {payslipDetails?.details?.map((item, ind) => (
              <View key={ind} style={{ ...styles.tableRow }}>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '6%',
                  }}
                />
                <View
                  style={{
                    ...styles.tableCell,
                    width: '25%',
                    border: 0,
                  }}
                >
                  <Text
                    style={{
                      ...styles.detailsText,
                    }}
                  >
                    {item?.desc}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '25%',
                    border: 0,
                  }}
                >
                  <Text
                    style={{
                      ...styles.detailsText,
                    }}
                  >
                    {`US $ ${parseValue(item?.usd_amt)} @ ${item?.days} days`}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '15%',
                    border: 0,
                    textAlign: 'right',
                    borderBottom:
                      payslipDetails?.details?.length == ind + 1 ? 1 : 0,
                  }}
                >
                  <Text
                    style={{
                      ...styles.detailsText,
                    }}
                  >
                    {parseValue(item?.computed)}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '20%',
                    border: 0,
                    textAlign: 'right',
                    borderBottom:
                      payslipDetails?.details?.length == ind + 1 ? 1 : 0,
                  }}
                >
                  <Text
                    style={{
                      ...styles.detailsText,
                    }}
                  >
                    {parseValue(item?.php_amt)}
                  </Text>
                </View>
              </View>
            ))}
          </View>

          <View style={{ ...styles.table, marginBottom: 5 }}>
            <View style={{ ...styles.tableRow }}>
              <View
                style={{
                  ...styles.tableCell,
                  width: '35%',
                  border: 0,
                }}
              >
                <Text
                  style={{
                    ...styles.headerText,
                    textDecoration: 'underline',
                    marginLeft: 10,
                  }}
                >
                  SUNDRIES AND ADJUSTMENTS:
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '25%',
                }}
              />
              <View
                style={{
                  ...styles.tableCell,
                  width: '15%',
                  textAlign: 'center',
                }}
              >
                <Text
                  style={{
                    ...styles.headerText,
                  }}
                >
                  US $
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '25%',
                  textAlign: 'center',
                }}
              >
                <Text
                  style={{
                    ...styles.headerText,
                  }}
                >
                  PHP
                </Text>
              </View>
            </View>
            {payslipDetails?.wages?.map((item, ind) => (
              <View key={ind} style={{ ...styles.tableRow }}>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '6%',
                  }}
                />
                <View
                  style={{
                    ...styles.tableCell,
                    width: '25%',
                    border: 0,
                  }}
                >
                  <Text
                    style={{
                      ...styles.detailsText,
                    }}
                  >
                    {item?.desc}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '25%',
                    border: 0,
                  }}
                >
                  <Text
                    style={{
                      ...styles.detailsText,
                    }}
                  >
                    {item?.details}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '15%',
                    border: 0,
                    textAlign: 'right',
                  }}
                >
                  <Text
                    style={{
                      ...styles.detailsText,
                    }}
                  >
                    {parseValue(item?.usd_amt)}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '20%',
                    border: 0,
                    textAlign: 'right',
                  }}
                >
                  <Text
                    style={{
                      ...styles.detailsText,
                    }}
                  >
                    {parseValue(item?.php_amt)}
                  </Text>
                </View>
              </View>
            ))}
            {payslipDetails?.addWages?.map((item, ind) => (
              <View key={ind} style={{ ...styles.tableRow }}>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '6%',
                  }}
                />
                <View
                  style={{
                    ...styles.tableCell,
                    width: '25%',
                    border: 0,
                  }}
                >
                  <Text
                    style={{
                      ...styles.detailsText,
                    }}
                  >
                    {item?.desc}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '25%',
                    border: 0,
                  }}
                />
                <View
                  style={{
                    ...styles.tableCell,
                    width: '15%',
                    border: 0,
                    textAlign: 'right',
                    borderBottom:
                      payslipDetails?.addWages?.length == ind + 1 ? 1 : 0,
                  }}
                >
                  <Text
                    style={{
                      ...styles.detailsText,
                    }}
                  >
                    {parseValue(item?.usd_amt)}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '20%',
                    border: 0,
                    textAlign: 'right',
                    borderBottom:
                      payslipDetails?.addWages?.length == ind + 1 ? 1 : 0,
                  }}
                >
                  <Text
                    style={{
                      ...styles.detailsText,
                    }}
                  >
                    {parseValue(item?.php_amt)}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View style={{ ...styles.table, marginBottom: 10 }}>
            <View style={{ ...styles.tableRow }}>
              <View
                style={{
                  ...styles.tableCell,
                  width: '41%',
                }}
              />
              <View
                style={{
                  ...styles.tableCell,
                  width: '15%',
                }}
              >
                <Text
                  style={{
                    ...styles.headerText,
                  }}
                >
                  {`Gross Pay ==>`}
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '15%',
                  border: 0,
                  textAlign: 'right',
                  borderBottom: 1,
                }}
              >
                <Text
                  style={{
                    ...styles.detailsText,
                  }}
                >
                  {parseValue(
                    getCalculatedFromFields('details', 'computed') +
                      getCalculatedFromFields('wages', 'usd_amt') +
                      getCalculatedFromFields('addWages', 'usd_amt')
                  )}
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '20%',
                  border: 0,
                  textAlign: 'right',
                  borderBottom: 1,
                }}
              >
                <Text
                  style={{
                    ...styles.detailsText,
                  }}
                >
                  {parseValue(
                    getCalculatedFromFields('details', 'php_amt') +
                      getCalculatedFromFields('wages', 'php_amt') +
                      getCalculatedFromFields('addWages', 'php_amt')
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ ...styles.table, marginBottom: 5 }}>
            <View style={{ ...styles.tableRow }}>
              <View
                style={{
                  ...styles.tableCell,
                  width: '25%',
                  border: 0,
                }}
              >
                <Text
                  style={{
                    ...styles.headerText,
                    textDecoration: 'underline',
                    marginLeft: 10,
                  }}
                >
                  DEDUCTIONS:
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '35%',
                }}
              />
              <View
                style={{
                  ...styles.tableCell,
                  width: '15%',
                  textAlign: 'center',
                }}
              >
                <Text
                  style={{
                    ...styles.headerText,
                  }}
                >
                  US $
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '25%',
                  textAlign: 'center',
                }}
              >
                <Text
                  style={{
                    ...styles.headerText,
                  }}
                >
                  PHP
                </Text>
              </View>
            </View>
            {payslipDetails?.govContri?.map((item, ind) => (
              <View key={ind} style={{ ...styles.tableRow }}>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '6%',
                  }}
                />
                <View
                  style={{
                    ...styles.tableCell,
                    width: '25%',
                    border: 0,
                  }}
                >
                  <Text
                    style={{
                      ...styles.detailsText,
                    }}
                  >
                    {item?.desc}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '25%',
                    border: 0,
                  }}
                />
                <View
                  style={{
                    ...styles.tableCell,
                    width: '15%',
                    border: 0,
                    textAlign: 'right',
                    borderBottom:
                      payslipDetails?.govContri?.length == ind + 1 ? 1 : 0,
                  }}
                >
                  <Text
                    style={{
                      ...styles.detailsText,
                    }}
                  >
                    {parseValue(item?.usd_amt)}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCell,
                    width: '20%',
                    border: 0,
                    textAlign: 'right',
                    borderBottom:
                      payslipDetails?.govContri?.length == ind + 1 ? 1 : 0,
                  }}
                >
                  <Text
                    style={{
                      ...styles.detailsText,
                    }}
                  >
                    {parseValue(item?.php_amt)}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View style={{ ...styles.table, marginBottom: 5 }}>
            <View style={{ ...styles.tableRow }}>
              <View
                style={{
                  ...styles.tableCell,
                  width: '31%',
                }}
              />
              <View
                style={{
                  ...styles.tableCell,
                  width: '25%',
                }}
              >
                <Text
                  style={{
                    ...styles.headerText,
                  }}
                >
                  {`Less Total Deductions ==>`}
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '15%',
                  border: 0,
                  textAlign: 'right',
                  borderBottom: 1,
                }}
              >
                <Text
                  style={{
                    ...styles.detailsText,
                  }}
                >
                  {parseValue(getCalculatedFromFields('govContri', 'usd_amt'))}
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '20%',
                  border: 0,
                  textAlign: 'right',
                  borderBottom: 1,
                }}
              >
                <Text
                  style={{
                    ...styles.detailsText,
                  }}
                >
                  {parseValue(getCalculatedFromFields('govContri', 'php_amt'))}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ ...styles.table, marginBottom: 10 }}>
            <View style={{ ...styles.tableRow }}>
              <View
                style={{
                  ...styles.tableCell,
                  width: '16%',
                }}
              />
              <View
                style={{
                  ...styles.tableCell,
                  width: '40%',
                }}
              >
                <Text
                  style={{
                    ...styles.headerText,
                  }}
                >
                  {`NET AMOUNT DUE / (COLLECTIBLE) ==>`}
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '15%',
                  border: 0,
                  textAlign: 'right',
                  borderBottom: 1,
                  paddingHorizontal: 0,
                }}
              >
                <Text
                  style={{
                    ...styles.detailsText,
                    borderBottom: 1,
                    fontWeight: 'bold',
                    marginBottom: 1,
                  }}
                >
                  {parseValue(
                    getCalculatedFromFields('details', 'computed') +
                      getCalculatedFromFields('wages', 'usd_amt') +
                      getCalculatedFromFields('addWages', 'usd_amt') -
                      getCalculatedFromFields('govContri', 'usd_amt')
                  )}
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '20%',
                  border: 0,
                  textAlign: 'right',
                  fontWeight: '700',
                  borderBottom: 1,
                  paddingHorizontal: 0,
                }}
              >
                <Text
                  style={{
                    ...styles.detailsText,
                    borderBottom: 1,
                    marginBottom: 1,
                  }}
                >
                  {parseValue(
                    getCalculatedFromFields('details', 'php_amt') +
                      getCalculatedFromFields('wages', 'php_amt') +
                      getCalculatedFromFields('addWages', 'php_amt') -
                      getCalculatedFromFields('govContri', 'php_amt')
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.footer} wrap={false}>
            <View style={{ ...styles.bottomContainer }}>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Prepared By:{`\n\n_____________________\n`} {`        `}
                  Administrator
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Checked By:{`\n\n_____________________\n`} {`           `}
                  Accountant
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.legends}>
                  Approved By:{`\n\n_____________________\n`} {`             `}
                  President
                </Text>
              </View>
            </View>
          </View>
          <Text
            style={{ ...styles.pageNumber, textAlign: 'right' }}
            render={({ pageNumber, totalPages }) =>
              `Page: ${pageNumber} / ${totalPages}`
            }
            fixed
          />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `Date printed: [${dayjs().format('YYYY-MM-DD HH:mm:ss')}]`
            }
            fixed
          />
        </Page>
      </Document>
    </PDFViewer>
  );
}
