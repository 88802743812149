import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import React, { useContext } from 'react';
import logoImage from '../../assets/icon-name.png';
import { UserContext } from '../../utils/context/UserContext';
import VesselSetupAllotmentType from '../../utils/types/VesselSetupAllotmentType';
import dayjs from 'dayjs';

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 10,
    paddingBottom: 40,
  },
  container: {
    flex: 1,
    alignItems: 'center',
  },
  headerImage: {
    height: 60,
    width: 60,
  },
  headerText: {
    fontSize: 10,
  },
  parametersText: {
    fontSize: 8,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
  },
  tableRow: {
    flexDirection: 'row',
    borderTopWidth: 0,
    paddingBottom: 0,
  },
  tableCell: {
    width: '6%',
    borderWidth: 0,
    paddingTop: 5,
    paddingBottom: 0,
    paddingHorizontal: 1,
    fontSize: '6px',
    marginBottom: 0,
  },

  pageNumber: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 10,
    fontSize: 6,
    textAlign: 'left',
    paddingHorizontal: 10,
  },
  legends: {
    //   position: 'absolute',
    //   bottom: 30,
    //   left: 80,
    //   right: 0,
    fontSize: 8,
    textAlign: 'left',
  },

  bottomContainer: { flexDirection: 'row', margin: 0, padding: 0 },
  column: {
    flex: 1,
    margin: 0,
    marginBottom: 5,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    // borderTopWidth: 1,
    // textAlign: 'center',
    // backgroundColor: 'lightgray',
    paddingTop: 10,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
});

export default function AllotmentConfirmationPDF({
  headerDetails = VesselSetupAllotmentType,
  crewList,
  month,
  year,
  grandTotal,
}) {
  const { inputToLocale } = useContext(UserContext);

  return (
    <PDFViewer style={{ width: '98%', height: '100vh' }}>
      <Document>
        <Page size='A4' style={styles.page} wrap>
          <View
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              alignItems: 'center',
              marginBottom: 10,
            }}
            fixed
          >
            <Image src={logoImage} style={styles.headerImage} />

            <Text style={styles.headerText}>
              ALLOTMENT CONFIRMATION LIST - ON BOARD CREW
            </Text>
            <Text style={styles.headerText}>
              {dayjs(`${month}-${year}`, 'M-YYYY').format('MMMM YYYY')}
            </Text>
          </View>
          <View style={{ ...styles.bottomContainer }}>
            <View
              style={{
                ...styles.column,
                marginLeft: 0,
              }}
            >
              <Text style={styles.parametersText}>
                VESSEL NAME:{' '}
                {`${headerDetails.vessel_name} {${headerDetails.vessel_code}}`}
              </Text>
            </View>
            <View
              style={{
                ...styles.column,
                marginLeft: 0,
              }}
            >
              <Text style={styles.parametersText}>
                GROUP: {headerDetails.group}
              </Text>
            </View>
          </View>
          <Text style={{ ...styles.parametersText, marginBottom: 5 }}>
            PARAMETERS:
          </Text>
          <View style={{ ...styles.tableRow }}>
            <View style={{ ...styles.tableCell, width: '30%' }}>
              <Text style={styles.parametersText}>
                1. Divisor (no. of days)
              </Text>
            </View>
            <View style={{ ...styles.tableCell, width: '5%' }}>
              <Text style={styles.parametersText}>:</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                width: '5%',
              }}
            >
              <Text style={styles.parametersText}>
                {headerDetails.no_of_days}
              </Text>
            </View>

            <View style={{ ...styles.tableCell, width: '35%' }}>
              <Text style={styles.parametersText}>
                9. Pag-Ibig Contribution
              </Text>
            </View>
            <View style={{ ...styles.tableCell, width: '5%' }}>
              <Text style={styles.parametersText}>:</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                width: '20%',
              }}
            >
              <Text style={styles.parametersText}>
                {headerDetails.is_pagibig_member == 1
                  ? 'With crew contribution'
                  : headerDetails.is_pagibig_member == 0
                  ? 'Without crew contribution'
                  : ''}
              </Text>
            </View>
          </View>
          <View style={{ ...styles.tableRow }}>
            <View style={{ ...styles.tableCell, width: '30%' }}>
              <Text style={styles.parametersText}>
                2. Home Allotment Cut-off Day
              </Text>
            </View>
            <View style={{ ...styles.tableCell, width: '5%' }}>
              <Text style={styles.parametersText}>:</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                width: '5%',
              }}
            >
              <Text style={styles.parametersText}>
                {headerDetails.allotment_cut_off}
              </Text>
            </View>

            <View style={{ ...styles.tableCell, width: '35%' }}>
              <Text style={styles.parametersText}>10. Union Type</Text>
            </View>
            <View style={{ ...styles.tableCell, width: '5%' }}>
              <Text style={styles.parametersText}>:</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                width: '20%',
              }}
            >
              <Text style={styles.parametersText}>
                {headerDetails.union_dues_desc}
              </Text>
            </View>
          </View>
          <View style={{ ...styles.tableRow }}>
            <View style={{ ...styles.tableCell, width: '30%' }}>
              <Text style={styles.parametersText}>
                3. Round-up Pro-rate Allotment
              </Text>
            </View>
            <View style={{ ...styles.tableCell, width: '5%' }}>
              <Text style={styles.parametersText}>:</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                width: '5%',
              }}
            >
              <Text style={styles.parametersText}>
                {headerDetails.round_pro_rate_allotment == 1 ? 'Yes' : ''}
              </Text>
            </View>

            <View style={{ ...styles.tableCell, width: '35%' }}>
              <Text style={styles.parametersText}>
                11. Allow pro-rate computation of government deductions
              </Text>
            </View>
            <View style={{ ...styles.tableCell, width: '5%' }}>
              <Text style={styles.parametersText}>:</Text>
            </View>
            <View style={{ ...styles.tableCell, width: '20%' }}>
              <Text style={styles.parametersText}>
                {headerDetails.pro_rate_computations_gov_deduc == 1
                  ? 'Yes'
                  : headerDetails.pro_rate_computations_gov_deduc == 0
                  ? 'No'
                  : ''}
              </Text>
            </View>
          </View>
          <View style={{ ...styles.tableRow }}>
            <View style={{ ...styles.tableCell, width: '30%' }}>
              <Text style={styles.parametersText}>
                4. Round down Pro-rate Allotment
              </Text>
            </View>
            <View style={{ ...styles.tableCell, width: '5%' }}>
              <Text style={styles.parametersText}>:</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                width: '5%',
              }}
            >
              <Text style={styles.parametersText}>
                {headerDetails.round_pro_rate_allotment == 2 ? 'Yes' : ''}
              </Text>
            </View>
          </View>
          <View style={{ ...styles.tableRow }}>
            <View style={{ ...styles.tableCell, width: '30%' }}>
              <Text style={styles.parametersText}>
                5. Round up Percentage Allotment
              </Text>
            </View>
            <View style={{ ...styles.tableCell, width: '5%' }}>
              <Text style={styles.parametersText}>:</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                width: '5%',
              }}
            >
              <Text style={styles.parametersText}>
                {headerDetails.round_percentage_allotment == 1 ? 'Yes' : ''}
              </Text>
            </View>
            <View style={{ ...styles.tableCell, width: '60%' }} />
          </View>
          <View style={{ ...styles.tableRow }}>
            <View style={{ ...styles.tableCell, width: '30%' }}>
              <Text style={styles.parametersText}>
                6. Round down Percentage Allotment
              </Text>
            </View>
            <View style={{ ...styles.tableCell, width: '5%' }}>
              <Text style={styles.parametersText}>:</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                width: '5%',
              }}
            >
              <Text style={styles.parametersText}>
                {headerDetails.round_percentage_allotment == 2 ? 'Yes' : ''}
              </Text>
            </View>
            <View style={{ ...styles.tableCell, width: '60%' }} />
          </View>
          <View style={{ ...styles.tableRow }}>
            <View style={{ ...styles.tableCell, width: '80%' }}>
              <Text style={styles.parametersText}>
                7. Include after Cut-Off pro-rate computation to the following
                month with pro-rate government contribution
              </Text>
            </View>
            <View style={{ ...styles.tableCell, width: '5%' }}>
              <Text style={styles.parametersText}>:</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                width: '5%',
              }}
            >
              <Text style={styles.parametersText}>
                {headerDetails.cut_off_pro_rate == 1 ? 'Yes' : ''}
              </Text>
            </View>
          </View>
          <View style={{ ...styles.tableRow }}>
            <View style={{ ...styles.tableCell, width: '80%' }}>
              <Text style={styles.parametersText}>
                8. Exclude after Cut-Off pro-rate computation to the following
                month but include government contribution based on Basic Salary
              </Text>
            </View>
            <View style={{ ...styles.tableCell, width: '5%' }}>
              <Text style={styles.parametersText}>:</Text>
            </View>
            <View
              style={{
                ...styles.tableCell,
                width: '5%',
              }}
            >
              <Text style={styles.parametersText}>
                {headerDetails.cut_off_pro_rate == 2 ? 'Yes' : ''}
              </Text>
            </View>
          </View>
          <View style={{ ...styles.table, marginTop: 5 }} fixed>
            <View
              style={{
                ...styles.tableRow,
                borderTopWidth: 1,
                borderBottomWidth: 1,
                paddingBottom: 2,
              }}
            >
              <View style={{ ...styles.tableCell, width: '2%' }}>
                <Text>SEQ.</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '2%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '25%' }}>
                <Text>CREW NAME</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text>RANK</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text>SIGN-ON DATE</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text>REPAT DATE</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text>BASIC PAY</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '15%' }}>
                <Text>ALLOTMENT SCHEME</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text>ALLOTMENT</Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text>CUT-OFF DATE</Text>
              </View>
            </View>
            {crewList?.map((record, index) => (
              <View key={`${index}-a-${index}`} style={styles.tableRow}>
                <View style={{ ...styles.tableCell, width: '2%' }}>
                  <Text>{index + 1}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: '2%' }}>
                  <Text>
                    {dayjs(record.sign_off_date, 'YYYY-MM-DD').month() + 1 ===
                      dayjs(`${year}-${month}`).month() + 1 &&
                    dayjs(record.sign_off_date, 'YYYY-MM-DD').year() ===
                      dayjs(`${year}-${month}`).year() ? (
                      <>..</>
                    ) : (
                      <></>
                    )}
                  </Text>
                </View>
                <View style={{ ...styles.tableCell, width: '25%' }}>
                  <Text>{`${record.last_name}, ${record.first_name} ${record.middle_name}`}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: '10%' }}>
                  <Text>{record.position_name}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: '10%' }}>
                  <Text>{record.embark_date}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: '10%' }}>
                  <Text>{record.sign_off_date}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: '15%' }}>
                  <Text>
                    {record.basic_pay > 0
                      ? `${inputToLocale(record.basic_pay)}`
                      : ''}
                  </Text>
                </View>
                <View style={{ ...styles.tableCell, width: '10%' }}>
                  <Text>
                    {inputToLocale(record.allotment.salary_for_allotment)}
                  </Text>
                </View>
                <View style={{ ...styles.tableCell, width: '10%' }}>
                  <Text>{record.allotment.allotment_amount}</Text>
                </View>
                <View style={{ ...styles.tableCell, width: '10%' }}>
                  <Text>
                    {record.crew_cut_off !== 0 ? record.crew_cut_off : ''}
                  </Text>
                </View>
              </View>
            ))}
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCell, width: '2%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '2%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '25%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '15%' }}>
                <Text></Text>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text></Text>
              </View>
              <View
                style={{
                  ...styles.tableCell,
                  width: '10%',
                  borderTopWidth: 1,
                  borderBottomWidth: 1,
                  padding: 0,
                  margin: 0,
                }}
              >
                <View style={{ borderBottomWidth: 1, marginBottom: 1 }}>
                  <Text>{inputToLocale(grandTotal)}</Text>
                </View>
              </View>
              <View style={{ ...styles.tableCell, width: '10%' }}>
                <Text></Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
