import dayjs from 'dayjs';
import PhilhealthContributionType from '../types/PhilhealthContributionType';
import PagibigContributionType from '../types/PagibigContributionType';
import CrewWagesType from '../types/CrewWagesType';

export const getCrewWagesMaintenanceFields = (
  isLoading,
  state = CrewWagesType,
  setState,
  setHasChanges
) => {
  return [
    {
      type: 'text',
      name: 'salary_code',
      placeholder: 'Salary Code',
      label: 'Salary Code',
      autofocus: true,
      required: false,
      disabled: true,
      value: state.salary_code,
      setValue: (e) => {
        setState({ ...state, salary_code: e.target.value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'wage_desc',
      placeholder: 'Description',
      label: 'Description',
      autofocus: false,
      required: false,
      disabled: true,
      value: state.wage_desc,
      setValue: (e) => {
        setState({ ...state, wage_desc: e.target.value });
        setHasChanges(true);
      },
    },
    {
      type: 'dropdown',
      name: 'wage_type',
      placeholder: 'Wage Type',
      label: 'Wage Type',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.wage_type,
      dropdownOptions: [
        { id: 'earnings', description: 'Earnings' },
        // { id: 'deductions', description: 'Deductions' },
        { id: 'leave', description: 'Accrued' },
      ],
      setValue: (value, option) => {
        setState({ ...state, wage_type: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'wage_other_desc',
      placeholder: 'Wage other description',
      label: 'Wage other description',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.wage_other_desc,
      setValue: (e) => {
        setState({ ...state, wage_other_desc: e.target.value });
        setHasChanges(true);
      },
    },
    {
      type: 'checkbox',
      name: 'is_travel_days_paid',
      label: 'Compute in travel days',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.is_travel_days_paid == 1 ? true : false,
      setValue: (e) => {
        const checked = e.target.checked;
        setState({ ...state, is_travel_days_paid: checked ? 1 : 0 });
        setHasChanges(true);
      },
    },
  ];
};
