import axios from 'axios';
import { BASE_ENDPOINT, REQUEST_HEADERS } from './_constants';

const POST_BANK_DOWNLOADING_ENDPONT = BASE_ENDPOINT + '/bank_download';

export async function postBankDownload(payload) {
  try {
    const { data } = await axios.post(POST_BANK_DOWNLOADING_ENDPONT, payload, {
      headers: REQUEST_HEADERS,
    });
    return { data };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
