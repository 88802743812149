export default {
  id: null,
  crew_no: null,
  crew_name: null,
  transdate: null,
  startdeduct: null,
  currency: null,
  amount: null,
  amounttoded: null,
  remaining_deduc: null,
  remarks: null,
  refno: null,
  stoppayment: null,
  od_id: null,
  od_desc: null,
  od_code: null,
  other_deduction_type: null,
  assign_to: [
    {
      allotte_othd_id: null,
      allottee_id: null,
      allottee_name: null,
      accountno: null,
      assign: null,
      amount: null,
      bankname: null,
      api_allottee_amount: null,
    },
  ],
};
