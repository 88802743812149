import { Button, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';

export function getRemainingWagesEntryTable(handleAction) {
  return [
    {
      title: 'Code',
      dataIndex: 'deduction_id',
      key: 'deduction_id',
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
    },
    {
      title: 'Amount',
      dataIndex: 'deduction_amount',
      key: 'deduction_amount',
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record, index) => (
        <>
          <Button
            type="primary"
            className="me-2"
            icon={<AiFillEdit size={15} />}
            onClick={() => {
              handleAction('edit', record);
            }}
          />
          <Popconfirm
            title="Delete record"
            description="Are you sure to remove this record?"
            onConfirm={() => {
              handleAction('delete', index);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button danger icon={<AiFillDelete size={15} />} />
          </Popconfirm>
        </>
      ),
    },
  ];
}
