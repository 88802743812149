import { Button, DatePicker, Input, Popconfirm, Space, Switch } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { AiFillDelete, AiFillEdit, AiFillPrinter } from 'react-icons/ai';
import { FaEye, FaLock, FaLockOpen, FaWrench } from 'react-icons/fa';
import { MdLockReset } from 'react-icons/md';

export function getUserSetupTable(handleAction) {
  return [
    {
      title: 'ID',
      dataIndex: 'user_id',
      key: 'user_id',
      sorter: (a, b) => a.user_id - b.user_id,
    },
    {
      title: 'Username',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
    },
    {
      title: 'Level',
      dataIndex: 'leveldesc',
      key: 'leveldesc',
      sorter: (a, b) => a.leveldesc.localeCompare(b.leveldesc),
    },
    {
      title: 'Last login',
      dataIndex: 'last_login',
      key: 'last_login',
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button
            danger={record.is_active == 1}
            onClick={() => handleAction(record, 'action')}
            icon={
              record.is_active == 1 ? (
                <FaLock size={15} />
              ) : (
                <FaLockOpen size={15} />
              )
            }
          />
          <Button
            type='primary'
            onClick={() => handleAction(record, 'view')}
            icon={<FaEye size={15} />}
          />
          <Button
            type='primary'
            onClick={() => handleAction(record, 'edit')}
            icon={<FaWrench size={15} />}
          />
          <Popconfirm
            title='Delete user'
            description='Please confirm your action?'
            onConfirm={() => handleAction(record, 'delete')}
            okText='Yes'
            cancelText='No'
          >
            <Button danger icon={<AiFillDelete size={15} />} />
          </Popconfirm>
          <Popconfirm
            title='Unban user'
            description='Please confirm your action?'
            onConfirm={() => handleAction(record, 'unban')}
            okText='Yes'
            cancelText='No'
          >
            <Button
              danger
              disabled={parseInt(record.bantime) < 3 || record.bantime == ''}
              icon={<MdLockReset size={15} />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
}
