export default {
  id: null,
  government_contribution_id: null,
  year: null,
  sal_range_from: null,
  sal_range_to: null,
  sal_bracket: null,
  employee_share: null,
  employer_share: null,
  total_contri: null,
  is_percentage: null,
  is_deleted: null,
  delete_by: null,
  created_at: null,
  updated_at: null,
};
