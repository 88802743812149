import DeductionType from '../types/DeductionType';

export const getDeductionFields = (
  isLoading,
  state = DeductionType,
  setState,
  setHasChanges
) => {
  return [
    {
      type: 'text',
      name: 'code',
      placeholder: 'Code',
      label: 'Code',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: state.code,
      setValue: (e) => {
        const value = e.target.value.toUpperCase();
        setState({ ...state, code: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'desc',
      placeholder: 'Description',
      label: 'Description',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: state.desc,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, desc: value });
        setHasChanges(true);
      },
    },
    {
      type: 'dropdown',
      name: 'type',
      placeholder: 'Type',
      label: 'Type',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: state.type,
      dropdownOptions: [
        { id: 'sss', description: 'SSS' },
        { id: 'philhealth', description: 'PhilHealth' },
        { id: 'pag_ibig', description: 'PAGIBIG' },
        { id: 'union', description: 'Union' },
      ],
      setValue: (value, option) => {
        if (value === 'sss') {
          setState({
            ...state,
            type: value,
            sss: 1,
            philhealth: 0,
            pag_ibig: 0,
            union: 0,
          });
        } else if (value === 'philhealth') {
          setState({
            ...state,
            type: value,
            sss: 0,
            philhealth: 1,
            pag_ibig: 0,
            union: 0,
          });
        } else if (value === 'pag_ibig') {
          setState({
            ...state,
            type: value,
            sss: 0,
            philhealth: 0,
            pag_ibig: 1,
            union: 0,
          });
        } else if (value === 'union') {
          setState({
            ...state,
            type: value,
            sss: 0,
            philhealth: 0,
            pag_ibig: 0,
            union: 1,
          });
        }
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'acct_code',
      placeholder: 'Account Code',
      label: 'Account Code',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.acct_code,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, acct_code: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'sl_acct_code',
      placeholder: 'SL Account Code',
      label: 'SL Account Code',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.sl_acct_code,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, sl_acct_code: value });
        setHasChanges(true);
      },
    },
    {
      type: 'text',
      name: 'expense_acct_code',
      placeholder: 'Expense Account Code',
      label: 'Expense Account Code',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: state.expense_acct_code,
      setValue: (e) => {
        const value = e.target.value;
        setState({ ...state, expense_acct_code: value });
        setHasChanges(true);
      },
    },
  ];
};
