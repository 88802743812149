import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import CrewType from '../../../../../utils/types/CrewType';
import {
  getAllDeductions,
  getScheduleOfDeduction,
  postAddScheduleOfDeduction,
} from '../../../../../utils/api/DeductionsAPI';
import { UserContext } from '../../../../../utils/context/UserContext';
import { GlobalContext } from '../../../../../App';
import { Table } from 'antd';
import { getScheduleOfDeductionTable } from '../../../../../utils/tableheader/Transactions/ScheduleOfDeductionsTable';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../../../../utils/api/_constants';
import ConfirmationModal from '../../../../../components/CustomModal/ConfirmationModal';

export default function EmbarkationScheduleOfDeductionsPage({
  selectedCrew = CrewType,
  isLoading,
  setIsLoading,
  hasChanges,
  setHasChanges,
  fetchDatas,
}) {
  const { inputToLocale } = useContext(UserContext);
  const { openNotification } = useContext(GlobalContext);

  const [screenLoading, setScreenLoading] = useState(false);

  const [tableContent, setTableContent] = useState([]);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedDeduction, setSelectedDeduction] = useState(null);

  const fetchDeductionsList = async () => {
    setScreenLoading(true);
    await Promise.all([
      getAllDeductions(),
      getScheduleOfDeduction(selectedCrew.crew_no),
    ])
      .then((response) => {
        const contents = [];
        const deductionList = response[0].data;

        selectedCrew.allotment?.allottee?.map((allottee, index) => {
          const configuredAllottee = response[1].data.filter(
            (record) =>
              record.allt_on_board_crew_id == allottee.crew_allottee_id
          );

          if (configuredAllottee.length > 0) {
            const tableEntry = deductionList.map((deduction, deducIndex) => {
              const configured = configuredAllottee.find(
                (rec) => rec.code == deduction.code
              );

              if (configured) {
                return {
                  ...configured,
                  key: `a-${deducIndex}${index}-b`,
                  deduction_id: deduction.id,
                  deduction_code: configured.code,
                  description: configured.desc,
                  account_no: allottee.acct_no,
                  last_name: allottee.last_name,
                  first_name: allottee.first_name,
                  middle_name: allottee.middle_name,
                  is_regular_allotment: configured.regular_allotment,
                  is_side_letter_bonus: configured.side_letter_bonus,
                  is_mpo: configured.mpo,
                  final_pay: configured.final_pay,
                  exclude_prev_month: configured.exclude_prev_month,
                };
              } else {
                return {
                  key: `ac-${deducIndex}${index}-a2`,
                  allt_on_board_crew_id: allottee.crew_allottee_id,
                  deduction_code: deduction.code,
                  deduction_id: deduction.id,
                  description: deduction.desc,
                  account_no: allottee.acct_no,
                  last_name: allottee.last_name,
                  first_name: allottee.first_name,
                  middle_name: allottee.middle_name,
                  is_regular_allotment:
                    allottee.regular_allotment.applied_deduction,
                  is_side_letter_bonus: allottee.side_letter.applied_deduction,
                  is_mpo: allottee.mpo.applied_deduction,
                  final_pay: 0,
                  exclude_prev_month: 0,
                };
              }
            });

            for (let index = 0; index < tableEntry.length; index++) {
              contents.push(tableEntry[index]);
            }
          } else {
            if (
              allottee.regular_allotment.applied_deduction == 1 ||
              allottee.side_letter.applied_deduction == 1 ||
              allottee.mpo.applied_deduction == 1
            ) {
              for (let i = 0; i < deductionList.length; i++) {
                const deduction = deductionList[i];
                contents.push({
                  key: `a-${i}${index}`,
                  allt_on_board_crew_id: allottee.crew_allottee_id,
                  deduction_id: deduction.id,
                  deduction_code: deduction.code,
                  description: deduction.desc,
                  account_no: allottee.acct_no,
                  last_name: allottee.last_name,
                  first_name: allottee.first_name,
                  middle_name: allottee.middle_name,
                  // is_regular_allotment_applied_deduction:
                  //   allottee.regular_allotment.applied_deduction,
                  // is_side_letter_bonus_applied_deduction:
                  //   allottee.side_letter.applied_deduction,
                  // is_mpo_applied_deduction: allottee.mpo.applied_deduction,
                  is_regular_allotment:
                    allottee.regular_allotment.applied_deduction,
                  is_side_letter_bonus: allottee.side_letter.applied_deduction,
                  is_mpo: allottee.mpo.applied_deduction,
                  final_pay: 0,
                  exclude_prev_month: 0,
                });
              }
            }
          }
        });
        setTableContent(contents);
      })
      .catch((error) => console.error(error));

    setScreenLoading(false);
  };

  const onRecordChange = async (record) => {
    const payload = {
      ...record,
      vescode: selectedCrew.vessel_id,
      crew_no: selectedCrew.crew_no,
      middle_initial: record.middle_name,
      regular_allotment: record.is_regular_allotment,
      side_letter_bonus: record.is_side_letter_bonus,
      mpo: record.is_mpo,
    };

    setSelectedDeduction(payload);
    setIsConfirmationModalOpen(true);
  };

  useEffect(() => {
    fetchDeductionsList();
  }, [selectedCrew]);

  return (
    <Content>
      <Table
        columns={getScheduleOfDeductionTable(onRecordChange)}
        dataSource={tableContent}
        loading={isLoading || screenLoading}
        size="small"
        bordered="true"
        scroll={{ x: true }}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} records`,
        }}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onCancel={async () => {
          fetchDeductionsList();
          setIsConfirmationModalOpen(false);
        }}
        onConfirm={async () => {
          if (selectedDeduction) {
            const { data: res, error } = await postAddScheduleOfDeduction(
              selectedDeduction
            );
            if (error) {
              openNotification(
                ERROR_NOTIFICATION_TYPE,
                'Schedule of deduction',
                'Failed updating deduction'
              );
            } else {
              openNotification(
                SUCCESS_NOTIFICATION_TYPE,
                'Schedule of deduction',
                'Success updating deduction'
              );
              setIsConfirmationModalOpen(false);
              fetchDeductionsList();
            }
          }
        }}
      />
    </Content>
  );
}
